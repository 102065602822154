import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation,TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const JMC=()=>{
    const progHiglight=[
{listName:'A comprehensive curriculum that blends theory and practice, covering a wide range of topics including journalism ethics, multimedia storytelling, digital media production, and media law along with ability enhancement, skill enhancement, value added, and vocational courses'},
{listName:'Students engage in experiential learning opportunities, including internships, fieldwork, and collaborative projects, providing real-world experience and networking opportunities.'},
{listName:'Tailor your education to your interests with specialized tracks in areas such as investigative journalism, digital media production, public relations, or media studies.'},
{listName:'Emphasizing ethical responsibility and critical thinking, our program prepares students to navigate the complexities of the media landscape with integrity and professionalism.'},
{listName:'Benefit from our strong relationships with media organizations, industry professionals, and alumni, providing valuable networking opportunities and potential career pathways.'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Tailored training programs to prepare students for placement opportunities'},
{listName:'Flexible credit system enabling students to pursue multidisciplinary courses'},
{listName:'Engaged students in community service, outreach programs, and social initiatives to foster a sense of social responsibility and civic engagement.'},
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹60200/- (Tuition Fee - ₹35000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹44200/- ( Tuition Fee – ₹35000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '60,200'
    const fee_2nd= '44,200'
    const eligibility='Passed Senior Secondary (10+2) or equivalent examination from any recognised board with 45% marks in aggregate.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="Bachelor of Arts (Journalism and Mass Communication)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    In an era defined by rapid information dissemination and evolving media landscapes, the role of journalists and communicators is more vital than ever. Our program is designed to equip students with the knowledge, skills, and ethical grounding necessary to thrive in the dynamic world of journalism and mass communication.
    <br/><br/>
Through a blend of theoretical exploration and practical application, students will delve into the core principles of journalism, communication theories, media ethics, and the latest technological advancements shaping the industry. With a focus on critical thinking, research proficiency, and storytelling prowess, graduates emerge ready to navigate the complexities of modern media environments.
<br/><br/>
This program fosters an environment of intellectual curiosity, creativity, and collaboration. Whether it's investigating breaking news stories, crafting compelling narratives, or leveraging digital platforms for effective communication, students are encouraged to explore their interests and develop their unique voices.
<br/><br/>
Beyond the classroom, students have access to internships, industry partnerships, and experiential learning opportunities that bridge theory and practice, preparing them for diverse career paths in journalism, broadcasting, public relations, digital media, and beyond.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Passed Senior Secondary (10+2) or equivalent examination from any recognised board with 45% marks in aggregate.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection of candidates will be determined by their performance in the MVNU Entrance Test, or their 12th-grade final examination scores
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Bachelor of Arts (Journalism and Mass Communication)
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default JMC;
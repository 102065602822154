import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";  

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-left"></i></button>
    );
}

function SlidebreadCrum() {

      
    const settings = {
        //     dots: true,
        //   infinite: true,
        //   speed: 500,
        //   slidesToShow: 1,
        //   slidesToScroll: 1
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        fade: true,
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
const sliderImg=[
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_01.jpg'},  
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_02.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_03.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_04.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_05.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_06.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_07.jpg'}, 
    /*{img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_08.jpg'}, */
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_09.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_10.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_11.jpg'}, 
    {img:'/assets/img/new_images/life-at-mvn/life_mvn_slider_12.jpg'},  
 

]

        return (
            <>
                <Slider className="slider-active" style={{ background: "#141b22" }} {...settings}>
                    {sliderImg.map((item)=>(
                        <div>
                        <img src={item.img} className='slider-height' />
                    </div>
                    ))}
                    
                </Slider>
                
            </>
        )

     
}

export default SlidebreadCrum
import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import BreadcrumbsBanner from './breadcrumbs';
import SOETHeader from './header';
import WidgetsMenu from '../muiCommonComponent/menuWidgets';
import NewMain from "../headerone/newMain";
function SOETKnowledgePartners() {
  const headerMenus = [
    { mainHeader: 'Home', path: '/school-of-engineering-technology', subHeader: [] },
    { mainHeader: 'SOET Insights', path: '', subHeader: [
        { title: 'Insights', path: '/soet/insights', },
        { title: 'Vision & Mission', path: '/soet/vision-mission', },
        { title: 'Knowledge Partners', path: '/soet/knowledge-partners', },
        { title: 'Pedagogical Advances – MOOCs', path: '/soet/mcoos', },
    ] },
    { mainHeader: 'SOET Departments', path: '', subHeader: [
        { title: 'Computer Science & Engineering', path: '/soet-cse/home', },
        { title: 'Mechanical Engineering', path: '/soet-me/home' },
        { title: 'Civil Engineering', path: '/soet-civil/home' },
        { title: 'Electronic & Communication Engineering', path: '/soet-ece/home' },
    ] },
    { mainHeader: 'MVN Times', path: '#', subHeader: [] },
    { mainHeader: 'Scholarly Work ', path: '#', subHeader: [] },
  ];
  return (<>
   <NewMain/>
    {/* <Navbar/> */}
    <WidgetsMenu headerMenus={headerMenus}/>
         <BreadcrumbsBanner bannerUrl={"https://3.imimg.com/data3/LW/IO/GLADMIN-9316260/content-images-all-services-knowlegdepartner-500x500.jpg"}/>
    <Container>
    <Grid container spacing={3}>
         {/* <Grid item xs={12} sm={12} md={12} lg={12} >
      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Knowledge Partners" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        In the ever-evolving landscape of Computer Science and Engineering, our strategic partnerships with industry leaders, innovative tech companies, and esteemed research institutions play a crucial role. These collaborations are the cornerstone of our commitment to enriching our academic programs, spearheading groundbreaking research, and equipping our students with the skills and experiences necessary to excel in their future careers. Knowledge partner help us to integrating cutting-edge technology, expert insights, and practical projects into our curriculum, that ensure community we remain at the forefront of technological progress. These symbiotic relationships empower our faculty, students, and partners to drive innovation and make significant contributions to the tech world.
        </Typography>
       </Grid>
      </Grid>
      </Container>
      </> )
}
export default SOETKnowledgePartners;

import React, { useEffect } from "react";

const ChatbotScript = () => {
  useEffect(() => {
    // Creating the script element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://chatbot.in8.nopaperforms.com/en-gb/backend/bots/niaachtbtscpt.js/e5ab011c69ea48d9b2eeb5116cca623f/320d65c303e3438583537c5e15611550";
    
    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this will run once after the component mounts

  return (
    <div className="npf_chatbots" data-w="320d65c303e3438583537c5e15611550" style={{ display: "none" }}></div>
  );
};

export default ChatbotScript;

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const AcademicButton = ({ path, pageName }) => {
    const navigate = useNavigate()
    const handleNavigate = (buttonName) => {
        navigate('/under-construction', { state: { title: buttonName, path: path, pageName: pageName } })
    }
    return (
        <div className="btn-group col-lg-12 col-md-6 col-sm-6">
            <button className='btn-course' onClick={() => handleNavigate('Grants')}>Grants</button>
            <button className='btn-course' onClick={() => handleNavigate('Consultancy')}>Consultancy</button>
            <button className='btn-course' onClick={() => handleNavigate('Patents')}>Patents</button>
            <button className='btn-course' onClick={() => handleNavigate('Publications')}>Publications</button>
            <button className='btn-course' onClick={() => handleNavigate('Technology Transfers')}>Technology Transfers</button>
            <button className='btn-course' onClick={() => handleNavigate('Collaborations')}>Collaborations</button>
            <button className='btn-course' onClick={() => handleNavigate('PhD Program')}>PhD Program</button>
            <button className='btn-course' onClick={() => handleNavigate('Team')}>Team</button>
        </div>
    )
}
export default AcademicButton;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSResearch=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Law" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Research & Development" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    We consider research and development to be essential foundations of our academic and professional philosophy. The faculty at our institution actively arranges research conferences and seminars, creating a dynamic forum for specialists, students, and professors to share innovative ideas and achievements in the field of law.
    <br/><br/>
Our commitment lies in continuously fostering the development of our teachers, guaranteeing their up-to-date knowledge of the most recent socio legal, technological, and pedagogical advancements. Our school engages in active collaboration with prominent legal persons, leaders, and academic institutions to undertake joint goals. The primary objective is to foster creativity and provide practical resolutions to real-world legal challenges. Our goal is to create a dynamic atmosphere where collaboration fosters innovation and education has a meaningful impact on society and the legal sphere.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSResearch;
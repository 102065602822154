import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const BBA=()=>{
    const progHiglight=[
{listName:'Bachelor of Business Administration (BBA) emphasizes on in-depth practical understanding of nuances of corporate management world through business workshops, analytical quizzes, case studies, reviews, and industry visits. '},
{listName:'Specialized coursework delves into advanced topics of managements for a wholistic understanding. '},
{listName:'Opportunity for internships and practical training to gain real-world experience in the field of management. '},
{listName:'AICTE model curriculum and Industry-oriented choice-based courses. Choice-based credit structure allows students to do interdisciplinary minors. '},
{listName:'Curriculum designed by Industry experts and reputed Academicians'},
{listName:'Skill-based training for enhancing employability skills through MOUs'},
{listName:'Special student grooming sessions and industry drives for placement.'},
{listName:'Opportunity for participation in Govt. funded projects/Consultancy/Start-ups'},
{listName:'Provision of Conference/Short-Term Training Programme/Workshops/Webinars/Online competition/Project competitions/Industry Internships'},
{listName:'Project and Experiential-based learning'},
{listName:'Opportunities for Higher Studies/Research in India/Abroad.'},
{listName:'Provision of up to 100% Scholarship.'},


      
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹75200/- (Tuition Fee - ₹50000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹59200/- ( Tuition Fee – ₹50000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '75,200'
    const fee_2nd= '59,200'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="BBA" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    MVN university's BBA program is one of the most sought-after Bachelor’s degree programs for students after Class 12. The MVN university BBA program is the gateway of job opportunities in business domains such as Sales, Marketing, Education, Finance, digital technologies, computational business analytics, human resources, business communication and Government sectors. Further elaborating, BBA of MVN university is a three-year professional Undergraduate course in Business Management. It is open to students from all three streams i.e., Science, Arts and Commerce. The BBA course offers knowledge in all domains and training in management and inculcate leadership qualities to excel in managerial roles and becoming entrepreneurs. The program pedagogy is oriented in such a manner to meet the aspirations of students from both rural and urban background. This program is cultivated to prepare today’s youth capable of being self-sustainability on one side and to excel at either employee or business portfolio on the other end. The curriculum encompasses all the required skill set desired at major corporates and business world. This program will endure the candidate to possess the knowledge of techno-commercial aspects of Indian Business Segment.
    <br/><br/>
The department constantly endeavours to contribute the best towards academic excellence and is committed towards making student experience the finest. We strongly believe in endorsing and nourishing an atmosphere where ideas are nurtured and shared, students are inspired and develop their skills in dynamic environment. We care for our students and seek their valuable suggestions all along to keep improvising. The students we serve are our greatest assets and we’re committed to make them successful. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.B.A.
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BBA;
import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Brand from '../../menhomethree/Brand'
import First from '../mencuet/First'

function Main() {
  return (
    <>
        <Header/>
        <Bredcom title="Home" subtitle="CUET 2023 Scholarship"/>
        <First/>
        {/* <Brand/> */}
    </>
  )
}

export default Main
import React from "react";
import { Grid } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import FacultyCard from "../../muiCommonComponent/facultyCards";

const FacultyList=()=>{
    const facultyList=[
      {img:'/assets/img/SSH/kuldeep.png',name:'Dr. Kuldeep Tanwar',position:'Associate Professor',email:'kuldeep.tanwar@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&amp;hl=en&amp;imq=Kuldeep+Tanwar#",
        linkedin: "https://www.linkedin.com/in/kuldeep-tanwar-",
        orcid: "https://orcid.org/0000-0002-0381-6043",
        rg: "https://www.researchgate.net/profile/Kuldeep-Tanwar-2",
        website: "#",
        aoi: "Operations Research",
        education: "Ph.D. (Mathematics)",
        re: "7",
        te: "15",
        iece: "N/A",
        articles:
          "<li>i. Tanwar K., Chauhan S. K. (2020), “Time-Cost Solution Pairs in Multi-Index Bulk Transportation Problem”. Communications in Computer and Information Science, Vol. 1230.</li><li> ii. Singh, S., Chauhan, S. K. and Kuldeep. (2018), “A Bi-Criteria Multi-Index Bulk Transportation Problem”. Annals of Pure and Applied Mathematics, Vol. 24.</li><li> iii. Tanwar, K. and Chauhan, S. K. (2021), “A Simple Algorithm for Fuzzy Time Minimizing Bulk Transportation Problem”. IEEE Transactions on Reliability, Infocom Technologies and Optimization.</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SSH/sagar.png',name:'Sagar',position:'Assistant Professor',email:'sagar.ash@mvn.edu.in', gs: "#",
          linkedin: "#",
          orcid: "#",
          rg: "#",
          website: "#",
          aoi: "N/A",
          education: "M.Sc(Mathematics), B.ed",
          re: "N/A",
          te: "1.5",
          iece: "N/A",
          articles:
            "N/A",
          books:
            "N/A",
          chapters:
            "N/A",
          patents:
            "N/A",
          membership:
            "N/A",
          aa1: "N/A",
          aa2: "",
          aa3: "",
          aa4: "",
          aa5: "",
          aa6: "",},
          {img:'/assets/img/SSH/vandana.png',name:'Dr. Vandna',position:'Assistant Professor',email:'Vandna.sharma@mvn.edu.in', gs: "#",
            linkedin: "#",
            orcid: "#",
            rg: "#",
            website: "#",
            aoi: "BJP’s manifestos comparison study",
            education: "M.A(Political science), M.Phil., B.ed., P.hd",
            re: "N/A",
            te: "10",
            iece: "N/A",
            articles:
              "<li>i. History of emerging China</li><li>ii. India China relation</li><li>iii. Right to Information 2005 importance and process</li><li>iv. Democracy meaning importance and process</li><li>v. Comparative study of working women</li>",
            books:
              "N/A",
            chapters:
              "Empowering Women To Lead Change",
            patents:
              "N/A",
            membership:
              "N/A",
            aa1: "2 nd rank in Haryana in Political Science",
            aa2: "College Topper in Political Science",
            aa3: "",
            aa4: "",
            aa5: "",
            aa6: "",},
        {img:'/assets/img/SSH/divya.png',name:'Divya Bharti',position:'Assistant Professor',email:'divya.bharti@mvn.edu.in', gs: "#",
          linkedin: "#",
          orcid: "#",
          rg: "#",
          website: "#",
          aoi: "N/A",
          education: "M.A, B.ed, D.El.Ed",
          re: "N/A",
          te: "1.5",
          iece: "N/A",
          articles:
            "N/A",
          books:
            "N/A",
          chapters:
            "N/A",
          patents:
            "N/A",
          membership:
            "N/A",
          aa1: "N/A",
          aa2: "",
          aa3: "",
          aa4: "",
          aa5: "",
          aa6: "",},
        {img:'/assets/img/SSH/geeta.png',name:'Geeta Sharma',position:'Assistant Professor',email:'geetasharma.ash@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=list_works&hl=en&user=7BGB8DYAAAAJ",
        linkedin: "http://www.linkedin.com/in/geeta-sharma-35b218304",
        orcid: "#",
        rg: "https://www.researchgate.net/profile/Geeta-Sharma-47",
        website: "#",
        aoi: "Sociology & Political Science",
        education: "Pursuing Ph.D. (Sociology)",
        re: "3.5",
        te: "5",
        iece: "N/A",
        articles:
          "<li>Geeta Sharma (2023) “Male Domestic Violence in Indian Scenario” in conference proceedings of DAVIM Faridabad. P 190-198</li><li>Geeta Sharma, B.P. Sing Thanua (2023) “Indigenous Tribes of Rajasthan: Reverse Migration- Impact on Their Lives During Covid-19” IJCRT Journal</li><li>Geeta Sharma, B.P. Sing Thanua, Poornima Jain (2024) “Impact of Covid 19 on forcefully displaced and migrant laborers of Faridabad: A study based on secondary sources” K.R Cama Oriental Institute Journal</li><li>Educational Impacts of the COVID-19 Pandemic: Challenges, Implication and Strategies for Resilience (In Progress)</li>",
        books:
          "N/A",
        chapters:
          "<li>Geeta Sharma, B.P. Sing Thanua, Poornima Jain (2023) “Suicide in India- scenario, causes and prevention” p 383-396 ISBN: 9789390253838</li><li>Geeta Sharma (2024) “Third Gender: Their History and Constitutional rights in India” ISBN-13 978-8196896287</li><li>NEP Implementation and Challenges (in progress)</li><li>Effect of Indian knowledge system on health and medicine during covid-19 A comparative analysis (in progress)</li>",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "NET qualified in Political Science and Sociology",
        aa2: "Written mains of UPPSC seven times and appeared in interview four times, also written mains of RPSC and appeared in interview two times",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SSH/madhu.png',name:'Madhu Bala',position:'Assistant Professor',email:'madhubala.ash@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=list_works&hl=en&user=7zZ8QN0AAAAJ",
        linkedin: "http://www.linkedin.com/in/madhu-tanwar-4a3370274",
        orcid: "#",
        rg: "https://www.researchgate.net/profile/Madhu-Bala-25",
        website: "#",
        aoi: "English Literature & Communication Skills",
        education: "Pursuing Ph.D. (English Literature)",
        re: "2",
        te: "3.5",
        iece: "N/A",
        articles:
          "<li>Present a research paper in Multidisciplinary National Seminar on 12th May 2023, entitled “ E-Library Impact on Higher Education</li><li>Present research paper in International Conference  in JVW University Jaipur on May 15th -16th  2023, Entitled “Effects of NEP 2020 on Future of Teacher Education in India”</li><li>Participate in Intellectual Basket of Women International Conference 2022 “ Organized by Faculty of Education and Methodology (FEM) in JVW University Jaipur.</li><li>Present a research paper entitled “ Empowerment of Rural Women in employment in India” Mahila Mahavidhalya Jhojhu Kala ,sponsored by Directorate of Higher Education , Haryana , on 18 March,2023</li><li>Research paper in “ International Journal of  Advanced Research (IJAR)” Entitled  “Critical Analysis of R.K Naryan in His Indian Social Philosophy”</li>",
        books:
          "N/A",
        chapters:
          "<li>Geeta Sharma, B.P. Sing Thanua, Poornima Jain (2023) “Suicide in India- scenario, causes and prevention” p 383-396 ISBN: 9789390253838</li><li>Geeta Sharma (2024) “Third Gender: Their History and Constitutional rights in India” ISBN-13 978-8196896287</li><li>NEP Implementation and Challenges (in progress)</li><li>Effect of Indian knowledge system on health and medicine during covid-19 A comparative analysis (in progress)</li>",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "NET qualified in Political Science and Sociology",
        aa2: "Written mains of UPPSC seven times and appeared in interview four times, also written mains of RPSC and appeared in interview two times",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
    ]
return(
    <Grid container spacing={2} sx={{margin:'10px 0px'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <MainTitle title='School of Science and Humanities'/>
        </Grid>
        {facultyList?.map((fac)=>(
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <FacultyCard faculty={fac} />
        </Grid>
        ))}
    </Grid>
)
}
export default FacultyList;
import React from 'react'
import { Container, Grid} from '@mui/material';
import MainTitle from './mainTitle';
import { ReactSlider } from '../../utils/utils';
import MUICard from '../muiCommonComponent/muiCard';
import CourseGrid from '../muiCommonComponent/coursesButtonGrid';
import ImagesSlider from '../muiCommonComponent/imageSlider';

function PressRelease() {
  const PressRelease = [
    {img: '/assets/pressRelease/pr1.jpeg'},
    {img: '/assets/pressRelease/pr2.jpeg'},
    {img: '/assets/pressRelease/pr3.jpeg'},
    {img: '/assets/pressRelease/pr4.jpeg'},
    // {name:'M.Sc. MLT',url:'/school-of-allied-health-sciences/msc-mlt'}
  ]; 
    const eventContent=()=>(
        <Grid container spacing={3}>
         {/* <Grid item xs={12} sm={12} md={12} lg={12} >
                        <MainTitle title="Upcoming Events"/>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12} lg={12} >         
        <ImagesSlider imgArr={PressRelease}/>
        </Grid>
        </Grid>
    )
return (
    <Container >
        <MUICard title={'Latest Press Release'} content={eventContent()}/>   
    </Container>
)
}

export default PressRelease;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation } from "../../../utils/utils";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCIVILHeader from "./header";
const CIVILBTechLab=()=>{
    const ceLab=[
{listName:'Sensors and Transducer Lab'},
{listName:'Computer Communication Lab'},
{listName:'System and Signal Processing lab'},
{listName:'Wireless Sensor Networks and IoT Lab'},
{listName:'IoT Communication Lab'},
{listName:'Digital System Design lab Lab'},
{listName:'Principle of Electrical and Electronics Lab'},
{listName:'Python Programming Lab'},
{listName:'MatLab'},
{listName:'Advance Software Lab'},
{listName:'Mobile App Development Lab'},
{listName:'Research and development lab'},

 ]
return (
<Grid container spacing={2}>
{/* <SOETCIVILHeader/>
        <BreadcrumbsBanner bannerUrl={"https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_692/https://www.iimtindia.net/Blog/wp-content/uploads/2021/07/What-is-ECE-Course.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Electronics & Communication Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="ECE Laboratories" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Electronics and Communication Engineering Department is proud to present its fully-equipped state-of-the-art laboratories. This collection is designed to cater to a broad spectrum of educational and research needs within our department. 
    <br/><br/>
Some of the prominent labs in the department are:
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={ceLab}/>
    </Grid>


    </Grid>

)
}
export default CIVILBTechLab;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation,TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const BPS=()=>{
    const progHiglight=[
{listName:'Comprehensive curriculum aligned with University Grant Commission standards that covers a wide range of subjects tailored to B.A. (Honours) Political Science along with ability enhancement, skill enhancement, value added, and vocational courses'},
{listName:'Curriculum crafted by distinguished academicians'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, and COURSERA'},
{listName:'Tailored training programs to prepare students for placement opportunities'},
{listName:'Flexible credit system enabling students to pursue multidisciplinary courses'},
{listName:'Successful job placement across diverse fields.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Engaged students in community service, outreach programs, and social initiatives to foster a sense of social responsibility and civic engagement.'},
{listName:'Availability of scholarships covering up to 100% of expenses.'},

    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹60200/- (Tuition Fee - ₹35000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹44200/- ( Tuition Fee – ₹35000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '60,200'
    const fee_2nd= '44,200'
    const eligibility='Passed Senior Secondary (10+2) or equivalent examination from any recognised board with 45% marks in aggregate.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="Bachelor of Arts (Honours) Political Science" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Bachelor of Arts (Honours) program in Political Science is designed to empower students with the knowledge, skills, and critical perspectives needed to understand and engage with the complexities of politics and governance in today's world.
    <br/><br/>
This program provides students with a comprehensive understanding of political theory, comparative politics, international relations, public policy, and research methods, equipping them with the analytical tools and insights to navigate the complexities of contemporary political life.
<br/><br/>
This program offers a rigorous and enriching curriculum that combines theoretical foundations with practical applications. Students have the opportunity to learn from distinguished faculty who are leading scholars and experts in their fields. Students have the opportunity to collaborate with peers who share their passion for politics and governance, creating a vibrant and intellectually stimulating academic community.
<br/><br/>
This program provides students with the opportunity to apply their knowledge in real-world settings, gain practical skills, and build valuable networks that prepare them for careers in politics, public service, law, journalism, advocacy, academia, and beyond.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Passed Senior Secondary (10+2) or equivalent examination from any recognised board with 45% marks in aggregate.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection of candidates will be determined by their performance in the MVNU Entrance Test, or their 12th-grade final examination scores
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Bachelor of Arts (Honours) Political Science
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BPS;
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AcademicButton from '../academicButton';

const faqItems = [
    {
        id: 'headingThree',
        question: '01 What legal specializations are offered at the School of Law, and how do they prepare students for diverse legal careers?',
        answer: 'The School of Law offers a range of specialized programs designed to equip students with the knowledge and skills necessary for various legal career paths. Our programs encompass areas such as criminal law, constitutional law, international law, and corporate law, among others. Students receive comprehensive training in legal research, writing, advocacy, and critical thinking, enabling them to excel in legal practice, public service, corporate settings, and beyond.',
    },
    {
        id: 'headingOne',
        question: '02 How does the School of Law prioritize experiential learning and practical legal skills?',
        answer: 'Experiential learning is at the heart of our Schools approach to legal education. Through moot court competitions, mock trials, legal clinics, and internships, students gain practical insights into the legal profession. These hands-on experiences provide opportunities to apply legal theory in real-world contexts, refine courtroom advocacy skills, and interact with clients. Graduates of our School emerge with a strong foundation in both theory and practice, positioning them for success in the legal field.',
    },
    {
        id: 'headingTwo',
        question: '03 What resources and opportunities are available to help students network and connect with legal professionals?',
        answer: 'The School of Law is dedicated to fostering connections between students and legal practitioners. We organize guest lectures, panel discussions, and networking events that allow students to engage with distinguished legal experts, alumni, and professionals. Additionally, our robust alumni network provides a valuable platform for mentorship and career guidance. Through these initiatives, students have the chance to build relationships, gain insights into various legal specialties, and expand their professional horizons.',
    },
];

function First() {
       return (
        <>
            <section className="project-detail">
                <div className="container">
                <AcademicButton path='/school-of-law' pageName="School of Law"/>
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                {/* <h2>School of Law</h2> */}
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="industryImg">
                                            <img src="https://admission.mvn.edu.in/wp-content/uploads/2021/08/school-of-law-slide-1.jpg" alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    {/* <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        <img src="/assets/img/bg/testi_avatar.png" alt="image" />
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/single-courses-2">Robto Jone</Link>
                                                        <p>Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Photoshop
                                                        </a>
                                                        <p>Categories</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                         <ul className="right">
                                            <li className="price">$59.00</li>
                                        </ul> 
                                    </div> */}
                                    <h3>Course Overview</h3>
                                    <p>
                                    The School of Law has been imparting quality legal education under the aegis of MVN University since 2015. The MVNU School of Law has established with a mandate to transform and redefine the process of legal education. Three Years Law Courses (i.e. LL.B.) are approved by Bar Council of India, New Delhi. All Under Graduate, Post Graduate and Research Courses are approved and run by University as per UGC and BCI norms.
                                    </p>
                                    <p>
                                    The MVNU SOL is equipped with a team of dedicated and experienced faculty ever ready to guide the students in academic and extra co-curricular activities. The School of Law also encourages the students to participate in academic events like Moot Courts, Debate, Legal Quiz, Educational Tour, Seminar and Workshops etc. The MVNU SOL also maintains a free legal aid clinic so as to acquaint the students with the client – counsel interactive process.
                                    </p>
                                    <h4>Objectives and Visions</h4>
                                    <p>
                                    To enable our students to possess the core competencies essential to embark on the practice of law.

With an aim to impart distinguished legal education, MVNU SOL inculcates a strong sense of commitment and ethics of students.
                                    </p>
                                    <p>
                                    We strongly believe that a blend of relevant knowledge, skill and right attitude is essential for the overall development of students which determines their growth and success in the legal profession. The SOL’s objective towards legal fraternity is not only the dissemination of legal knowledge, but also includes the agenda of national development by the proper delivery of justice at every end.

MVNU SOL believes to achieve excellence in education profession, serving the public and fostering justice.
                                    </p>

                                    <h4>Vision</h4>

                                    <p>To be a premier institution of excellence in legal education, research and services by creating competent legal human resources for serving the society.</p>

                                    <h4>Mission</h4>
                                    <p>
                                    The Mission of the School of Law is to develop legal professionalism in the students while making them intellectually alive, morally upright, socially responsible citizens with a positive legal attitude for the betterment of self and society.
                                    </p>

                                    <ul className="pr-ul">
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                            Constitutional Law
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                            Criminal Law
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                              International Law
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                            Environmental Law
                                            </div>
                                        </li>
                                    </ul>
                                    
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Courses Offered</th>
                                                <th>Length</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Link to="/ballb">B.A LLB</Link></td>
                                                <td>5 years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/llb">LL.B</Link></td>
                                                <td>3 years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/llm">LL.M</Link></td>
                                                <td>1 years full time</td>                                                
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h4>Message from Dean</h4>
                                    <p>
                                    I, on behalf of MVNU School of Law, extend a warm and hearty welcome to all the students joining LL.B. (3 Years), B.A.LL.B. (5 Years), BBA LL.B. (5 Years), LL.M. (1 Year) and Ph.D. in Law Courses. MVNU School of Law has been imparting legal education since 2015 and its contribution to the Bar and the Bench is remarkable.
                                    </p>
                                    <p>
                                    Being fully conscious of the fact that law and society are interrelated and interdependent and the importance of law in the light of globalization is increasing continuously. The place of law is always supreme in any country and every citizen of nation is supposed to know the basic law of their country and therefore the demand of law education has necessitated that law colleges should take up the responsibility of spreading legal education by integrating several disciplines.
                                    </p>
                                    <p>
                                    The SOL’s firm commitment is to inspire the confidence and imbue courage in the students to meet successfully the challenges in the profession and inculcate the professional culture of advocacy and in still a sense of loyalty to serve the needy persons in the society.
                                    </p>

                                    <p>
                                    Besides regular class room lectures in accordance with prescribed curriculum the college also arranges guest lectures by eminent personalities from academic.
                                    </p>

                                    <p>
                                    We strongly believe in “there is no substitute for hard work”. The work! “More you work, more you develop your ability to work!” But of course, work without vision is a journey without direction.
                                    </p>

                                    <p>
                                    I assure the maximum cooperation and guidance to all from me as well as from the learned faculty and once again cordially welcome you to MVNU School of Law and look forward for our successful and sincere association in realizing the common goal of being a responsible citizen.
                                    </p>

                                    <p>
                                    A warm welcome to you! Join us to work with vision!
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3 ">
                                        <h3>About School of LAW</h3>
                                        <ul className="project-info ">
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-vision"> <strong>Vision & Mission</strong> </Link> 
                                            </li>                                            
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-peo-po-pso-ba-llb"><strong>PEO, PO & PSO - B.A.LLB & LLB</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-peo-po-pso-llm"> <strong>PEO, PO & PSO - LLM</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-scope-of-law"> <strong>Scope Of Law</strong> </Link> 
                                            </li>                                             
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-B.A.LL_.B.-2020-2025-Batch.pdf"><strong>SOS - B.A.LLB 2020-25 </strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-LL.B.-2020-2023-Batch.pdf"><strong>SOS - LLB 2020-23</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-LL.M.-2020-2021-Batch.pdf"><strong>SOS - LLM 2020-21</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-activities"> <strong>Legal Activites</strong> </Link> 
                                            </li>  
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-visits"> <strong>Legal Visits</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-aid-clinic"> <strong>Legal Aid Clinic</strong> </Link> 
                                            </li>                                              
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-infrastructure"><strong>Infrastructure</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-law-legal-media"> <strong>Media Attention</strong> </Link> 
                                            </li>                                            
                                           
                                            <li>
                                                <div className="slider-btn">
                                                    <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                        Enroll <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
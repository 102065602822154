import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main' 
import First from './first'
import NewMain from '../../headerone/newMain'
function ApplicationPolicy() {

    return (
        <>
        <NewMain />
      {/* <div className="titleStripContainer">
        <h4>Admission Policy</h4>
      </div> */}
      <Bredcom title="Home" subtitle="Admission Policy"/>
         <First/>
        </>
    )
}

export default ApplicationPolicy
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AcademicButton from '../academicButton';

const faqItems = [
    {
        id: 'headingThree',
        question: '01 What career opportunities are available to graduates of the Computer Science Engineering program ?',
        answer: 'A degree in Computer Science Engineering opens up a wide range of career paths. Graduates often pursue roles such as software developer, computer programmer, systems analyst, AI/ML engineer, web developer, cybersecurity analyst, and more. The tech industry continues to expand, and qualified computer engineers are in demand across various sectors, including finance, healthcare, entertainment, and telecommunications.',
    },
    {
        id: 'headingOne',
        question: '02 How does the program stay up-to-date with the latest technology trends and advancements in the field?',
        answer: 'The Computer Science Engineering program is regularly updated to reflect the rapidly evolving technology landscape. The curriculum is often designed in consultation with industry experts to ensure its relevance and alignment with current industry practices. Moreover, students may have opportunities for internships, co-op programs, or industry projects, which expose them to real-world challenges and the latest tools being used in the field. This way, students are well-prepared to enter the workforce with up-to-date knowledge and skills.',
    },
    {
        id: 'headingTwo',
        question: '03 What kind of career support is available for graduates of the Computer Science Engineering program?',
        answer: 'Our university is dedicated to supporting the career development of our graduates. The Career Services Center works in collaboration with the Computer Science Engineering department to provide various resources such as resume building, interview preparation, and internship opportunities. We frequently host career fairs, bringing in top-tier technology companies to connect with our talented students. Our alumni have gone on to work at industry giants like TechCo, SoftLink, and DigitalVista, showcasing the vast employment opportunities available upon graduation. Furthermore, our strong alumni network, consisting of numerous successful professionals in the field of computer science engineering, often provides mentorship and guidance to current students, opening doors to exciting career pathways.',
    },
];

function First() {

    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <section className="project-detail">
                <div className="container">
                <AcademicButton path='/school-of-arts-science-humanities' pageName="School of Science & Humanities"/>
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-9 col-sm-12">
                                {/* <h2>Computer Science Engineering</h2> */}
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="industryImg">
                                            <img src="/assets/img/class/class-9.jpg" alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    
                                    <h3>Course Overview</h3>
                                    <p>
                                    The focus of the School of Science &amp; Humanities is to equip a fresher with the necessary
skills and knowledge in the field of science and humanities to cope with challenges in life.
This is achieved through classroom teaching, case studies, brainstorming sessions,
practical field experiences etc. M.Sc. Mathematics, BA (Honours) Economics &amp; B.A.
Political Science are the programs which are taught in the School of Science &amp; Humanities.
                                    </p>
                                    <p>
                                    The core objective of the School of Science &amp; Humanities is to introduce a student to the
world by exploring their inherent talent and skills. To bring the best out of a student, we
have strategically designed courseware &amp; teaching methodology. The implementation and
retention of knowledge by the students are of paramount importance for securing a
successful future, our academia takes utmost care in imparting the knowledge interwoven
with wisdom in an effective manner. No effort is spared to ensure teaching methodology is
delivered in its best manner. School of Science &amp; Humanities makes the students capable
of not only self-development but also makes them socially conscious of their larger role in
the society. Here the students are sensitized to the values and issues of justice, freedom
and equality. School provides knowledge not for knowledge sake but for the betterment of
the society.
                                    </p>
                                    
                                    {/* <ul className="pr-ul">
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Cloud & Big Data
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Web Design
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Mobile App Development
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                AI & Machine Learning
                                            </div>
                                        </li>
                                    </ul>
                              
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Courses Offered</th>
                                                <th>Length</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Link to="/bTechCSE">B.Tech CSE</Link></td>
                                                <td>4 years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/bScComputerScience">B.Sc Computer Science</Link></td>
                                                <td>3 years full time</td>                                              
                                            </tr>
                                            <tr>
                                                <td><Link to="/Bca">BCA</Link></td>
                                                <td>3 years full time</td>                                                
                                            </tr>
                                        </tbody>
                                    </table> */}
                                    
                                </div>
                            </div>
                            {/* <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3 ">
                                        <h3>About</h3>
                                        <ul className="project-info ">
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-vision-mission"> <strong>Vision & Mission</strong> </Link> 
                                            </li>                                            
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-peo-po-pso-cse"><strong>PEO, PO & PSO - CSE </strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-peo-po-pso-bca"> <strong>PEO, PO & PSO - BCA</strong> </Link> 
                                            </li>                                            
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-B.Tech-2021-25.pdf"><strong>SOS - B.Tech CSE 2021-25 </strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2023/03/SOS-%E2%80%93-BCA-2022-25.pdf"><strong>SOS - BCA 2022-25</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/05/Events-Computer-Science-and-Engineering.pdf"> <strong>Events</strong> </Link> 
                                            </li>                                            
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/06/Publications.pdf"><strong>Research Contributions </strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-media"> <strong>Media</strong> </Link> 
                                            </li>                                            
                                           
                                            <li>
                                                <div className="slider-btn">
                                                    <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                        Enroll <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOllm() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Law" subtitle="PEO, PO & PSO – LLM" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                
                                                               
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                
                                <p>[PEO.1]: To impart exhaustive legal knowledge with excellent professional skills in the area of chosen specialization domain.</p>

                                <p>[PEO.2]: To enrich students with legal analytical/reasoning skills to solve legal issues/problems from a scholarly and objective perspective by application of laws.</p>

                                <p>[PEO.3]: To produce legally competent professionals with ability to define, explain, analyze, evaluate, justify and implement laws in the pursuit of legal career goals and benefit society as a whole.</p>

                                <p>[PEO.4]: To develop a foundation of legal excellence with understanding of national and international current affairs to meet the present time challenges.</p>

                                <p>[PEO.5]: To create a sense of responsibility for serving the society through intensive, critical, contextual legal research in higher studies.</p>
                                

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>[PO.1] Legal Knowledge: Deep conceptual knowledge of substantive and procedural laws and capability to understand the structure of the legal system with roles and procedures of Legal Institutions.</p>

                                <p>[PO.2] Inter-disciplinary Awareness: Awareness about the socio-economic, political, technological, cultural and managerial environment in the country and corporate with applications of relevant laws in these domains.</p>

                                <p>[PO.3] Problem Analysis: Ability to analyze socio-legal problems with the help of research in sound judgments and governing legal principles from law database and case studies.</p>

                                <p>[PO.4] Communication and Counseling Skills: Ability to express thoughts and ideas effectively in writing and orally, confidently share one’s views, demonstrate the ability to listen carefully and understand analytically to define and evaluate a client’s legal problems and explain to him/her the legal options to best effectuate the client’s goals.</p>

                                <p>[PO.5] Advocacy and Lawyering Proficiency: Ability to develop legal professional advocacy and learning skills through clinical training essentials in the form of Moot Court exercises, Legal Aid Awareness camps, Legal Aid Clinic, Legal visits, Case studies, Mock Trial, Client counseling sessions etc and enhance the sense of responsibility to serve the society through these skills.</p>

                                <p>[PO.6] Legal Professionalism and Ethics: Capability to practice legal professional skills based on human values and ethical principles.</p>

                                <p>[PO.7] Lifelong Learning: Ability to understand, develop and embrace an attitude for continuous learning for legal professional development.</p>

                                <p>[PO.8] Individual and Team Player: Ability to work efficiently, effectively and respectfully as an individual and in groups for common cause with a vast domain of legal knowledge.</p>

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                
                                <p>[PSO.1] Comprehensive Understanding of Legal System: Ability to express legal opinion on substantive and procedural laws, legislations, regulations and associated rules with advanced knowledge of chosen specialization.</p>

                                <p>[PSO.2] Critical Thinking and Analysis: Ability to combine critical thinking and legal knowledge based case analysis leading to potentially justifiable solutions.</p>

                                <p>[PSO.3] Legal Research: Ability to apply comprehensive legal research competence in the domain of Indian Legal discourse using traditional and contemporary technological methodologies of doctrinal and empirical research.</p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOllm
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {
	return (
		<>
			<section id="work" className="pt-150 pb-105">
				<div className="container">
					<div className="portfolio ">
						<div className="row  fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
							<div className="elementor-widget-wrap elementor-element-populated">
								<div className="elementor-element elementor-element-ada8c66 elementor-widget elementor-widget-shortcode" data-id="ada8c66" data-element_type="widget" data-widget_type="shortcode.default">
									<div className="elementor-widget-container">
										<div className="elementor-shortcode">
											<div id="tablepress-1_wrapper" className="dataTables_wrapper no-footer"><div className="dataTables_scrollBody" style={{ position: 'relative', overflow: 'auto', width: "100%" }}><table id="tablepress-1" className="tablepress tablepress-id-1 dataTable no-footer" style={{ width: '100%' }}><thead style={{ background: '#d9edf7', padding: '15px' }}>
												<tr className="row-1 odd">
													<th colSpan={4}> Scholarship</th>

												</tr>
											</thead>

												<tbody className="row-hover">
													<tr className="row-2 even">
														<td className="column-1"><strong>Category</strong></td><td className="column-2"><strong>Description</strong></td><td className="column-3"><strong>Scholarship</strong></td><td className="column-4"><strong></strong></td><td className="column-5"><strong></strong></td><td className="column-6"><strong></strong></td><td className="column-7"><strong></strong></td><td className="column-8"><strong></strong></td><td className="column-9"><strong></strong></td><td className="column-10"><strong></strong></td><td className="column-11"><strong></strong></td>
													</tr>
													<tr className="row-3 odd">
														<td className="column-1">A1</td><td className="column-2"><strong><span ><strong>≥ 90% </strong></span><span >aggregate in qualifying examination</span></strong></td><td className="column-3"><strong><span ><strong>50%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-4 even">
														<td className="column-1">A2</td><td className="column-2"><strong><span ><strong>≥85%  &lt;90%</strong></span><span > aggregate in qualifying examination</span></strong></td><td className="column-3"><strong><span ><strong>35%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-5 odd">
														<td className="column-1">A3</td><td className="column-2"><strong><span ><strong>≥80% &lt;85%</strong></span><span > aggregate in qualifying examination</span></strong></td><td className="column-3"><strong><span ><strong>25%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-6 even">
														<td className="column-1">A4</td><td className="column-2"><strong><span ><strong>≥75% &lt;80%</strong></span><span > aggregate in qualifying examination</span></strong></td><td className="column-3"><strong><span ><strong>15%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-7 odd">
														<td className="column-1">A5</td><td className="column-2"><strong><span ><strong>≥65% &lt; 75%</strong></span><span > aggregate in qualifying examination</span></strong></td><td className="column-3"><strong><span ><strong>10%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-8 even">
														<td className="column-1">B1</td><td className="column-2"><strong><span ><strong>12th / 10th Pass</strong></span><span > from MVN School </span></strong></td><td className="column-3"><strong><span ><strong>20% </strong></span><span >Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-9 odd">
														<td className="column-1">B2</td><td className="column-2"><strong><span ><strong>Diploma to UG &amp; UG to PG</strong></span><span > in MVN University</span></strong></td><td className="column-3"><strong><span ><strong>20%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-10 even">
														<td className="column-1">B3</td><td className="column-2"><strong><span ><strong>Siblings</strong></span><span > at MVN University</span></strong></td><td className="column-3"><strong><span ><strong>20%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-11 odd">
														<td className="column-1">B4</td><td className="column-2"><strong>EWS/Handicapped </strong></td><td className="column-3"><strong><span ><strong>20%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-12 even">
														<td className="column-1">B5</td><td className="column-2"><strong><span ><strong>Sports </strong></span><span >&amp; </span><span ><strong>Defence</strong></span><span > personnel</span></strong></td><td className="column-3"><strong><span ><strong>10%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-13 odd">
														<td className="column-1">B6</td><td className="column-2"><strong>Girl Child</strong></td><td className="column-3"><strong><span ><strong>15%</strong></span><span > Tuition fee waiver (Over and above)</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-14 even">
														<td colspan="3" className="column-1"><strong>Special Scholarships for JEE (Mains) qualified candidates</strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-15 odd">
														<td className="column-1">1.0</td><td className="column-2"><strong><span ><strong>JEE (Mains)</strong></span><span > rank ≤ </span><span ><strong>1.50 Lacs</strong></span></strong></td><td className="column-3"><strong><span ><strong>50%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-16 even">
														<td className="column-1">2.0</td><td className="column-2"><strong><span ><strong>JEE (Mains) </strong></span><span >rank</span><span ><strong> ( &gt; 1.50 lacs ≤ 2.50 Lacs)</strong></span></strong></td><td className="column-3"><strong><span ><strong>25%</strong></span><span > Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-17 odd">
														<td className="column-1">3.0</td><td className="column-2"><strong><span ><strong>JEE (Mains)</strong></span><span > rank </span><span ><strong>(&gt;2.50 lacs ≤ 4.00 Lacs)</strong></span></strong></td><td className="column-3"><strong><span ><strong>10% </strong></span><span >Tuition fee waiver</span></strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-18 even">
														<td colspan="3" className="column-1"></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-19 odd">
														<td className="column-1"></td><td className="column-2"></td><td className="column-3"></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-20 even">
														<td colspan="3" className="column-1"><strong>Proposed&nbsp;Common University Entrance Test(CUET) 2023<br />
															&nbsp;Scholarship&nbsp;</strong></td><td className="column-4"><strong></strong></td><td className="column-5"><strong></strong></td><td className="column-6"><strong></strong></td><td className="column-7"><strong></strong></td><td className="column-8"><strong></strong></td><td className="column-9"><strong></strong></td><td className="column-10"><strong></strong></td><td className="column-11"><strong></strong></td>
													</tr>
													<tr className="row-21 odd">
														<td className="column-1"><strong>SL No.&nbsp;</strong></td><td className="column-2"><strong>Percentile Range</strong></td><td className="column-3"><strong>Scholarship offered in Tuition Fee in %</strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-22 even">
														<td className="column-1">Slab 1</td><td className="column-2">50%-74.99%</td><td className="column-3">25% Tuition fee waived off</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-23 odd">
														<td className="column-1">Slab 2</td><td className="column-2">75% - 84.99%</td><td className="column-3">35% Tuition fee waived off</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-24 even">
														<td className="column-1">Slab 3</td><td className="column-2">85% -94.99%</td><td className="column-3">50%Tuition fee waived off</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-25 odd">
														<td className="column-1">Slab 4</td><td className="column-2">95% and above</td><td className="column-3">100%Tuition fee waived off</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-26 even">
														<td className="column-1"></td><td className="column-2"></td><td className="column-3"></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-27 odd">
														<td colspan="3" className="column-1"><span ><strong><span ><strong>Proposed: Scholarships to students seeking&nbsp;</strong></span><span ><strong>admission&nbsp;for integrated Law Programmes on the basis their CLAT-2023 Score/Marks as per following criteria</strong></span></strong></span></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-28 even">
														<td className="column-1"><strong>SL No. </strong></td><td className="column-2"><span ><strong>CLAT -2023 Marks/Score</strong></span></td><td className="column-3"><span ><strong>Tuition Fee Waiver</strong></span></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-29 odd">
														<td className="column-1">Slab 1</td><td className="column-2">101 &amp; above</td><td className="column-3">90%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-30 even">
														<td className="column-1">Slab 2</td><td className="column-2">91 to 100</td><td className="column-3">50%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-31 odd">
														<td className="column-1">Slab 3</td><td className="column-2">70 to 90</td><td className="column-3">25%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-32 even">
														<td className="column-1">Slab 4</td><td className="column-2">50 to 70</td><td className="column-3">10%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-33 odd">
														<td className="column-1"></td><td className="column-2"></td><td className="column-3"></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-34 even">
														<td colspan="3" className="column-1"><span ><strong>Proposed: Scholarships to students seeking admission for MBA programmes on the basis their CAT/MAT/CMAT/ATMA-2023 Score/Marksas per following criteria&nbsp;On the basis of CAT-2023</strong></span></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-35 odd">
														<td className="column-1"><strong>SL No.</strong></td><td className="column-2"><strong>CAT-2023 Score / Marks</strong></td><td className="column-3"><strong>Tuition Fee Waiver</strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-36 even">
														<td className="column-1">Slab 1</td><td className="column-2">80% and above</td><td className="column-3">100%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-37 odd">
														<td className="column-1">Slab 2</td><td className="column-2">71% to 79.99%</td><td className="column-3">50%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-38 even">
														<td className="column-1">Slab 3</td><td className="column-2">50% to 70.99%</td><td className="column-3">25%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-39 odd">
														<td className="column-1"></td><td className="column-2"></td><td className="column-3"></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-40 even">
														<td className="column-1">SL No. </td><td className="column-2"><strong>MAT/CMAT/ATMA-2023 Score / Marks</strong></td><td className="column-3"><strong>Tuition Fee Waiver</strong></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-41 odd">
														<td className="column-1">Slab 1</td><td className="column-2">80% and above</td><td className="column-3">100%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-42 even">
														<td className="column-1">Slab 2</td><td className="column-2">71% to 79.99%</td><td className="column-3">50%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-43 odd">
														<td className="column-1">Slab 3</td><td className="column-2">50% to 70.99%</td><td className="column-3">25%</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-44 even">
														<td className="column-1"></td><td className="column-2"></td><td className="column-3"></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-45 odd">
														<td colspan="4" className="column-1"><span ><strong>Scholarship for outstanding achievers in sports category</strong></span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-46 even">
														<td colspan="4" className="column-1"><span ><strong>A provision of scholarship for outstanding achievers in sports is available for admission seeker at Higher Educational Institutions (HEIs) as per following categories:</strong></span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-47 odd">
														<td className="column-1"></td><td className="column-2"><span ><strong>Event/Level</strong></span></td><td className="column-3"><span ><strong>Participation</strong></span></td><td className="column-4"><span ><strong>Medal Win</strong></span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-48 even">
														<td className="column-1">Slab 1</td><td className="column-2"><span >Olympics</span></td><td className="column-3"><span >100% of Tuition Fee</span></td><td className="column-4"><span >100% of Tuition Fee</span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-49 odd">
														<td className="column-1">Slab 2</td><td className="column-2"><span >Asian Games / Asian Championship</span></td><td className="column-3"><span >75% of Tuition Fee</span></td><td className="column-4"><span >100% of Tuition Fee</span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-50 even">
														<td className="column-1">Slab 3</td><td className="column-2"><span >Common Wealth Games</span></td><td className="column-3"><span >50% of Tuition Fee</span></td><td className="column-4"><span >75% of Tuition Fee</span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-51 odd">
														<td className="column-1">Slab 4</td><td className="column-2"><span >World Cup</span></td><td className="column-3"><span >50% of Tuition Fee</span></td><td className="column-4"><span >75% of Tuition Fee</span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-52 even">
														<td className="column-1">Slab 5</td><td className="column-2"><span >National</span></td><td className="column-3"><span >25% of Tuition Fee</span></td><td className="column-4"><span >50% of Tuition Fee</span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-53 odd">
														<td className="column-1">Slab 6</td><td className="column-2"><span >State (with compulsory participation in National)</span></td><td className="column-3"><span >15 % of Tuition Fee</span></td><td className="column-4"><span >25% of Tuition Fee</span></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-54 even">
														<td className="column-1">Slab 7</td><td className="column-2"><span >Sports</span></td><td className="column-3">10% of Tuition Fee</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-55 odd">
														<td className="column-1"></td><td className="column-2"></td><td className="column-3"></td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
													<tr className="row-56 even">
														<td colspan="4" className="column-1"><strong>Swami Dayanand Education Foundation Merit-cum-Means Scholarships to exceptionally talented but poor students&nbsp;</strong></td><td className="column-5"><strong></strong></td><td className="column-6"><strong></strong></td><td className="column-7"><strong></strong></td><td className="column-8"><strong></strong></td><td className="column-9"><strong></strong></td><td className="column-10"><strong></strong></td><td className="column-11"><strong></strong></td>
													</tr>
													<tr className="row-57 odd">
														<td className="column-1"><strong>SL No.&nbsp;</strong></td><td className="column-2"><strong>Name of Program</strong></td><td className="column-3"><strong>Scholarship proposed by the MVN University</strong></td><td className="column-4"><strong></strong></td><td className="column-5"><strong></strong></td><td className="column-6"><strong></strong></td><td className="column-7"><strong></strong></td><td className="column-8"><strong></strong></td><td className="column-9"><strong></strong></td><td className="column-10"><strong></strong></td><td className="column-11"><strong></strong></td>
													</tr>
													<tr className="row-58 even">
														<td className="column-1">1.0</td><td className="column-2">Any Program/Course</td><td className="column-3">25000/- Rs scholarship for 1st 10 Students</td><td className="column-4"></td><td className="column-5"></td><td className="column-6"></td><td className="column-7"></td><td className="column-8"></td><td className="column-9"></td><td className="column-10"></td><td className="column-11"></td>
													</tr>
												</tbody>
											</table></div></div></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		</>
	)
}

export default First
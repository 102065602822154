import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBCom() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="PEO, PO & PSO – B.Com" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p>
                                [PEO.1]: To develop comprehensive knowledge of Accounting, Finance & Taxation and other related subjects.
                                </p>

                                <p>
                                [PEO.2]: To develop understanding of allied functional areas of Commerce such as Law, Economics, Banking & Insurance, Finance and Accounting and Audit.
                                </p>

                                <p>
                                [PEO.3] To foster Competencies towards innovation and entrepreneurial skills.
                                </p>
                                <p>
                                [PEO.4] To develop presentation and Writing skills to communicate and understand business affairs.
                                </p>

                                <p>
                                [PEO.5] To develop understanding of Accounting, Financial Management, Quantitative techniques, taxation with application of Accounting Software’s, working on group projects and Industry Internship.
                                </p>

                                <p>
                                [PEO.6] Inculcate human Values, ethics, teamwork skills, leadership skills, communication skills and sensitivity towards societal needs & values.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                [PO.1] Having an ability to apply theories and practices of commerce and trade.
                                </p>

                                <p>
                                [PO.2] Having an ability to understand interconnect of different sectors of economy Such as industry, Banking, insurance, financial institutions, logistics and Distribution..
                                </p>

                                <p>
                                [PO.3] Entire the skills of marketing, selling, Accounts, Finance and journal Administration of Business entity..
                                </p>

                                <p>
                                [PO.4] Having an ability to lead and achieve Business goals by creating team environment.
                                </p>

                                <p>
                                [PO.5] Having an ability to start their Business Micro and Small Businesses.
                                </p>

                                <p>
                                [PO.6] Having an ability to understand national economies legal and ethical aspects of business.
                                </p>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                [PSO.1] To Prepare Students who will implement Business Solutions for problem across various functions of management.
                                </p>

                                <p>
                                [PSO.2] To Prepare Graduates who will contribute to the growth, sustainability and Development of Society through their creative entrepreneurship.
                                </p>

                                <p>
                                [PSO.3] To prepare Graduates equipped with creative and required analytical, interpersonal and Communication Skills.
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBCom
import React from 'react';
import { Grid, Typography, List, ListItem, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';

function ProgramList() {
  const programs = [
    { name: "B.Tech CSE", link: "b-tech" },
    { name: "B.Tech CSE (Artificial Intelligence)", link: "b-tech-ai" },
    { name: "B.Tech (Machine Learning)", link: "b-tech-ml" },
    { name: "B.Tech (Cyber Security)", link: "b-tech-cs" },
    { name: "B.Sc. Computer Science", link: "bsc-it" },
    { name: "BCA", link: "bca" },
    { name: "BCA (Data Science)", link: "bca-ds" },
    { name: "BCA (Artificial Intelligence)", link: "bca-ai" },
    { name: "BCA (Machine Learning)", link: "bca-ml" },
    { name: "MCA", link: "mca" },
    { name: "M.Tech", link: "m-tech" },
    { name: "Ph.D.", link: "#" }
  ];

  return (
    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
      {programs.map((program, index) => (
        <Grid item xs={6} key={index}>
          <List sx={{ margin: 0, padding: 0 }}>
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Typography component="span" variant="body1">
                    &#8226; <Link to={`/soet-cse/course/${program.link}`} color="inherit"><u>{program.name}</u></Link>
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      ))}
    </Grid>
  );
}

export default ProgramList;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import ImagesSlider from "../../muiCommonComponent/imageSlider";

const Health_camps=()=>{

  const eventOrgnized=[
    {img:'/assets/img/footer_pages/health_camp1.png'},
    {img:'/assets/img/footer_pages/health_camp2.png'},
    {img:'/assets/img/footer_pages/health_camp3.png'},
    {img:'/assets/img/footer_pages/health_camp4.png'},
  ]
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="HEALTHY PEOPLE MAKE A HEALTHY SOCIETY AND COUNTRY" />
    </Grid>

    <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Camps Organized" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={eventOrgnized} />
      </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Welcome to our The rural welfare wing of MVN University has embarked on a remarkable journey to uplift society through a series of health camps in the neighboring villages of Palwal. We are proud to announce that the School of Pharmaceutical Sciences and the School of Allied Health Sciences have joined forces to amplify the impact of our initiatives for societal betterment. At MVN University, our commitment to the welfare of society is unwavering, and we remain steadfast in our dedication to making a meaningful difference in the lives of those we serve. Our mission is crystal clear to serve the community by conducting vital preventive health checkups such as blood sugar testing, blood pressure monitoring, and haemoglobin assessment. 
    <br/><br/>
The role of pharmacy department is to educate patients & healthcare professionals on the safe use of medication, developing & conducting research on medication use & patient safety, explaining & utilizing principles of health & wellness as appropriate to the provision of specific individuals & populations health & wellness information.
<br/><br/>
In our endeavor to promote holistic well-being, we recognize the pivotal role of physiotherapy. This specialized form of treatment offers invaluable support in addressing injuries, disorders, and diseases by employing targeted physical techniques. By leveraging expert knowledge of bodily functions and employing medically sanctioned procedures, physiotherapy facilitates the restoration of mobility, function, and movement in affected areas.


</Typography>
    </Grid>
    </Grid>

)
}
export default Health_camps;
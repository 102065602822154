import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BPT() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.Sc. (Medical Microbiology)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                Department of Physiotherapy was established under School of Allied Health Sciences (SOAHS) in year 2017. Here, at MVN university, Department of Physiotherapy, we are morally bound to enlighten and equip the students with appropriate skills by rendering knowledge in a interesting way. Here, we have a team of highly qualified and experienced faculty ably supported by an equally dedicated staff.
                                </p>

                                <p>
                                Physiotherapy means a therapeutic system of medicine which involves assessment, treatment, advice and instruction to the patient in relation to physical disorder, disability, movement, bodily malfunction and healing and pain from trauma and disease by using various electrical modalities and techniques such as manipulation and mobilization and exercises.
                                </p>

                                <p>
                                Physiotherapist practice in many settings such as O.P.D, rehabilitation centers, extended care facilities, research centers, schools, industrial workplace, fitness centers and sports training centers and can setup their own clinical setup.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Vision</h3>
                                </div>
                                <p>
                                To be recognized in the global arena as an outstanding physiotherapy educational program by imparting quality education, empowering the lives of patients to maximize independence, enhance therapeutic benefit and promote clinical accountability.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Mission</h3>
                                </div>
                                <p>
                                To impart knowledge, develop skills and competencies in students in physiotherapy practice and also to provide for research and development in the emerging areas of health sciences. To engage the intelligence, imagination and curiosity of physiotherapy students empowering them to lead fulfilling lives marked by high achievement, personal responsibility and public contribution in a diverse society. Imaparting clinical exposure through internships in multispeciality hospitals. Providing advanced knowledge and creating a research environment by making them sound in scientific approaches to patient treatment.
                                </p>
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Eligibility Criteria</h3>
                                </div>
                                <p>
                                The candidate should have passed the qualifying XII STD/HSC examination (or equivalent) having English as a subject and a minimum of 50% marks, in Science with Physics, Chemistry, Biology as mandatory subjects.
                                </p>    
                                

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Duration</h3>
                                </div>
                                <p>
                                4.5 Years Department of Physiotherapy, MVN university is recognized by IAP.
                                </p>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BPT
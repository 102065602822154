import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSInitiative=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Business Management and Commerce" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Within the School of Business Management and Commerce, our endeavors echo our dedication to innovation and academic excellence converge to shape the business leaders of tomorrow. One of our key initiatives is the integration of specialization courses within our MBA and BBA programs. Recognizing the evolving needs of the business world, we offer a diverse range of specialization options that allow students to tailor their education to their interests and career goals. Whether it's Business analytics, marketing, finance, healthcare, or pharm management, our specialized courses provide students with the knowledge and skills needed to excel in their chosen field.
    <br/><br/>
In addition to our focus on specialization, we are dedicated to fostering a culture of research and innovation within our school. We regularly organize research conferences and Management development programs/ Faculty Development programmes (MDP/FDP) that bring together academics, industry professionals, and students to discuss the latest trends and advancements in the business world.  We have successfully organized MVN University’s 1st and 2nd International conference, workshops, MDPs and actively strategizing for numerous upcoming endeavors.
<br/><br/>
Our commitment to specialization courses and research conferences reflects our belief in providing students with a well-rounded education that prepares them for success in the dynamic and competitive business landscape.

<br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSInitiative;
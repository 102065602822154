import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>
      
    );
}

function Courses() {
    const courses = [
        {
            category: 'Engineering',
            title: 'School of Engineering & Technology',
            description: 'School of Engineering and Technology offers courses in Bachelors and Doctoral. In Bachelor it offers a Bachelor in Technology with a specialization in Computer Science Engineering and Bachelors in Computer Applications.',
            link: '/computer-science-engineering',
            img: '/assets/img/programs/engineering.jpg',
        },
        {
            category: 'Law',
            title: 'School of Law',
            description: "School of law offers a variety of courses in Bachelor's, master's, and doctoral. The school offers BALLB, BBA LLB, and LLB in the undergraduate program and LLM in the master's degree program.",
            link: '/school-of-law',
            img: '/assets/img/programs/law.jpg',
        },
        {
            category: 'Health Sciences',
            title: 'School of Allied Health Sciences',
            description: "The School of Allied Health Science(SAHS) offers courses in Bachelor’s in Physiotherapy(BPT), a B.Sc. in Medical Laboratory Technology, a B.Sc. in Medical Microbiology, and a Diploma in Medical Laboratory Technology. In masters, it offers  M.Sc. (Microbiology) Course.",
            link: '/school-of-allied-health-sciences',
            img: '/assets/img/programs/health.jpg',
        },
        {
            category: 'Pharmaceutical',
            title: 'School of Pharmaceutical Sciences',
            description: 'School of Pharmaceutical Sciences (SPS)  offers courses in Diploma, Bachelor, Master, and Doctoral. The School offers  Dpharma, B.Pharma and M.Pharma respectively in Diploma Bachelors and Master Category',
            link: '/school-of-pharmaceutical',
            img: '/assets/img/programs/pharmaceutical.jpg',
        },
        {
            category: 'Management & Commerce',
            title: 'School of Business Management & Commerce',
            description: 'The School of Business Management & Commerce is running four programs, i.e., three-year B. Com (H), three years BBA, two years MBA, and Ph. D. in Business Management & Commerce.',
            link: '/school-of-business-management',
            img: '/assets/img/programs/management.png',
        },
        // {
        //     category: 'Finance',
        //     title: 'Corporate Finance',
        //     description: "To become a CPA, you must earn a bachelor's degree in business administration, finance, or accounting and complete 150 hours of coursework.",
        //     link: '/single-courses',
        //     img: '/assets/img/bg/couress-img-6.jpg',
        // },
    ];

    const settings = {
        dots: false,
        infinite: true,
        arrows: true,
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <section className="courses pt-30 pb-45 p-relative fix">
                <div className="animations-01">
                    <img src="/assets/img/bg/an-img-03.png" alt="an-img-01" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-relative">
                            <div className="section-title center-align mb-50 wow fadeInDown animated text-center" data-animation="fadeInDown" data-delay=".4s" >
                                {/* <h5>
                                    <i className="fal fa-graduation-cap" /> Our Programs
                                </h5> */}
                                <h3>MVN Programs</h3>
                            </div>
                        </div>
                    </div>
                    <Slider className="row conatiner class-active" {...settings}>
                        {courses.map((course, index) => (
                            <div key={index} className="col-lg-4 col-md-6 ">
                                <div className="courses-item mb-30 hover-zoomin">
                                    <div className="thumb fix" style={{ height:'150px',display:'none'}}>
                                        <Link to={course.link}>
                                            <img src={course.img} alt="contact-bg-an-01"/>
                                        </Link>
                                    </div>
                                    <div className="update-courses-content">
                                        {/* <div className="">
                                            <i className="fal fa-graduation-cap" /> {course.category}
                                        </div> */}
                                        <h4 style={{height:80}}>
                                            <Link to={course.link}>{course.title}</Link>
                                        </h4>
                                        <p className='update-testimonial-para'>
                                            {course.description}
                                        </p>
                                        <Link to={course.link} className="readmore">
                                            Read More <i className="fal fa-long-arrow-right" />
                                        </Link>
                                    </div>
                                    <div className="icon">
                                        <img src="/assets/img/icon/cou-icon.png" alt="img" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>
        </>
    )
}

export default Courses
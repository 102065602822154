import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function LawLegalVisits() {

    const Data = [        
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/supreme-court-visit.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/supreme-court-visit-news.jpg", category : "Financial"},
        
      ]

      const [current, setcurrent] = useState(Data);

    const aids = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/parliament-visit-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/parliament-visit-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/parliament-visit-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/parliament-visit-4.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/parliament-visit-5.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/parliament-visit-6.jpg", category : "Financial"},
    ];
    
    const vita = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/high-court-visit-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/high-court-visit-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/high-court-visit-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/high-court-visit-4.jpg", category : "Financial"},
       
    ];

    


    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Law" subtitle="Legal Visits" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>SUPREME COURT VISIT</h3>
                                </div>
                                <p>
                                    MVN University School of Law organized on regular basis visit to Apex Court of Justice i.e. the Supreme Court of India. This visit was aimed at making the students aware of the structure, procedure and functioning of the highest body of justice.
                                </p>
                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {current.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PARLIAMENT AND RASHTRAPATI BHAWAN VISIT</h3>
                                </div>

                                <p>
                                Recently MVNU SOL has also organized visit to the. Parliament of India, the Federal Highest Legislative Body of the Republic of India to make the law students understand the importance of the Parliament and its structure as well as functions.
                                </p>
                                <p>
                                In the Parliament visit the students got the opportunity to learn about the Provisions of the Indian Constitution and Proceedings of both the Houses for Enacting Laws.
                                <br/> The students of law school recently visited the Rashtrapati Bhawan’s iconic Mughal Gardens. The garden, said to be the “SOUL” of the Presidential Palace.     
                                </p>
                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {aids.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>HIGH COURT VISIT</h3>
                                </div>

                                <p>                                   
                                    MVN University , School of Law organised a visit to Delhi High Court for students. The visit was aimed at making the students aware of structure, procedure and functioning of Delhi High Court. Along with the High Court the students were also taken to the National War Memorial where they got an opportunity to see national architecture for Indian Martyrs. The students were taken to India Gate. India Gate is a war memorial of 70,000 soldiers of British Indian Army who died in the period of 1914-21 in the First World War.         
                                </p>
                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {vita.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LawLegalVisits
import React from "react";
import { Grid } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import FacultyCard from "../../../muiCommonComponent/facultyCards";

const FacultyList=()=>{
    const facultyList=[
        {img:'/assets/img/SBMC/np_singh.png',name:'N.P. Singh',position:'Pro-Vice Chancellor',email:'pvc@mvn.edu.in & dr.npsingh@mcn.edu.in ',gs: "https://scholar.google.com/citations?user=cUgmsSMAAAAJ&hl=en",
        linkedin: "https://www.linkedin.com/in/netra-pal-singh-a6a6253/",
        orcid: "https://orcid.org/0000-0002-3006-9522",
        rg: "https://www.researchgate.net/profile/Netra-Singh ",
        website: "#",
        aoi: "IT Management/ Machine Learning/AI ",
        education: " PhD (Statistics)",
        re: "42",
        te: "42",
        iece: "N/A",
        articles:
          "<li>1. Singh, N. P. (2007). Consumer Perception about fast food in India, (with Anita Goyal). British Food Journal, Vol.109 (2): 182-195 (Citations -462: IF 4.09).</li><li>2. Singh, N.P. (2007). Online Frauds in banks with Phishing, The Journal of Internet and Banking Commerce, 12 (2), 1-27.(Citations-66) </li><li>3. Dahiya, Shashi; S. S. Handa, and N P Singh (2017). A feature selection enabled hybrid bagging algorithm for credit risk evaluation, Expert Systems, 34 (6): 1-11 (Citations-58- IF – 2.812) </li>          "
          ,
        books:
          "N/A",
        chapters:
          "1. Gupta, Nakul and Singh N.P. (2019). When Artificial Intelligence Meets Augmented Reality Management and Business Education in the Time of Artificial Intelligence, pages 195–210. Copyright © 2019 by Information Age Publishing (Book Edited by Dr. Agata and Dr. Wolfgang).",
        patents:
          "N/A",
        membership:
          "<li>1. Life Member of Indian Science Congress Association</li><li>2. Life Member of Indian Society of Agricultural Statistics</li><li>3. Member of AIMA, IEEE</li>",
        aa1: "1. University Scholarship by Haryana Agricultural University during Ph.D. 1977.        ",
        aa2: "2. Council of Scientific & Industrial Research (CSIR): Junior Research Fellowship 1978- 1981        ",
        aa3: "3. Young Scientist Award by Indian Scoiety of Agricultural Research 1989",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOET/rajeev_ratan.png',name:'Dr. Rajeev Ratan',position:'Professor & Registrar',email:'rajeev.arora@mvn.edu.in', gs: "https://shorturl.at/npRU1",
        linkedin: "https://shorturl.at/sxzBD",
        orcid: "https://orcid.org/0000-0003-1727-8108",
        rg: "https://www.researchgate.net/profile/Rajeev-Ratan",
        website: "#",
        aoi: " Digital Image Processing, Computer Vision,Multirate DSP, Agri-Electronics",
        education: "PhD (Electronics & Communication Engineering)",
        re: "5",
        te: "18",
        iece: "1",
        articles:
          "<li>Yogesh, A. K Dubey, Rajeev Ratan and A. Rocha, “Multiclass Classification of Nutrients Deficiency of Apple using Deep Neural Network”. Neural Computing & Applications-Springer (2020). https://doi.org/10.1007/s00521-020-05310-x (SCI Indexed; Impact Factor: 4.680).</li><li>Yogesh, A. K Dubey, R. Arora and A. Rocha, “Computer vision based analysis and detection of defects in fruits causes due to nutrients deficiency”, Cluster Computing, 1817–1826 (2020). https://doi.org/10.1007/s10586-019-03029-6 (SCI Indexed; Impact Factor: 1.9).</li><li>Rajeev Ratan, Sanjay Sharma, and Amit Kumar Kohli, “Performance Comparison of Quadrature Amplitude Modulation (QAM) Based Single and Double-stage Digital Interpolators,” International Journal of Electronics, Taylor & Francis, vol. 100, no. 12, pp. 1724- 1734, December 2013. (SCI Indexed; Impact Factor: 0.751</li>",
        books:
          "Main Author	:	Dr. Rajeev Ratan<br/>Co-author	:	Dr. Deepak Batra<br/>Publisher	:	ACME Learning Pvt. Ltd., New Delhi",
        chapters:
          "<li>Y. K. Chauhan and Rajeev Ratan, “Study on Placement of Sensors for Readings Accuracy Level Enhancement in Greenhouse,” Lecture Notes in Electrical Engineering, 2019, vol. 553, pp. 245-254. https://doi.org/10.1007/978-981-13-6772-4_22</li><li>Priyanka Kaushik, Rajeev Ratan, “A Novel Method to Detect the Tumor Using Low- Contrast Image Segmentation Technique,” Proceedings of International Conference on Computational Intelligence, pp 161–170, Part of the Algorithms for Intelligent Systems book series (AIS)- SPRINGER. DOI: 10.1007/978-981-16-3802-2_13</li><li>Sandeep Kumar, R. Ratan, J. V. Desai, “A Study of iOS Machine Learning and Artificial Intelligence Frameworks and Libraries for Cotton Plant Disease Detection,” Machine Learning, Advances in Computing, Renewable Energy and Communication pp 259–270. Part of the Lecture Notes in Electrical Engineering book series (LNEE,volume 768)- Springer Singapore, August 2021. https://doi.org/10.1007/978-981-16-2354-7_24</li>",
        patents:
          "<li>Dr. Alok Srivastava, Dr. Rajeev Ratan, “An Apparatus for secure and Consistent path selection in Wireless Cooperative Communication and Improved Method Thereof” Published 06/05/2022</li>",
        membership:
          "<li> Associate Member - Institution of Engineers (India) </li><li> Life Time Member of Indian Society of Technical Education</li><li>Life Time Member of Computer Society of India</li>",
        aa1: "Total Citations: 411",
        aa2: "h-indez: 9",
        aa3: "i10- index: 8",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOET/amit_sagu.png',name:'Amit Sagu',position:'Assistant Professor',email:'amit.sagu@mvn.edu.in', gs: "https://scholar.google.com/citations?user=gy4TiZAAAAAJ&hl=en",
        linkedin: "http://www.linkedin.com/in/saguamit51",
        orcid: "https://orcid.org/0000-0002-0129-7665",
        rg: "http://www.researchgate.net/profile/Amit-Sagu",
        website: "#",
        aoi: " Machine Learning, Internet of Things (IoT)",
        education: "PhD (Computer Science)",
        re: "5",
        te: "5",
        iece: "2",
        articles:
          "<li>Amit Sagu, Nasib Singh Gill, Preeti Gulia, I. Priyadarshini and J. M. Chatterjee, \"Hybrid Optimization Algorithm for Detection of Security Attacks in IoT-Enabled Cyber-Physical Systems,\" in IEEE Transactions on Big Data, doi: 10.1109/TBDATA.2024.3372368.</li><li>Amit Sagu, Nasib Singh Gill, Preeti Gulia, Jyotir Moy Chatterjee, and Ishaani Priyadarshini. 2022. \"A Hybrid Deep Learning Model with Self-Improved Optimization Algorithm for Detection of Security Attacks in IoT Environment\" Future Internet 14, no. 10: 301. https://doi.org/10.3390/fi14100301.</li><li>Amit Sagu, Nasib Singh Gill, Preeti Gulia, Pradeep Kumar Singh, and Wei-Chiang Hong. 2023. \"Design of Metaheuristic Optimization Algorithms for Deep Learning Model for Secure IoT Environment\" Sustainability 15, no. 3: 2204. https://doi.org/10.3390/su15032204.</li>",
        books:
          "Amit Sagu. Cyber Security: Understanding the concept of cyber security, cybercrimes, cyber forensic and standard, ISBN: 9789389960952, Rudra Publication, 2020.",
        chapters:
          "N/A",
        patents:
          "Model for Detection of Security Attack in IoT Enabled Environment”, Inventors: Amit Sagu, Dr. Nasib Singh Gill and Dr. Preeti Gulia, Publication Date: 22 July 2022, Application No.: 202211040819 A.           ",
        membership:
          "N/A",
        aa1: "Reviewer, IEEE Access, 2024 – Present",
        aa2: "National Eligibility Test (N.E.T.), by Union Grant Commission (UGC) India, 2019",
        aa3: "National Eligibility Test (N.E.T.) with Junior Research Fellowship (JRF) by Union Grant Commission (UGC) India from 2018 – 2023.",
        aa4: "Reviewer, IEEE Access, 2024 – Present",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOET/daya_shankar.png',name:'Daya Shankar Prasad',position:'Assistant Professor',email:'dayashankar.prasad@mvn.edu.in', gs: "https://scholar.google.com/citations?view_new_articles&hl4#d=gs",
        linkedin: "https://www.linkedin.com/in/daya-shankar-prasad-43171099",
        orcid: "https://orcid.org/0009-0004-8399-2910",
        rg: ":www.researchgate.net/profile/Daya-Prasad/research",
        website: "#",
        aoi: "Thermal Engineering, Nanomaterials, Heat transfer, Thermodynamics, Fluid Mechanics and   Solar Energy",
        education: "PhD* (Pursuing), M.Tech –Thermal (Gold Medalist-NIT Patna), MBA B.Tech-Mechanical Engineering (Branch Topper)",
        re: "N/A",
        te: "9",
        iece: "N/A",
        articles:
          "<li> Prasad. D. S and Singh P.K, “Thermal analysis of Graphene distributed in Sugar-Alcohol based PCM for Energy Storage System in a Heat Exchanger” during ICMME-2023 jointly organized by Department of Mechanical Engineering, GLA University, Mathura (India) and UFRGS, Porto Alegre (Brazil) held on 13th – 14th October 2023</li><li>Prasad. D. S, “Simulation of Radiation Source for Experimental Solar Pond”, paper presented in International Conference & Exhibition on Cutting Edge Technological Challenges (CETCME) in Mechanical Engineering held on 21-22 March. 2015.</li><li>Prasad D. S and Singh P.K, “Experimental Investigation of Salt-Gradient Solar-Pond using Halogen bulb as a Solar Simulator” Paper presented in 2nd International Conference on Campus to Corporate: Industry 4.0 at MVN University, Palwal on 14th March, 2024.</li>",
        books:
          "<li>Daya Shankar Prasad, “Heat Transfer” Published by Prime International Publishing House. ISBN No.-978-93-6010-436-8, 2023.</li><li>Daya Shankar Prasad, A textbook of Engineering Graphics” Published by Foundation Publishing House, Govind Mitra Road, Patna-80004. ISBN No.-978-93-89273-49-6. 2022</li>",
        chapters:
          "N/A",
        patents:
          "Design patent applied for “5G Industrial IoT enabled Cloud Manufacturing Security Service Architecture based on Fog Computing and Blockchain” Application no.- 202211031970.  https://iprsearch.ipindia.gov.in/PatentSearch/PatentSearch/ViewApplicationStatus",
        membership:
          "Member of IAENG",
        aa1: "Four times GATE Qualified with good percentile continuously from 2011 onward. ",
        aa2: "I.E.S Written Examination Qualified.",
        aa3: "Got Appreciation Certificate from IIT, BHU in honour of full support as Faculty Coordinator for Organising first round of Caliber “de-en-quete” from 31st March-1st April, 2018",
        aa4: "Got Certificate from IIT Roorkee (NPTEL) for completing a course on “Steam Power and Gas System” in 2020.",
        aa5: "Got Certificate from IIT Roorkee (NPTEL) for completing a course on “Refrigeration & Air Conditioning” in 2019.",
        aa6: "Got Certificate from IIT Kharagpur (NPTEL) for completing a course on on “Concept of Thermodynamics” in 2019.",
        aa7: "Got Certificate from NITTTR (NPTEL) for completing a course on “Quality Assurance through NAAC Accreditation process” in 2022.",
        aa8:"Gold Medalist for branch as well as NIT Patna’s topper in M.Tech. Programme. / Gold Medalist for branch topper in B.Tech Programme / Awarded for best student in 2009 by Indian Society for Technical Education. / Awarded for best new comer in 2006 by BGIET. / Awarded for Block topper in 10th class by MADHAVA MAHARUPI TRUST. "},
        {img:'/assets/img/SOET/hema_rani.png',name:'Hema Rani',position:'Assistant Professor',email:'hemarani.cse@mvn.edu.in', gs: "#",
        linkedin: "www.linkedin.com/in/hema-mangla-526031279",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: "Web Development",
        education: "M.TECH (Computer Science)",
        re: "0",
        te: "10",
        iece: "1",
        articles:
          "<li> A.  Hema Rani,   Yogita Yashveer Raghav, Vaibhav Vyas “ Load Balancing using dynamic Algorithms for Cloud Environment: An Analysis,”  Materials Today: Proceedings, vol. </li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "National Eligibility Test (N.E.T.) by National Testing Agency (NTA) India, 2022.",
        aa2: "Oracle Certified Professional, Java SE 6 Programmer, 2015.",
        aa3: "Haryana Teacher Eligibility Test (H.T.E.T.) india, 2022.",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOET/manisha_mudgal.png',name:'Manisha Mudgal',position:'Assistant Professor',email:'manisha.mudgal@mvn.edu.in', gs: "#",
        linkedin: "https://www.linkedin.com/in/manisha-mudgal-b3a63661/",
        orcid: "https://orcid.org/0000-0001-5724-3159",
        rg: "https://www.researchgate.net/profile/Manisha-Mudgal",
        website: "#",
        aoi: " Machine Learning",
        education: "M.tech , PhD (Computer Science) Pursuing",
        re: "4+",
        te: "6",
        iece: "N/A",
        articles:
          "<li> Mudgal, Manisha, Deepika Punj, and Anuradha Pillai. \"Suspicious action detection in intelligent surveillance system using action attribute modelling.\" Journal of Web Engineering 20.1 (2021): 129-146.</li><li>Mudgal, Manisha, Deepika Punj, and Anuradha Pillai. \"Theoretical and empirical analysis of crime data.\" Journal of Web Engineering 20.1 (2021): 113-128.</li><li>Mudgal, Manisha, Deepika Punj, and Anuradha Pillai. \"A Novel Approach for Accident Detection and Localization Using Deep Learning.\" 2023 International Conference on Advances in Computation, Communication and Information Technology (ICAICCIT). IEEE, 2023.</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "National Eligibility Test (N.E.T.) by Union Grant Commission (UGC) India, Dec 2019.",
        aa2: "HTET in Jan 2020.",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOET/sanju_bala.png',name:'Sanju Bala',position:'Assistant Professor',email:'sanju.bala@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Sanju+Bala",
        linkedin: "http://www.linkedin.com/in/sanju-bala-5b4948301/",
        orcid: "https://orcid.org/0009-0006-0361-5037",
        rg: "https://www.researchgate.net/profile/Sanju-Bala-2",
        website: "http://sanjueng.blogspot.com/",
        aoi: "Theory of Computation, Compiler Design, Data Structure, Software Engineering, Operating System",
        education: "M.Tech (Computer Science)",
        re: "N/A",
        te: "6",
        iece: "N/A",
        articles:
          "<li>Research paper published in international journal of  Advanced Research in science and Engineering(ISSN-2319-8354)Title :  ” proposed surway on quality of service based resource over MANET”Issue Date:- 01-March-2015, Volume- 04, url-www.ijarse.com</li><li>Research paper published in international journal in Advanced Research in Science and engineering. (ISSN 2319-8954)Title: ”Automated Detection of fake profile” Issue Date:-01-may-2015,Volume- 04, Url- www.ijarse.com</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "Qualified NIC  for Specialist Officer  in March 2024",
        aa2: "Qualified UGC NET dec 2020+june 2021 cycle",
        aa3: "Qualified HTET for PGT computer science",
        aa4: "Qualified Cucet in 2020 for Phd. for Central University of states including Haryana, Rajasthan and Punjab.",
        aa5: "Participated   in “XVII Senior National Kabaddi Championship” conducted by “Amateur Circle Kabaddi Federation of India” held in the year 2005.    ",
        aa6: "Achieved First Position in “2nd School Sports Competition” conducted by “Mewat Development Agency (MDA)” in the year 2004. ",
        aa7: "Achieved Second Position in the “XV National Circle Kabaddi Championship” conducted by “Kabaddi (CS) Federation of India” in the year 2004.",
        aa8: "Achieved First Position in “Javelin Throw” in the Athletic Meet organized by “SES Cardinals”, Sports Committee of School of Engineering and Sciences held in the year 2012.",
        aa9: "Achieved First Position in “Shot-put” in the Athletic Meet organized by “SES Cardinals”, Sports Committee of School of Engineering and Sciences held in the year 2012."},
        {img:'/assets/img/SOET/sumit.png',name:'Sumit',position:'Assistant Professor',email:'sumit.soet@mvn.edu.in', gs: "#",
        linkedin: "#",
        orcid: "https://orcid.org/0000-0002-8747-836X",
        rg: "https://www.researchgate.net/profile/Sumit-Sumit-12",
        website: "#",
        aoi: "IoT & Machine Learning",
        education: "Ph.D. Pursuing (Computer Science & Applications)",
        re: "5",
        te: "1",
        iece: "N/A",
        articles:
          "<li>Sumit, R. S. Chhillar, S. Dalal, S. Dalal, U. K. Lilhore, and S. Samiya, “A dynamic and optimized routing approach for VANET communication in smart cities to secure intelligent transportation system via a chaotic multi-verse optimization algorithm,” Cluster Comput., vol. 9, 2024, doi: 10.1007/s10586-024-04322-9. (Springer Nature, SCI Indexed with Impact Factor 4.4)</li><li>Sumit, Prof. Rajender Singh Chhillar and Sandeep Dalal (2023). Simulating MIMT Attackers’ Strategies in VANET to Secure ITS in Smart Cities via Multiverse Optimization-based Hybrid Routing Approach. International Journal on Recent and Innovation Trends in Computing and Communication, 11(10), 1326–1337. Retrieved from https://ijritcc.org/index.php/ijritcc/article/view/8675 (Scopus Indexed)</li><li>R. S. Chhillar and S. Dalal, “INTELLIGENT SYSTEMS AND APPLICATIONS IN ENGINEERING Secure Multiverse Optimization based Dynamic Routing Approach for Intelligent Transportation System in Smart Cities,” vol. 11, no. 2, pp. 252–261, 2023. (Scopus Indexed)</li><li>Sumit and Prof. Rajender Singh Chhillar (June 2022) “A Review of Intelligent Transportation System in Existing framework using IoT\", International Journal of Engineering Trends & Technology (IJETT, ISSN: 2231-5381, vol. 6, 2022 Page No. 137-143. https://ijettjournal.org/archive/ijett-v70i6p217</li>",
        books:
          "<li>Sumit, Prof. Rajender Singh Chhillar and Sandeep Dalal “Performance Analysis of Node Classification to Secure Intelligent Transportation System in VANET” Intelligent Systems for Smart Cities from 10.1007/978-981-99-6984-5_17(Scopus Indexed)</li><li>Sumit and R. S. Chhillar, \"IoT Enabled Security Management Approach for Intelligent Transportation System in Smart Cities,\" 2022 IEEE 6th Conference on Information and Communication Technology (CICT), Gwalior, India, 2022, pp. 1-5, doi: 10.1109/CICT56698.2022.9997813. (Scopus Indexed Proceedings)</li><li>Sumit and Prof. Rajender Singh Chhillar (September 2022 Accepted) “Comparative Analysis of Existing Security Techniques in Intelligent Transportation System\", International Conference on Data Science & Smart System to be Held in November 2022 (Scopus & Web of Science Proceedings.)</li>",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "National Eligibility Test (N.E.T.) by Union Grant Commission (UGC) India, December 2018        ",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOET/dheeraj.png',name:'dheeraj',position:'Teaching Assistant',email:'dheeraj.cse@mvn.edu.in', gs: "#",
        linkedin: "https://www.linkedin.com/in/mr-dheeraj-singh-71941a246",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: " Operating System",
        education: "Msc (Computer Science)",
        re: "N/A",
        te: "2",
        iece: "N/A",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOET/sagar_sharma.png',name:'Sagar Sharma',position:'Teaching Assistant',email:'sagar.sharma@mvn.edu.in', gs: "https://scholar.google.com/citations?hl=en&user=k8zz7CwAAAAJ",
        linkedin: "www.linkedin.com/in/sagar-sharma-94bb6324b",
        orcid: "https://orcid.org/0009-0002-1368-8636",
        rg: "https://www.researchgate.net/profile/Sagar-Sharma-29",
        website: "#",
        aoi: " Machine Learning",
        education: " M.sc (Computer Science)",
        re: "N/A",
        te: "0.5",
        iece: "0.5",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
    ]
return(
    <Grid container spacing={2} sx={{margin:'10px 0px'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <MainTitle title='Electronics and Communication Engineering Faculties'/>
        </Grid>
        {facultyList?.map((fac)=>(
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <FacultyCard faculty={fac} />
        </Grid>
        ))}
    </Grid>
)
}
export default FacultyList;
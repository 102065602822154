import React from 'react' 
import Bredcom from '../Bredcom/Main' 
import Header from '../headerone/Main' 


 
function CulturalCommittee() { 
  return ( 
    <> 
        <Header/> 
        <Bredcom title="Home" subtitle="Cultural Committee" />
        <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">                               
                                <div className="inner-column">

                                    <h2>Cultural Committee</h2>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name</th>
                                                <th>Designation</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Rahul Varshney</td>
                                                <td>Chairperson</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Mr. Tarun Virmani</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Mr. Vikram Manchanda</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Dr. Kuldeep Tanwar</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Ms. Babita Yadav</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Ms. Bhanu Priya</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Dr. Vandana</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Mr. Mahesh Singh Danu</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Ms. Aradhana Sorout</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Dr. Swati Chitranshi</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>11</td>                                                
                                                <td>Charu, BALLB (21SL2004)</td>
                                                <td>Student Representative</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>Manshi, BPT (21BP1007)</td>
                                                <td>Student Representative</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>Payal, MBA (22MS5009)</td>
                                                <td>Student Representative</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>Dy. Registrar </td>
                                                <td>Member Secretary</td>
                                                
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        
    </> 
  ) 
} 
 
export default CulturalCommittee 
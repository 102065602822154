import React from "react";
import { Grid } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import FacultyCard from "../../muiCommonComponent/facultyCards";

const FacultyList=()=>{
    const facultyList=[
        {img:'/assets/img/SOL/rahul_varshney.png',name:'Dr. Rahul Varshney',position:'Professor & Dean',email:'rahul.varshney@mvn.edu.in',
        name: "Dr. Rahul Varshney",
        gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Prof+Rahul+Varshney&nuan-1=Dr+Rahul+Varshney&authuser=2#",
        linkedin: " https://www.linkedin.com/in/dr-rahul-varshney-26550650",
        orcid: "https://orcid.org/0000-0003-2000-5995",
        rg: "https://www.researchgate.net/profile/Rahul-Varshney-4",
        website: "#",
        aoi: "Corporate and Commercial Law, Consumer Law, Competition Law, Jurisprudence, Law of Torts ",
        education: "B.Sc. (PCM), LL.B., LL.M., Ph.D. (Law) ",
        re: "7",
        te: "20",
        iece: "2",
        articles: "“Digital Discourse: A Look on Social Media Regulations” Published in International Research Mirror (An International Peer Reviewed, Refereed, Indexed, Interdisciplinary, Multilingual, Monthly Research Journal), ISSN (P)-2250-253x, ISSN (E)-2320-544x, November, 2023, Volume-I, ISSUE-11 and Page No.41 to 47., “Executive Legislation in India: Legality of Ordinance Making Power” Published in Praxis International Journal of Social Science and Literature, ISSN No: 2581-6675, Volume-6, Issue-8, August 2023.“Trail by Media And Its Effect On Fair Trail” Published in Indian Journal of Integrated Research in Law, ISSN-2583-0538, Volume-II, ISSUE-I,  January, 2022.",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "Member of Bar Council of Uttar Pradesh.",
        aa1: "Best Employee of the Year 2014 (Harlal School of Law, Greater Noida) ",
        aa2: "Best All Rounder of the Year 2013 (Harlal School of Law, Greater Noida)",
        aa3: "All round Performer of the Year 2011-12 (Harlal School of Law, Greater Noida)",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/ramveer_singh.png',name:'Dr. Ramveer Singh',position:'Associate Professor',email:'ramveer.singh@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Dr.+Ramveer+Singh%23",
        linkedin: "https://www.linkedin.com/in/dr-ramveer-singh-b96a281a6/",
        orcid: "https://orcid.org/0000-0002-3359-121X",
        rg: "https://www.researchgate.net/profile/Ramveer-Singh-2",
        website: "#",
        aoi: "Criminal Law, Constitutional Law, Family Law.",
        education: "PhD (Criminal Law)",
        re: "10",
        te: "14",
        iece: "17",
        articles: "<li>“Hired Womb (Surrogate Mother) - Many Questions?” Published in International Journal of Legal Research and Studies, UGC Sr. No.1224 and Journal No. 48489, ISSN No.-2456-608X, Volume 3- Issue 1, January- March 2018, Page No.37 to 40.</li><li>“Adultery: No Longer a Criminal Affair” Published in Shodh Samiksha Aur Mulyankan, UGC Regd. No.41004, International Indexed, Peer Reviewed & Refereed Journal, ISSN-0974-2832(Print), E-ISSN-2320-5472, April, 2019, ISSUE-123 and Page No.27 to 30.</li><li>“Mob Lynching: A New Challenge” Published in Research Analysis & Evaluation, UGC Regd. No.41022, International Indexed Peer Review & Research Journal, ISSN-0975-3486(Print), E-ISSN-2320-5482, April, 2019, ISSUE-115 and Page No.04 to 07.</li><li>Note: - Total Articles and Research Papers published in various UGC Listed journals - 20</li>      ",
        books: "N/A",
        chapters: "<li> “Apprehension of Dire Consequences of Deteriorating Environment in Future: A Critical Study” Published in Environment Impact Assessment by Prof. Vimlendu Tayal, ISBN: 978-81-8198-385-5, M/s University Book House (Pvt.) Ltd. 79, Chaura Rasta, Jaipur-3, Page No.-240 – 245.</li><li>“Important Role of Judiciary in Protecting Child Rights” Published in Child Rights & Child Protection Issues, Challenges and Strategies by Dr. Sitaram & Dr. Mohammad Azvar Khan, ISBN: 978-81-949509-2-9, Published by: Satyam Law International, 2/13, Ansari Road, Daryaganj, New Delhi-110002, India. Page No.-129 – 136.</li>",
        patents: "N/A",
        membership: "Member of All India Bar Council.",
        aa1: "National Eligibility Test (N.E.T.) by Union Grant Commission (UGC) India, 2013.",
        aa2: "Common Eligibility Test (C.E.T.) by Ram Manohar Lohia Avadh University, Ayodhya India from 2012.",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/jyoti.png',name:'Jyoti',position:'Assistant Professor',email:'jyoti.law@mvn.edu.in', gs: "https://scholar.google.com/citations?hl=en&user=8UfZXOwAAAAJ",
        linkedin: " https://www.linkedin.com/in/jyoti-dagar-b562b4273/",
        orcid: "https://orcid.org/0009-0009-4670-1105",
        rg: "https://www.researchgate.net/profile/Jyoti-Dagar-3",
        website: "#",
        aoi: " Criminal law, Family law and Constitutional Law",
        education: " B.B.A.LL.B, LL.M., Ph.D.(Pursuing)   ",
        re: "1",
        te: "0.8",
        iece: "N/A",
        articles: "N/A",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "ALL INDIA BAR COUNCIL",
        aa1: "National Eligibility Test (N.E.T.) by Union Grant Commission (UGC) India, 2022.",
        aa2: "Successfully completed Online courses in year 2021 on coursera on the following titles: \"Terrorism and Counterterrorism: Comparing theory and Practice\" \" Human Rights for Open Societies\" ",
        aa3: "Semifinalist in International and National Moot Court Competitions.",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/kavita_kaushik.png',name:'Kavita Kaushik',position:'Assistant Professor',email:'kavita.kaushik@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_profile&hl=en",
        linkedin: "https://www.linkedin.com/in/kavita-kaushik-213944301/dInLinkedIn",
        orcid: "https://orcid.org/0009-0007-1087-7585",
        rg: "https://www.researchgate.net/profile/Kavita-Kaushik-2",
        website: "#",
        aoi: " HRM, Marketing",
        education: "BBA, MBA (HRM + Finance), PhD (pursuing)",
        re: "0.5",
        te: "2",
        iece: "1",
        articles: "N/A",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "<li>Qualified UGC Net (2018)</li><li>Gold Medalist in LLM </li>",
        aa1: "Completed one year practical training in reputed Pharma Agency.",
        aa2: "Completed one year practical training in reputed CA firm. ",
        aa3: "Passed with distinction in MBA program.",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/pawan_bindal.png',name:'Pawan Bindal',position:'Assistant Professor',email:'pawan.bindal@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Pawan+Bindal%23",
        linkedin: "https://www.linkedin.com/in/pawan-bindal-577a80272/",
        orcid: "https://orcid.org/0009-0007-7523-3932%20",
        rg: "https://www.researchgate.net/profile/Pawan-Bindal",
        website: "#",
        aoi: "Environmental & Constitutional Law  ",
        education: "LLM, PhD (Law Perusing)",
        re: "1",
        te: "2",
        iece: "4",
        articles: "Pawan bindal & Dr. Rahul Varshney, ’Balancing the threat of AI with Right To Privacy”, in 2nd international conference at MVN UNIVERSITY",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "<li>Member of All India Bar Council.</li>",
        aa1: "National Eligibility Test (N.E.T.) in Law by Union Grant Commission (UGC) India, 2022.",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/sachin_sharma.png',name:'Sachin Sharma',position:'Assistant Professor',email:'sachin.sharma@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=sachin+sharma%23",
        linkedin: "#",
        orcid: " https://orcid.org/0000-0002-5644-1310",
        rg: "https://www.researchgate.net/profile/Sachin-Sharma-122",
        website: "#",
        aoi: "Environmental & Constitutional Law  ",
        education: "LLM, PhD (Law Perusing)",
        re: "2",
        te: "2",
        iece: "4",
        articles: "",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "<li>Qualified UGC Net (2018)</li><li>Gold Medalist in LLM </li>",
        aa1: "Mr Sachin Sharma & Dr. Rahul  Varshney., Digital Discourse : A Look on Social Media Regulations ”International Reserch Mirror, Vol-1. Issue-11, Nov-2023, ",
        aa2: "Mr Sachin Sharma & Dr. Rahul  Varshney., Executive Legislation in India:Legality of ordinance Making Power Praxis International vol. 6 issue 8, Aug-2023 DoI No. 10.51879/PIJSL/060819",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/sakshi_rewaria.png',name:'Sakshi Rewaria',position:'Assistant Professor',email:'sakshi.rewaria@mvn.edu.in ',gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Sakshi+Rewaria",
        linkedin: " https://www.linkedin.com/in/sakshi-rewaria-23945b188",
        orcid: "https://orcid.org/0009-0004-8066-660X",
        rg: "https://www.researchgate.net/profile/Sakshi-Rewaria/research",
        website: "#",
        aoi: " Criminal Law, Family Law and Constitutional Law",
        education: "B.A.LL.B. (Hons.), LL.M., Ph.D. (Pursuing)",
        re: "2",
        te: "0.5",
        iece: "2.8",
        articles: "<li>Research paper published on “An Analysis of Insider Trading in India” published in Volume 2 issue 7 of International Journal of Research Publication and Reviews (IJRPR). (ISSN 2582-7421).</li><li>Research paper published on “Child Sexual Abuse in India: An Analysis” published in Volume 2 Issue 7 of International Journal of Research Publication and Reviews (IJRPR) (ISSN 2582- 7421)</li><li>Research paper published on “Human Rights of Physically and mentally disabled people in India” in Journal of Galgotias University, Noida.</li>",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "<li> ENROLLED AS AN ADVOCATE IN SUPREME COURT OF INDIA</li><li>ALL INDIA BAR ASSOCIATION</li>",
        aa1: "National Eligibility Test (N.E.T.) in Law by Union Grant Commission (UGC) India, 2023",
        aa2: "Secured Second Position - Silver Medalist, in Post Graduation in Criminal Law by Galgotias University, 2021.",
        aa3: "Awarded as “Best Intern” for stellar performance and meritorious conduct by Gorkela Law Office. ",
        aa4: "Certificate of Excellence been received for Legal research Work Titled “Techno-legal Development Issues and Indian Perspective” published on Legal Services India",
        aa5: "Pursued Certificate Course on “VALIDITY OF CONTRACTS IN INDIA” conducted by The LAW Learners in 2021.",
        aa6: "Pursued Certificate Course on “ENFORCEMENT OF FUNDAMENTAL RIGHTS” conducted by the LAW Learners in 2021",
        aa7: "Pursued online course on “THE ENVIRONMENT AND HUMAN RIGHTS” conducted by the Council of Europe HELP (Human Rights Education for Legal Professionals) in 2021",
        aa8: "Pursued online course on “ACCESS TO JUSTICE FOR WOMEN” from the Council of Europe HELP (Human Rights Education for Legal Professionals) in 2021",
        aa9: "Pursued online course on “ETHICS FOR JUDGES, PROSECUTORS AND LAWYERS” from the Council of Europe HELP (Human Rights Education for Legal Professionals) in 2021",},
        {img:'/assets/img/SOL/shivali_garg.png',name:'Shivali Garg',position:'Assistant Professor',email:'shivali.garg@mvn.edu.in',gs: "https://scholar.google.com/citations?view_op=new_profile&hl=en",
        linkedin: "http://www.linkedin.com/in/shivali-garg-a887a3301",
        orcid: "https://orcid.org/0009-0006-8712-6432",
        rg: "https://www.researchgate.net/profile/Shivali-Garg-2",
        website: "#",
        aoi: "Cyber laws, Intellectual Property Laws",
        education: " B.A., LL.B., LL.M., Ph.D.(Pursuing)",
        re: "0.5",
        te: "1",
        iece: "5",
        articles: "",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "Member of All India Bar Council.",
        aa1: "Received M.L.Baneja Award for securing first position in Graduation.",
        aa2: "Received Academic Award from the Governor of Punjab and Haryana for securing second position in LL.B. .",
        aa3: "National Eligibility Test (N.E.T.) by Union Grant Commission (UGC) India, 2023.",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/bhanu_priya.png',name:'Bhanu Priya',position:'Assistant Professor',email:'bhanupriya.law@mvn.edu.in ',gs: " https://scholar.google.com/citations?hl=en&user=Dhu_ickAAAAJ ",
        linkedin: "https://www.researchgate.net/?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InNpZ251cC IsInBhZ2UiOiJzaWdudXAifX0%3D ",
        orcid: "https://orcid.org/0009-0005-1909-3088 ",
        rg: "https://www.researchgate.net/?_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InNpZ251cC IsInBhZ2UiOiJzaWdudXAifX0%3D",
        website: "#",
        aoi: "CRIMINAL LAW, CONSTITUTIONAL LAW",
        education: " BA LLB, LLM( Specialization in Criminal Law)",
        re: "2",
        te: "2",
        iece: "0.5",
        articles: "",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "<li>ALL INDIA BAR ASSOCIATION </li><li>RED CROSS SOCIETY</li><li>DISTRICT TAX BAR ASSOCIATION </li>",
        aa1: "I. Secured Gold Medal at B.S Anangpuria Institute of Law for overall 4 years and secured 1st position in MDU. ",
        aa2: "II. Workshop on Research Methodology. ",
        aa3: "III. Pursued online course on “ Introduction to International Criminal justice system”from coursera",
        aa4: "IV. Pursued online course on “ AI and Law” from coursera.",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SOL/anjali_aggarwal.png',name:'Anjali Aggarwal',position:'Assistant Professor',email:'anjali.aggarwal@mvn.edu.in',gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Anjali+Aggarwal",
        linkedin: " https://www.linkedin.com/in/anjali-aggarwal-32a893135/ ",
        orcid: "https://orcid.org/my-orcid?orcid=0009-0003-3842-7405 ",
        rg: "https://www.researchgate.net/profile/Anjali-Aggarwal-10 ",
        website: "#",
        aoi: " Constitutional law & Administrative Law",
        education: "LLM, PhD (Pursuing)",
        re: "0.5",
        te: "1",
        iece: "2",
        articles: "Anjali Aggarwal & Anushka Saraswat,“Does Right to Privacy Die with a Person?” in Advocatespedia Foundation, 2018",
        books: "N/A",
        chapters: "N/A",
        patents: "N/A",
        membership: "<li>ALL INDIA BAR ASSOCIATION </li><li>RED CROSS SOCIETY</li><li>DISTRICT TAX BAR ASSOCIATION </li>",
        aa1: "Participated in competition related to women law held by Banasthali University, Rajasthan, 2019",
        aa2: "Coordinator in TILA International Moot Court Competition on Right to Energy International Law 2018",
        aa3: "Member of Organizing team National Seminar on Moral &amp; Ethics Issues in Legal Profession, Banasthali, 2018",
        aa4: "Certificate course in National Social Scheme, 2017",
        aa5: "",
        aa6: "",},
    ]
return(
    <Grid container spacing={2} sx={{margin:'10px 0px'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <MainTitle title='School of Law Faculties'/>
        </Grid>
        {facultyList?.map((fac)=>(
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <FacultyCard faculty={fac} />
        </Grid>
        ))}
    </Grid>
)
}
export default FacultyList;
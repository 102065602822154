import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {

    const Data = [
        
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-3.jpg", category : "Financial"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-4.jpg", category : "Analyzing"},
    
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-5.jpg", category : "Insurance"},
    
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-6.jpg", category : "Family"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-7.jpg", category : "Business"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-8.jpg", category : "Financial"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-9.jpg", category : "Marketing"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-10.jpg", category : "Marketing"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-11.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-12.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-13.jpg", category : "Financial"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-14.jpg", category : "Analyzing"},
    
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-15.jpg", category : "Insurance"},
    
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-16.jpg", category : "Family"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-17.jpg", category : "Business"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-18.jpg", category : "Financial"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-19.jpg", category : "Marketing"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-20.jpg", category : "Marketing"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-21.jpg", category : "Business"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-22.jpg", category : "Financial"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-23.jpg", category : "Marketing"},

        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/industrial-tour-health-camps-25.jpg", category : "Marketing"},

      ]

      const [current, setcurrent] = useState(Data);

      const Filter = (category) =>{
          const arr = [];
          if(category === "View All"){
          setcurrent(Data)
          }
          else{
          Data.map((item)=> {
              if(item.category === category){
              arr.push(item);
              }
          })
          setcurrent(arr);
          }
      };

  return (
    <>
        <section id="work" className="pt-150 pb-105">
            <div className="container">
                <div className="portfolio ">
                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                    {current.map( (item, Index)=> ( 
                        <div className="col-md-3 col-sm-12" key={Index}>
                                    <img src={item.img} alt="img" className='healthImg' />
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First
import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function StudentActivities() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Student Activity" />
      
      <section>
          <div className='container'>
              
              <div className='mvn_imse_omse'> 
                  <p>  At MVN University, we believe that education extends beyond the classroom. Our vibrant campus life offers a plethora of opportunities for students to engage, learn, and grow through various activities and organizations. Here’s a glimpse into the dynamic student life at our university:
                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Clubs and Organizations                  </h3>
                  <p>  Our students can join a wide array of clubs and organizations that cater to diverse interests, including academic, cultural, recreational, and special interests. From the Computer Science Club to the Music and Arts Society, there's something for everyone. These groups provide a platform for students to pursue their passions, develop leadership skills, and build lasting friendships.
                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Sports and Recreation  </h3>
                  <p> We encourage our students to maintain a healthy balance between academics and physical well-being. Our state-of-the-art sports facilities host a range of sports activities, including cricket, basketball, volleyball, and more. Regular tournaments competitions keep the spirit of sportsmanship alive on campus.
                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Cultural Events                  </h3>
                  <p>  MVN University hosts numerous cultural events throughout the year, celebrating the rich diversity of our student body. Festivals, talent shows, and cultural nights offer students a chance to showcase their talents and learn about different cultures and traditions. These events foster a sense of community and belonging among students.

                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Workshops, Conferences and Seminars
                  </h3>
                  <p>  To complement the academic curriculum, MVN University organize various workshops, Conferences and seminars that provide hands-on learning experiences and exposure to industry trends. Expert speakers and professionals from different fields are invited to share their knowledge, inspiring students to think beyond textbooks and explore new horizons.

                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Community Service
                  </h3>
                  <p> We believe in giving back to society, and our students are actively involved in community service projects. Through various initiatives, students work towards making a positive impact on the community, developing a sense of responsibility and empathy. Volunteering activities range from local community projects to international outreach programs.

                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Innovation and Entrepreneurship

                  </h3>
                  <p> For the innovators and entrepreneurs among us, we have dedicated support systems to nurture their ideas. Our incubation centers and entrepreneurship programs provide resources, mentorship, and networking opportunities to help students turn their innovative ideas into successful ventures.

                  </p>
              </div>

              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Annual Events
                  </h3>
                  <p> We encourage our students to push the boundaries of knowledge and innovation. Our research centers and innovation labs provide a platform for students to work on cutting-edge projects and collaborate with industry experts. We support entrepreneurial ventures and innovative ideas through mentorship and resources </p>
              </div>

              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>  Community Engagement </h3>
                  <p> Signature events like our annual TechFest, Cultural Fest, and Sports Meet are highlights of the academic year. These events bring together students from all disciplines to participate, compete, and celebrate. They are a testament to the collaborative and spirited environment that defines our university. 
                  </p>
              </div>

              <div className='mvn_imse_omse'>
                   
                  <p> At MVN University, student activities are an integral part of our educational philosophy. We strive to provide a holistic environment where students can thrive academically, socially, and personally, preparing them for a well-rounded future.

                  </p>
              </div>
 

              
          </div>
      </section>




    </>
  )
}

export default StudentActivities
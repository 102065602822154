import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../headerone/Main'

function CSETeams() {
    const location = useLocation()
    console.log('location:::::::::::::', location)
    return (
        <>
            <Header />
            <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-left">
                                <div className="breadcrumb-title">
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb-wrap2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/computer-science-engineering">Computer Science Engineering</Link>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="project-detail">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className='text-center'> Our Team</h2>
                            
                            <div className="table-scroll">
                        <table id="admission">
                            <tr>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Position</th>
                                <th>School/Department</th>
                                <th>Research Interests</th>
                            </tr>
					<tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Prof. N P Singh</div>
                        <div>(dr.npsingh@mvn.edu.in)</div>
                        </td>
                        <td>Professor</td>
                        <td>School of Business Management & Commerce</td>
                        <td>Data Analytics, AI, Machine learning, Mergers & Acquisitions, IT, Telecom, etc</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Prof. (Dr.) Rajeev Ratan</div>
                        <div>(rajeev.arora@mvn.edu.in)</div></td>
                        <td>Professor & Registrar</td>
                        <td>Computer Science & Engineering</td>
                        <td>Digital Image Processing, IoT Based Smart Agriculture, Multirate DSP</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Dr. Alok Srivastava</div>
                        <div>(alok.srivastava@mvn.edu.in)</div></td>
                        <td>Associate Profssor </td>
                        <td>School of Engineering and Technology (CSE)</td>
                        <td>IoT, WSN, AI,ML</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Mr. Daya Shankar Prasad </div>
                        <div>(dayashankar.prasad@mvn.edu.in)</div></td>
                        <td>Associate Profssor </td>
                        <td>School of Engineering and Technology (CSE)</td>
                        <td>Nano Materials, Thermal Engineering and Solar Engineering</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Manisha Mudgal</div>
                        <div>(mudgal.05.manisha@gmail.com)</div></td>
                        <td>Associate Profssor </td>
                        <td>SOET/ CSE</td>
                        <td>machine learning, deep learning</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Mr. Amit Sagu</div>
                        <div>(amit.sagu@mvn.edu.in)</div></td>
                        <td>Associate Profssor </td>
                        <td>Computer Science and Engineering </td>
                        <td>Machine learning, Artificial Intelligence, Internet of Things, Big Data, Data Mining</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Ms. Rashi Singh </div>
                        <div>(Rashi.singh@mvn.edu.in)</div></td>
                        <td>Associate Profssor </td>
                        <td>SOET</td>
                        <td>Artificial Intelligence, Machine Learning, IoT, Cyber security</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Hema Rani</div>
                        <div>(hemarani.cse@mvn.edu.in)</div></td>
                        <td>Associate Profssor </td>
                        <td>Computer Science Department</td>
                        <td>Load Balancing using dynamic Algorithms for Cloud Environment: An Analysis</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Poonam</div>
                        <div>(Poonam.dagar@mvn.edu.in)</div></td>
                        <td>Associate Profssor </td>
                        <td>SOET</td>
                        <td>IOT and A.I ,M.L</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Sagar Sharma </div>
                        <div>(sagar.sharma@mvn.edu.in)</div></td>
                        <td>Teaching assistant </td>
                        <td>CSE</td>
                        <td>Machine learning</td>

					</tr>
                    <tr>
						<td> <img height={'110px'} width={'110px'} src="https://cdn1.iconfinder.com/data/icons/user-pictures/101/malecostume-512.png" alt="" /></td>
                        <td><div>Mr. Dheeraj</div>
                        <div>(dheeraj.cse@mvn.edu.in)</div></td>
                        <td>Lecturer</td>
                        <td>CSE DEPARTMENT </td>
                        <td>Artificial intelligence </td>

					</tr>
                    </table>
                    </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CSETeams;
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBbaHons() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="PEO, PO & PSO – BBA HONS" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                                               
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p>
                                PEO 1: To provide core business knowledge so that students are able to demonstrate their competency while working with industry and other organizations.
                                </p>

                                <p>
                                PEO2: To prepare students to exhibit critical thinking to manage diverse business challenges and opportunities.
                                </p>

                                <p>
                                PEO3: To instil in students analytical and reflective thinking to analyse business environment and take effective decisions.
                                </p>
                                <p>
                                PEO4: To prepare the students to enhance their oral and written communication skills
                                </p>

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                PO1. Enhancing the horizon of knowledge so as to enable the learners to pursue academic or professional careers.
                                </p>

                                <p>
                                PO2. Developing problem analysis skills, knowledge and applying the same in real life situation.
                                </p>

                                <p>
                                PO3. Using knowledge and aptitude required in the course of study for solving Business problems.
                                </p>

                                <p>
                                PO4. Understanding the role and applicability of knowledge acquired in the context of society , environment and sustainable development sticking on the ethics and values.
                                </p>

                                <p>
                                PO5. Developing effective communication skills and ability to work in teams by strengthening group dynamics.
                                </p>

                                <p>
                                PO6. Fostering ability to engage in lifelong learning, demonstrating, empathetic social concern, contributing to the development of nation by making sure of awareness gained on various issues.
                                </p>

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                PSO1. Develop high quality educational environment in Management at undergraduate level
                                </p>

                                <p>
                                PSO2. Enable students to become competitive by providing innovative pedagogical techniques, applications of information technologies, group activities and presentations, class discussions and e-learning within the classroom.
                                </p>

                                <p>
                                PSO3. Prepare the students to have strong foundation in various domain of management, economics, entrepreneurship and skilling to do business
                                </p>

                                <p>
                                PSO4. Provide a solid foundation in management for the students to pursue professional careers and take up higher educational PG courses in management
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBbaHons
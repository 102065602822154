import React from 'react' 
import Bredcom from '../Bredcom/Main' 
import Header from '../headerone/Main' 


 
function CanteenCommittee() { 
  return ( 
    <> 
        <Header/> 
        <Bredcom title="Home" subtitle="Canteen Committee" />
        <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">                               
                                <div className="inner-column">
                                    <h2>Canteen Committee</h2>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name</th>
                                                <th>Designation</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Mayank Chaturvedi</td>
                                                <td>Chairman</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Mr. Ashwani Sharma</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Ms. Rashi Singh</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Ms. Madhubala</td>
                                                <td>Member</td>
                                                
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Mr. Prashant Kumar</td>
                                                <td>Member</td>                                                
                                            </tr>                                                                                       
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        
    </> 
  ) 
} 
 
export default CanteenCommittee 
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSPlacement=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Pharmaceutical Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Training and Placement" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Unlocking Career Opportunities: Introducing the Training and Placement Cell at the School of Pharmaceutical Sciences</b>
        <br/><br/>
        At the School of Pharmaceutical Sciences, our Training and Placement Cell (TPC) serves as the cornerstone of our commitment to fostering seamless transitions from academia to industry. With a primary focus on empowering students for success in the corporate world, our TPC offers a comprehensive suite of services designed to equip them with the skills, knowledge, and experiences needed to excel in their careers.
        <ul>
<li><b>Internship Opportunities</b>:</li>
Collaborating with a diverse array of companies and organizations, our TPC facilitates valuable internship opportunities for students. These internships provide hands-on experience, enabling students to apply classroom knowledge to real-world scenarios and develop crucial industry-specific skills.
<br/><br/>
<li><b>Placement Assistance</b>:</li>
Our TPC goes above and beyond to support students in securing placements. From organizing placement drives to conducting mock interviews, our team is dedicated to ensuring that students are well-prepared and confident as they embark on their professional journeys.
<br/><br/>
<li><b>Industry Insights</b>:</li>
To keep students abreast of industry trends and best practices, our TPC hosts guest lectures, workshops, seminars, and industry visits. These engagements offer invaluable insights and networking opportunities, enriching students' understanding of the pharmaceutical landscape.
<br/><br/>
<li><b>Skill Development</b>:</li>
Recognizing the significance of soft skills and employability traits, our TPC conducts skill development workshops and training programs. These initiatives focus on enhancing communication, presentation, problem-solving, and interpersonal skills, enhancing students' marketability to potential employers.
<br/><br/>
<li><b>Personalized Guidance</b>:</li>
Our TPC provides personalized career counseling and guidance to help students make informed decisions about their career paths. By maintaining strong connections with alumni, we offer mentorship programs and networking events to facilitate knowledge sharing and career development.

        </ul>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSPlacement;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSAlumni=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Pharmaceutical Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Our Alumni" />
    <b>Let's continue to inspire, innovate, and excel together.</b>
    <br/><br/>
    <b>Celebrating Our Alumni</b>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    At the School of Pharmaceutical Sciences, MVN University, our alumni represent the embodiment of unwavering spirit and excellence. From leading multinational corporations to pioneering startups, our graduates have left an indelible mark on the pharmaceutical world, spanning the globe and setting new standards of achievement.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Inspiring Journeys, Remarkable Achievements</b>
        <br/>
        This section is dedicated to celebrating the remarkable achievements of our alumni and sharing their inspiring journeys. Through their accomplishments, they showcase the caliber of education and guidance provided by our school, serving as inspirational figures for current and future students alike.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Enriching Our Community</b>
        <br/>
        Our alumni have made significant contributions to the pharmaceutical business, demonstrating leadership in diverse industries and maintaining ongoing involvement with our institution. Their impact enriches our community, cultivating a legacy of innovation and distinction.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Stay Connected</b>
        <br/>
        Here, we offer details on alumni gatherings, networking opportunities, and avenues for giving back. We believe in fostering connections within our expanding network of alumni, ensuring that our graduates remain an integral part of our evolving narrative.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Join us in celebrating the achievements of our alumni and in embracing the spirit of excellence that defines the School of Pharmaceutical Sciences at MVN University.
    <ul>
<li>Explore Our Alumni Stories</li>
<li>Connect with Us</li>
<li>Support Our Community</li>
    </ul>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSAlumni;
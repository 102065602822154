import { Grid, Typography, Card, Box } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSStudentshub=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Science & Humanities" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Student’s Hub" />
    </Grid>

    <Grid item xs={12} sm={12} md={12}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_student_hub.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Student Hub serves as a focal point for fostering a supportive and inclusive environment where students from diverse backgrounds may engage in meaningful experiences that complement their academic studies. The Student Hub is more than just a physical space; it's a vibrant community where students come together to learn, grow, and thrive Our mission is to empower students to reach their full potential academically, professionally, and personally. The Student Hub provided opportunities to connect with peers, faculty, and resources that will enrich your academic journey and beyond. Our dedicated team of advisors and mentors is here to support you every step of the way such as understanding complex course material, guidance on research projects, or assistance with career planning. Student Hub offers a wide range of extracurricular activities like sports club, cultural club, literature club etc. to enhance your university experience and help you develop valuable skills outside the classroom. Student Hub connects with fellow students, faculty members, alumni, and industry professionals through networking events, workshops, and guest lectures, and build valuable relationships that will benefit you throughout your academic and professional journey. Student Hub accesses a wealth of resources, including study materials, academic journals, career guides, and internship opportunities, to help you succeed academically and prepare for life after graduation. Student Hub connects the students with the local community through service projects, outreach initiatives, and partnerships with community organizations, and make a positive impact on society while developing your leadership and teamwork skills. 
    <br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSStudentshub;
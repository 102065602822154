import React from 'react'
import Header from '../headerone/Main'
import { Link } from 'react-router-dom'
import Bredcom from '../Bredcom/Main'

function CRC() {
    return (
        <>
            <Header />
            <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage:"url(/assets/homepage/banner1.jpg)",backgroundSize:"100% 100%" }} >
            <div className="container">
                <div className="row align-items-center">
                <div className="col-xl-12 col-lg-12">
                    <div className="breadcrumb-wrap text-left">
                        <div className="breadcrumb-title">
                            {/* <h2> {image?"":subtitle} </h2> */}
                        </div>
                    </div>
                </div>
                <div className="breadcrumb-wrap2">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                        <Link to="/"> Home </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                        Corporate Resource Center
                        </li>
                        <li className="breadcrumb-item">
                        <Link to="/crc-activities"> CRC Activities </Link>
                        </li>
                    </ol>
                    </nav>
                </div>
                </div>
            </div>
        </section>
            {/* <Bredcom title="Home" subtitle="Corporate Resource Center" /> */}
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h3>MVN University - Corporate Resource Center (CRC)</h3>
                                </div>
                                <p>
                                    The world is on the cusp of entering a new reality in which human potential
                                    itself will become a major agent of economic growth. Unleashing this spirit,
                                    individuals’ potential will become the ultimate quest. The inescapable
                                    pressure to do more with less has awakened employers to explore true
                                    power of human potential: With the right people in the right place at the
                                    right time, organizations can achieve all they did before, and more! These
                                    new pressures are creating a scenario where the only way to adapt, evolve
                                    and rebuild will be through the determination of the human spirit.</p>

                                <p> At the MVN University, we foster an environment where collaboration with
                                    industry thrives, generating both breakthrough innovation that can support
                                    continuous growth. With a perfect track record of productive relationships
                                    with corporates, from start-ups to mature and successful enterprises, our
                                    institution provides innovative and productive minds</p>
                                <p>At the MVN University students have been recruited in various corporates
                                    and have proved their worth as an asset to the organizations. Our students
                                    would assuredly create a new level of performance and take their recruiting
                                    organizations to new heights in their respective fields.</p>


                            </div>
                        </div>
                        <div className=" col-lg-12 col-md-12 col-sm-12">
                        <p><b>Contact details :</b></p>
                        <p style={{ marginTop: '-20px' }}><b>Name :</b> Mr. Rajesh Kumar</p>
                                <p style={{ marginTop: '-20px' }}><b>Designation :  </b>Director- Training & Placement, MVN University</p>
                                <p style={{ marginTop: '-20px' }}><b>Email :</b> Director.crc@mvn.edu.in </p>
                                <p style={{ marginTop: '-20px' }}><b>Phone :  </b>9999633358</p>
                                </div>
                        {/* <div className="col-lg-8 col-md-8 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <p>The sole objective of the Corporate Resource Center is to make the student’s industry ready and capable to face the multiple challenges of the corporate world and have a smooth transition from campus to the corporate world. The CRC Department plays an important role in shaping the careers of students from their induction, summer internships, final placements, and even beyond.

                                </p><p>Following are the tasks of the CRC Department:</p>
                                <ul>
                                    <ol>1.Enhancing student employability</ol>
                                    <ol> 2.Pre-placement Talk, Guest Lecture Workshop, etc</ol>
                                    <ol>3.Continuous improvement in the systems and processes</ol>
                                    <ol>4.Industrial Visits. Summer Internship</ol>
                                    <ol>5.Higher and better job placements with a wider industry reach</ol>
                                    <ol>6.Obtaining industry feedback for improving the academic curriculum</ol>
                                </ul>
                                <p><b>Contact details :</b></p>
                                <p style={{ marginTop: '-20px' }}><b>Email :</b> Director.crc@mvn.edu.in </p>
                                 <p style={{ marginTop: '-20px' }}><b>Phone :  </b>9999633358</p>
                                <p style={{ marginTop: '-20px' }}><b>Phone :  </b>9999633358</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="content-right wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >

                                <img src="/assets/img/rajeshKumar.jpg" alt="img" className='crc-img' />
                                <div className="crc-about">
                                    <p>Mr. Rajesh Kumar</p>
                                    <p style={{ marginTop: '-20px' }}>Director- Training & Placement, MVN University</p>
                                    {/* <p>9999633358</p>
                                        <p>Director.crc@mvn.edu.in</p> 

                                </div>
                            </div>
                        </div> */}

                    </div>
                </div>
            </section>

        </>
    )
}

export default CRC
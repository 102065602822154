import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function AlliedHealthHospitals() {
    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Allied Health Sciences" subtitle="Collaboration with Hospital" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Hospital List</h3>
                                </div>

                                <p><Link to="/https://www.admission.mvn.edu.in/wp-content/uploads/2021/10/Guru-Nanak-Hospital.pdf"> <strong>Guru Nanak Hospital</strong> </Link></p>
                                <p><Link to="/https://www.admission.mvn.edu.in/wp-content/uploads/2021/10/Sarvodaya-Hospital.pdf"> <strong>Sarvodaya Hospital</strong> </Link></p>
                                <p><Link to="/https://www.admission.mvn.edu.in/wp-content/uploads/2021/10/Asian-Fidelis-Multi-Speciality-Hospital.pdf"> <strong>Asian Fidelis Multi Speciality Hospital</strong> </Link></p>
                                <p><Link to="/https://www.admission.mvn.edu.in/wp-content/uploads/2021/10/Metro-Heart-Institute-with-Multispeciality.pdf"> <strong>Metro Heart Institute with Multispeciality</strong> </Link></p>                                                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AlliedHealthHospitals
import React, { useState } from 'react'
import axios from 'axios'
import { baseURL } from '../../config';
import Loader from '../Pages/Loader/Loader';
import ScriptLoader from '../mencontact/scriptLoader';
const SpecialRegex=/^[\w-\]+[A-Za-z\s]*$/g
const EmailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

const faqItems = [
    {
        question: 'Classrooms',
        answer: 'Our air conditioned classrooms are spacious and IT enabled. They have been designed to ensure maximum interactivity. Our faculty members make regular use of audio-visual teaching aids to provide greater clarity on concepts, increase recall and make the sessions more interesting and enjoyable.',
    },
    {
        question: 'Conference Rooms',
        answer: 'We lay great emphasis on widening the exposure of our students. We organize regular interactions with prominent personalities as well as host educative seminars. Our air conditioned theaters and conference rooms, with the latest projection and audio equipment, enable knowledge sharing and personality development in a comfortable environment.',
    },
    {
        question: 'Library',
        answer: 'Our library has an extensive collection of relevant books and online journals. We have a Book Bank facility, which means that our students can issue books for the entire semester. The library is subscribed to DELNET and INDEST, which ensures that our students have access to a plethora of eBooks, online journals, newspapers and magazines.',
    },
    {
        question: 'Laboratories',
        answer: 'For hands-on industry experience and to encourage the spirit of innovation, we have several labs with the latest equipment.',
    },
    {
        question: 'Recreation',
        answer: 'All work and no play? That’s not MVN University! You can spend time sweating it out in the basket ball court or football field or you can cool off at the swimming pool. You can share a story with your friend at the skating rink or have fun beating him in a game of table tennis. For times when hunger calls, we have a large and exciting cafeteria. Meals are prepared hygienically, with a focus on the food being nourishing. Of course, there is some fun “junk” stuff tool.',
    },
    {
        question: 'Hostels',
        answer: 'Comfortable, safe and homely… that’s what our hostels are like. The rooms are spacious, air conditioned and Internet enabled. We have separate hostels for boys and girls. To add to your convenience, the hostels have mess facility, 100% power backup, round-the-clock water supply and indoor gym.',
    },
    {
        question: 'Medical Facility',
        answer: 'The campus has a well-equipped medical facility, with a full-time qualified nurse. We have the arrangement for calling in doctors for exigencies and consultation. We also have an arrangement with a premier hospital, located within the vicinity, for medical care at subsidized charges.',
    },
];

function Frequently() {

    const [activeIndex, setActiveIndex] = useState(null);
    const [isLoading,setIsLoading]=useState(false)
    const [enquiryForm, setEnquiryForm] = useState({
        name: '',
        email: '',
        phone_number: '',
        comments: ''
    })
    const [errorStatus, setErrorStatus] = useState(false)
    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (enquiryForm?.name==""||Boolean(enquiryForm?.name.match(SpecialRegex))==false || Boolean(enquiryForm?.email.match(EmailRegex))==false|| enquiryForm?.phone_number<10) {
            setErrorStatus(true)
            setIsLoading(false)
            // var x = document.getElementById("snackbar");
            // x.className = "show";
            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
        }
        else {
            let url = `${baseURL}/enquiry`
            axios.post(url, enquiryForm, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",

                }
            }).then((response) => {
                var x = document.getElementById("successsnackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
                setIsLoading(false)
                setEnquiryForm({
                    name: '',
                    email: '',
                    phone_number: '',
                    comments: ''
                })
            }).catch(error=>{
                setIsLoading(false)
            })

        }
    }
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if(name=="phone_number"){
            if(value?.length<11){

                setEnquiryForm((values) => ({ ...values, [name]: value }))
            }
        }else{
            setEnquiryForm((values) => ({ ...values, [name]: value }))

        }
        
    }
    return (
        <>
        <ScriptLoader/>
            <section className="faq-area pt-60 pb-60 p-relative fix">
                <div className="animations-10">
                    <img src="/assets/img/bg/an-img-04.png" alt="an-img-01" />
                </div>
                <div className="animations-08">
                    <img src="/assets/img/bg/an-img-05.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center  align-items-center">
                        <div className="col-lg-7">
                            <div className="section-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s">
                                <h2>Academic Infrastructure</h2>
                                <p>
                                    MVN University represents a combination of the most modern facilities amidst the most natural environs. Spread over 23 acres, the campus is green and sprawling, providing a tranquil backdrop for the highly motivated students and faculty of our university.
                                </p>
                            </div>
                            <div className="faq-wrap mt-30 pr-30">
                                <div className="accordion" id="accordionExample">
                                    {faqItems.map((item, index) => (
                                        <div className="card" key={index}>
                                            <div className="card-header" id={`heading${index}`}>
                                                <h2 className="mb-0">
                                                    <button className={`faq-btn${activeIndex === index ? '' : ' collapsed'}`}
                                                        type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} onClick={() => handleToggle(index)} >
                                                        {item.question}
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id={`collapse${index}`} className={`collapse${activeIndex === index ? ' show' : ''}`} data-bs-parent="#accordionExample" >
                                                <div className="card-body">{item.answer}</div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="contact-bg02" style={{padding:20}}>
                            <div className="section-title center-align text-center">
                                    <h2>Enquiry Form</h2>
                                </div>

                                 {/*<form className="contact-form mt-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-name mb-20">
                                                <input type="text" id="firstn" name="name" value={enquiryForm?.name} onChange={handleInput} placeholder="Name *" required />
                                                {errorStatus&&<span style={{ color: "red" }}>{Boolean(enquiryForm?.name.match(SpecialRegex))==false?"Special character not allowed !":enquiryForm?.name==""&&"Please input a valid name !"}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <input type="email" id="email" name="email" value={enquiryForm?.email} onChange={handleInput} placeholder="Email *" required />
                                                {errorStatus&&Boolean(enquiryForm?.email.match(EmailRegex))==false&&<span style={{ color: "red" }}>Please input a valid email !</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-20">
                                                <input type="number" maxLength={10} id="phone" name="phone_number" value={enquiryForm?.phone_number} onChange={handleInput} placeholder="Phone No. *" required />
                                                {errorStatus&&enquiryForm?.phone_number?.length<10&&<span style={{ color: "red" }}>Phone number should be 10 digit !</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-message mb-30">
                                                <textarea name="comments" id="message" value={enquiryForm?.comments} onChange={handleInput} cols={30} rows={10} placeholder="Write comments" defaultValue={""} />
                                            </div>
                                            <div className="slider-btn">
                                                <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s" onClick={handleSubmit}>
                                                    <span>Submit Now</span>{" "}
                                                    <i className="fal fa-long-arrow-right" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form> */}
        <div className="npf_wgts" data-height="505px" data-w="dfc8352494c1e0ec69cfdd4fec2fbdfd"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="snackbar">Please fill * required  field..</div>
            <div id="successsnackbar">Feedback submit successfully !</div>
            {isLoading&&<Loader/>}
        </>
    )
}

export default Frequently
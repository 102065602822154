import { Typography, Grid, Card, Avatar, Box } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";
const CSEBTechLab = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MainTitle title="School of Law" />
      </Grid>

      <Grid item xs={12}>
        <MainTitle title="Computer Labs" />
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab1.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
            <br/>
          The School of Law is pleased to introduce its state-of-the-art
          computer laboratory, which contains a wide range of computers. This
          collection is specifically curated to meet a wide range of teaching
          and research requirements within our school. Our laboratory offers a
          variety of systems, beginning with entry-level models that are
          equipped with dual-core processors. These systems are suitable for
          performing essential computer activities and for acquiring
          foundational programming skills. These fundamental configurations
          include necessary RAM and storage capacities, guaranteeing dependable
          performance for introductory courses and tasks. 
          <br/>
          <br/>
          At the heart of modern education lies the integration of technology into the learning
          process. Recognizing this, the School of Law at MVN University has
          invested significantly in state-of-the-art computer laboratories.
          These laboratories serve as the nerve center for innovation, research,
          and skill development, aligning perfectly with the university's
          commitment to nurturing future-ready professionals in following
          several ways/ reasons:
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={8}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
            <ol>
<li><b>1. Legal Research and Writing:</b> Computer labs provide access to legal research databases, and online libraries for legal documents. Familiarity with these tools is crucial for law students to conduct thorough legal research, analyze case law, draft memoranda, and produce professional-quality legal documents.</li>
<li><b>2. Technology in Legal Practice:</b> The legal profession is becoming increasingly reliant on technology. Law firms utilize case management software, electronic filing systems, and digital research tools. By exposing students to these technologies in a controlled environment, computer labs help bridge the gap between theoretical legal education and practical legal practice.</li>
<li><b>3. E-Discovery and Digital Evidence:</b> With the proliferation of electronic data, understanding e-discovery processes and handling digital evidence are essential skills for modern legal practitioners. Computer labs equipped with e-discovery tools allow students to gain hands-on experience in managing digital evidence and navigating complex litigation scenarios.</li>
<li><b>4. Legal Technology and Innovation:</b> The legal industry is undergoing a technological revolution, with innovations such as artificial intelligence (AI), blockchain, and legal analytics reshaping legal practice. Computer labs provide a platform for students to explore emerging legal technologies, understand their applications in different legal domains, and develop proficiency in using them effectively.</li>
<li><b>5. Remote Learning and Collaboration:</b> In light of recent global events such as the COVID-19 pandemic, remote learning has become increasingly prevalent in legal education. Computer labs equipped with video conferencing software, collaborative platforms, and remote access tools facilitate virtual classrooms, online seminars, and group projects, enabling students to collaborate effectively in remote environments.</li>
<li><b>6. Access to Legal Resources:</b> Not all law students have access to personal computers or high-speed internet connections outside of campus. Computer labs ensure equitable access to essential legal resources, databases, and software tools for all students, regardless of their socioeconomic background or technological resources.</li>
<li><b>7. Cybersecurity and Data Privacy:</b> Understanding cybersecurity threats and protecting sensitive legal information are paramount concerns for legal professionals. Computer labs provide a controlled environment for teaching cybersecurity principles, data privacy regulations, and best practices for safeguarding client information, thereby preparing students to address cybersecurity challenges in legal practice.</li>
            </ol>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab2.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
        <br/>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab3.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >We at School of Law, are providing legal education to students with access to essential technology tools, fostering digital literacy and competency, preparing them for the evolving demands of legal practice, and promoting equity in access to legal resources and education.</Typography>
        </Grid>
    </Grid>
  );
};
export default CSEBTechLab;

import { Grid, Typography, Card, Box } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";
const CSEBTechLab=()=>{

return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Science & Humanities" />
    </Grid>

    <Grid item xs={12}>
        <MainTitle title="Computer Labs" />
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab3.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
            <br/>
            The computer labs at the School of Science and Humanities are equipped with the latest hardware, software, and peripherals. The computer labs facilitate students with a dynamic environment conducive to learning, research, and collaboration. Offering a wide range of tools and resources to help students' academic activities, the labs include programming, data analysis, graphic design, and multimedia production. Students may easily do online research, access digital libraries, and take part in virtual collaborations with peers and faculty members because of high-speed internet connectivity. The labs also feature specialized equipment and software for disciplines such as engineering, computer science, and digital arts, enabling students to unleash their creativity and push the boundaries of innovation. Students can practice and experiment to improve their technical skills under the guidance of faculty members and skilled technicians. Students can hone their craft and follow their passions in a secure and encouraging environment in the labs.
<br/><br/>
The computer labs are open to all students, regardless of their field of study. Through equitable access to cutting-edge technology and expert guidance, the labs enable students to harness the power of computing to drive positive change and shape the future of our digital world.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={8}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
            <ol>
<li><b>High-Speed Connectivity:</b> In today's interconnected world, seamless internet connectivity is indispensable for academic pursuits. Our computer laboratories are equipped with high-speed internet access, enabling students to leverage online resources, participate in virtual collaborations, and stay abreast of the latest developments in their field.</li>
<li><b>Specialized Software:</b> Students have access to a comprehensive suite of industry-standard software applications, facilitating hands-on learning experiences. Whether it's mastering accounting software, honing programming skills, or exploring data visualization tools, our labs provide the ideal environment for practical skill development.</li>
<li><b>Innovation Hub:</b> Beyond serving as mere facilities for academic activities, our computer laboratories double as innovation hubs where students can experiment, innovate, and push the boundaries of conventional thinking. Whether it's developing a cutting-edge app, conducting market research using big data analytics, or simulating real-world business scenarios, our labs provide the tools and resources to turn ideas into reality.</li>
            </ol>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab2.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>

)
}
export default CSEBTechLab;
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBmlt() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="PEO, PO & PSO – BMLT" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p>
                                PEO 1. To provide thorough knowledge in Medical laboratory subjects including theoretical and practical training for appropriate continuing education opportunities to medical laboratory professionals. Provide opportunities for technology enhanced experiential learning in both the classroom and the laboratory.
                                </p>

                                <p>
                                EO 2. Prepare graduates who excel as entry-level technologists. Provide a leading-edge educational experience that provides academic depth and breadth to support professional advancement.
                                </p>

                                <p>
                                PEO 3. To prepare graduates for employment in a variety of settings at career entry level. To provide an equal opportunity for all the students to become an entrepreneur through various labs visit and internship.
                                </p>
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                PO1: Have Knowledge and technical skills associated with medical laboratory technology for delivering quality clinical investigations.
                                </p>

                                <p>
                                PO2: Perform safe use of basic laboratory glassware and equipment including the cell counter, microscope, centrifuge, incubator, colorimeter, analytical balance, and microtome.
                                </p>

                                <p>
                                    PO3: Conduct routine clinical laboratory procedures within acceptable quality control parameters in hematology, biochemistry, immunohematology and microbiology.
                                </p>    
                                <p> PO4: Learn Problem solving techniques in identification and correction of pre analytical, post analytical & analytical variables.</p>
                                <p> PO5: Demonstrate technical skills, social behavior and professional awareness for functioning effectively as a laboratory technician.</p>
                                <p> PO6: Identify the impact of laboratory tests in a global and environmental context.</p>
                                <p> PO7: Use the fundamentals of research process to complete and present research studies that enrich the field of clinical laboratories.</p>
                                <p>PO8: Gain practical knowledge through internship at various multi-specialty hospitals.</p>

                                

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p> PSO1. Appropriate and successful collection of blood specimens through venipuncture and capillary puncture.</p>
                                <p>PSO2. Describe the morphological variations of various blood cells and discuss their clinical importance.</p>
                                <p>PSO3. Understand normal ranges/values for all common hematology/Biochemical parameters and their clinical significance.</p>
                                <p>PSO4. Describe primary aspects of the blood bank including ABO-Rh and other common blood group systems, transfusion therapy, transfusion reactions, and hemolytic disease of the newborn.</p>
                                <p>PSO5. Learn various gram positive and gram negative bacteria, viruses and fungi causing diseases to human beings.</p>
                                <p>PSO6. Explain principle, rationale uses and interpretation of routine biochemical tests for organism identification. Demonstrate proficient use of routine biochemical tests.</p>
                                <p>PSO7. Perform routine tissue processing and freeze drying technique in histopathology. Perform various staining techniques to identify premalignant or malignant condition.</p>
                                

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBmlt
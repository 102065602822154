import React,{useState} from 'react';
import { Grid, Typography, Card} from '@mui/material';

const BannerCoursesDurationFee=({duration,fee_1st,fee_2nd,eligibility})=>{
    const [showFullText, setShowFullText] = useState(false);
    const maxChars=200;
    const text='Passed 10+2 examination with Physics and Mathematics as mandatory course. For remaining single course select any course from Chemistry/ Computer... Passed 10+2 examination with Physics and Mathematics as mandatory course. For remaining single course select any course from Chemistry/ Computer...'
    return (
          <div style={{marginTop:'-50px',marginBottom:'20px',padding:'0px 5px',width:'100%',position:'relative'}}>
          <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <Card sx={{height:'100px',width:'100%'}}>
            <Typography  textAlign='center' fontSize="16px" fontWeight="600"  textTransform="uppercase">
            Duration:
          </Typography>
          <Typography gutterBottom textAlign='center' fontSize="15px" letterSpacing="0.5px" fontFamily="sans-serif" >
          {duration}
          </Typography>
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
            <Card sx={{height:'100px',width:'100%'}}>
          <Typography gutterBottom textAlign='center' fontSize="16px" fontWeight="600"  textTransform="uppercase">
          Programme Fee Per Year:
          </Typography>
          <Typography gutterBottom textAlign='center' fontSize="15px" letterSpacing="0.5px" fontFamily="sans-serif" >
          1st Year Fees: RS. {fee_1st?fee_1st:0}
          </Typography>
          <Typography gutterBottom textAlign='center' fontSize="15px" letterSpacing="0.5px" fontFamily="sans-serif" >
          2nd year onwards: RS. {fee_2nd?fee_2nd:0}
          </Typography>
            </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
        <Card sx={{ minHeight: '100px', width: '100%', position: 'relative' }}>
              <Typography gutterBottom textAlign='center' fontSize="16px" fontWeight="600" textTransform="uppercase">
                  Eligibility Criteria:
              </Typography>
              <Typography gutterBottom textAlign='left' fontSize="15px" letterSpacing="0.5px" fontFamily="sans-serif" variant='h5' paddingLeft={5}>
                  {showFullText ? eligibility : `${eligibility?.slice(0, maxChars)}...`}
                  <span style={{ color: 'red', cursor: 'pointer' }} onClick={() => setShowFullText(!showFullText)}>
                      {showFullText ? ' Read Less' : '...Read More'}
                  </span>
              </Typography>
          </Card>
        </Grid>
        </Grid>
        </div>
    );
}
export default BannerCoursesDurationFee;
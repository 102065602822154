import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const DHA=()=>{
    const progHiglight=[
{listName:'Availability of scholarships covering up to 100% of expenses.'},
{listName:'Successful job placement showcasing a variety of opportunities with leading global hospitals, Diagnostics laboratories, and Microbiology laboratories.'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Modern laboratories equipped with sophisticated instruments.'},
{listName:'Curriculum crafted by industry professionals and distinguished academics.'},
{listName:'Emphasis on project-based and experiential learning methodologies'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, and Coursera'},
{listName:'Tailored training programs to prepare students for placement opportunities.'},
{listName:'Highly motivated faculties expert of the domain has vast research experience for experiential learning.'},
{listName:'Faculties have publications in SCI-E/WoS and Scopus indexed Journals of high impact factor.'},
{listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},


    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹75200/- (Tuition Fee - ₹50000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹59200/- ( Tuition Fee – ₹50000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '75,200'
    const fee_2nd= '59,200'
    const eligibility='Applicant should have completed 10th or 12th class from a recognized board (Some colleges also give admission after completion of 10th class with a minimum of 50% in boards as the criteria), 45% marks are required for applicants from SC/ST categories.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="Diploma in Health Assistance" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Diploma in Health Assistance is a valuable program that equips students with essential skills for healthcare management and patient care. Let’s explore a few aspects of this diploma. The program typically spans two to three years. It is designed to teach basic patient-care skills to candidates. Students learn about anatomy, physiology, chemistry, zoology, and other relevant subjects. Additionally, classes on personality development and skills enhancement prepare students to face modern challenges confidently.
    <br/><br/>
    <ul>
<li>Completing this diploma opens up a world of opportunities in the healthcare field.</li>
<li>Graduates can contribute to private, public, or government sectors.</li>
<li>Their real-time applications are limitless, making this a rewarding career choice.</li>
</ul>
<br/><br/>
Diploma in Health Assistant helps those who have caring nature and want to start their career as a nurse, are most suitable for this course. Students those have good Communication including inter-personal skills are also appropriate for it.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Applicant should have completed 10th or 12th class from a recognized board (Some colleges also give admission after completion of 10th class with a minimum of 50% in boards as the criteria)
    <br/>
45% marks are required for applicants from SC/ST categories.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their 10th or 12th grade final examination scores. Aggregate in PCB/PCM will only be considered for merit list.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Diploma in Health Assistance
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default DHA;
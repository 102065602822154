import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSPlacement=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Business Management and Commerce" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Training and Placement" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Training and Placement Cell (TPC) of management and commerce departments plays a pivotal role in bridging the gap between academia and industry. Its primary objective is to facilitate the smooth transition of students from the classroom to the corporate world by providing them with opportunities for internships, projects, and placements. Our TPC collaborates with various companies and organizations to facilitate internship opportunities for students. These internships allow students to gain practical exposure to industry practices, apply theoretical knowledge in real-world scenarios, and develop essential skills required in the corporate environment. Our TPC assist students in building and nurturing their relationships with a wide network of recruiters, organizing placement drives, conducting mock interviews, and offering career guidance and counseling. It organizes guest lectures, workshops, seminars, and industry visits to provide students with insights into current industry trends, challenges, and best practices. Recognizing the importance of soft skills and employability traits, our TPC conducts various skill development workshops and training programs. These programs focus on enhancing students' communication skills, presentation skills, problem-solving abilities, and interpersonal skills, making them more marketable to potential employers. The TPC offers personalized career guidance and counseling to students to help them make informed decisions about their career paths. Our TPC maintains strong connections with alumni and may involve them in mentoring programs, networking events, and industry panels to share their experiences and insights with students.
<br/><br/>
In conclusion, our Training and Placement Cell is an indispensable part of management and commerce departments, playing a crucial role in preparing students for successful careers in the corporate world. 

<br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSPlacement;
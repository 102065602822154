import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSInitiative=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Science & Humanities" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
School of Science and Humanities (SSH) is committed providing high quality education. Several initiatives have been taken for the holistic development of the students. SSH has implemented a mentorship program where faculty members mentor students in their academic and career pursuits. This fosters a supportive environment for personal and professional growth, offering guidance, advice, and networking opportunities. SSH offers regular skill development programs on essential skills such as communication, time management, leadership, and teamwork. These workshops equip students with the practical skills necessary for success in both their academic and professional lives. SSH has initiated community engagement program where students can apply their academic knowledge and skills to address real-world challenges facing local communities. This not only provides students with hands-on learning experiences but also instills a sense of social responsibility and civic engagement.
<br/><br/>
Career counseling services are provided to students in exploring career options, developing career plans, and navigating the job market. This includes resume writing assistance, mock interviews, and networking events with industry professionals. Encouraging the formation of student-led clubs and organizations focused on various interests within the School of Science and Humanities. These clubs provide opportunities for students to pursue their passions, develop leadership skills, and build a sense of community within the school.
<br/><br/>
SSH has implemented a regular feedback mechanism to take input from students on their academic experiences, support services, and overall satisfaction with the School of Science and Humanities. This feedback helps identify areas for improvement and ensures that initiatives are tailored to meet the evolving needs of students.
<br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSInitiative;
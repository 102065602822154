import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function VisionMission() {
    return (
        <>
            <Header />
            <Bredcom title="Home / School of Pharmaceutical Sciences" subtitle="Vision & Mission" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>VISION</h3>
                                </div>
                                <p>
                                To be a leader in pharmaceutical sciences & pharmacy practice education, training, research and continuous professional development for pharmacists and pharmaceutical scientists providing competent patient care and nurturing drug discovery and development.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>MISSION</h3>
                                </div>

                                <p>                                    
                                To impart knowledge, develop skills and competencies in students in pharmaceutical sciences and pharmacy practice
                                To develop and advance the knowledge, attitude and skills of pharmacists and faculty members who can provide comprehensive pharmaceutical care to patients, improve patient outcomes, and meet societal needs for safe and effective drug therapy
                                To develop, promote and nurture research activities in pharmaceutical sciences and pharmacy practice and translating research into healthcare
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VisionMission
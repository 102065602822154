import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCIVILHeader from "./header";

const ECEBTechInitiative=()=>{
return (
<Grid container spacing={2}>
{/* <SOETCIVILHeader/>
        <BreadcrumbsBanner bannerUrl={"https://sp-ao.shortpixel.ai/client/to_webp,q_glossy,ret_img,w_692/https://www.iimtindia.net/Blog/wp-content/uploads/2021/07/What-is-ECE-Course.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Electronics & Communication Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Within the Electronics and Communication Engineering Department, our endeavors mirror a steadfast dedication to innovation, social responsibility, and the advancement of technology for the collective good. This segment highlights the broad spectrum of projects and initiatives we've undertaken to confront real-world challenges, cultivate inclusivity in technology, and advocate for sustainable practices both on and off campus.
    <br/><br/>
From outreach programs designed to ignite passion in the next generation of tech enthusiasts to technological endeavors fostering environmental sustainability, our initiatives aim to yield tangible results. We prioritize diversity and inclusion, ensuring that access to technology education is equitable and fostering an environment where all individuals can flourish.
<br/><br/>
Through strategic partnerships with industry stakeholders, non-governmental organizations, and governmental entities, we extend our impact, applying our expertise and skills to serve society. Our commitment to these initiatives not only enhances the learning journey of our students but also positions our department as a force for positive change within the tech realm and the broader community.

    </Typography>
    </Grid>
    </Grid>

)
}
export default ECEBTechInitiative;
import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BMLT() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.Sc. MLT (B.Sc. in Medical Lab Technology)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                There is always a high demand for trained lab professionals and with the advent of accreditation to laboratories the need for competent, skilled medical laboratory technicians is high and is expected to increase significantly in the coming years. Medical Lab Technology professionals may work as technicians or technologists at medical laboratories. Private hospitals, government hospitals, Blood bank, nursing homes, and clinics having medical labs are known to recruit medical technicians. Commercial medical labs and pathology labs have also recruited these professionals.
                                </p>

                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Vision</h3>
                                </div>
                                <p>
                                We are committed to prepare well educated, competent medical laboratory scientist who contribute to the provision of quality healthcare in our country.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Mission</h3>
                                </div>
                                <p>
                                Mission of the Medical Laboratory Technology program at MVN University is to prepare graduates with the knowledge, skills and professional behaviors needed to function effectively in a wide range of laboratory settings. Medical laboratory Program is to prepare professionals to demonstrate the highest quality of technical and clinical competence in serving their patients and the laboratory profession.
                                </p>
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Eligibility Criteria</h3>
                                </div>
                                <p>
                                10+2 or its equivalent with science (Physics, Chemistry, Biology) with English as one of the subject with minimum 45% marks (40 % incase of SC/ST Candidates of Haryana State.
                                </p>    
                                

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Career Options</h3>
                                </div>
                                <p>
                                With the growth in the private sector, there are so many private hospitals, nursing homes, blood banks, pathology laboratories etc. the demand for laboratory technicians is on the upswing. A certified medical laboratory technician may find a challenging career in public and private sector hospital, minor emergency centres, laboratory, blood donation centres. With adequate knowledge and experience, Medical Laboratory Technologists can advance to supervisory or management positions in laboratories and hospitals. These Laboratory Technologists can also run private pathology laboratories. Teaching in medical collages and institutions is also a good option for them.
                                </p> 

                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Lab Technician
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Lab Technologist
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Lab Supervisor
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Lab Manager
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Lab Assistant
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Instructor/Tutor
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Lab Analyst
                                        </div>    
                                    </li>
                                    
                                </ul>   
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BMLT

const First=()=>{
return(
    <section className="about-area about-p pt-60 pb-60 p-relative fix">
   <div className="container">
          <div className="row justify-content-center ">
            <div className="col-sm-12 col-lg-3 col-md-3 ">
              <div className="wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                <img src="/assets/img/prashant.jpg" alt="img" className="vc-img" />
                <div className="vc-about">
                  <p>Mr. Prashant Kumar</p>
                  <p style={{marginTop:'-20px'}}>Director (Admissions)</p>
                </div>
              </div>

              <div className="wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
              <br/><br/>
                <img src="/assets/img/AshutoshSingh.jpg" alt="img" className="vc-img" />
                <div className="vc-about">
                  <p>Mr. Ashutosh Singh</p>
                  <p style={{marginTop:'-20px'}}>Associates Director, (Admissions)</p>
                </div>
              </div>


              </div>
        <div className="col-sm-12 col-md-9 col-lg-9 text-justify">
          <h4>General Admission Guidelines</h4>
          <ul>
              <li>1.	Admission of students shall be made strictly on the basis of merit based on qualifying examination.</li>
              <li>2.	Only such candidates who have passed a qualifying examination from an Indian University/ Board established or recognized by State/Central Government or such other examination as has been recognized equivalent by State/Central Government/ University shall be considered for admission</li>
              <li>3.	Admissions to the various programs of studies will be made by the Admission Committee of the concerned Department comprising of the Dean/ HOD as Chairman, and two members nominated by the Registrar.</li>
              <li>4.	Minimum qualifications for admission to the programs in various Departments have been prescribed and are available in information brochure as well as on University website.</li>
              <li>5.	The University shall follow reservation policy prescribed by the Govt. of Haryana for private universities for admissions.</li>
              <li>6.	If, at any time, it is discovered that a candidate has provided a false or incorrect information or has used fraudulent means for securing admission, his/her admission shall be cancelled</li>
              <li>7.	The student shall deposit fees as prescribed by the University, on or before the due date prescribed by the University.</li>
              <li>8.	Commencement of Semester/year starts from First week of August every year or as per notification of UGC.</li>
              <li>9.	The student would be liable for necessary action as deemed fit by the University for any wrong/false/incomplete/fraud information filled in the application form by him/her and his/her admission shall be cancelled and all fees and other dues paid by him/her will be forfeited. The University may also take further strict legal action as deemed fit against such candidates. </li>
              <li>10.	A student shall also be eligible for admission to any program of study of this University on migration from any other University or Board if she/he has passed an examination equivalent to the examination of this University prescribed by the Ordinances for admission to the concerned program of this University. For transfer of credits please refer to Ordinance (Transfer of Credits) of the University.</li>
              
              <li>11.	The annual charges may be increased on actual basis as per recommendation of university finance committee.</li>


          </ul>
 
 
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 text-justify">
              <ul>


            
                 
          </ul>

          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 text-justify">
            <br/> <br/>
          </div>
          
          {/*
          <div className="col-sm-12 col-lg-3 col-md-3 ">
              <div className="wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                <img src="/assets/img/AshutoshSingh.jpg" alt="img" className="vc-img" />
                <div className="vc-about">
                  <p>Mr. Ashutosh Singh</p>
                  <p style={{marginTop:'-20px'}}>Associates Director, (Admissions)</p>
                </div>
              </div>
              </div>  
          */ }



          <div className="col-sm-12 col-md-12 col-lg-12  text-justify">

         
          <h4>SCHEDULE OF PAYMENT OF FEES IN SUBSEQUENT YEARS</h4>
          <p>
          The total annual fee shall be paid in two instalments as per the schedule notified by the university and same shall be paid through Net Banking, Credit & Debit Card, UPI or Cash Deposit at University fee counter. Fee Payment transfer details are also available on university website.
          </p>

          <br/>  
          <h4>Non-Payment of Charges & Fee:</h4>
          <p>
          Those students who fail to deposit fee by the due date shall be levied a late fee of Rs. 50/- per day including Sunday/Holidays upto 15 days. Such a student whose name stands struck-off from the University may be re-admitted at the discretion of the Vice-Chancellor, on payment of all arrears due from him/her along with fine applicable at the time of submission of re-admission request i.e. an increment of Rs.500/- for every 15 days from the last date to deposit the fee with fine and re-admission fee of Rs.5000/-. Re-admission is allowed before start of examination only. Students whose name stands struck-off from University rolls shall not be allowed to appear for end semester examinations.
          </p>

          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 text-justify">

          <br/>  
          <h4>Admission Procedure</h4><br/>
          <h4>Rules and General Instructions</h4>
          <p>
          The prospectus-cum-admission form can be obtained from the Administrative Office of the University on payment of Rs. 1000/-. The Admission Form can also be downloaded from the website of University However, such candidates will have to pay Rs. 1000/- with the downloaded Admission Form Important instructions for Filling up the Admission Form. The Admission Form for seeking admission to any course, duly completed in all respects, must be accompanied with the required documents <br/><br/>
After submission of the Admission Form, the candidates are required to report for Counseling and Admission at the University campus with the all original certificates to the course concerned. 

          </p>

          <br/> <br/>
          <h4>Other Important Instructions for admission:</h4>
           <ul>
              <li>1	Admission Forms which are incomplete or received late, are liable to be rejected.</li>
              <li>2	A candidate, who does not report for the Counseling in person on the date of Counseling shall lead to forfeit of his/her seat.</li>
              <li>3	The fee is to be deposited at the time of Counseling.</li>
              <li>4	There will be no rounding of percentage of marks for determining the eligibility for admission to various courses. </li>
              <li>5	In case two or more candidates in merit list have equal percentage of marks, the marks obtained in the next lower of the qualifying examination will be taken into consideration for determining the merit. In case there is still a tie, the candidate senior in age will be given preference.</li>
              <li>6	The candidate possessing the qualification of qualifying examination from any Foreign University/Board, should submit equivalence certificate of their qualifying examination from Association of Indian Universities (AIU), New Delhi.</li>
              <li>7	If a student fails to attend his/her classes continuously for a week from the date of commencement of the classes or from the date of admission (in case admission has been made after the commencement of the classes) his/her admission shall be cancelled without any further notice.</li>
              <li>8	The Application Fees deposited by the candidate is non-refundable. </li>
              <li>9	Refund will be made strictly as per the UGC norms.</li>
              <li>10	All the disputes shall lie in the jurisdiction of District Palwal.</li>
             
           </ul>

          <br/> <br/>
          <h4>Attendance Rules</h4>
          <p>
          It is obligatory on the part of HOD/Teacher concerned to announce attendance position of each student at the end of every month in the classes. The students, who are falling short of attendance, are advised to improve their attendance position. However, the students are also advised to get their attendance noted from their concerned HOD/Teachers at the end of every month to avoid their total shortage of attendance at the end of the semester.<br/><br/>
          1. Every student should get at least 75% attendance or as prescribed by authorities in aggregate in all subjects at the end of each semester/year to be eligible to appear in university examinations.<br/>
          2. Deficiency up to 5% can be condoned by Dean/HOD in severe cases on compensatory ground/medical cases students with 70% or above attendance can be eligible to appear in the semester exam if 5% deficiency is condoned by the Registsrar.<br/>
          3. The student having attendance below 70% are liable to be detained.<br/>
          4. Detained student will have to repeat the respective academic subjects/semester/year in the next academic session by paying their fee/dues as applicable.<br/>

          </p>


          <br/> <br/>
          <h4>NOTE</h4>
          <p>
          However, the Parents/Guardians of students are also requested to keep close watch on the attendance position of their son/daughter/ward by enquiring from the concerned Head of Department. No further notice shall be issued in this regard.

          </p>


          <br/> <br/>
          <h4>Ragging an Offence</h4><br/>
          <h4>RAGGING IS A GRIEVOUS OFFENCE</h4>
          <p>
          As per the orders of the Hon'ble Supreme Court, UGC Regulations on curbing the menace of Ragging in Higher Educational Institutions, 2009 and The Haryana Prohibition of Ragging in Educational Institutions Ordinance, 2012. Ragging is a non-bailable offence.
          </p>

 
        </div>
      </div>
      
    </div>
    </section>
)
}
export default First;
import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

const CourseGrid = ({courses}) => {
  return (
    <Grid container spacing={2}>
      {courses.map((course, index) => (
        <Grid item key={index} xs={6} sm={4} md={3}>
          <Button
            component={Link}
            to={course.url} // Example route: /courses/1, /courses/2, etc.
            variant="contained"
            color="primary"
            fullWidth
            sx={{height:'80px'}}
          >
            {course.name}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default CourseGrid;


import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../headerone/Main'

function Consultancy() {
    const location = useLocation()
    console.log('location:::::::::::::', location)
    return (
        <>
            <Header />
            <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-left">
                                <div className="breadcrumb-title">
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb-wrap2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/computer-science-engineering">Computer Science Engineering</Link>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="project-detail">
                <div className="container">
                    <div className="row">
                        {/* <div className="btn-group col-lg-12 col-md-12 col-sm-12">
                            <button className='btn-course active'>Grants</button>
                            <button className='btn-course'>Consultancy</button>
                            <button className='btn-course'>Patents</button>
                            <button className='btn-course'>Publications</button>
                            <button className='btn-course'>Technology Transfers</button>
                            <button className='btn-course'>collaborations</button>
                            <button className='btn-course'>PhD Program</button>
                            <button className='btn-course'>Team</button>

                        </div> */}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <ul>
                                <li>1. HirePro Consulting Pvt. Ltd. </li>
                                <li>2. Jianjani Technologies Pvt. Ltd.</li>
                                <li>3. Orient Electric</li> 
                                <li>4. Apurva enterprises</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Consultancy;
import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BTechCSE() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.Tech CSE" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p>
                                    B.Tech Computer Science and Engineering (CSE) is an undergraduate degree program that combines the fields of computer science and engineering. This program is designed to equip students with a strong foundation in the principles of computer science and the engineering techniques used to design, develop, and maintain computer software and hardware systems.
                                </p>

                                <p>
                                The program typically covers a wide range of topics including programming languages, algorithms, computer architecture, database management, operating systems, computer networks, software engineering, and artificial intelligence. Students also gain hands-on experience through practical assignments, projects, and internships.
                                </p>
                                <p>
                                Career prospects for graduates of B.Tech CSE are vast and varied, as computer science and engineering are two of the fastest-growing fields in the world. Graduates can find work as software engineers, system analysts, database administrators, network engineers, cybersecurity specialists, web developers, and many other roles in both the public and private sectors.
                                </p>
                                <p>
                                Overall, B.Tech CSE is an excellent choice for students who are interested in technology, problem-solving, and innovation. It offers a challenging and rewarding educational experience that prepares graduates for success in a rapidly evolving industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BTechCSE
import React from 'react'
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './first'
import NewMain from '../../headerone/newMain'
function ExaminationReEvaluation() {
  return (
    <>
      <NewMain />
      <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  {/* <h2> {image?"":subtitle} </h2> */}
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home </Link>
                  </li> 
                  <li className="breadcrumb-item">
                    <Link to="/re-evalution-rules">Re-Evaluation Rules  </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area about-p pt-60 pb-60 p-relative fix">
             
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                    <h3 class="new_title">Re-Evaluation  </h3>
                        <p className="text-justify">
                        (These rules will be applicable to all the examinations from the Academic Year 2023 -24)
Students seeking re-evaluation of their answer sheet(s) are advised to go through the following 
rules before they apply for re-evaluation: 

                        </p>

                        <ul class="list-numbers">
                              <li>
                              When you apply for re-evaluation, you have to submit your original Detailed Grades Card and accept the revised result in which: 

                              <ul>
                                  <li> the marks can be increased, </li>
                                  <li> the marks can be decreased, </li>
                                  <li> there can be no change. </li> 
                              </ul>


                              </li>
                              <li>Re-evaluation will be permitted only for the theory examinations conducted by this University. </li>
                              <li>No re-evaluation is allowed for examination in Sessionals, Practicals, Viva, Internal Assessment, 
Project Report, Dissertations, Thesis, Viva-Voce, or any other subjects where there is a joint 
evaluation by two examiners. 
</li>
                              <li>A student who wishes to seek re-evaluation of his or her answer sheet(s) may apply for re-evaluation on the prescribed application form along with the original Detailed Grades card or 
certificate and requisite fee within 15 days of the declaration of the result or the dispatch of the 
detailed grades card to the Examination Department, whichever is later. </li>
                              <li>Before submitting the re-evaluation form to the University Examination Department, the 
students should retain sufficient attested photocopies of their original Detailed Grades Card for 
academic purposes, seeking a job, etc. </li>
                              <li>The application form, once submitted, may be withdrawn on written request in respect of one or 
all subjects applied for re-evaluation, provided that the answer sheets have not been sent to the 
re-evaluator. No fee refund, however, will be permissible in such a situation.</li>
                              <li>All entries in the application form for re-evaluation should be complete and correct in all 
respects. The Examination Department will not be responsible for the delay or rejection of the 
case if the form is not complete in all respects or is not accompanied by a full fee and/or original 
Detailed Grades Card or Certificate. No change in the entries once made by the student shall be 
allowed after the receipt of the application by the Examination Department. Ignorance of the 
title of any subject or subject shall not be accepted as a plea for a wrong entry in the application. </li>
                              <li>No increase or decrease will be made in the marks of those failed students whose result remains 
                              unchanged even after re-evaluation. </li>
                              <li>The re-evaluation will be done under the rules framed by the Academic Council from time to 
                              time. </li>
                              <li> A refund of the fee will be admissible only in the case where re-evaluation is not possible due to 
                              some administrative or technical reasons</li>
                              <li>

                                  <ul>
                                      <li>If the difference between the re-evaluation score and the original score in a subject is less than 
15% of the maximum marks of the concerned subject, then the average of the two scores will be 
considered the final score. 
</li>
                                      <li>If the difference is more than 15%, then the answer sheet will be sent to the second re-evaluator, and the average of the best two scores out of the three scores thus available will 
be considered the final score. 
</li>
                                      <li>If, after re-evaluation, the grade of a student remains unchanged, the increase or decrease in 
marks, if any, will not be shown on the Detailed Grades Card. However, the original re-evaluated marks will be shown in the Result Sheet. 
</li>
                                      

                                  </ul>


                              </li>
                              <li> If the answer sheet is lost after the original evaluation and is not traceable for re-evaluation for 
any reason, then the concerned student will be offered a re-examination of that subject at the 
next examination. If the student does not want a re-examination, then his or her previous result 
may remain unchanged. No re-examination fee shall be charged from a student who opts for re-examination under this clause. 
</li>
                              <li> If a student who has re-appear in a subject(s) and has applied for re-evaluation but his or her 
result of re-evaluation is not declared before the next examination and he or she appears in the 
next examination in the subject(s) in which he or she had re-appear, the better of the two scores, 
‘Re-appear score’ or ‘Re-evaluation Score', would be considered. 
</li>
                              <li>The student should plan their future programme of taking examinations or admissions in 
accordance with the original result already communicated by the university until it is actually 
superseded by the re-evaluation result. The university will not take any responsibility for any 
consequences arising out of the delay in the completion of the process of re-evaluation and the 
declaration of the re-evaluated result. 
</li>
                              <li>In case the re-evaluation result has not been declared and the next supplementary or re-appear 
                              examinations are held, a student may apply for such an examination without waiting for the re-evaluation result. </li>
                              <li>Only one form will be used for one or more subjects. So be careful while selecting the subject(s) 
                              for re-evaluation. </li>
                              <li> No appeal or review against the re-evaluation would be entertained.</li>
                              <li>If, due to re-evaluation, one or more student(s) secures more marks than the original student 
securing first position in the merit list, he or she will also be eligible for the award of a gold 
medal.</li>
                                
                        </ul>
                        
                </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default ExaminationReEvaluation
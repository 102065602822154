import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BScComputerScience() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.Sc Computer Science" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p>
                                The University offers Bachelor of Science in Computer Science (Hons.) under NEP 2020 to students after completion of their +2/ 12th class examination. The Programme will be of Three-Year (Bachelor’s Degree) or Four-Year (Honours/Research) duration. The students will have the opportunity for multiple entry and exit with appropriate certification viz. Undergraduate Certificate after one year (two semesters), Undergraduate Diploma after two years (four semesters), Bachelor’s Degree after three years (six semesters) and Bachelor’s Degree with Honours/Research after 4 years (eight semesters). This will allow them to continue or leave a course as per their convenience/passion.
                                </p>

                                <p>
                                The programme is designed to help the students learn programming techniques and the syntax of one or more programming languages and thereby make them solve problems, accomplish IT tasks, and express creativity, both individually and collaboratively. It is meant for persons with a passionate interest in technology, who wish to progress in careers such as software engineer, system administrator, data scientist, financial analyst, app developer and IT consultant.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BScComputerScience
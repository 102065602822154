import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

const Placement=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="PLACEMENT" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >MVN university's Placement Cell is dedicated to bridging the gap between academia and industry, ensuring our students are well-prepared for successful careers. We offer comprehensive support services, including career counselling, resume building, interview preparation, and skill development workshops. Our strong industry connections enable us to organize regular campus recruitment drives, internships, and networking events with leading companies across various sectors. With a focus on holistic development, our Placement Cell strives to match students with opportunities that align with their skills and aspirations, ensuring a smooth transition from education to employment.</Typography>
    </Grid>
    </Grid>

)
}
export default Placement;
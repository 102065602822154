import React, { useState } from "react";
// import { Link } from "react-router-dom";
import {  Grid, Card, CardMedia, CardContent, Typography, Stack, Button, Avatar, Box, List,  ListItem, ListItemText, Link, Table, TableCell, TableHead, TableBody, TableRow } from "@mui/material";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const displayFlex={ display: 'flex', justifyContent: 'center', textAlign: 'center' }
const listItemStyle = {
  marginTop: -3,
  paddingBottom: 0,
};
const classes={
    table: {
      borderCollapse: 'collapse',
      width: '100%',
    },
    headerCell: {
      backgroundColor: '#f2f2f2',
      border: '1px solid #ddd',
      paddingLeft: '5px',
      fontWeight: 'bold',
      textAlign: 'left',
    },
    cell: {
      border: '1px solid #ddd',
      paddingLeft: '5px',
    },
  }

export const CustomTypography = (props) => (
    <Typography {...props} />
)
export const CustomButton = (props) => (
    <Button {...props} />
)
export const CustomCard = (props) => (
    <Card {...props} />
)

export const ReactSlider = (props) => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const { cardList } = props
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        // prevArrow: <PrevArrow />,
        // nextArrow: <NextArrow />,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        customPaging: (i) => (
            <div
                style={{
                    width: '8px',
                    height: '8px',
                    background: i === currentSlide ? 'red' : 'gray',
                    //   borderRadius: '5px',
                    marginRight: '5px', // Adjust the spacing between dots
                    marginTop: '10px'
                }}
            />
        ),
        beforeChange: (current, next) => setCurrentSlide(next),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Stack sx={{ marginBottom: '30px' }}>
            <Slider  {...settings} sx={{ marginBottom: '20px' }}>
                {cardList.map((card, index) => (
                    <Grid container spacing={2} >
                        <Grid item xs={12} textAlign="center">
                            <Card className="slidecard" key={index} sx={{ height: '400px',padding:'10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px' }}>
                                {card.name ?
                                    <Box justifyContent="center" alignItems="center">
                                        <Avatar src={card.img} sx={{ height: '140px', width: '140px', margin: 'auto' }} />
                                    </Box>
                                    : <CardMedia
                                        component="img"
                                        height="140"
                                        image={card.img}
                                        alt={card.title}
                                    />
                                }

                                <CardContent   className="slidecard_content">
                                    <Typography className="slidecard_title" fontSize="18px" letterSpacing={0.5} textAlign="center" height={card.name?"20px":'60px'} fontWeight="600">
                                        {card.title || card.name}
                                    </Typography>
                                    {card.name&&(<>
                                    <Typography fontSize="14px" letterSpacing={0.5} textAlign="center" height="20px" >
                                        {card.degree}
                                    </Typography>
                                    <Typography fontSize="14px" letterSpacing={0.5} textAlign="center" height="20px" marginBottom="10px" >
                                        {card.placement}
                                    </Typography>
                                    </>)}
                                    <Typography className="slidecard_description"  fontSize="14px" letterSpacing={0.5} height="120px" textAlign="center" sx={{overflowY:"auto"}}>
                                        {card.description}
                                    </Typography>
                                    {/*<Link to={card.link}>
                                        <Button color='error' variant='contained'>

                                            Read More...
                                        </Button>
                                    </Link>*/}
                                    <a href={card.link}>
                                        <Button color='error' variant='contained'>

                                            Read More...
                                        </Button>
                                    </a>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                ))}
            </Slider>
        </Stack>
    )
}


export const ListDataPresentation=({DataList,isFlex, isLink})=>(
    DataList.map((list, index) => (
      <Grid item xs={isFlex?6:12} key={index}>
        <List>
          <ListItem sx={index>0&&listItemStyle}>
            <ListItemText
              primary={
                <Typography component="span" variant="body1">
                  &#8226; 
                  {!isLink
                  ?list.listName
                  :<Link href={`/soet-cse/course/${list.link}`} color="inherit">{list.listName}</Link>
                }
                </Typography>
              }
            />
          </ListItem>
        </List>
      </Grid>
    ))
)

export const TableDataPresentation=({data})=>{
    const headers = Object.keys(data[0]);
return (
    <Table sx={classes.table}>
      <TableHead>
        <TableRow>
          {headers.map(header => (
            <TableCell sx={classes.headerCell} key={header}>{header.charAt(0).toUpperCase() + header.slice(1)}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            {headers.map(header => (
              <TableCell sx={classes.cell} key={header}>{row[header]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
)
}
import React from 'react'

function Map() {
  return (
    <>
        <div className="map fix" style={{ background: "#f5f5f5" }}>
            <div className="container-flud">
                <div className="row">
                <div className="col-lg-12">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3522.2803467608614!2d77.3323570754784!3d28.01590607600696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39732db30911a2bd%3A0x1a95b70271a44d12!2sMVN%20University!5e0!3m2!1sen!2sin!4v1691954873522!5m2!1sen!2sin" width={600} height={450} style={{ border: 0 }} allowFullScreen="" loading="lazy" />            
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Map
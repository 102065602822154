import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { baseURL } from '../../../config'
import Loader from '../Loader/Loader'
const SpecialRegex=/^[\w-\]+[A-Za-z\s]*$/g
const EmailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
function First() {
    const [isLoading, setIsLoading] = useState(false)
    const [errorStatus, setErrorStatus] = useState(false)
    const [payload, setPayload] = useState({
        name: "",
        roll_number: "",
        department: "",
        message: ""
    })
    const handleInput = (e) => {
        const name = e.target.name
        const value = e.target.value
        if(name=="roll_number"){
            if(value?.length<11){

                setPayload((values) => ({ ...values, [name]: value }))
            }
        }else{
            setPayload((values) => ({ ...values, [name]: value }))

        }

    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (payload?.name == "" || Boolean(payload?.name.match(SpecialRegex)) == false || payload?.department == "" || Boolean(payload?.department.match(SpecialRegex)) == false|| payload?.roll_number < 10) {
            setErrorStatus(true)
            setIsLoading(false)
            // var x = document.getElementById("snackbar");
            // x.className = "show";
            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
        }
        else {
            let url = `${baseURL}/studentGrievance`
            axios.post(url, payload, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",

                }
            }).then((response) => {
                var x = document.getElementById("successsnackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
                setIsLoading(false)
                setPayload({
                    name: "",
                    roll_number: "",
                    department: "",
                    message: ""
                })
            }).catch(error => {
                setIsLoading(false)
            })

        }
    }
    return (
        <>
            <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix" >
                <div className="container" style={{ background: "#e7f0f8", padding: '10px' }}>
                    <div className="row">
                        <div className="col-lg-12 order-2">
                            <div className="contact-bg">
                                <div className="section-title center-align text-center mb-50">
                                    <h2>STUDENT GRIEVANCE FORM</h2>
                                </div>
                                <form action="mail.php" method="post" className="contact-form mt-30 " >
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-name mb-30">
                                                <input type="text" id="firstn" name="name" value={payload?.name} onChange={handleInput} placeholder="Full Name *" required="" />
                                                {errorStatus && <span style={{ color: "red" }}>{Boolean(payload?.name.match(SpecialRegex)) == false ? "Special character not allowed !" : payload?.name == "" && "Please input a valid name !"}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="number" id="roll_number" name="roll_number" value={payload?.roll_number} onChange={handleInput} placeholder="Roll No. *" required="" />
                                                {errorStatus&&payload?.roll_number?.length<10&&<span style={{ color: "red" }}>Roll number should be 10 digit !</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="phone" name="department"value={payload?.department} onChange={handleInput} placeholder="Department *" required="" />
                                                {errorStatus && <span style={{ color: "red" }}>{Boolean(payload?.department.match(SpecialRegex)) == false ? "Special character not allowed !" : payload?.name == "" && "Please input a valid department !"}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-message mb-30">
                                                <textarea name="message" id="message" cols={30} rows={50} value={payload?.message} onChange={handleInput} placeholder="Grievance" defaultValue={""} />
                                                {/* <i className="icon fal fa-edit" /> */}
                                            </div>
                                            <div className="slider-btn text-center">
                                                <center><button className="btn ss-btn text-center" data-animation="fadeInRight" data-delay=".8s" onClick={handleSubmit}>
                                                    Submit
                                                </button>
                                                </center>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="snackbar">Please fill * required  field..</div>
            <div id="successsnackbar">Student Grievance submit successfully !</div>
            {isLoading&&<Loader />}
        </>
    )
}

export default First
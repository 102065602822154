import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSResearch=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Pharmaceutical Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Research & Development" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Unveiling the Pillars of Progress: Research and Development at the School of Pharmaceutical Sciences, MVN University</b>
        <br/><br/>
        Welcome to the vibrant hub of innovation and discovery at the School of Pharmaceutical Sciences, MVN University. Here, we embrace the spirit of inquiry and endeavor to unlock the mysteries of pharmaceutical science through relentless research and development initiatives. Research forms the cornerstone of our identity at the School of Pharmaceutical Sciences, MVN University. It is not merely an extracurricular pursuit but an intrinsic part of our academic ethos. We firmly believe that research is the catalyst for transformative change, driving progress and innovation in the field of pharmaceutical sciences.
        <br/><br/>
        <b>But what sets us apart? Why is research such an integral part of our DNA at SOPS, MVNU?</b>
        <br/>
        First and foremost, research fuels our quest for knowledge and understanding. In a rapidly evolving landscape, where new challenges and opportunities abound, research provides us with the tools to navigate uncharted territories and push the boundaries of scientific exploration. Moreover, research lies at the heart of our commitment to excellence. By fostering a culture of curiosity and inquiry, we empower our faculty members and students to strive for academic and intellectual excellence in everything they do. At SOPS, MVNU, we are proud to boast a diverse and dynamic community of researchers, each bringing their unique perspectives and expertise to the table. Our faculty members are not just educators but seasoned researchers with a track record of ground breaking discoveries and innovations in their respective fields. But it's not just about the faculty. Our students, too, play a pivotal role in driving research forward. Through hands-on experiential learning opportunities and mentorship programs, we cultivate the next generation of scientific leaders and innovators, empowering them to make meaningful contributions to society.
<br/><br/>
As you navigate through our website, we invite you to explore the myriad research opportunities available at SOPS, MVNU. Whether you're a prospective student, a fellow researcher, or a curious enthusiast, there's something here for everyone.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSResearch;
import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import BreadcrumbsBanner from './breadcrumbs';
import SOETHeader from './header';
import WidgetsMenu from '../muiCommonComponent/menuWidgets';
import NewMain from "../headerone/newMain";
function SOETVisionMission() {
  const headerMenus = [
    { mainHeader: 'Home', path: '/school-of-engineering-technology', subHeader: [] },
    { mainHeader: 'SOET Insights', path: '', subHeader: [
        { title: 'Insights', path: '/soet/insights', },
        { title: 'Vision & Mission', path: '/soet/vision-mission', },
        { title: 'Knowledge Partners', path: '/soet/knowledge-partners', },
        { title: 'Pedagogical Advances – MOOCs', path: '/soet/mcoos', },
    ] },
    { mainHeader: 'SOET Departments', path: '', subHeader: [
        { title: 'Computer Science & Engineering', path: '/soet-cse/home', },
        { title: 'Mechanical Engineering', path: '/soet-me/home' },
        { title: 'Civil Engineering', path: '/soet-civil/home' },
        { title: 'Electronic & Communication Engineering', path: '/soet-ece/home' },
    ] },
    { mainHeader: 'MVN Times', path: '#', subHeader: [] },
    { mainHeader: 'Scholarly Work ', path: '#', subHeader: [] },
  ];
  return (<>
  <NewMain/>
    {/* <Navbar/> */}
    <WidgetsMenu headerMenus={headerMenus}/>
         <BreadcrumbsBanner bannerUrl={"https://www.umu.ac.in/wp-content/uploads/2022/02/Vision-Mission.jpg"}/>
    <Container>
    <Grid container spacing={3}>
         {/* <Grid item xs={12} sm={12} md={12} lg={12} >
      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Vision" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        The Computer Science and Engineering department is committed to continually improve the educational environment in order to develop professionals with strong technical and research backgrounds.
        </Typography>
       </Grid>
       <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Mission" />
      </Grid>
       <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        To provide quality education in both theoretical and applied foundations of Computer Science and Engineering. Create highly skilled computer engineers, capable of being gainfully employed in the rapidly expanding Information Technology sector and also develop solutions for the betterment of the nation. To inculcate social sensitivity, economic prudence, professional and ethical values and environmental consciousness among students.
        </Typography>
      </Grid>
      </Grid>
      </Container>
      </> )
}
export default SOETVisionMission;

import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function MPharm() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="M Pharm" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                M.Pharm. (Master of Pharmacy) is a postgraduate academic degree which is awarded for a course or program in the medical field of Pharmacy. The duration of Master Degree in Pharmacy is 2 years. M.Pharm. is divided into four semesters. At the end of the each semester, there is an examination wherein candidates are examined in the courses prescribed for that semester.
                                </p>

                                <div className="about-title second-title pb-25">                                    
                                    <h3>Course Details</h3>
                                </div>

                                <p>
                                Master of Pharmacy (M.Pharm) in Pharmaceutics is a Post-graduate course. The course lasts for a period of 2 years. Duration of this Academic program is 2 years (Exam Pattern – Semester System).
                                </p>

                                <div className="about-title second-title pb-25">                                    
                                    <h3>Eligibility Criteria</h3>
                                </div>

                                <p>
                                    For admission to first year of M.Pharm, the minimum academic qualification is:
                                    Minimum 60% (55% for SC/ST Candidates) marks in aggregate in B.Pharm.
                                </p>
                                
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Career Options</h3>
                                </div>
                               
                                <p>
                                    Before going on M. Pharmacy career scope, we should know about “Pharmacy”. “Pharmacy is the branch of health sciences that deals with the preparation and dispensing of drugs. The aim of pharmacy is the assurance of the safe and effective use of medicines”. Pharmacists are health professionals and the experts of pharmacy field; they have done their studies in medicines. They can use their knowledge of medicines for the benefit of patients and society.
                                </p>

                                <p>
                                In older ages, the pharmacists were engaged with dispensing of drugs only. But, the new era is of research and technology. Hence, the pharmacy syllabus cannot be concise to dispensing and preparations of drugs
                                </p>

                                <div className="about-title second-title pb-25">                                    
                                    <h3>Scope of M.Pharm (Pharmaceutics)</h3>
                                </div>

                                <p>
                                    This field is related to production of medicines and technology that is used in production of drugs used in pharmaceutical companies. Master of Pharmacy degree in pharmaceutics has scope in the formulations of drugs, research in formulations and excipients, manufacturing, drug regulatory affairs.
                                </p>


                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Formulation & Development
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Production
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Advanced materials and delivery system
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Patents & IPR
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Cosmetics
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        QC & QA Testing
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Private Entrepreneurship
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Packaging
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Drug regulatory affairs
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Academics
                                        </div>    
                                    </li>
                                </ul>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MPharm
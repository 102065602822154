import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BBA() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.B.A" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p> 
                                    In the post Covid Era, setting up a new business or running the existing one is not a simple task for business owners. Businesses in this competitive age are witnessing ever increasing technological innovation and implementation of these new technologies to compete in all functional areas of their businesses. These happening have made competition somewhat unusual as compared to last century. To encounter the challenges created by fast pace innovation in technology and being updated to work in this technologically changing environment of both product development and processes, pursuing a program in Bachelor of Business Administration (BBA) of MVN university which oriented towards learning applications of digital technologies, changing strategies (fiancé, operations and marketing), and computational business analytics will be always beneficial for the students in this fast growing region of NCR. As it is known fact that in the present day context, even large companies with very high market capitalization are struggling to level their net incomes. Hiring and laying off employees is the routine activities in these companies to fulfil need of new business knowledge workers who are talented and imbibed the quality of being a leader, strategic thinker, innovative mind, and problem-solvers. This gap if any will be filled by BBA qualified manager in days to come.
                                </p>

                                <p>
                                    To above stated goals of companies, MVN university's BBA program is one of the most sought-after Bachelor’s degree programs for students after Class 12. The MVN university BBA program is the gateway of job opportunities in business domains such as Sales, Marketing, Education, Finance, digital technologies, computational business analytics, human resources, business communication, Government sectors etc., Further elaborating, BBA of MVN university is a three-year professional Undergraduate course in Business Management. It is open to students from all three streams i.e., Science, Arts and Commerce. The BBA course offers knowledge and training in management and leadership skills to prepare them for managerial roles and entrepreneurship. The program teaching methods are oriented to meet the aspirations of country with emphasis on girls and boys from rural and urban youth who can develop self-sustainability and become a torch bearer to the upcoming generations. This program is cultivated with motto of professionals to excel at either employee or business portfolio. The curriculum is developed in lieu of required skill set being desired at major corporates and business world. This program will endure the candidate to possess the knowledge of techno-commercial aspects of Indian Business Segment.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BBA
import { useState, useEffect } from "react";

export function PersonalInfo({ state, setState, currentStep, setCurrentStep, errorStatus, setErrorStatus, setAddressCheck, addressCheck }) {
    const SpecialRegex = /^[\w-\]+[A-Za-z\s]*$/g
    const EmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name == "student_phone") {
            if (value.length <= 10) {
                setState((values) => ({ ...values, [name]: value }))
            }
        } else if (name == "mother_aadhar_num" || name == "father_aadhar_num" || name == "student_aadhar_num") {
            if (value.length <= 12) {
                setState((values) => ({ ...values, [name]: value }))
            }
        }
        else if (name == "pin_code" || name == "permanent_pin_code") {
            if (value.length <= 6) {
                setState((values) => ({ ...values, [name]: value }))
            }
        }
        else {
            setState((values) => ({ ...values, [name]: value }))
        }
    }
    const handleNext = () => {
        if (state.student_name == "" || Boolean(state.student_name.match(SpecialRegex)) == false || Boolean(state?.student_email.match(EmailRegex)) == false || state?.student_phone?.length < 10) {
            setErrorStatus(true)
        }
        else {
            setCurrentStep(currentStep + 1)
            setErrorStatus(false)
        }
    }
    useEffect(() => {
        if (addressCheck) {
            setState((values) => ({
                ...values,
                postal_address: state.permanent_address,
                city: state.permanent_city,
                state: state.permanent_state,
                pin_code: state.permanent_pin_code,
                // permanent_address:state.postal_address,
                // permanent_city:state.city,
                // permanent_state:state.state,
                // permanent_pin_code:state.pin_code
            }))
        }
    }, [addressCheck])
    return (
        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-7">
                        <h2 className="fs-title">Personal Information:</h2>
                    </div>
                    <div className="col-5">
                        <h2 className="steps">Step 1 - 3</h2>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Name *</label>
                        <input type="text" name="student_name" value={state?.student_name} onChange={handleInput} placeholder="Name" required />
                        {errorStatus && <span style={{ color: "red" }}>{Boolean(state?.student_name.match(SpecialRegex)) == false ? "Special character not allowed !" : state?.student_name == "" && "Please input a valid name !"}</span>}
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">E-mail *</label>
                        <input type="email" name="student_email" value={state?.student_email} onChange={handleInput} placeholder="email" />
                        {errorStatus && Boolean(state?.student_email.match(EmailRegex)) == false && <span style={{ color: "red" }}>Please input a valid email !</span>}
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Phone No * </label>
                        <input type="number" maxLength={10} name="student_phone" value={state?.student_phone} onChange={handleInput} placeholder="Phone Number" />
                        {errorStatus && state?.student_phone?.length < 10 && <span style={{ color: "red" }}>Phone number should be 10 digit !</span>}
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Father`s Name Mr. </label>
                        <input type="email" name="father_name" value={state?.father_name} onChange={handleInput} placeholder="Father`s Name" required />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Mother`s Name Mrs. </label>
                        <input type="text" name="mother_name" value={state?.mother_name} onChange={handleInput} placeholder="Mother`s Name Mrs." required />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Guardian’s Name (if other than parents) </label>
                        <input type="email" name="father_name" value={state?.father_name} onChange={handleInput} placeholder="Guardian’s Name" required />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Relationship </label>
                        <input type="text" name="gurdain_relationship" value={state?.gurdain_relationship} onChange={handleInput} placeholder="Relationship" required />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Annual Income of Father or Gurdian</label>
                        <input type="text" name="gurdian_annual_incom" value={state?.gurdian_annual_incom} onChange={handleInput} placeholder="Annual Income" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Profession</label>
                        <input type="text" name="gurdain_profession" value={state?.gurdain_profession} onChange={handleInput} placeholder="Profession" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Nationality</label>
                        <input type="text" name="student_nationality" value={state?.student_nationality} onChange={handleInput} placeholder="Nationality" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Religion</label>
                        <input type="text" name="student_religion" value={state?.student_religion} onChange={handleInput} placeholder="Religion" />
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <label className="fieldlabels">Date of Birth</label>
                        <input type="date" name="student_dob" value={state?.student_dob} onChange={handleInput} placeholder="Religion" />
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                        <label className="fieldlabels">Gender</label>
                        <select
                            onChange={handleInput}
                            value={state.gender}
                            name="gender">
                            <option value=''>Select</option>
                            <option value='Male'>Male</option>
                            <option value='Female'>Female</option>
                            {/* <option value='Other'>Other</option> */}
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-3">
                        <label className="fieldlabels">Category</label>
                        <select
                            onChange={handleInput}
                            value={state.category}
                            name="category">
                            <option value=''>Select</option>
                            <option value='GEN(HR)'>GEN(HR)</option>
                            <option value='GEN(All India)'>GEN(All India)</option>
                            <option value='SC(HR)'>SC(HR)</option>
                            <option value='ST(HR)'>ST(HR)</option>
                            <option value='OBC(HR)'>OBC(HR)</option>
                            <option value='PH'>PH</option>
                            <option value='KM'>KM</option>
                            {/* <option value='Minorties'>Minorties</option>
                            <option value='EWS(HR)'>EWS(HR)</option>
                            <option value='Other State'>Other State</option> */}
                        </select>
                    </div>
                    
                    {/* <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Disablity if any</label>
                        <select
                            onChange={handleInput}
                            value={state.disablity}
                            name="disablity">
                            <option value=''>Select</option>
                            <option value='Eyes/Eyesight'>Eyes/Eyesight</option>
                            <option value='Hearing'>Hearing</option>
                            <option value='Physical Challenged'>Physical Challenged</option>
                            <option value='Other'>Other</option>
                        </select>
                    </div> */}
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Permanent Address </label>
                        <input type="text" name="permanent_address" value={state?.permanent_address} onChange={handleInput} placeholder="Address" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">City</label>
                        <input type="text" name="permanent_city" value={state?.permanent_city} onChange={handleInput} placeholder="City" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">State</label>
                        <input type="text" name="permanent_state" value={state?.permanent_state} onChange={handleInput} placeholder="State" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Pin Code</label>
                        <input type="number" name="permanent_pin_code" value={state?.permanent_pin_code} onChange={handleInput} placeholder="Pin Code" />
                    </div>
                    <div className="col-sm-1 col-md-1 col-lg-1">
                        <input type="checkbox" checked={addressCheck} name="category" onChange={() => setAddressCheck(!addressCheck)} value="Same as above address" />
                    </div>
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <label for="vehicle1">Same as above address</label>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels">Postal Address </label>
                        <input type="text" name="postal_address" value={state?.postal_address} onChange={handleInput} placeholder="Address" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">City</label>
                        <input type="text" name="city" value={state?.city} onChange={handleInput} placeholder="City" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">State</label>
                        <input type="text" name="state" value={state?.state} onChange={handleInput} placeholder="State" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Pin Code</label>
                        <input type="number" name="pin_code" value={state?.pin_code} onChange={handleInput} placeholder="Pin Code" />
                    </div>
                </div>
            </div>
            <input type="button" name="next" className="next action-button" value="Next" onClick={handleNext} />
        </fieldset>
    );
}



export function Qualification({ currentStep, setCurrentStep, state, setState }) {
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState((values) => ({ ...values, [name]: value }))
    }
    const handleSubjectChange = (e, ind) => {
        const name = e.target.name;
        const value = e.target.value;
        let updatedSubject = state?.subjects.map((item, index) => {
            if (ind == index) {
                item[name] = value
            }
            return item
        })
        setState((values) => ({ ...values, subjects: updatedSubject }))

    }
    const handleEntanceExam = (e, ind) => {
        const name = e.target.name;
        const value = e.target.value;
        let updatedEntrance = state?.subjects.map((item, index) => {
            if (ind == index) {
                item[name] = value
            }
            return item
        })
        setState((values) => ({ ...values, entrance_exam: updatedEntrance }))

    }
    useEffect(()=>{
        if(state.facilities=="No"){
            setState((values)=>({...values,type_facilities:'',pickup_station:''}))
        }
        if(state.transport_service=="No"){
            setState((values)=>({...values,pickup_station:''}))
        }

    },[state.facilities,state.transport_service])
    return (
        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-sm-12 col-md-7 col-lg-7">
                        <h2 className="fs-title">Eligibility Examination Details</h2>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-5">
                        <h2 className="steps">Step 2 - 3</h2>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <table id="admission">
                            <tr>
                                <th class="column-1"><strong>Entrance Examination</strong></th>
                                <th class="column-1"><strong>Roll Number</strong></th>
                                <th class="column-1"><strong>Marks Obtained</strong></th>
                                <th class="column-1"><strong>Percentage</strong></th>
                            </tr>
                            {state?.entrance_exam.map((exam, ind) => (
                                <tr>
                                   <td><strong>{exam.entrance_type}</strong></td>
                                    <td><input type="text" name="roll_num" value={exam.roll_num} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                    <td><input type="number" name="mark_obtained" value={exam.mark_obtained} onChange={(e) => handleEntanceExam(e, ind)} /></td>
                                    <td><input type="number" name="percentage" value={exam.percentage} onChange={(e) => handleEntanceExam(e, ind)} /></td>
               
                                </tr>
                            ))}

                        </table>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels"><b>Academic Qualifications</b></label>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: 10 }} >
                        <table id="admission">
                            <tr>
                            <th class="column-1"><strong>Examination</strong></th>
                                <th class="column-1"><strong>Subject</strong></th>
                                <th class="column-1"><strong>Board</strong></th>
                                <th class="column-1"><strong>Year</strong></th>
                                <th class="column-1"><strong>Percentage<br/>Marks /CGPA</strong></th>
                                <th class="column-1"><strong>Division</strong></th>

                            </tr>
                            {state?.subjects.map((qualify, ind) => (
                                <tr>
                                    <td><strong>{qualify.examination}</strong></td>
                                    <td><input type="text" name="subject" value={qualify.subject} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="text" name="board" value={qualify.board} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="year" value={qualify.year} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="percentage" value={qualify.percentage} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="division" value={qualify.division} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                </tr>
                            ))}

                        </table>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Do you have any Pending Supplementary papers?</label>
                        <select
                            onChange={handleInput}
                            value={state.pending_supplementary}
                            name="pending_supplementary">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                    {state.pending_supplementary == "Yes" &&
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <label className="fieldlabels">Pending Supplementary Detail</label>
                            <input type="text" name="pending_supplementary_detail" value={state?.pending_supplementary_detail} onChange={handleInput} placeholder="Supplementary Detail" />
                                
                        </div>
                    }
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Do you have any disqualified Board or University?</label>
                        <select
                            onChange={handleInput}
                            value={state.disqualified_board}
                            name="disqualified_board">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                    {state.disqualified_board == "Yes" &&
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <label className="fieldlabels">Disqualified Detail</label>
                            <input type="text" name="disqualified_board_detail" value={state?.disqualified_board_detail} onChange={handleInput} placeholder="Supplementary Detail" />
                                
                        </div>
                    }
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Any disciplinary case is pending against you?</label>
                        <select
                            onChange={handleInput}
                            value={state.disciplinary_case}
                            name="disciplinary_case">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                    {state.disciplinary_case == "Yes" &&
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <label className="fieldlabels"> Disciplinary Case Detail</label>
                            <input type="text" name="disciplinary_case_detail" value={state?.disciplinary_case_detail} onChange={handleInput} placeholder="Disciplinary Case Detail" />
                                
                        </div>
                    }
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Any criminal case is pending against you? </label>
                        <select
                            onChange={handleInput}
                            value={state.criminal_case}
                            name="criminal_case">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                    {state.criminal_case == "Yes" &&
                        <div className="col-sm-12 col-md-8 col-lg-8">
                            <label className="fieldlabels">Criminal Case Detail</label>
                            <input type="text" name="criminal_case_detail" value={state?.criminal_case_detail} onChange={handleInput} placeholder="Criminal Case Detail" />
                                
                        </div>
                    }
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels"><b>School/Department in which the candidate proposes to work</b></label>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                            <label className="fieldlabels">Subject Area</label>
                            <input type="text" name="subject_area" value={state?.subject_area} onChange={handleInput} placeholder="Subject Area" />
                                
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label className="fieldlabels">Department</label>
                            <input type="text" name="department" value={state?.department} onChange={handleInput} placeholder="Department" />
                                
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <label className="fieldlabels">School</label>
                            <input type="text" name="school" value={state?.school} onChange={handleInput} placeholder="" />
                                
                        </div>
                </div>
                <input type="button" name="next" className="next action-button" value="Next" onClick={() => setCurrentStep(currentStep + 1)} />
                <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={() => setCurrentStep(currentStep - 1)} />
            </div>
        </fieldset>
    )
}
export function ApplyCourse({ state, setState, currentStep, setCurrentStep, handleSubmit }) {
    
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setState((values) => ({ ...values, [name]: value }))
    }
    const handleSubjectChange = (e, ind) => {
        const name = e.target.name;
        const value = e.target.value;
        let updatedSExperience = state?.experienceList.map((item, index) => {
            if (ind == index) {
                item[name] = value
            }
            return item
        })
        setState((values) => ({ ...values, experienceList: updatedSExperience }))

    }
    const handleEmployementChange = (e, ind) => {
        const name = e.target.name;
        const value = e.target.value;
        let updatedSExperience = state?.experienceList.map((item, index) => {
            if (ind == index) {
                item[name] = value
            }
            return item
        })
        setState((values) => ({ ...values, employmentList: updatedSExperience }))

    }
    return (

        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-10">
                        <h3 className="fs-title"> Research/Professional Experience, Paper Published etc.: (Attach Separate sheet for detail, if required )
</h3>
                    </div>
                    <div className="col-2">
                        <h2 className="steps">Step 3 - 3</h2>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginTop: 10 }} >
                        <table id="admission">
                            <tr>
                            <th class="column-1"><strong>Name of University/Institute/Industry</strong></th>
                                <th class="column-1"><strong>Period </strong></th>
                                <th class="column-1"><strong>Position Held/Nature of duties</strong></th>
                                <th class="column-1"><strong>Name of Supervisor</strong></th>
                                <th class="column-1"><strong>Title of Dissertation /Topic of Research /Paper Published/ Patent Filed</strong></th>
                            </tr>
                            {state?.experienceList.map((qualify, ind) => (
                                <tr>
                                    <td><input type="text" name="universitry_name" value={qualify.universitry_name} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="text" name="period" value={qualify.period} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="position" value={qualify.position} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="supervisor_name" value={qualify.supervisor_name} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                    <td><input type="number" name="patent_filed" value={qualify.patent_filed} onChange={(e) => handleSubjectChange(e, ind)} /></td>
                                </tr>
                            ))}

                        </table>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <label className="fieldlabels"><b>Employment details (after acquiring the qualifying degree)</b></label>
                    </div>
                    <table id="admission">
                        <tr>
                        <th class="column-1" colSpan={4}><strong>Total duration (in months): </strong></th>
                        </tr>
                            <tr>
                            <th class="column-1"rowSpan={2}><strong>Name and Address of the Organization </strong></th>
                                <th class="column-1"rowSpan={2}><strong>Designation </strong></th>
                                <th class="column-1"colSpan={2}><strong>Date</strong></th>
                            </tr>
                            <tr>
                            <th class="column-1"><strong>from</strong></th> 
                            <th class="column-1"><strong>to</strong></th>  
                            </tr>
                            {state?.employmentList.map((employ, ind) => (
                                <tr>
                                    <td><input type="text" name="organization_name" value={employ.organization_name} onChange={(e) => handleEmployementChange(e, ind)} /></td>
                                    <td><input type="text" name="designation" value={employ.designation} onChange={(e) => handleEmployementChange(e, ind)} /></td>
                                    <td><input type="date" name="from_date" value={employ.from_date} onChange={(e) => handleEmployementChange(e, ind)} /></td>
                                    <td><input type="date" name="from_to" value={employ.from_to} onChange={(e) => handleEmployementChange(e, ind)} /></td>
                                </tr>
                            ))}

                        </table>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <label className="fieldlabels"> Please describe in brief about your proposed area of research</label>
                            <textarea type="text" name="propsed_area" value={state?.propsed_area} onChange={handleInput} placeholder="" />
                                
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <label className="fieldlabels"> Elaborate your career objectives</label>
                            <textarea type="text" name="career_objectives" value={state?.career_objectives} onChange={handleInput} placeholder="" />
                                
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <label className="fieldlabels"> How do you think that Ph.D. programme shall help you to achieve your career objectives?</label>
                            <textarea type="text" name="phd_programme" value={state?.phd_programme} onChange={handleInput} placeholder="" />
                                
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label className="fieldlabels"> References with contact phone</label>
                            <input type="number" name="references_mobile" value={state?.references_mobile} onChange={handleInput} placeholder="" />
                                
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <label className="fieldlabels">References with contact email</label>
                            <input type="text" name="references_email" value={state?.phd_programme} onChange={handleInput} placeholder="" />
                                
                        </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Present employment status </label>
                        <select
                            onChange={handleInput}
                            value={state.employment}
                            name="employment">
                            <option value=''>Select</option>
                            <option value='Employed'>Employed</option>
                            <option value='Not Employed'>Not Employed</option>
                        </select>
                    </div>
                        <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Hostel Facility </label>
                        <select
                            onChange={handleInput}
                            value={state.hostel}
                            name="hostel">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="fieldlabels">Transport Service </label>
                        <select
                            onChange={handleInput}
                            value={state.transport}
                            name="transport">
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                        </select>
                    </div>
                </div>
            </div> <input type="button" name="next" className="next action-button" value="Submit" onClick={handleSubmit} />
            <input type="button" name="previous" className="previous action-button-previous" value="Previous" onClick={() => setCurrentStep(currentStep - 1)} />
        </fieldset>
    )
}

export function FinishScreen({ }) {
    return (
        <fieldset>
            <div className="form-card">
                <div className="row">
                    <div className="col-7">
                        <h2 className="fs-title">Finish:</h2>
                    </div>
                    <div className="col-5">
                        <h2 className="steps">Step 3 - 3</h2>
                    </div>
                </div> <br />
                <h2 className="purple-text text-center"><strong>SUCCESS !</strong></h2> <br />
                <div className="row justify-content-center">
                    <div className="col-3"> <img src="https://i.imgur.com/GwStPmg.png" className="fit-image" /> </div>
                </div> <br />
                <div className="row justify-content-center">
                    <div className="col-7 text-center">
                        <h5 className="purple-text text-center">You Have Successfully Filling form</h5>
                    </div>
                </div>
            </div>
        </fieldset>
    )

}

  // Create similar components for Step1 and Step3

import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function ProViceChancellorMessage() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Vice Chancellor’s Message" />
      <section className="about-area about-p pt-60 pb-60 p-relative fix">
        <div className="animations-02">
          <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" > 
                <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/pro_vc_chancellor_imge.jpg`}  alt="img" className="vc-img" />
                <div className="vc-about"> 
                  <p><strong>Dr. N.P. Singh </strong> <br/>PVC, MVN University   </p> 
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                <div className="about-title second-title pb-25">
                  {/* <h5>
                    <i className="fal fa-graduation-cap" /> Chancellor’s Message
                  </h5> */}
                  <h2>Pro Vice Chancellor Message</h2>
                </div>
                <p >
                It is with immense pleasure that I reach out to you through this message, as the Pro Vice-Chancellor of MVN University. This message is a testament to our commitment to nurturing a futuristic student talent for rapidly evolving corporate world. At MVN University, we understand the pivotal role of our stakeholders to improve the education scenario matching with need of hour continuously. Education goes beyond the boundaries of the classroom, and it is the synergy between academia and the corporate sector that truly empowers our students to excel in their careers with an inclusive and equitable education. We are proud of our students who have passed through our gates, armed with not only knowledge but also the values of integrity, leadership, and innovation. The quality of education they receive at our institution is a testament to the dedication and expertise of our faculty, who work tirelessly to ensure that our students are well-prepared for the professional world.
 
                
                </p>
                                
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ProViceChancellorMessage
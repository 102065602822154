import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function MBA() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="MBA (Marketing/Finance/HR/Business Analytics)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p> 
                                    Master’s of Business Administration is one of the most popular postgraduate qualifications across the globe. Graduates of all types such as – Art, Science, commerce, engineers, and doctors aspire to this qualification. A highly lucrative career awaits those who complete an MBA with flying colors. Jobs are available in almost every sector, including corporate houses, banks and financial institutions, media, export/import firms, manufacturing companies, non-profit organization, as well as teaching jobs in colleges and universities. After completing an MBA, one could also consider entrepreneurship.
                                </p>

                                <p>
                                School of Business Management & Commerce ensures the best inputs for its students with the use of state-of-the-art teaching aids, guest lectures by successful professionals, industry visit and much more. The faculty is committed to bringing out the best in each student so that they are equipped with skills that will stand them in good stead throughout their lives.
                                </p>

                                <p>
                                    MBA course is divided into 2 years. Each year is comprised of two semesters of six months each. Therefore there is total four semester spread over 2 year’s duration.
                                </p>

                                <h4>MBA Specializations in</h4>

                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Marketing + Finance
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        HRM + Marketing
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Marketing + Banking & Insurance
                                        </div>    
                                    </li>
                                    
                                </ul>
                                <h4> Eligibility </h4>
                                <p>
                                    A candidate aspiring for admission in MVN should have at least three years Bachelor’s Degree or equivalent in any discipline with min 50% marks. Final year students can also apply.
                                </p>
                                <h4> Curriculum </h4>
                                <p>In the present business environment, it can be very difficult to go up the ladder without management qualification. It is because of the complexity of the surrounding decision environment. Hence, necessary skills and knowledge into various management areas viz. Finance, Marketing, Accounting and Operations are required to meet with the situations and to have holistic development of an individual. At MVN University overall emphasis is on involving the students in learning an application of knowledge to business requirements. The MBA programme also aims to integrate the use of computers and IT in different functional areas of management. Emphasis is also laid on developing effective communication skills vital in the present scenario. The curriculum of MBA at MVN has been designed sharpen the focus of students in various domains so as to be quite strong in various concepts, policies, techniques require to tackle various unprecedented situations.  </p>
                                <p>
                                    Our evaluation system for the MBA has been designed to achieve the following:
                                </p>
                                <p>
                                    To evaluate the progress of learning of each student
                                </p>
                                <p>
                                    Prescribe and promote uniform standards of comprehension.
                                </p>
                                <p>
                                    Encourage a healthy competition among the students.
                                </p>
                                <p>
                                We follow a system of continuous evaluation. Throughout the term, a student is tested on his her ability to understand concepts, learn techniques and apply them to problems in the real world through assignments, sessions, projects work, workshops /seminars, class participation’s and end examinations.
                                </p>

                                <h4> Pedagogy </h4>
                                <p>The core objective of the School of Business Management & Commerce is to introduce a student to the professional world by exploring their inherent talent and skills. To bring the best out of a student, we have strategically designed courseware & teaching methodology. The implementation and retention of knowledge by the students is of paramount importance for securing a successful future, our academia takes utmost care to in imparting the knowledge interwoven with wisdom in an effective manner. No effort is spared to ensure teaching methodology is delivered in its best manner. The journey of a student at School of Business Management & Commerce from student life to an aspiring professional gets concluded after he goes through a joyful days of journey wherein each of the following teaching methodology contributes to his success</p>

                                <h4>Case Study Learning</h4>
                                <p>In management Curriculum Case study learning proves to be a very productive platform for the students to work upon their ability to analyze problems and developing a rational approach to problem solving techniques.</p>

                                <h4>Group Discussions</h4>
                                <p>Group discussion makes high quality decisions. Most group communication disrupts progress towards accomplishing tasks, but counteractive communication can bring people back to rational inquiry. Group interaction has a positive impact on the final decision by coming up with a quality solution and top to all it makes students more expressive.</p>

                                <h4>Industry Visits</h4>
                                <p>During the course students get the advantage of visiting various corporate and industrial houses and get a feel of working environment prevailing there. The visits are aimed at making the students able to correlate academics with the practical world.</p>
                            
                                <h4>Classroom Learning</h4>
                                <p>Classroom learning gives the students a very relaxed environment where they can grasp the knowledge bestowed upon them by theoretical lectures delivered by distinguished faculty. Classroom learning also gives them the opportunity to engage themselves in question and answer sessions</p>
                            
                                <h4>Presentations</h4>
                                <p>At periodic intervals, the students are encouraged to undertake project work in their chosen subject under the guidance of their guide faculty. The idea is to encourage students work with the industry people ,learn and understand the culture.</p>
                            
                                <h4>Role Playing</h4>
                                <p>Problem-solving; communications; and self-awareness, these skills cannot be learned by reading any number of books, although a little didactic material can be helpful in creating an intellectual framework for the accommodative learning. Rather, the kinds of skills needed for flexible, creative, rational thinking must be exercised, practiced, and learned in a process of interaction, risk-taking, self-expression, feedback, encouragement, and, in short, Role Playing is a process which is closer to learning to swim than learning the capitol cities of the various states.</p>
                            
                                <h4>Brain Storming Sessions</h4>
                                <p>The purpose of Brain Storming session is to</p>
                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            To introduce the practice of idea collection prior to beginning tasks such as writing or solving problems to teach acceptance and respect for individual differences.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            To encourage learners to take risks in sharing their ideas and opinions.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            To demonstrate to students that their scientific knowledge and their language abilities are valued and accepted.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            To provide an opportunity for students to share ideas and expand their existing knowledge by building on each other’s contributions.
                                        </div>    
                                    </li>                                    
                                </ul>

                                <h4>PDP</h4>
                                <p>Our earnest effort throughout the year is to help students develop all the qualities lying dormant in them. The students are made to attend Personality Development Programme in addition to their regular classes. The sessions are conducted meticulously by expert faculty and external resource persons who attempt to hone innate skills of the students. The different subjects which are taken up in the programme are public speaking skills, listening and talking skills, presentation skills, grooming skills, social skills and etiquettes etc. This course prepares the participants to handle all kinds of interview panels with success. A strong emphasis is laid to enhance EQ of the students so that they know how to handle stress, failures and negativity in them.</p>

                                <h4>Seminars</h4>
                                <p>A strong emphasis is laid on seminars and interactive events throughout the year. These events make a lot of difference to the budding managers and engineers who are at the infancy stage of their career with us. Students get a chance to have a glimpse of the world where they would join soon after graduating. Various Coordinators are also outsourced to conduct such seminars and events on annual basis for the benefit of students. Some of the key events that take place are anger management, meditation, and art of living, negotiating skills, leadership skills, time management and stress management.</p>                    
                            
                                <h4>Summer Internship</h4>
                                <p>After completing the first year of the programme, as a part of the academic curriculum requirement, MBA students at MVN are required to undertake Six Weeks summer internship and project with an organization. The objective is to get students to go through internship and complete a project given by the organization whereby they can gain practical experience while applying the concepts they have learnt. In most of the cases students are able to get pre placement offer due to their work & performance shown during summer training.</p>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MBA
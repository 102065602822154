import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Main from '../headerone/Main';
import { Stack } from '@mui/material';
// import Background from '..//assets/homepage/banner1.jpg'
// import Backgroundtwo from '..//assets/homepage/banner2.jpg'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-left"></i></button>
    );
}

function Sliderone() {

    const settings = {
        //     dots: true,
        //   infinite: true,
        //   speed: 500,
        //   slidesToShow: 1,
        //   slidesToScroll: 1
        autoplay: true,
        autoplaySpeed: 10000,
        dots: false,
        fade: true,
        // arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const [currentLinkIndex, setCurrentLinkIndex] = React.useState(0);
    const links = [
      {url:"/phd-admission-form",label:'Ph.D ADMISSION SESSION : 2024-24 (WINTER)'},
      {url:"/phd-admission-form",label:'2nd International conference on campus to corporate industry 4.0 (14-03-2024)'}, 
    ];
  
    React.useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentLinkIndex((prevIndex) => (prevIndex + 1) % links.length);
      }, 5000); // Adjust the interval duration as needed
  
      return () => clearInterval(intervalId);
    }, [links.length]);
    return (
        <>
            {/* <Slider className="slider-active" style={{ background: "#141b22" }} {...settings}>
                <div>
                    <img src="/assets/homepage/video.jpg" className='slider-height' />
                </div>
                <div>
                    <img src="/assets/homepage/banner16.jpg" className='slider-height' />
                </div>
                <div>
                    <img src="/assets/homepage/banner4.jpg" className='slider-height' />
                </div>
                <div>
                    <img src="/assets/homepage/banner1.jpg" className='slider-height' />
                 
                </div>
            </Slider> */}
            <div className="video-banner">
                <video src="/video_banner.mp4" autoPlay loop muted controls={false}>
                    Your browser does not support the video tag.
                </video>
            </div>
               
            <section className="service-details-two p-relative">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12" style={{background:'#07123d',marginTop:'10px'}}>
            <marquee width="100%" direction="left" height="30px" >
                <Stack flexDirection={'row'} justifyContent={'space-between'} marginRight={'50px'}>
            <Link to="/phd-admission-form"><h4 style={{color:"#fff"}}>Ph.D ADMISSION SESSION : 2024-25 (WINTER)</h4></Link>
            <Link><h4 style={{color:"#fff",marginLeft:'20px'}}>|</h4></Link>
           <a href='/International_conference.pdf' style={{color:'#fff',marginLeft:'20px'}} target='_blank'><h4 style={{color:'#fff'}}>2nd International conference on campus to corporate industry 4.0 (14-03-2024)</h4></a>
            </Stack>
</marquee>
{/* <div style={{ width: '100%', overflow: 'hidden' }}>
      <div style={{ display: 'flex', animation: 'marquee 30s linear infinite',justifyContent:'flex-start' }}>
        {links.map((link, index) => (
          <div key={index} >
            <Link to={link.url} style={{ color: '#fff', textDecoration: 'none' }}>
              <h4 style={{color:"#fff"}}>{link.label}</h4>
            </Link>
          </div>
        ))}
      </div>
    </div> */}


            </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="services-box07">
                                <div className="sr-contner">
                                    <div className="icon">
                                        <img src="/assets/img/icon/sve-icon4.png" alt="icon01" />
                                    </div>
                                    <div className="text">
                                        <h5>
                                            <Link to="/">NAD - ABC Scheme</Link>
                                        </h5>
                                        <p>
                                            As per National Education Policy 2020, the Academic Bank of Credits (ABC) has been envisaged to facilitate the academic mobility of students with the freedom to study across the Higher Education Institutions in the country with an appropriate “credit transfer” mechanism from one programme to another, leading to attain a Degree/ Diploma/PG-diploma, etc.,
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="services-box07 active mobile-spacing">
                                <div className="sr-contner">
                                    <div className="icon">
                                        <img src="/assets/img/icon/sve-icon5.png" alt="icon01" />
                                    </div>
                                    <div className="text">
                                        <h5>
                                            <Link to="/">Eligibility for admission aspirants</Link>
                                        </h5>
                                        <p>
                                            The candidates need to check the eligibility criteria of their desired programme while applying for MVN  University admission 2023. The application fee of MVN  University is Rs 1100 and can be paid through debit card or credit card.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="services-box07 mobile-spacing">
                                <div className="sr-contner">
                                    <div className="icon">
                                        <img src="/assets/img/icon/sve-icon6.png" alt="icon01" />
                                    </div>
                                    <div className="text">
                                        <h5>
                                        <Link to="/phd-admission-form">Inviting applications for Ph.D
                                                Program for Winter Session 2024-25</Link>
                                        </h5>
                                        <p>
                                            Providing all the necessary information will help prospective applicants understand the application process and requirements, leading to a higher likelihood of qualified candidates applying to your Ph.D. program.
                                        </p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Sliderone
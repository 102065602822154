import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOMba() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="PEO, PO & PSO – MBA" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p>
                                [PEO.1]: To imbibe contemporary business management skills to develop strategies of managing in the era of disruptions in recovering markets and to deploy them in successfully running business organizations.
                                </p>

                                <p>
                                [PEO.2]: To enhance interpersonal skills interaction for business communication (written and oral) and learning skills to work under difficult economic and managerial situations.
                                </p>

                                <p>
                                [PEO.3]: To enhance the in-depth understanding of business activities for evidence based decision making by imposing analytical skills and project based learning.
                                </p>
                                <p>
                                [PEO.4]: To develop capabilities of applying integrated tools techniques and concepts from multiple functional areas of management (i.e. Human Resource, Management, finance& Accounts, Marketing, Banking & Insurance, International Business, Operational Management, Information technology, Analytics, and allied subjects) to solve business problems.
                                </p>

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                [PO.1]: Having an ability to apply Management theories and practices to solve Business Problems.
                                </p>

                                <p>
                                [PO.2]: Having an ability for Data Based Decision Making.
                                </p>

                                <p>
                                [PO.3]: Having an ability to imbibe value based Leadership.
                                </p>

                                <p>
                                [PO.4]: Having an ability to lead and achieve Business goals by Creating team Environment.
                                </p>

                                <p>
                                [PO.5]: Having an ability of being socially and environmentally sensitive human being.
                                </p>

                                <p>
                                [PO.6]: Having an ability to innovate and being active action researcher in developing new product and management process.
                                </p>

                                <p>
                                [PO.7]: Having an ability to analyze national and global economies legal and ethical aspects of business.
                                </p>

                                <p>
                                [PO.8]: Having an ability of entrepreneurship with analytical and creative thinking.
                                </p>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                [PSO.1]: To Prepare Students who will create and implement Business Solutions for problem across various functions of management.
                                </p>

                                <p>
                                [PSO.2]: To Prepare Graduates who will contribute to the growth, sustainability and Development of Society through their research, innovation and entrepreneurship.
                                </p>

                                <p>
                                [PSO.3]: To prepare Graduates equipped with creative and required analytical, interpersonal and Communication Skills.
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOMba
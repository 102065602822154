import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSAlumni=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Law" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Our Alumni" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Our alumni stand as living testaments to the unwavering spirit and excellence embodied by the School of Law at MVN University. They have made a name for themselves as trailblazers in every sector of the legal world, from industry heavyweights to innovative new ventures, and their influence spans the entire sphere. In this area, we will honour their outstanding accomplishments, share their motivational stories, and encourage them to reach out to others in our growing alumni network. Graduates not only demonstrate the value of our school's instruction and support via their achievements, but they also act as role models for students both here and abroad. They leave a legacy of innovation and distinction via their influence in law field, leadership in other fields, and continued engagement with our institution, which enriches our community. So that our alums can continue to play a significant role in our story as it develops, we also provide information on alumni events, networking opportunities, and ways to give back.
    <br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSAlumni;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

const MVNSociety=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="ABOUT MVN SOCIETY" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Modern Vidya Niketan (MVN) Society, established in 1983 under the leadership of Late Shri Gopal Sharma, has been a trailblazer in providing quality education. With schools like MVN Sector 17, MVN Aravali Hills, MVN Aurangabad, Palwal, and the prestigious MVN University, the society has expanded its reach and impact. MVN University, an epitome of academic excellence, offers a wide range of programs aimed at holistic development. Under the dynamic leadership of Mr. Varun Sharma and Mrs. Kanta Sharma, MVN continues to excel in academic and extracurricular domains. The society's commitment to holistic education is evident through its state-of-the-art facilities, emphasis on sports, and remarkable academic achievements, including producing top ranks in competitive exams like IIT JEE. MVN's alumni have made their mark globally, reflecting the institution's dedication to nurturing future leaders. With a focus on safety, scholarship programs, and comprehensive student development, MVN aims to shape responsible and compassionate individuals who contribute positively to society.</Typography>
    </Grid>
    </Grid>

)
}
export default MVNSociety;
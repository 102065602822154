import React from "react";
import { Container } from "@mui/material";
import PageBanner from "../muiCommonComponent/pageBanner";
import Home from "./home";
import NewMain from "../headerone/newMain";
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
import Studentshub from "./studentshub";
import Initiative from "./initiative";
import Alumni from "./alumni";
import Faculty from "./faculties/facultyList";
import Placement from "./training&placement";
import Research from "./r&d";
import Lab from "./lab";
import { useParams } from "react-router-dom";
import BALLB from './SOL/ballb'
import BBALLB from "./SOL/bballb";
import LLB from "./SOL/llb";
import LLM from "./SOL/llm";
import FacultyDetail from "../facultydetail/main";

const SOETHome=()=>{
    const { screenname } = useParams();
    console.log('screenname::::::::::::',screenname)
    const screensName=()=>{
        let screen=''
        if(screenname=='home'){
            screen=Home()
        }
       else if(screenname=='student-hub'){
            screen=Studentshub()
        }else if(screenname=='intiative'){
            screen=Initiative()
        }
        else if(screenname=="our-alumi"){
            screen=Alumni()
        }
        else if(screenname=='t-p'){
            screen=Placement()
        }else if(screenname=='r-d'){
            screen=Research()
        }
        else if(screenname=="laboratory"){
            screen=Lab()
        }
        else if(screenname=='ba-llb'){
            screen=BALLB()
        }else if(screenname=='bba-llb'){
            screen=BBALLB()
        }
        else if(screenname=="llb"){
            screen=LLB()
        }
        else if(screenname=='llm'){
            screen=LLM()
        }
        else if(screenname=='faculties'){
            screen=Faculty()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
       
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/school-of-law/home', subHeader: [] },
        { mainHeader: 'About SOL', path: '/school-of-law/home', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [
            { title: 'B.A.LL.B. ', path: '/school-of-law/ba-llb', },
            { title: 'B.B.A.LL.B.', path: '/school-of-law/bba-llb', },
            { title: 'LL.B.', path: '/school-of-law/llb', },
            { title: 'LL.M.', path: '/school-of-law/llm', },
        ] },
        { mainHeader: 'Laboratories ', path: '/school-of-law/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/school-of-law/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/school-of-law/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/school-of-law/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/school-of-law/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/school-of-law/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/school-of-law/intiative', subHeader: [] },
    ]
return(<>
<NewMain/>
<WidgetsMenu headerMenus={headerMenus}/>
<PageBanner bannerUrl={"/assets/img/SOL/sol.jpg"}/>
<Container>
{screensName()}
</Container>
</>)
}
export default SOETHome;

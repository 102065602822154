import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function AboutDeanRdc() { 
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="About Director, RDC" />
      <section className="about-area about-p pt-60 pb-60 p-relative fix">
        <div className="animations-02">
          <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" > 
                <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/direction_rdc.jpg`}  alt="img" className="vc-img" />
                <div className="vc-about"> 
                  <p><strong>Dr. Vineet Kumar  </strong> <br/>Director, RDC   </p> 
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                <div className="about-title second-title pb-25">
                  {/* <h5>
                    <i className="fal fa-graduation-cap" /> Chancellor’s Message
                  </h5> 
                  <h2>Pro Vice Chancellor Message</h2> */}
                </div>
                <p >
                Dr. Vineet Kumar is an accomplished academician and researcher, currently serving as Professor and Dean of the School of Allied Health Sciences & Research at MVN University, Palwal. With over 15 years of professional experience, Dr. Kumar has made significant contributions to the fields of neurobiology, cancer research, and molecular biology. <br/> <br/>
 
                  Dr. Kumar earned his Ph.D. in Biomedical Sciences from the University of Delhi in 2010. Following that, he pursued his passion for research at esteemed institutions like the National University of Singapore (NUS) and Nanyang Technological University (NTU) in Singapore. He received a young investigator travel grant from EMBO to present a paper at the University of Oxford, UK in 2009. He has qualified UGC-CSIR-NET JRF, ICMR-JRF, ICAR-NET in Biotechnology exams.
                  <br/> <br/>
                  His research interests encompass a wide range of topics including the neurobiology of pheromones, HPV, cancer, and patch-clamping. Dr. Kumar's work has been published in reputable journals, and he has received recognition for his contributions through awards and achievements such as qualifying for prestigious fellowship programs and national eligibility tests.
                  <br/> <br/>
                  During his tenure at NUS and NTU, Dr. Kumar designed and conducted experiments that investigated various aspects of auditory processing, stress response, and the physiological role of pathogens like Toxoplasma gondii. His research findings have contributed to advancements in understanding complex biological processes and diseases.
                  <br/> <br/>
                  At MVN University, Dr. Kumar continues to lead groundbreaking research initiatives while also mentoring PhD scholars and teaching undergraduate and postgraduate students. His dedication to academic excellence and commitment to fostering the next generation of researchers make him an invaluable asset to the university.
                  <br/> <br/>
                  For inquiries or collaboration opportunities, Dr. Kumar can be reached via email at drvineet.kumar@mvn.edu.in or by phone at +91-9811910353.
 
                
                </p>
                                
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default AboutDeanRdc
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

const GreenInitiative=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="Green Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Welcome to our MVN university is committed to fostering a sustainable and eco-friendly campus through our Green Initiative. This initiative encompasses a variety of environmentally conscious practices, including energy-efficient building designs, renewable energy usage, waste reduction programs, and the promotion of green transportation. By integrating these practices into our daily operations, we aim to reduce our carbon footprint and create a healthier, more sustainable environment for our students, staff, and the wider community. We believe that through education and action, we can inspire future generations to prioritize and protect our planet.</Typography>
    </Grid>

    </Grid>

)
}
export default GreenInitiative;
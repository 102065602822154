import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";

let cardHeader={
    background:'#4a75b7',
    color:'#fff',
    height:'50px',
    borderBottom:'3px outset #ffad6d',
    display:'flex',
    justifyContent: 'center',
    textTransform:'uppercase',
    fontWeight: 'bold'
  }
const MUICard=({title,content})=>{
return(
    <Card>
              <CardHeader
                title={<Typography textAlign={'center'} fontWeight={'bold'} color={'#fff'}>{title}</Typography>}
                sx={cardHeader}
              />
             <CardContent>
             {content}
              </CardContent>
            </Card>
)
}
export default MUICard
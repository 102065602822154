import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation,TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const BPT=()=>{
    const progHiglight=[
{listName:'Comprehensive curriculum aligned with University Grant Commission standards that covers a wide range of subjects tailored to the Non-Medical and Medical streams along with ability enhancement, skill enhancement, value added, and vocational courses'},
{listName:'Curriculum crafted by distinguished academicians'},
{listName:'4 advanced laboratories with full equipment'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, and COURSERA'},
{listName:'Tailored training programs to prepare students for placement opportunities'},
{listName:'Flexible credit system enabling students to pursue interdisciplinary minors'},
{listName:'Experiential learning is prioritized to complement theoretical knowledge through laboratory experiments, fieldwork, internships, and research projects'},
{listName:'Encourages interdisciplinary collaboration, allowing students to explore connections between different scientific disciplines'},
{listName:'Successful job placement across diverse fields like banking, technology, telecommunications, consulting, and more, showcasing a variety of opportunities with leading global corporations'},
{listName:'Employment opportunities in prestigious organizations across defence, research, government, telecommunications, banking, healthcare, etc.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Engaged students in community service, outreach programs, and social initiatives to foster a sense of social responsibility and civic engagement.'},


    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹60200/- (Tuition Fee - ₹35000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹44200/- ( Tuition Fee – ₹35000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '60,200'
    const fee_2nd= '44,200'
    const eligibility='Passed Senior Secondary (10+2) or equivalent examination from any recognised board with 45% marks in aggregate. The candidates seeking admission to B. Sc. General (Non-Medical) must have passed the above mentioned qualifying examination with Physics, Mathematics, Chemistry and English and those seeking admission to B.Sc. General (Medical) must have passed the above mentioned qualifying examination with Physics, Chemistry, Biology, and English. '
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="B. Sc. General (Non-Medical & Medical)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    B.Sc. General is a dynamic undergraduate degree program designed to provide students with a comprehensive understanding of scientific principles, preparing them for a wide array of career opportunities. This program is structured to foster critical thinking, analytical skills, and problem-solving abilities essential for success in today's rapidly evolving scientific landscape. B.Sc. General program offers two distinct streams: Non-Medical and Medical, catering to the diverse interests and ambitions of the students.
    <br/><br/>
In the Non-Medical stream, students delve into core subjects such as Mathematics, Physics, and Chemistry. This stream lays a strong foundation for careers in fields such as engineering, technology, research, and academia.
<br/><br/>
For students inclined towards the healthcare sector, the medical stream of the B.Sc. General program offers a specialized curriculum encompassing subjects such as Chemistry, Biology, Zoology, Anatomy, and Physiology. This stream lays a strong foundation for careers in fields such as medicine, dentistry, pharmacy, allied health, and biomedical research.
<br/><br/>
Students are provided ample opportunities to engage in laboratory work, field studies, internships, and collaborative research projects. Our state-of-the-art facilities and supportive learning environment empower students to apply theoretical concepts to real-world challenges and fostering innovation.
<br/><br/>
Upon completion of the B.Sc. General program, graduates emerge as well-rounded individuals with a deep understanding of scientific principles, equipped to make meaningful contributions to their chosen fields and society at large. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Passed Senior Secondary (10+2) or equivalent examination from any recognised board with 45% marks in aggregate.
    <br/><br/>
The candidates seeking admission to B. Sc. General (Non-Medical) must have passed the above mentioned qualifying examination with Physics, Mathematics, Chemistry and English and those seeking admission to B.Sc. General (Medical) must have passed the above mentioned qualifying examination with Physics, Chemistry, Biology, and English. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection of candidates will be determined by their performance in the MVNU Entrance Test, or their 12th-grade final examination scores
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B. Sc. General (Non-Medical & Medical)
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BPT;
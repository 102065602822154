import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBaLLB() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Law" subtitle="PEO, PO & PSO – B.A.LL.B. and LL.B." />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                                               
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p> [PEO.1]: To impart legal knowledge with excellent advocacy skills in the chosen legal stream.</p>

                                <p>[PEO.2]: To enrich students with specialized comprehensive legal knowledge and communication skills with equal focus on practical clinical training exercises like moot court, problem based learning, case studies, legal aid awareness camp etc as per chosen legal domain.</p>

                                <p>
                                [PEO.3]: To produce legally competent professionals with ability to define, explain, analyze, evaluate, justify and implement laws for furthering individual legal careers and betterment of society as a whole.
                                </p>
                                <p>
                                [PEO.4]: To prepare industry ready students with strong legal research and problem solving skills.
                                </p>
                                <p>
                                [PEO.5]: To inculcate a sense of responsibility for serving the society through professional skills in advocacy, judicial and other legal services.
                                </p>

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>[PO.1] Legal Knowledge: Deep conceptual knowledge of substantive and procedural laws and capability to understand the structure of the legal system with roles and procedures of Legal Institutions.</p>

                                <p>[PO.2] Inter-disciplinary Awareness: Awareness about the socio-economic, political, technological, cultural and managerial environment in the country and corporate with applications of relevant laws in these domains.</p>

                                <p>[PO.3] Problem Analysis: Ability to analyze socio-legal problems with the help of research in sound judgments and governing legal principles from law database and case studies.</p>

                                <p>[PO.4] Communication and Counseling Skills: Ability to express thoughts and ideas effectively in writing and orally, confidently share one’s views, demonstrate the ability to listen carefully and understand analytically to define and evaluate a client’s legal problems and explain to him/her the legal options to best effectuate the client’s goals.</p>

                                <p>[PO.5] Advocacy and Lawyering Proficiency: Ability to develop legal professional advocacy and learning skills through clinical training essentials in the form of Moot Court exercises, Legal Aid Awareness camps, Legal Aid Clinic, Legal visits, Case studies, Mock Trial, Client counseling sessions etc and enhance the sense of responsibility to serve the society through these skills.</p>

                                <p>[PO.6] Legal Professionalism and Ethics: Capability to practice legal professional skills based on human values and ethical principles.</p>

                                <p>[PO.7] Lifelong Learning: Ability to understand, develop and embrace an attitude for continuous learning for legal professional development.</p>

                                <p>[PO.8] Individual and Team Player: Ability to work efficiently, effectively and respectfully as an individual and in groups for common cause with a vast domain of legal knowledge.</p>

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>[PSO.1] Research Ability: A sense of enquiry and capability for asking relevant/appropriate questions, synthesizing and articulating; recognizing cause-and-effect relationships, define problems, formulate, test and establish hypotheses with data based research to predict outcomes; ability to plan, execute and report the results of an investigation.</p>

                                <p>[PSO.2] Information/ Digital Literacy: Capability to use Information and Communication Technology in a variety of learning situations, demonstrate ability to access, evaluate and use a variety of relevant information sources; and use appropriate software for analysis of data in the legal world.</p>

                                <p>[PSO.3] Employability: Capability for mapping out the tasks of a team or an organization and setting direction, formulating an inspiring vision, building a team who can help achieve the vision, motivating and inspiring team members to engage with that vision and using work-readiness skills to guide people to the right destination, in a smooth and efficient way.</p>

                                <p>[PSO.4] Effective Public Service: Ability to understand a sense of social responsibility and commitment and work on various socio-legal issues and provide Pro Bono legal services for societal reforms through engagement in community legal aid programs.</p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBaLLB
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const LLB=()=>{
    const progHiglight=[
        {listName:'1. Comprehensive Legal Foundation: L.L.B. program offers a thorough grounding in key legal principles, statutes, and case law, laying the groundwork for a successful legal career.'},
        {listName:'2. Multifaceted Curriculum: Students explore various fields of law such as criminal law, contract law, tort law, constitutional law, and more, gaining a broad understanding of the legal landscape.'},
        {listName:'3. Interactive Learning: L.L.B. program often incorporates interactive teaching methods such as case studies, debates, seminars, and moot court exercises, fostering active engagement and critical thinking.'},
        {listName:'4. Practical Skills Development: Through moot court competitions, legal clinics, internships, and mock trials, students hone essential legal skills including legal research, writing, negotiation, and advocacy.'},
        {listName:'5. Faculty Expertise: L.L.B. program is led by experienced faculty members who are often accomplished legal professionals, scholars, and practitioners, providing students with valuable insights and mentorship.'},
        {listName:'6. Professional Networking: Students have opportunities to connect with legal professionals, alumni, and industry leaders through networking events, guest lectures, and internships, expanding their professional network.'},
        {listName:'7. Career Preparation: L.L.B. program offers career development resources such as resume workshops, mock interviews, and job fairs to help students transition smoothly into the legal profession that can enhance their career prospects, whether pursuing opportunities in law firms, corporations, government agencies, or academia.'},
        {listName:'8. Assessment: Evaluation includes: 30% Mid-term written examination  / 50% End-term written examination / 20% Assessment on various grounds.'},
        {listName:'9. Passing Criteria: The maximum grade point is 10 (Outstanding), and the minimum passing grade is 4. Students scoring less than 40% absolute marks in each category will be considered as fail.'},
        {listName:'10. Scholarship: Availability of scholarships as per the University norms and additional benefits for Girl students specifically.'},
        {listName:'11. Pathways to Further Education: The L.L.B. degree serves as a stepping stone for further legal education, including pursuing a L.L.M. (Master of Laws) degree, Ph.D. (Doctor of Philosophy) in law, bar exams, or specialized certifications, opening up diverse career opportunities in the legal field.'},
                
            ]
            const feeStructureData=[
                {duration:'1st Year Fees','3 Years':'₹75200/- (Tuition Fee - ₹50000 + Other Charges- ₹25200/-)'},
                {duration:'2nd Year Onwards Fees','3 Years':'₹59200/- ( Tuition Fee – ₹50000 + Other Charges- ₹ 9200/-)'}
            ]
            const hostalFeeData=[
                {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
                {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
            ]
            const duration='3 years'
            const fee_1st= '75,200'
            const fee_2nd= '59,200'
    const eligibility='The applicant should have graduation/ post-graduation in any discipline of knowledge from a recognised University with the minimum percentage of 45% in case of general category applicants, 42% in case of OBC applicants and 40% in case of SC and ST applicants of total marks and fulfil the minimum eligibility criteria for admission as per BCI. '
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="LL.B. (Bachelor of Laws)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The three-year LL.B. (Bachelor of Laws) program is an undergraduate degree in law. It serves as the foundational legal education for individuals aspiring to become legal professionals, including lawyers, judges, and legal scholars. LL.B. programs offer a comprehensive study of the principles, theories, and practices of law, covering various areas such as constitutional law, international law, criminal law, company law, contract law, property law and more. Students engage in coursework, moot court competitions, legal clinics, and internships to develop essential legal skills like legal research, writing, and advocacy. Upon completion, graduates are equipped with the knowledge and qualifications necessary to pursue further legal education or enter the legal profession. The course enables students to acquire fundamental legal skills such as legal research and analysis.
    <br/><br/>
The School of Law is equipped with a team of dedicated and experienced faculties ever ready to guide the students in academic and extra co-curricular activities like Moot Court, Debate, Legal Quiz, Seminar, Conferences, Workshops and Educational Tour etc. The programme will bridge the gap between classroom learning and teaching theory and practice. 
<br/><br/>
Completion of an LL.B. program can open doors to specialized legal practice, advanced legal careers or academia.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The applicant should have graduation/ post-graduation in any discipline of knowledge from a recognised University with the minimum percentage of 45% in case of general category applicants, 42% in case of OBC applicants and 40% in case of SC and ST applicants of total marks and fulfil the minimum eligibility criteria for admission as per BCI. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    To be eligible for admission in the LL.B. programme, an applicant should have graduated/ post-graduated in any discipline of knowledge from a recognised University. The selection and ranking of applicant will be determined by their performance in the CLAT/ LSAT/ graduation/ post-graduation final examination scores with personal interview. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Why one should pursue LLB?</b>
    <br/><br/>
It is obvious that the health care sector is among the fastest-growing sector and the rise of demand of professionals who are experts and have complete knowledge in handling various technical equipment that are used all over the clinics and hospitals concerns every so often. If you have a degree in LLB, several job opportunities will open to you in various sectors.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    L.L.B
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default LLB;
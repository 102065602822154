import React from 'react' 
import Bredcom from '../../Bredcom/Main' 
import Header from '../../headerone/Main' 
import First from './First' 
 
function ComputerScienceEngineering() { 
  return ( 
    <> 
        <Header/> 
        <Bredcom title="Home" subtitle="School of Science & Humanities" /> 
        <First/> 
    </> 
  ) 
} 
 
export default ComputerScienceEngineering 
import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import First from './first'
import NewMain from '../../headerone/newMain'
function ExaminationPolicy() {
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Ph.D Admission Form" />
     <First/>
    </>
  )
}

export default ExaminationPolicy
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSResearch=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Science & Humanities" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Research & Development" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    In the School of Science and Humanities at MVN University, we give priority to research and development as the cornerstones of our academic. Our faculty actively organizes guest lectures, research conferences and workshops, providing a lively platform for experts, students, and faculty to exchange pioneering ideas and advancements in the field of science and humanities. We are committed to our faculty members' continuous development and make sure they remain in touch with pedagogical and technological advancements. We aim to push the boundaries of knowledge through pioneering research, and interdisciplinary collaboration. Our focus is to enable scholars, and students to address global challenges and shape the future through transformative research and innovation. We offer opportunities for undergraduate and graduate students to engage in hands-on research experiences through internships, research assistantships, and student-led projects. We engage with the local community through outreach programs, public lectures, and knowledge-sharing initiatives, fostering a culture of lifelong learning and civic engagement. We facilitate interdisciplinary collaboration among faculty members, students, and industry experts to address complex challenges from multiple perspectives. We provide resources, mentorship, and support to nurture innovative ideas and translate them into tangible solutions and entrepreneurial ventures. We continually invest in state-of-the-art laboratories, equipment, and facilities to provide researchers with the resources they need to conduct high-impact research. We uphold the highest standards of integrity, transparency, and ethical conduct in all research activities, ensuring that our work has a positive impact on society.
<br/><br/>
Our research contributes to the betterment of humankind and the sustainable development of society.
    <br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSResearch;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const Bcom_hons=()=>{
    const progHiglight=[
{listName:'Bachelor of Commerce (Honours) emphasizes on in-depth understanding of financial management, accounting principles, and business economics.'},
{listName:'Specialized coursework delves into advanced topics such as corporate law, taxation, and auditing.'},
{listName:'Opportunity for internships and practical training to gain real-world experience in the field of commerce.'},
{listName:'A strong foundation for pursuing further studies in fields like finance, accounting, or business administration.'},
{listName:'AICTE model curriculum and Industry-oriented choice-based courses'},
{listName:'Curriculum designed by Industry experts and reputed Academicians'},
{listName:'Skill-based training for enhancing employability skills through MOUs'},
{listName:'Special student grooming for placement drives.'},
{listName:'100+ research papers in international and international refereed conferences & journals'},
{listName:'Opportunity for participation in Govt. funded projects/Consultancy/Start-ups'},
{listName:'Provision of Conference/Short-Term Training Programme/Workshops/Webinars/Online competition/Project competitions/Industry Internships'},
{listName:'Project and Experiential-based learning'},
{listName:'Choice-based credit structure allows students to do interdisciplinary minors'},
{listName:'Opportunities for Higher Studies/Research in India/Abroad.'},
{listName:'Provision of up to 100% Scholarship.'},

      
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹60200/- (Tuition Fee - ₹35000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹44200/- ( Tuition Fee – ₹35000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '60,200'
    const fee_2nd= '44,200'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects. '
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="B.Com.(Hons.)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    B.Com.(Hons.) program at MVN University is a multi-disciplinary program consisting of subjects from areas such as – Accounting, Finance, Management, Economics, Mathematics and Statistics, Computer Applications, Taxation, Business Research Methods, Entrepreneurship, Business Law and Communication.
    <br/><br/>
The program is complemented and strengthened by dedicated computerized accounting, statistics and taxation labs coupled with numerous co-curricular activities. The curriculum is designed keeping in view current market trends, adequately grooming the students in the skill sets that will help them deliver in an ever-changing competitive global business environment with confidence.
<br/><br/>
The department constantly endeavours to contribute the best towards academic excellence and is committed towards making student experience the finest. We strongly believe in endorsing and nourishing an atmosphere where ideas are nurtured and shared, students are enthused and teaching is dynamic. We genuinely care for our students, our assets and future glory and seek their valuable suggestions and inputs all along. The students we serve are our greatest priority and we’re committed to dedicating all our resources and channelizing all our energies in an effort to supporting their success.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.Com.
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default Bcom_hons;
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {
    return (
        <>
            <section id="work" className="pt-150 pb-105">
                <div className="container">
                    <div className="portfolio ">
                        <div className="row  fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className='elementor-element-63bca71'>
                            <p>MVN University supports and encourages higher education and research. Doing a PhD is an important step if you wish to pursue a career in the teaching or research fields. Once you have completed your PhD, you can become a researcher and conduct independent research in your chosen area. Our PhD programs encompass course work, in order to enhance your knowledge of the domain, as well as thesis, which will give you practical experience in conducting your own research and working on research problems.</p>
                            <p><strong>MVN University offers the opportunity to complete your Ph.D in:</strong></p>
                            <ul><li><span className='circle-dot'/><strong>School of Engineering &amp; Technology</strong></li><li><span className='circle-dot'/><strong>School of Business Management &amp; Commerce</strong></li><li><span className='circle-dot'/><strong>School of Law</strong></li><li><span className='circle-dot'/><strong>School of Pharmaceutical Sciences</strong></li><li><span className='circle-dot'/><strong>School of Allied Health Sciences</strong></li><li><span className='circle-dot'/><p className="elementor-heading-title elementor-size-default"><strong>School of Science and Humanities</strong></p></li></ul>
                            </div>
                            <section className="elementor-section elementor-inner-section elementor-element elementor-element-178f1d7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="178f1d7" data-element_type="section">
                                <div className="elementor-container elementor-column-gap-extended row " >
                                    <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-c03e196 col-md-6 col-sm-12" data-id="c03e196" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-bc7244d elementor-widget elementor-widget-heading" data-id="bc7244d" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">Ph.D Admissions</h2>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-275e74b elementor-widget elementor-widget-text-editor" data-id="275e74b" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div className="elementor-widget-container">
                                                    <ul><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2021/09/admission-guidelines.pdf" target="_blank" rel="noopener">Ph.D Eligiblilty and Admission Guidelines</a></li><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2023/07/Ph.D.-2023-24-SUMMER-ADVERT.pdf" target="_blank" rel="noopener">Ph.D Program for Summer Session 2024-25</a></li></ul>						</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-f5c570d col-md-6 col-sm-12" data-id="f5c570d" data-element_type="column">
                                        <div className="elementor-widget-wrap elementor-element-populated">
                                            <div className="elementor-element elementor-element-25649fe elementor-widget elementor-widget-heading" data-id="25649fe" data-element_type="widget" data-widget_type="heading.default">
                                                <div className="elementor-widget-container">
                                                    <h2 className="elementor-heading-title elementor-size-default">Forms Related to Ph.D</h2>		</div>
                                            </div>
                                            <div className="elementor-element elementor-element-6fb1e00 elementor-widget elementor-widget-text-editor" data-id="6fb1e00" data-element_type="widget" data-widget_type="text-editor.default">
                                                <div className="elementor-widget-container">
                                                    <ul><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2021/09/topic-approval-form.pdf" target="_blank" rel="noopener">Form for Approval of Research Proposal Guide</a></li><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2021/09/pre-thesis-submission-form.pdf" target="_blank" rel="noopener">PRE-THESIS SUBMISSION FORM</a></li><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2021/09/progress-report-format.pdf" target="_blank" rel="noopener">Progress Report Format for Ph.D</a></li><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2021/09/thesis-submission-form.pdf" target="_blank" rel="noopener">THESIS SUBMISSION FORM</a></li></ul>						</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
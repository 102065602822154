import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>
      
    );
}

function Event() {
    let settings = {
        dots: true,
        infinite: true,
        arrows: false,
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 3,
        // autoplay: true, 
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

  return (
    <>
        <section className="event pt-90 pb-45 p-relative fix">
            <div className="animations-06">
                <img src="/assets/img/bg/an-img-06.png" alt="an-img-01" />
            </div>
            <div className="animations-07">
                <img src="/assets/img/bg/an-img-07.png" alt="contact-bg-an-01" />
            </div>
            <div className="animations-08">
                <img src="/assets/img/bg/an-img-08.png" alt="contact-bg-an-01" />
            </div>
            <div className="animations-09">
                <img src="/assets/img/bg/an-img-09.png" alt="contact-bg-an-01" />
            </div>
            <div className="container">
                <div className="row">
                <div className="col-lg-12 p-relative">
                    <div className="section-title center-align mb-50 text-center wow fadeInDown animated"
                    data-animation="fadeInDown"
                    data-delay=".4s">
                    <h5>
                        <i className="fal fa-graduation-cap" /> Our Events
                    </h5>
                    <h2>Upcoming Events</h2>
                    </div>
                </div>
               
               {/* <div className="row"> */}
               <div className="col-lg-12">
                {/* <div className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >  */}
                <Slider className="testimonial-active wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" {...settings}>
                
                    <div className="event-item mb-30 ">
                        <div className="thumb" style={{ height:'150px'}}>
                            <Link to="/">
                            <img src="/fair_job.jpeg" alt="contact-bg-an-01" />
                            </Link>
                        </div>
                        <div className="event-content">
                            <div className="date">
                            <strong>15</strong> March, 2024
                            </div>
                            <h3>
                            <Link to="/">
                                {" "}
                                Registration link for conference
                            </Link>
                            </h3>
                            <p className='testimonial-para'>
                            <p><a href='https://docs.google.com/forms/d/1yaMJ-0hPazlmH5yOXHERu1ZbAA3ook7c0U0RGX5XaCQ/edit ' target='_blank' >• <u>https://docs.google.com/forms/d/1yaMJ-0hPazlmH5yOXHERu1ZbAA3ook7c0U0RGX5XaCQ/edit </u></a></p>

                            </p>
                           
                        </div>
                    </div>
                    <div className="event-item mb-30 ">
                        <div className="thumb" style={{ height:'150px'}}>
                            <Link to="/">
                            <img src="/about-img.jpeg" alt="contact-bg-an-01" />
                            </Link>
                        </div>
                        <div className="event-content">
                            <div className="date">
                            <strong>16</strong> March, 2024
                            </div>
                            <h3>
                            <Link to="/">
                                {" "}
                             Convocation Registration link
                            </Link>
                            </h3>
                            <p className='testimonial-para'>
                            <p><a href='https://forms.gle/oaDSW5Uv3kVgqX5t6' target='_blank' >• <u>Convocation Registration Link for students</u></a></p>
                            <p><a href='/List_of_Eligible_Student.pdf' target='_blank' >• <u>List of Eligible students for convocation</u></a></p> 

                            </p>
                           
                        </div>
                    </div>
                    <div className="event-item mb-30 ">
                        <div className="thumb" style={{ height:'150px'}}>
                            <Link to="/">
                            <img src="https://admission.mvn.edu.in/wp-content/uploads/2022/02/Workshop-School-of-Law.jpg" alt="contact-bg-an-01" />
                            </Link>
                        </div>
                        <div className="event-content">
                            <div className="date">
                            <strong>18</strong> March, 2023
                            </div>
                            <h3>
                            <Link to="/">
                                {" "}
                            PROTECTION WOMEN AND CHILDREN
                            </Link>
                            </h3>
                            <p className='testimonial-para'>
                            Establish and enforce comprehensive legal frameworks that address issues such as domestic violence, child abuse, human trafficking, child labor, and child marriage.
                            </p>
                            {/* <div className="time">
                            3:30 pm - 4:30 pm <i className="fal fa-long-arrow-right" />{" "}
                            <strong>United Kingdom</strong>
                            </div> */}
                        </div>
                    </div>
                {/* </div> */}
                {/* <div className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" > */}
                    <div className="event-item mb-30 ">
                    <div className="thumb" style={{ height:'150px'}}>
                        <Link to="/">
                            <img src="https://admission.mvn.edu.in/wp-content/uploads/2022/02/Faculty-Development-Program-Technology-Series.jpg" alt="contact-bg-an-01" />
                        </Link>
                    </div>
                    <div className="event-content">
                        <div className="date">
                        <strong>20</strong> March, 2023
                        </div>
                        <h3>
                        <Link to="/">
                            Decenterlizing Trust With Blockchain Technology
                        </Link>
                        </h3>
                        <p className='testimonial-para'>
                        In the digital age, trust is the cornerstone of our interconnected world. From financial transactions to data sharing, trust ensures that our interactions are secure, transparent, and reliable.
                        </p>
                        {/* <div className="time">
                        3:30 pm - 4:30 pm <i className="fal fa-long-arrow-right" />{" "}
                        <strong>United Kingdom</strong>
                        </div> */}
                    </div>
                    </div>
                {/* </div> */}
                {/* <div className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" > */}
                    <div className="event-item mb-30 ">
                    <div className="thumb" style={{ height:'150px'}}>
                        <Link to="/">
                            <img src="https://admission.mvn.edu.in/wp-content/uploads/2022/02/Faculty-Development-Program-Agriculture.jpg" alt="contact-bg-an-01" />
                        </Link>
                    </div>
                    <div className="event-content">
                        <div className="date">
                        <strong>22</strong> March, 2023
                        </div>
                        <h3>
                        <Link to="/">
                            {" "}
                            Faculty Development program
                        </Link>
                        </h3>
                        <p className='testimonial-para'>
                        Our program focuses on enriching teaching methodologies through workshops, seminars, and training sessions that incorporate the latest educational trends and best practices.
                        </p>
                        {/* <div className="time">
                        3:30 pm - 4:30 pm <i className="fal fa-long-arrow-right" />{" "}
                        <strong>United Kingdom</strong>
                        </div> */}
                    </div>
                    </div>

                    </Slider>
                    </div>
                    </div>
                {/* </div> */}
                {/* <div className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                    <div className="event-item mb-30 ">
                    <div className="thumb">
                        <Link to="/">
                        <img src="/assets/img/bg/evn-img-4.jpg" alt="contact-bg-an-01" />
                        </Link>
                    </div>
                    <div className="event-content">
                        <div className="date">
                        <strong>22</strong> March, 2023
                        </div>
                        <h3>
                        <Link to="/">
                            {" "}
                            Next-Gen Higher Education Students Have Arrived?
                        </Link>
                        </h3>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea tically
                        </p>
                        <div className="time">
                        3:30 pm - 4:30 pm <i className="fal fa-long-arrow-right" />{" "}
                        <strong>United Kingdom</strong>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                    <div className="event-item mb-30 ">
                    <div className="thumb">
                        <Link to="/">
                            <img src="/assets/img/bg/evn-img-5.jpg" alt="contact-bg-an-01" />
                        </Link>
                    </div>
                    <div className="event-content">
                        <div className="date">
                        <strong>24</strong> March, 2023
                        </div>
                        <h3>
                        <Link to="/">
                            {" "}
                            Digital Art &amp; 3D Model – a future for film company
                        </Link>
                        </h3>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea tically
                        </p>
                        <div className="time">
                        3:30 pm - 4:30 pm <i className="fal fa-long-arrow-right" />{" "}
                        <strong>United Kingdom</strong>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6  wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
                    <div className="event-item mb-30 ">
                    <div className="thumb">
                        <Link to="/">
                            <img src="/assets/img/bg/evn-img-6.jpg" alt="contact-bg-an-01" />
                        </Link>
                    </div>
                    <div className="event-content">
                        <div className="date">
                        <strong>30</strong> March, 2023
                        </div>
                        <h3>
                        <Link to="/">
                            {" "}
                            Conscious Discipline Summer Institute
                        </Link>
                        </h3>
                        <p>
                        Seamlessly visualize quality ellectual capital without superior
                        collaboration and idea tically
                        </p>
                        <div className="time">
                        3:30 pm - 4:30 pm <i className="fal fa-long-arrow-right" />{" "}
                        <strong>United Kingdom</strong>
                        </div>
                    </div>
                    </div>
                </div> */}
                </div>
                
            {/* </div> */}
        </section>
    </>
  )
}

export default Event
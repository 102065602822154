import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBba() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="PEO, PO & PSO – BBA" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                
                                <div className="about-title second-title pb-25">                                    
                                    <h3>BBA PROGRAM OF SCHOOL OF BUSINESS MANAGEMENT</h3>
                                </div>

                                <p>
                                In the post Covid Era, setting up a new business or running the existing one is not a simple task for business owners. Businesses in this competitive age are witnessing ever increasing technological innovation and implementation of these new technologies to compete in all functional areas of their businesses. These happening have made competition somewhat unusual as compared to last century. To encounter the challenges created by fast pace innovation in technology and being updated to work in this technologically changing environment of both product development and processes, pursuing a program in Bachelor of Business Administration (BBA) of MVN university which oriented towards learning applications of digital technologies, changing strategies (fiancé, operations and marketing), and computational business analytics will be always beneficial for the students in this fast growing region of NCR. As it is known fact that in the present day context, even large companies with very high market capitalization are struggling to level their net incomes. Hiring and laying off employees is the routine activities in these companies to fulfil need of new business knowledge workers who are talented and imbibed the quality of being a leader, strategic thinker, innovative mind, and problem-solvers. This gap if any will be filled by BBA qualified manager in days to come.
                                </p>

                                <p>
                                To above stated goals of companies, MVN university’s BBA program is one of the most sought-after Bachelor’s degree programs for students after Class 12. The MVN university BBA program is the gateway of job opportunities in business domains such as Sales, Marketing, Education, Finance, digital technologies, computational business analytics, human resources, business communication, Government sectors etc., Further elaborating, BBA of MVN university is a three-year professional Undergraduate course in Business Management. It is open to students from all three streams i.e., Science, Arts and Commerce. The BBA course offers knowledge and training in management and leadership skills to prepare them for managerial roles and entrepreneurship. The program teaching methods are oriented to meet the aspirations of country with emphasis on girls and boys from rural and urban youth who can develop self-sustainability and become a torch bearer to the upcoming generations. This program is cultivated with motto of professionals to excel at either employee or business portfolio. The curriculum is developed in lieu of required skill set being desired at major corporates and business world. This program will endure the candidate to possess the knowledge of techno-commercial aspects of Indian Business Segment.
                                </p>



                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p>
                                PEO 1: To provide core business knowledge so that students are able to demonstrate their competency while working with industry and other organizations.
                                </p>

                                <p>
                                PEO2: To prepare students to exhibit critical thinking to manage diverse business challenges and opportunities.
                                </p>

                                <p>
                                PEO3: To instil in students analytical and reflective thinking to analyse business environment and take effective decisions.
                                </p>
                                <p>
                                PEO4: To prepare the students to enhance their oral and written communication skills
                                </p>

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                PO1. Enhancing the horizon of knowledge so as to enable the learners to pursue academic or professional careers.
                                </p>

                                <p>
                                PO2. Developing problem analysis skills, knowledge and applying the same in real life situation.
                                </p>

                                <p>
                                PO3. Using knowledge and aptitude required in the course of study for solving Business problems.
                                </p>

                                <p>
                                PO4. Understanding the role and applicability of knowledge acquired in the context of society , environment and sustainable development sticking on the ethics and values.
                                </p>

                                <p>
                                PO5. Developing effective communication skills and ability to work in teams by strengthening group dynamics.
                                </p>

                                <p>
                                PO6. Fostering ability to engage in lifelong learning, demonstrating, empathetic social concern, contributing to the development of nation by making sure of awareness gained on various issues.
                                </p>

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                PSO1. Develop high quality educational environment in Management at undergraduate level
                                </p>

                                <p>
                                PSO2. Enable students to become competitive by providing innovative pedagogical techniques, applications of information technologies, group activities and presentations, class discussions and e-learning within the classroom.
                                </p>

                                <p>
                                PSO3. Prepare the students to have strong foundation in various domain of management, economics, entrepreneurship and skilling to do business
                                </p>

                                <p>
                                PSO4. Provide a solid foundation in management for the students to pursue professional careers and take up higher educational PG courses in management
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBba
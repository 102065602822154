// import * as React from 'react';
// import { Grid } from '@mui/material';
// import ImageList from '@mui/material/ImageList';
// import ImageListItem from '@mui/material/ImageListItem';
// import MainTitle from '../muiHome/mainTitle';

// export default function EventNewsGallary() {
//   return (
//     <Grid container spacing={3}>
//     <Grid item xs={12} sm={12} md={12} lg={12} >
//                    <MainTitle title="Event & News"/>
//                </Grid>
//                <Grid item xs={12} sm={12} md={12} lg={12} >   
//     <ImageList  cols={3} >
//       {itemData.map((item) => (
//         <ImageListItem key={item.img}>
//           <img
//             srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
//             src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
//             alt={item.title}
//             loading="lazy"
//           />
//         </ImageListItem>
//       ))}
//     </ImageList>
//     </Grid>
//     </Grid>
//   );
// }

// const itemData = [
//   {
//     img: '/assets/img/SOET/event1.jpg',
//     title: 'Breakfast',
//   },
//   {
//     img: '/assets/img/SOET/event2.jpg',
//     title: 'Burger',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
//     title: 'Camera',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
//     title: 'Coffee',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
//     title: 'Hats',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
//     title: 'Honey',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
//     title: 'Basketball',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
//     title: 'Fern',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
//     title: 'Mushrooms',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
//     title: 'Tomato basil',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
//     title: 'Sea star',
//   },
//   {
//     img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
//     title: 'Bike',
//   },
// ];


import React, { useState } from 'react';
import { Grid } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import ImagesSlider from '../muiCommonComponent/imageSlider';

const eventGallery = [
  { img: '/assets/img/SOET/event1.jpg' },
  { img: '/assets/img/SOET/event2.jpg' },
  { img: '/assets/img/SOET/event3.jpg' },
  { img: '/assets/img/SOET/event4.jpg' },
  { img: '/assets/img/SOET/event5.jpg' },
  { img: '/assets/img/SOET/event6.jpg' },
  { img: '/assets/img/SOET/event7.jpg' },
  { img: '/assets/img/SOET/event8.jpg' },
  { img: '/assets/img/SOET/news1.jpg' },
  { img: '/assets/img/SOET/news2.jpg' },
  { img: '/assets/img/SOET/news3.jpg' },
  { img: '/assets/img/SOET/news4.jpg' },
  // Add more logo URLs as needed
];

const EventNewsGallary = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Event & News Gallery" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <ImagesSlider imgArr={eventGallery} />
      </Grid>

    </Grid>

  );
};

export default EventNewsGallary;

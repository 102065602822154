import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation } from "../../../utils/utils";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCIVILHeader from "./header";
const CIVILBTechLab=()=>{
    const ceLab=[
{listName:'Materials Testing Lab '},
{listName:'Structural Analysis Lab'},
{listName:'Geotechnical Engineering Lab'},
{listName:'Hydraulics Lab'},
{listName:'Environmental Engineering Lab '},
{listName:'Transportation Engineering Lab '},
{listName:'sSurveying Lab '},
{listName:'Construction Engineering Lab '},
{listName:'Earthquake Engineering Lab '},
{listName:'Pavement Engineering Lab '},
 ]
return (
<Grid container spacing={2}>
{/* <SOETCIVILHeader/>
        <BreadcrumbsBanner bannerUrl={"https://www.dreamdesigninc.com/wp-content/uploads/2022/09/civilengineering_hero.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Civil Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="CE Lab" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Civil engineering labs are crucial for teaching students practical skills and for conducting research that advances the field. Here’s a list of common civil engineering labs found in universities and research institutions:
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={ceLab}/>
    </Grid>


    </Grid>

)
}
export default CIVILBTechLab;
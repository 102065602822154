import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {

    const Data = [
        
        {video : "https://www.youtube.com/embed/YNoUjpiixbI", category : "Financial"},
        {video : "https://www.youtube.com/embed/YEj-kTYLXhw", category : "Financial"},
        {video : "https://www.youtube.com/embed/7yQ4WXQgbiQ", category : "Financial"},
        {video : "https://www.youtube.com/embed/xmFAz4LWxpc", category : "Financial"},
        {video : "https://www.youtube.com/embed/kq8V0bvuagg", category : "Financial"},
        {video : "https://www.youtube.com/embed/Qq8XRr5Uk0s", category : "Financial"},
        {video : "https://www.youtube.com/embed/ybnipOOBXvI", category : "Financial"},
        {video : "https://www.youtube.com/embed/KGPZESDgqD4", category : "Financial"},
      ]

      const [current, setcurrent] = useState(Data);

      const Filter = (category) =>{
          const arr = [];
          if(category === "View All"){
          setcurrent(Data)
          }
          else{
          Data.map((item)=> {
              if(item.category === category){
              arr.push(item);
              }
          })
          setcurrent(arr);
          }
      };

  return (
    <>
        <section id="work" className="pt-150 pb-105">
            <div className="container">
                <div className="portfolio ">
                <div className="row  fadeInUp  animated"  data-animation="fadeInUp" data-delay=".4s" >
                    {current.map( (item, Index)=> ( 
                        <div className="col-md-3 col-sm-12" style={{padding:'15px'}} key={Index}>
                                   <iframe className='student-testimonial' src={item.video} title="MVN students selected AON-Hewit Testimonial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First
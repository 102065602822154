import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";
const CSEBTechLab=()=>{

return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="B. Tech Computer Science Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="CSE Laboratories" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    
The Computer Science and Engineering Department is proud to present its fully-equipped computer laboratory, which houses a diverse array of computers. This collection is designed to cater to a broad spectrum of educational and research needs within our department. Our lab features a range of systems, starting from basic models equipped with dual-core processors, sufficient for fundamental computing tasks and learning the basics of programming. These basic setups come with essential RAM and storage capabilities, ensuring reliable performance for introductory courses and projects.
<br/><br/>
Advancing from the foundational models, our lab also boasts a selection of more sophisticated computers. These are powered by modern i3 and i5 processors, marking a significant step up in terms of computing power and efficiency. To complement these processors, the advanced systems are fitted with higher RAM capacities and Solid-State Drives (SSDs). The inclusion of SSDs is crucial as they offer faster data access speeds, significantly reducing boot times and loading times for software and applications. This is particularly beneficial for software development tasks, complex simulations, and data-intensive projects that require more robust computing resources.

    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBTechLab;
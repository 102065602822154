import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const DPharma=()=>{
    const progHiglight=[
{listName:'16 World Class laboratories with full loaded latest Instruments Such as UV, HPLC, Brookfield Viscometer and many more'},
{listName:'Curriculum aligning with EDUCATION REGULATION 2020 framed by PCI'},
{listName:'Curriculum constructed by various industrial experts and eminent academicians from PCI'},
{listName:'GPAT qualified and Ph.D. holder Faculty Members'},
{listName:'Expert guidance for various competitive exams like D.Pharm Exit Exam Test  and government pharmacist test '},
{listName:'Foster a strong research culture among its faculty and students'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Publication of about 20 books published by national and international publishing house. '},
{listName:'Organising health camps and diseases awareness rally on regular basis in nearby villages or towns to impart practical exposure to the students and social welfare'},
{listName:'Celebration of various special days associated with health like world heart day, world diabetes day, world tuberculosis day, and more'},
{listName:'Organizing conferences, short-term training, workshops, and webinars, best out of waste contest, project contests, and industry internships. '},
{listName:'Conduct hospital training and industrial training to impart practical exposure to the students '},
{listName:'Successful job placement across diverse fields like pharmaceutical marketing, retail pharmacist and hospital pharmacist '},
    
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','2 Years':'₹110200/- (Tuition Fee - ₹85000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','2 Years':'₹94200/- ( Tuition Fee – ₹85000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='2 years'
    const fee_1st= '1,10,200'
    const fee_2nd= '94,200'
    const eligibility='Pass in 10+2 examination (science academic stream) with Physics, Chemistry and Biology or Mathematics or Any other qualification approved by the Pharmacy Council of India as equivalent to the above examination.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="D.Pharm" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Diploma in Pharmacy program at MVN University in Haryana sounds like an exciting opportunity for aspiring pharmacists. The emphasis on academic excellence and industry relevance suggests that students will receive a well-rounded education that prepares them for the challenges of the field. Having experienced faculty members and state-of-the-art facilities can greatly enhance the learning experience and ensure that students have access to the latest resources and technologies in the field of pharmacy. This program seems like a great choice for anyone looking to pursue a career in pharmacy due to tenure of two years. It serves as a fundamental platform for individuals aspiring to pursue a career in the pharmaceutical industry. It equips students with essential knowledge and skills in pharmaceutical sciences, including pharmacology, pharmaceutics, pharmacognosy, and pharmaceutical chemistry. One of the significant advantages of this program is its emphasis on practical training, which allows students to gain hands-on experience in various aspects of pharmacy practice. The candidates after completion of D. Pharmacy are expected to attain the professional competencies like review Prescriptions, dispense prescription / non-prescription medicines, provide patient counselling / education, hospital and community pharmacy management, expertise on medications, proficiency on drugs / pharmaceuticals, entrepreneurship and leadership, deliver primary and preventive healthcare, professional, ethical and legal practice and continuing professional development. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 examination (science academic stream) with Physics, Chemistry and Biology or Mathematics or Any other qualification approved by the Pharmacy Council of India as equivalent to the above examination.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    D.Pharma
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default DPharma;
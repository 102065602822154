import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCIVILHeader from "./header";
import Home from "./home";
import NewMain from "../../headerone/newMain";
import DepartmentWidgetsMenu from "../departmentWidgetsMenu";
import CIVILBTechStudentshub from "./studentshub";
import CIVILBTechInitiative from "./initiative";
// import CIVILBTechAlumni from "./alumni";
import CIVILBTechPlacement from "./training&placement";
import CIVILBTechResearch from "./r&d";
import CIVILBTechLab from "./lab";
import FacultyList from "./faculties/facultyList";
import WidgetsMenu from "../../muiCommonComponent/menuWidgets";
import FacultyDetail from "../../facultydetail/main";
import ECEBTech from "./ECE_courses/bTech";
const SOETCIVIL=()=>{
    const { screenname } = useParams();
    console.log('ECE scrennnnn:::::::',screenname)
    const screensName=()=>{
        let screen=''
        if(screenname=='home'){
            screen=Home()
        }else if(screenname=='student-hub'){
            screen=CIVILBTechStudentshub()
        }else if(screenname=='intiative'){
            screen=CIVILBTechInitiative()
        }
        // else if(screenname=="our-alumi"){
        //     screen=CIVILBTechAlumni()
        // }
        else if(screenname=='t-p'){
            screen=CIVILBTechPlacement()
        }else if(screenname=='r-d'){
            screen=CIVILBTechResearch()
        }
        else if(screenname=="laboratory"){
            screen=CIVILBTechLab()
        }
        else if(screenname=="faculties"){
            screen=FacultyList()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
        else if(screenname=="btech-ece"){
            screen=ECEBTech()
        }
        // else if(screenname=='bca-ai'){
        //     screen=CSEBCAAI()
        // }else if(screenname=='bca-ml'){
        //     screen=CSEBCAML()
        // }
        // else if(screenname=="mca"){
        //     screen=CSEMCA()
        // }
        // else if(screenname=='m-tech'){
        //     screen=CSEMTech()
        // }
        // else if(screenname=="mca"){
        //     screen=CSEMCA()
        // }
        // else if(screenname=="b-tech-cs"){
        //     screen=CSEBTechCyberSecurity()
        // }
     
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/soet-ece/home', subHeader: [] },
        { mainHeader: 'About ECE', path: '/soet-ece/btech-ece', subHeader: [] },
        // { mainHeader: 'Academic courses ',path: '#', subHeader: [
        //     { title: 'B.Tech Computer science & Engineering', path: '/soet-cse/course/b-tech', },
        //     { title: 'B.Tech CSE with specilisation in Artificial Intelligence', path: '/soet-cse/course/b-tech-ai', },
        //     { title: 'B.Tech CSE with specilisation in Machine Learning', path: '/soet-cse/course/b-tech-ml', },
        //     { title: 'B.Tech CSE with specilisation in Cyber Security', path: '/soet-cse/course/b-tech-cs', },
        //     { title: 'B.Tech civil Engineering',path:''},
        //     { title: 'B.Tech CSE with specilisation in Robotics & Automation',path: ''},
        //     { title: 'B.Tech Electronics and Comunnication Engineering',path: ''},
        //     { title: 'M.Tech Computer science & Engineering', path: '/soet-cse/course/m-tech', },
        //     { title: 'B.Sc Computer science', path: '/soet-cse/course/bsc-it', },
        //     { title: 'BCA', path: '/soet-cse/course/bca', },
        //     { title: 'BCA with Specilisation in Data Science', path: '/soet-cse/course/bca-ds', },
        //     { title: 'BCA with Specilisation in Artificial Intelligence', path: '/soet-cse/course/bca-ai', },
        //     { title: 'BCA with Specilisation in Machine Learning', path: '/soet-cse/course/bca-ml', },
        //     { title: 'MCA', path: '/soet-cse/course/mca', },
        // ] },
        { mainHeader: 'Laboratories ', path: '/soet-ece/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/soet-ece/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/soet-ece/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/soet-ece/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/soet-ece/student-hub', subHeader: [] },
        // { mainHeader: 'Our Alumni', path: '/soet-ece/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/soet-ece/intiative', subHeader: [] },
    ]
    return(<>
           <NewMain/>
        <WidgetsMenu headerMenus={headerMenus}/>
        <BreadcrumbsBanner bannerUrl={"/assets/img/SOET/ece.jpg"}/> 
    <Container>
        {screensName()}
        {/* <Home/> */}
    </Container>
    </>)
}
export default SOETCIVIL;
import React from 'react'
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './first'
import NewMain from '../../headerone/newMain'
function ExaminationPolicy() {
  return (
    <>
      <NewMain />
      <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  {/* <h2> {image?"":subtitle} </h2> */}
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Examination Policy
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/re-evalution-rules">Re-Evaluation Rules </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* <Bredcom title="Home" subtitle="Examination Policy" /> */}
      <First />
    </>
  )
}

export default ExaminationPolicy
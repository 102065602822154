import { Grid, Typography, Card, Box } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSStudentshub=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Business Management and Commerce" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Student’s Hub" />
    </Grid>

    <Grid item xs={12} sm={12} md={12}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_student_hub.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    A student's hub functions as the dynamic core of academic life, promoting cooperation, learning, and development. It is a vibrant environment where students get together to exchange ideas, seek advice, and participate in educational activities. The hub embodies the fundamental nature of an educational community, providing resources, assistance, and prospects for individual and intellectual growth. It is a location where students may foster relationships, delve into their hobbies, and uncover their passions. The hub serves as a centre for progress, exploration, and change, embodying the essence of oneness and promoting inclusiveness. Student’s hub serves as focal points for student engagement, collaboration, and assistance, aiming to enhance the overall student experience and promote academic success, personal growth, and well-being across various academic, administrative, and personal domains. 
    <br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSStudentshub;
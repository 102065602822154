import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import NewMain from '../../headerone/newMain'




function BrochureDownload() {

    return (
        <>
            <NewMain />
            <Bredcom title="Home" subtitle="Brochure Download" />


            <section className="project-detail">
                <div className="container">

                <h2 class="text-center"> Brochure Download
                </h2>

                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                <div className="inner-column">
                                        <div className='bros_img'>
                                            <img src="/assets/img/3rd_International_Conference_Brochure_A4-1.jpg" />
                                            <img src="/assets/img/3rd_International_Conference_Brochure_A4-2.jpg" />
                                        </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BrochureDownload
import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Brand from '../../menhomethree/Brand'
import First from '../menphpguidlines/First'
import NewMain from '../../headerone/newMain'
function Main() {
  return (
    <>
        <NewMain/>
        <Bredcom title="Home" subtitle="Ph.D Guidelines"/>
        <First/>
        {/* <Brand/> */}
    </>
  )
}

export default Main
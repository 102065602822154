import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOCse() {
    return (
        <>
            <Header />
            <Bredcom title="Home/ Computer Science Engineering" subtitle="PEO, PO & PSO – CSE" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>

                                <p>
                                    <strong>Program Educational Objectives: PEOs are broad statements that describe the potential career and professional accomplishments after graduation that the program is preparing graduates to achieve.</strong> <br/>
                                    The dynamic professional scope of Computer Science graduates requires lifelong learning and professional development during the entire career span and thus they will be prepared for innovation and ethical practices to become global technology leaders. The program educational objectives of UG program in Computer Science Engineering are:
                                </p>
                                <p>
                                [PEO.1] : To produce graduates having a sound foundation in mathematical, scientific and engineering fundamentals necessary to solve engineering problems.
                                </p>

                                <p>
                                [PEO.2] : To produce technically competent graduates with ability to analyze, design, develop, optimize and implement software and systems across Computer Science and allied domains.
                                </p>

                                <p>
                                [PEO.3] : To produce confident graduates with a professional outlook who can communicate effectively and interact responsibly with colleagues, clients, employers and the society.
                                </p>
                                <p>
                                [PEO.4] : To produce graduates with the learning trajectory matching the technological advancement ensuring growthful employability and/or entrepreneurial ventures.
                                </p>

                                <p>
                                [PEO.5] : To produce graduates who will pursue lifelong learning for career development through successful completion of advanced degrees, professional development courses, industrial training etc.
                                </p>

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                [PO.1] Engineering Knowledge: Ability to apply knowledge in mathematics and science domain problems along with engineering fundamentals.
                                </p>

                                <p>
                                [PO.2] Problem Analysis: Ability to survey existing literature, design and conduct experiments to interpret data and analyze the results to reach conclusions.
                                </p>

                                <p>
                                [PO.3] Domain Knowledge: Ability to apply programming knowledge to solve challenging contemporary computer science domain problems.
                                </p>

                                <p>
                                [PO.4] Modern Tool usage: Ability to create, select, and apply appropriate techniques, resources, and modern engineering and IT tools including prediction and modeling to complex cross domain engineering activities
                                </p>

                                <p>
                                [PO.5] Design/Development of Solutions: Ability to design system components or processes to satisfy the needs of the society within realistic constraints such as economic, social, political, ethical, health, safety and manufacturing.
                                </p>

                                <p>
                                    [PO.6] Individual and Team Player: Ability to function as a fully committed individual professional, and as a member/leader on multi-disciplinary teams.
                                </p>
                                <p>
                                [PO.7] Professionalism and Ethics: Ability to understand and apply human values and ethical principles and commit to professional ethics and responsibilities of the engineering practices.
                                </p>
                                <p>
                                [PO.8] Communication: Ability to communicate effectively on both technical and non technical level with the engineering community and with society at large, verbally and in writing using reports, presentations and documentation.
                                </p>

                                <p>
                                [PO.9] Environmental and Social Awareness: Ability to apply gained knowledge to understand and impact globally relevant environmental and societal context issues using engineering solutions.
                                </p>

                                <p>
                                [PO.10] Economics and Project Management: Ability to understand applications of economics and computational finance to understand project management, economics of online platforms and start-ups.
                                </p>
                                <p>
                                [PO.11] General Awareness: Ability to understand, analyse and gain knowledge from current affairs of the country and global geo-political contemporary issues.
                                </p>

                                <p>
                                [PO.12] Life-long learning: Ability to understand the need for and to engage in a life-long learning process.
                                </p>

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                    <strong>Program Specific Outcomes are statements that describe what the graduates of a specific engineering program should be able to do. The Computer Science Engineering Program at MVNU has been designed for the following outcomes.</strong>
                                </p>

                                <p>
                                    [PSO.1] : PREPARATION
                                    The graduates shall have a scientific outlook with a wide spectrum foundational knowledge of applied mathematics including statistics and probability, basic engineering principles of physics and mechanics and their application as problem solving skills in the computer science domain.
                                </p>

                                <p>
                                    [PSO.2] : CORE AND ANALYTICAL COMPETENCE
                                    The graduates shall have thorough knowledge in Computer Science & Engineering subjects including knowledge of core computing concepts, theoretical and practical training to design, conduct and analyze experiments for basic conversion of problem statements to programs through application development across the full stack.
                                </p>

                                <p>
                                [PSO.3] : SOFT SKILL
The graduates shall have demonstrable interpersonal and social communication skills with extensive training in soft skills via English language, communication, verbal, logical, analytical, comprehension, team building, interpersonal relationship, group discussion, current affairs etc.
                                </p>

                                <p>
                                [PSO.4] : PROFESSIONALISM
The graduates shall have knowledge of individual and team based project development clubbed with ethical values for professional development, and shall be able to apply the knowledge to solve complex problems across multi-disciplinary domains towards corporate and societal benefits besides furthering their own growth.
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOCse
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function ComputerVisionMission() {
    return (
        <>
            <Header />
            <Bredcom title="Home/Computer Science Engineering" subtitle="Vision & Mission" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>VISION</h3>
                                </div>
                                <p>
                                The Computer Science and Engineering department is committed to continually improve the educational environment in order to develop professionals with strong technical and programming backgrounds.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>MISSION</h3>
                                </div>

                                <p>                                    
                                To provide quality education in both theoretical and applied foundations of Computer Science and Engineering. Create highly skilled computer engineers, capable of being gainfully employed in the rapidly expanding Information Technology sector and also develop solutions for the betterment of the nation. To inculcate social sensitivity, economic prudence, professional and ethical values and environmental consciousness among students.
                                </p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComputerVisionMission
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCSEHeader from "./header";
const CSEBTechResearch=()=>{
return (
<Grid container spacing={2}>
{/* <SOETCSEHeader/>
        <BreadcrumbsBanner bannerUrl={"https://uploads.sarvgyan.com/2014/06/mechanical-engineering-image.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Computer Science Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Research & Development" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    In the Computer Science and Engineering Department, research and development are keystones of our academic and professional ethos. We actively engage in organizing research conferences and workshops, creating a vibrant platform for experts, students, and faculty to exchange groundbreaking ideas and technological advancements. Our research spans a wide array of areas, including but not limited to Artificial Intelligence, Machine Learning, the Internet of Things, and Cybersecurity, aiming to tackle some of the most pressing challenges in the tech world today
    <br/><br/>
This culture of continuous learning is further supported by our commitment to faculty development, ensuring that our educators remain at the cutting edge of technological and teaching methodologies. Our department's initiatives extend into collaborative projects with industry leaders and academic institutions, aiming to drive innovation and solve real-world problems. Through these endeavors, we strive to foster a dynamic environment where exploration leads to discovery, and education transcends the classroom to make a meaningful impact on society and the technological landscape.

    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBTechResearch;
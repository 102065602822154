import React from "react";
import { Typography, Grid, List, ListItem, ListItemText } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

function Home() {
  const key_highlight = [
    "Curriculum aligning with AICTE standards and offering industry-relevant elective courses.",
    "State of arts laboratories with full equipment",
    "Flexible choice-based credit system (CBCS) enabling students to pursue interdisciplinary minors",
    "Course curriculum designed with the input of Industry persons",
    "Outstanding placements records",
    "Project based teaching and learning",
    "Curriculum crafted by industry professionals and distinguished academics",
    "Partnerships providing skill-enhancement training to boost job readiness",
    "Access to e-learning platforms like MOOC, NPTEL, Coursera etc.",
    "Tailored training programs to prepare students for placement opportunities",
    "Publication of over 100 research papers in both national and international journals and conferences",
    "Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student start-ups",
    "Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships",
    "Courses on Design Thinking and Innovation, focusing on originality, start up viability, and the documentation of outcomes such as research papers, patents, products, start-ups, or copyrights",
    "Employment opportunities in various sectors like Automobile, Chemical, Aerospace and Defence, construction as well as Electronics industry. So its scope is equally in Government sector as well as in private sectors. ",
    "Prospects for advanced studies or research in both domestic and international institutions",
    "Availability of scholarships covering up to 100% of expenses.",
    "Prospects for advanced studies or research in both domestic and international institutions.",
    

];

const mission = [
  "To impart in graduates solid knowledge of engineering fundamentals, principles and application.",
  "To instill in graduates a sense of accountability and responsibility to the environment and society with respect to professional ethics, social conduct and the environment.",
  "To equip graduates with the ability to apply critical thinking to identify, formulate and solve mechanical engineering problems.",
  "To develop alliances with world class R&D organizations, educational institutions, industry and alumni for excellence in teaching, research and consultancy practices.",
];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Department Of Mechanical Engineering" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
Welcome to the Mechanical Engineering (ME) Department at MVN University!
<br/><br/>
The Department of Mechanical Engineering, over the course of time has grown its expertise and competence in the Mechanical Engineering and allied research areas. The department is continually taking action to train the mechanical engineers of the future by fusing theoretical and applied knowledge and putting an emphasis on critical thinking and learning. It aims to equip students with the skills they need to meet society's emerging challenges. The Department offers B.Tech in Mechanical Engineering and specialization in Robotics and Automation.
<br/><br/>
The vision of the Department of Mechanical Engineering is to strive for excellence in education and targeted research themes in emerging areas, with an aim to become one of the leaders in the education field for mechanical engineering students. To do this, the department works to produce highly qualified graduates through demanding curriculum and cutting-edge research projects. The course contents are periodically updated for introducing new scientific and technological developments. The School maintains active research groups for carrying out collaborative and interdisciplinary research. In addition to the regular academic work, the students of the department are encouraged to participate in co-curricular activities.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Key Highlights" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {key_highlight.map((item, index) => (
          <List sx={{ margin: 0, padding: 0 }}>
            <ListItem disableGutters key={index}>
              <ListItemText
                primary={
                  <Typography component="span" variant="body1">
                    &#8226; {item}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        ))}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Vision" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
          The Mechanical Engineering Department strives for excellence in education and targeted research themes in emerging areas, with an aim to become one of the leaders in the education field for mechanical engineering students.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Mission" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
           The Mechanical Engineering department aspires:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {mission.map((item, index) => (
          <List sx={{ margin: 0, padding: 0 }}>
            <ListItem disableGutters key={index}>
              <ListItemText
                primary={
                  <Typography component="span" variant="body1">
                    &#8226; {item}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        ))}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Scope" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
According to US Bureau of Labour Statistics the U.S. Bureau of Labour Statistics (BLS) projects a 4 percent growth in employment for mechanical engineers from 2019 to 2029, which is about the same as the average for all occupations. This growth is driven by various factors, including the need for mechanical engineers in industries such as automotive, aerospace, energy, and manufacturing.
<br/><br/>
As technology continues to advance, job prospects may indeed be best for those who stay updated with the latest developments in their field. Mechanical engineers who possess strong skills in areas such as computer-aided design (CAD), computer-aided engineering (CAE), mechatronics, and renewable energy systems are likely to have better opportunities in the job market. Additionally, those with experience in emerging fields such as additive manufacturing (3D printing), robotics, and sustainable design may find themselves in high demand.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Home;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCSEHeader from "./header";

const CSEBTechStudentshub=()=>{
return (
<Grid container spacing={2}>
{/* <SOETCSEHeader/> */}
        {/* <BreadcrumbsBanner bannerUrl={"https://uploads.sarvgyan.com/2014/06/mechanical-engineering-image.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Computer Science Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Student’s Hub" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Student's Hub serves as a dedicated space designed to support, engage, and empower our students throughout their academic journey. This comprehensive platform offers access to a wealth of resources, including academic advisories, project collaboration opportunities, and a calendar of events that enrich the student experience. Using hub students can connect with peers, explore interdisciplinary projects, and participate in student-led organizations and clubs that span interests from coding and cybersecurity to innovation and entrepreneurship. The Hub is also a gateway to wellness and support services, ensuring that students have the support they need to thrive both academically and personally. Through workshops, mentorship programs, and networking events, students are encouraged to expand their horizons, develop new skills, and build a strong community of future tech leaders. The Student's Hub is not just about fostering academic excellence; it's about nurturing a supportive, dynamic environment where every student can find their path to success.
    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBTechStudentshub;
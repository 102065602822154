import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import First from './first'
function FacultyAccomplishments() {
  return (
    <>
      <Header />
      <Bredcom title="Home" subtitle="Faculty Accomplishments / Certifications" />
     <First/>
    </>
  )
}

export default FacultyAccomplishments
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../../utils/utils";
import BannerCoursesDurationFee from "../../../muiCommonComponent/bannerCoursesDurationFee";
const CSEMCA=()=>{
    const progHiglight=[
        {listName:'Comprehensive Curriculum: Covers subjects like advanced databases, software development,web technologies, and systems analysis.'},
        {listName:'Hands-On Experience: Extensive practical labs and projects to apply theoretical knowledge.'},
        {listName:'Industry Integration: Regular guest lectures, workshops, and seminars with IT industry experts.'},
        {listName:'Research Opportunities: Encouragement and support for participation in faculty-led research projects.'},
        {listName:'Internships and Placements: Strong links with industry partners to facilitate internships and job placements.'},
        {listName:'Professional Development: Programs tailored to enhance professional skills and prepare students for leadership roles.'},
        {listName:'Multidisciplinary Approach: Opportunities to engage with related fields such as data science and cybersecurity.'},
        {listName:'Global Exposure: Options for student exchanges and participation in international conferences.'},
        {listName:'Cutting-Edge Technologies: Training on the latest tools and technologies in the IT industry.'},
        {listName:'Capstone Projects: Major projects that synthesize course learning into practical, innovative solutions.'}
        
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','2 Years':'₹75200/- (Tuition Fee - ₹50000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Fees','2 Years':'₹59200/- ( Tuition Fee – ₹50000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='2 years'
    const fee_1st= '75,200'
    const fee_2nd= '59,200'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
</Grid>
<Grid item xs={12}>
    <MainTitle title="Master of Computer Application" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Master of Computer Applications (MCA) program at MVN University is designed to provide
graduate students with comprehensive and advanced knowledge in computer applications and
information technology. This intensive program focuses on equipping students with the skills necessary
to excel in the rapidly evolving IT industry, blending theoretical knowledge with practical application.
Throughout the duration of the program, students engage in detailed study of system development,
systems engineering, software development, database administration, and web technologies. The
curriculum is tailored to not only enhance students&#39; technical expertise but also to foster analytical and
problem-solving skills that are crucial for tackling complex challenges in the tech world. The MCA
program is particularly beneficial for individuals looking to advance their careers in the fields of
software development, data science, systems analysis, and IT management. With a strong emphasis on
the application of computing technologies to solve real-world problems, the program includes project
work, case studies, and hands-on training through internships with leading tech companies. Upon
completion of the MCA, graduates possess the capabilities to design, develop, and implement
computer applications and systems, ensuring they are well-prepared for leadership roles in technology
and business settings. The program is ideal for those aspiring to become influential innovators and
thought leaders in the technology sector.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 examination with at least 55% marks for MCA programmes in aggregate in 5 subjects and
    eligibility shall be determined on the basis of percentage of aggregate marks in English, Physics,
    Chemistry &amp; Mathematics.

    </Typography>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    . Criteria for Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Merit preparation / short listing of candidates shall be on the basis of Valid GATE score/ Marks in Qualifying Examination.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The M.Tech in Computer Science and Engineering program at MVN University is designed to advance knowledge and skills in the field of computing. Here are some of the key highlights of the program:
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    These program highlights demonstrate the commitment of the M.Tech in Computer Science and Engineering at MVN University to provide a comprehensive and dynamic education that prepares graduates for successful careers in technology and research.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Master of Computer Application Fee Structure
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you'll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEMCA;
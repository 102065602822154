import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const MBAHM=()=>{
    const progHiglight=[
{listName:'Rigorous Curriculum: Our MBA program offers a comprehensive curriculum that covers essential topics such as finance, marketing, operations, human resources, and strategic management.'},
{listName:'Experiential Learning: Emphasis on experiential learning through case studies, simulations, industry projects, and internships to bridge the gap between theory and practice.'},
{listName:'Expert Faculty: Learn from a diverse team of experienced faculty members and industry practitioners who bring real-world insights into the classroom.'},
{listName:'Personalized Mentorship: Receive personalized mentorship and guidance to help you chart your career path and achieve your professional goals.'},
{listName:'Networking Opportunities: Access to a vast network of alumni, industry professionals, and corporate partners for networking, internships, and job placements.'},
{listName:'Leadership Development: Develop leadership skills through workshops, seminars, and leadership development programs designed to nurture future business leaders.'},
{listName:'State-of-the-Art Facilities: Study in a modern campus equipped with state-of-the-art facilities, including libraries, computer labs, seminar halls, and recreational areas.'},
{listName:'Industry Partnerships: Benefit from our strong industry partnerships that provide opportunities for industry exposure, guest lectures, and corporate mentorship programs.'},
{listName:'Holistic Development: Focus on holistic development, including communication skills, teamwork, problem-solving abilities, and ethical decision-making.'},

]
    const feeStructureData=[
        {duration:'1st Year Fees','2 Years':'₹100200/- (Tuition Fee - ₹75000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','2 Years':'₹84200/- ( Tuition Fee – ₹75000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='2 years'
    const fee_1st= '1,00,200'
    const fee_2nd= '84,200'
    const eligibility='50% in Graduation in any Discipline.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="MBA- Master of Business Administration in Healthcare Management" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Health and social care are a significant and rising service sector, thanks to increased government investments that have raised its profile to unprecedented levels. Rapid changes in the sector indicate that there will be a demand for well-trained and multi-skilled workers.
<br/><br/>
The program is designed exclusively for existing and aspiring managers looking to advance their careers in healthcare, social care, and third-sector companies. It gives you the opportunity to learn the knowledge and skills needed to advance to senior management and leadership positions. We assist you in developing fundamental knowledge of concepts and business models, as well as a thorough grasp of procedures and practices, in order to improve leadership, financial, and project management abilities in the healthcare management sector.
<br/><br/>
MBA (HM) is a two-year postgraduate curriculum that provides healthcare workers with specialised and general management training. This programme's curriculum focuses on giving students with both intellectual and practical insights into healthcare management, preparing them to take on crucial managerial positions in the globalised healthcare system. This training also gives professionals with insights into broad management perspectives and a greater awareness of the community.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
50% in Graduation in any Discipline. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.B.A.
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default MBAHM;
import React from 'react';
import { Typography, Grid, Card, Avatar, Box } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import { TableDataPresentation, ListDataPresentation } from '../../utils/utils';
import CourseGrid from '../muiCommonComponent/coursesButtonGrid';

function Home() {
  const courses = [
    {name:'B.A.LLB.',url:'/school-of-law/ba-llb'},
    {name:'B.B.A.LLB.',url:'/school-of-law/bba-llb'},
    {name:'LL.B.',url:'/school-of-law/llb'},
    {name:'LL.M.',url:'/school-of-law/llm'},
  ]; 
  const offeredProgramme=[
    {'program':'B.A.LL.B.','duration':'5','Eligibility Criteria':'Senior Secondary or 10+2 or 12 th or equivalent from a recognised Board/ University with the minimum percentage as per BCI norms. Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'B.B.A.LL.B','duration':'5','Eligibility Criteria':'Senior Secondary or 10+2 or 12 th or quivalent from a recognised Board/ University with the minimum percentage as per BCI norms.Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'LL.B.','duration':'3','Eligibility Criteria':'Graduation or post-graduation from a recognised University with the minimum percentage as per BCI norms.Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'LL.M.','duration':'1','Eligibility Criteria':'Law graduation from a recognised University with the minimum percentage as per UGC/ University norms.'},
]
const progHiglight=[
 {listName:'Multifaceted and meticulous industry relevant integrated curriculum'},
 {listName:'Interdisciplinary and interactive learning'},
 {listName:'Comprehensive legal foundation and versatile career paths'},
  {listName:'Practical skills development'},
  {listName:'Proficient faculty expertise'},
  {listName:'Professional networking opportunities'},
  {listName:'Problem-solving skills'},
  {listName:'Career preparation and advancement'},
  {listName:'Pathways to further education'},
  
]
const careersArr=[
  {"career":"Government Services","career1":"Judicial Services","career2":"Legal Advisor/ Consultant"},
  {"career":"Public Prosecutor/Advocate","career1":"Advocate/ Legal Practitioner","career2":"Legal Researcher"},
  {"career":"Expert/ Member of various Commissions","career1":"Corporate Counsel","career2":"Legal Academia"},
  {"career":"Environmental Protection Analyst/Consultant","career1":"Law Firm","career2":"Legal Aid and  Advocacy"},
  {"career":"Environmental Policy Analyst","career1":"Crime Intelligence Analyst","career2":"LPO – Legal Process Outsourcing"},
  {"career":"Drug Policy","career1":"Cyber Lawyer and Expert","career2":"Direct and Indirect Tax Consultant"},
  {"career":"Labour Officer","career1":"National and International Arbitration Lawyer and Expert","career2":"Social Work/ Politics"},
  {"career":"Nonprofit and NGO Sector","career1":"Business Consultant","career2":"Entrepreneurship"},
]
const strengths=[
  {listName:'Profession Oriented Curriculum, experienced teaching faculty, innovative Teaching Pedagogy, and co-curricular activities for overall growth.'},
 {listName:'Interactive sessions with legal luminaries, Senior Advocates, Professionals related to law field etc.'},
 {listName:'Active participation in Moot Court Competitions, Workshops, Seminars, Conferences, &amp; other activities.'},
 {listName:'Periodic visits to legal Institutions like the Supreme Court of India, High Court,Parliament, District ourt, Jail, Police Stations etc.'},
{listName:'Frequent Legal Awareness/ Aid Camps under the aegis of the DLSA Palwal.'},
 {listName:'Collaborations with valued law firms and advocates for work experiences,campus interviews, and placements.'},
 {listName:'Wi-fi enable campus, AC Classrooms, Moot Court, Legal Aid Clinic, and well- equipped Library.'},
{listName:'Active Alumni Network to Leverage the Strength of Professional Connections'},
{listName:'Strong emphasis on professional training and holistic personality development'},
{listName:'Elective options that encourage a multidisciplinary approach'},
]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Law" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Vision" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        To be a premier institution of excellence in legal education, research and services by
creating competent legal human resources for serving the society.
</Typography>
</Grid>
<Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Mission" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
The Mission of the School of Law is to develop legal professionalism in the students
while making them intellectually alive, morally upright, socially responsible citizens
with a positive legal attitude for the betterment of self and society.
</Typography>
</Grid>
<Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="ABOUT SCHOOL OF LAW" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
The School of Law (SOL) has been imparting high-quality legal education under the
aegis of MVN University since 2015 with a clear objective to revolutionise, redefine,
and reshape the landscape of legal education. The Five Years integrated Law Courses
(i.e. B.A.LL.B. &amp; B.B.A.LL.B.) and Three Years Law Course (i.e. LL.B.) are
approved by Bar Council of India, New Delhi. All Undergraduate, Postgraduate and
Research Courses are approved and run by the University as per BCI and UGC norms.
The SOL is staffed by a team of committed and seasoned faculty members who are
always prepared to provide guidance to students in both academic and extracurricular
pursuits. Additionally, the School of Law promotes student engagement in various
academic activities such as Moot Courts, Debate, Legal Quiz, Educational Tours,
Seminars, Conferences, and Workshops. The SOL also operates a free legal aid clinic
in order to familiarise students with the interactive process between clients and
lawyers. The SOL possesses a distinct Law Library that offers a substantial collection
of textbooks, law journals, periodicals, and online access to legal databases, all of
which are designed to meet the educational requirements of the students.
<br/>
<b>A Noble Duty: Towards the service of humanity!</b>
</Typography>
</Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="MESSAGE FROM DEAN (Dr. RAHUL VARSHNEY)" />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Card sx={{ height: 'auto', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px' }}>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <Avatar alt={'NP'} src={'/assets/img/SOL/rahul_varshney.png'} sx={{ width: 150, height: 180, marginBottom: 2 }} />
            <Box sx={{ background: '#ED1E24', borderRadius: '10px', padding: 2 }}>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Prof. (Dr) Rahul Varshney
              </Typography>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Dean of School of Law
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Being fully conscious of the fact that law and society are interrelated and
interdependent and the importance of law in the light of globalization is increasing
continuously. The place of law is always supreme in any country and every citizen of
nation is supposed to know the basic law of their country and therefore the demand of
law education has necessitated that law colleges should take up the responsibility of
spreading legal education by integrating several disciplines.
<br/>
        Welcome to the world of School of Law, where education means all round
development of the student. The SOL’s firm commitment is to inspire the confidence
and imbue courage in the students to successfully meet the challenges in the
profession and inculcate the professional culture of advocacy and in still a sense of
loyalty to serve the needy persons in the society.
<br/>
At School of Law, we are dedicated to fostering a vibrant community of scholars,
practitioners, and learners who are passionate about the law and its impact on society.
Our faculty comprises distinguished legal experts who are committed to excellence in
teaching, research, and service. They bring a wealth of experience from diverse legal
backgrounds, enriching our academic environment and providing invaluable
mentorship to our students.
<br/>
Whether you are a prospective student, a current member of our community, or an
alumnus/alumna, I invite you to explore our School of Law and learn more about the
exciting opportunities available at SOL. Together, we are shaping the future of legal
education and making a positive impact on the world.
Join us to work with vision.
Sincerely,
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Law Courses" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <CourseGrid courses={courses}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Offered Programs" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <TableDataPresentation data={offeredProgramme}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="PROGRAM HIGHLIGHTS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={progHiglight}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="STRENGTHS OF SCHOOL OF LAW" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={strengths}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="CAREER OPTION AFTER LAW DEGREE" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
      We are governed by law, and hence, legal education becomes inevitable to us.
Therefore, it has become the world’s largest and most influential governance in the
country. In India, legal education is offered at different levels. Candidates intended to
become legal professionals or want to enter in law field can choose B.A.LL.B.
(Integrated Bachelor of Arts, Bachelor of Laws), B.B.A.LL.B. (Integrated Bachelor of
Business Administration, Bachelor of Laws), LL.B. (Bachelor of Laws), LL.M.
(Master of Laws), Ph.D. (Doctor of Philosophy in Law) Courses.
It is very important to ensure that legal education produces legal persons with the
vision of social terms. Specially Lawyers are also called as social engineers. The

scope of law has widened. It includes policymakers, administrators, law teachers,
lawyers and much more.
<br/>
<br/>
Following are some of the good options for those who want to pursue law:
        </Typography>
        <TableDataPresentation data={careersArr}/>
      </Grid>
    </Grid>
  );
}

export default Home;

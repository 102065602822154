import { useParams } from "react-router-dom";
import BreadcrumbsBanner from "../../muiSOET/breadcrumbs";
import { Container } from "@mui/material";
import NewMain from "../../headerone/newMain";
import Agri_extension from "./agri_extension";
import AlumniConnect from "./alumni_connect";
import GreenInitiative from "./green_initiative";
import Health_camps from "./health_camps";
import Legal_aid from "./legal_aid";
import MVNSociety from "./mvn_society";
import MVNUniversityabout from "./";
import OPD from "./opd";
import Placement from "./placement";
const Index=()=>{
    const { screenname } = useParams();
    console.log('screenname::::::::::::',screenname)
    const screensName=()=>{
        let screen=''
       if(screenname=='mvn-society'){
            screen=MVNSociety()
        }else if(screenname=='mvn-university'){
            screen=MVNUniversityabout()
        }
        else if(screenname=="opd-clinic"){
            screen=OPD()
        }
        else if(screenname=='legal-aid'){
            screen=Legal_aid()
        }else if(screenname=='health-campus'){
            screen=Health_camps()
        }
        else if(screenname=="agriculture-extension"){
            screen=Agri_extension()
        }
        // else if(screenname=='social-responsibility'){
        //     screen=BSCBiotech()
        // }
        else if(screenname=='green-initiatives'){
            screen=GreenInitiative()
        }else if(screenname=='alumni-connect'){
            screen=AlumniConnect()
        }else if(screenname=='placements'){
            screen=Placement()
        }
     
        return screen
    }
return(<>
<NewMain/>
<BreadcrumbsBanner bannerUrl={"https://www.nichepursuits.com/wp-content/uploads/2022/05/footers-1200x750.png"}/>
<Container>
  {screensName()} 
</Container>
</>)
}
export default Index;
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {
    return (
        <>
            <section id="work" className="pt-150 pb-105">
                <div className="container">
                    <div className="portfolio ">
                        <div className="row  fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                            {/* <div className='elementor-element-63bca71 col-md-8 col-sm-12'>
                                <h2>Check CUET 2023 Results</h2>
                                <ul><li> <span className='circle-dot'/>Visit CUET website- https://cuet.samarth.ac.in/</li><li><span className='circle-dot'/>Click on the CUET UG 2023 result</li><li><span className='circle-dot'/>Enter credentials</li><li><span className='circle-dot'/>Check Results</li><li><span className='circle-dot'/>Check Answerkey-https://cuet.samarth.ac.in/</li></ul>
                                <br />
                                <h2>CUET Counselling 2023</h2>
                                <ul><li><span className='circle-dot'/>Schedule</li><li><span className='circle-dot'/>Registration</li><li><span className='circle-dot'/>Seat Allotment</li><li><span className='circle-dot'/>Result</li></ul>
                                <br />
                                <h2>Documents Required for Counselling</h2>
                                <p>Along with the admission form, candidates also need to carry some other documents such as:</p>
                                <ul><li><span className='circle-dot'/>Class 10 mark sheet</li><li><span className='circle-dot'/>Class 12 mark sheet</li><li><span className='circle-dot'/>CUET 2023 scorecard</li><li><span className='circle-dot'/>CUET 2023 admit card</li><li><span className='circle-dot'/>Category certificate (if applicable)</li></ul>
                                <br />
                                <h2>CUET 2023 - Important Dates</h2>
                                <table style={{border:'1px outset #dddddd',width:'100%'}}><tbody><tr><th style={{border:'1px outset #dddddd'}}>Events</th><th style={{border:'1px outset #dddddd'}}>Dates (Tentative)</th></tr><tr><td style={{border:'1px outset #dddddd'}}>Application form</td><td style={{border:'1px outset #dddddd'}}>In the first week of February 2023</td></tr><tr><td style={{border:'1px outset #dddddd'}}>Application correction window</td><td style={{border:'1px outset #dddddd'}}>To be notified</td></tr><tr><td style={{border:'1px outset #dddddd'}}>CUET hall ticket 2023</td><td>To be notified</td></tr><tr><td style={{border:'1px outset #dddddd'}}>CUET exam date 2023</td><td style={{border:'1px outset #dddddd'}}>May 21 to 31, 2023<br />Reserved dates – June 1 to 7, 2023</td></tr><tr><td style={{border:'1px outset #dddddd'}}>Provisional CUET answer key 2023</td><td style={{border:'1px outset #dddddd'}}>To be notified</td></tr><tr><td style={{border:'1px outset #dddddd'}}>Objections against CUET 2023 provisional answer key</td><td style={{border:'1px outset #dddddd'}}>To be notified</td></tr><tr><td style={{border:'1px outset #dddddd'}}>CUET final answer key 2023</td><td style={{border:'1px outset #dddddd'}}>To be notified</td></tr><tr><td style={{border:'1px outset #dddddd'}}>CUET Result 2023 date</td><td style={{border:'1px outset #dddddd'}}>Third week of June</td></tr></tbody></table>
                            </div>
                            <div className='col-md-4 col-sm-12'>
                                <div className="elementor-widget-container">
                                    <nav className="menu-cuet-2023-container" aria-label="Menu"><ul id="menu-item-32455" className="menu"><li id="menu-item-32456" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-32213"><a target="_blank" rel="noopener" href="https://admission.mvn.edu.in/wp-content/uploads/2023/03/Draft-Press-Release-regarding-building-awareness-dated-06-March-2023.pdf" className="menu-link">Press Release</a></li>
                                        <li id="menu-item-32456" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-32456"><a href='/cuet-scholarships-criteria' className="menu-link"> <Link  to="/cuet-scholarships-criteria"/>CUET Scholarships Criteria</a></li>
                                        <li id="menu-item-32456" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-32166"><a href="#Check%20CUET%202023%20Results" className="menu-link">Check CUET 2023 Results</a></li>
                                        <li id="menu-item-32456" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-32167"><a href="#CUET%20Counselling%202023" className="menu-link">CUET Counselling 2023</a></li>
                                        <li id="menu-item-32456" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-32168"><a href="#Documents%20Required%20for%20Counselling" className="menu-link">Documents Required for Counselling</a></li>
                                        <li id="menu-item-32456" className="menu-item menu-item-type-custom menu-item-object-custom menu-item-32169"><a href="#CUET%202023%20-%20Important%20Dates" className="menu-link">CUET 2023 Counselling Dates (Tentative)</a></li>
                                    </ul></nav>		</div>
                            </div> */}
                            <h2>Under Construction</h2>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
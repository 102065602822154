import SOETHeader from "./header";
import BreadcrumbsBanner from "./breadcrumbs";
import SOETContent from "./home";
import { Container } from "@mui/material";
import SOETDepartments from "./departments";
import EventNewsGallary from "./eventNews";
import RecuiterCompany from "./recuiterCompany";
// import WidgetsMenu from "./menuWidgets";
import NewMain from "../headerone/newMain";
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
const SOETHome=()=>{
    const headerMenus = [
        { mainHeader: 'Home', path: '/school-of-engineering-technology', subHeader: [] },
        { mainHeader: 'SOET Insights', path: '', subHeader: [
            { title: 'Insights', path: '/soet/insights', },
            { title: 'Vision & Mission', path: '/soet/vision-mission', },
            { title: 'Knowledge Partners', path: '/soet/knowledge-partners', },
            { title: 'Pedagogical Advances – MOOCs', path: '/soet/mcoos', },
        ] },
        { mainHeader: 'SOET Departments', path: '', subHeader: [
            { title: 'Computer Science & Engineering', path: '/soet-cse/home', },
            { title: 'Mechanical Engineering', path: '/soet-me/home' },
            { title: 'Civil Engineering', path: '/soet-civil/home' },
            { title: 'Electronic & Communication Engineering', path: '/soet-ece/home' },
        ] },
        { mainHeader: 'MVN Times', path: '#', subHeader: [] },
        { mainHeader: 'Scholarly Work ', path: '#', subHeader: [] },
      ];
return(<>
<NewMain/>
{/* <Navbar/> */}
<WidgetsMenu headerMenus={headerMenus}/>
<BreadcrumbsBanner bannerUrl={"/assets/img/SOET/soet.jpg"}/>
<Container>
<SOETContent/>
<SOETDepartments/>
<EventNewsGallary/>
<RecuiterCompany/>
</Container>
</>)
}
export default SOETHome;
import React, { useState } from 'react';
import { PersonalInfo, ApplyCourse, Qualification, FinishScreen } from './forms';
import { baseURL } from '../../../config';
import Loader from '../Loader/Loader';
import axios from 'axios';
const SpecialRegex=/^[\w-\]+[A-Za-z\s]*$/g
const EmailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
const steps = [{ level: 'Personal Info', id: 'personal' }, { level: 'Qualification', id: 'qualification' }, { level: 'Experience', id: 'account' }]
function MultiStepForm() {
    const [errorStatus,setErrorStatus]=useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [addressCheck,setAddressCheck]=useState(false)
    const [admissionForm, setAdmissionForm] = useState({
        student_name:"",
        father_name:"",
        mother_name:"",
        gurdian_name:"",
        gurdian_annual_incom:"",
        gurdain_relationship:"",
        gurdain_profession:"",
        student_nationality:"",
        student_religion:"",
        student_dob:"",
        student_gender:"",
        postal_address:"",
        city:"",
        state:"",
        pin_code:"",
        permanent_address:"",
        permanent_city:"",
        permanent_state:"",
        permanent_pin_code:"",
        student_phone:"",
        student_email:"",
        category:"",

    });
    const [qualification,setQualification]=useState({
        entrance_exam:[
            {entrance_type:"GATE/NET/SLET",roll_num:"",mark_obtained:"",percentage:""},
            {entrance_type:"M. Phil.",roll_num:"",mark_obtained:"",percentage:""},
            {entrance_type:"Any Other",roll_num:"",mark_obtained:"",percentage:""},
        ],
        subjects:[
            {examination:"High School or equivalent",subject:"",board:"",year:"",percentage:"",division:""},
            {examination:"Sr. Secondary,Intermediate or equivalent",subject:"",board:"",year:"",percentage:"",division:""},
            {examination:"Graduation or equivalent in",subject:"",board:"",year:"",percentage:"",division:""},
            {examination:"Post Graduateor equivalent in",subject:"",board:"",year:"",percentage:"",division:""}
        ],
        pending_supplementary:"",
        pending_supplementary_detail:"",
        disqualified_board:"",
        disqualified_board_detail:"",
        disciplinary_case:"",
        disciplinary_case_detail:"",
        criminal_case:"",
        criminal_case_detail:"",
        subject_area:"",
        department:"",
        school:""
    })
    const [experiencePayload,setExperiencePayload]=useState({
       experienceList:[
        {universitry_name:"",period:"",position:"",supervisor_name:"",patent_filed:""},
        {universitry_name:"",period:"",position:"",supervisor_name:"",patent_filed:""},
        {universitry_name:"",period:"",position:"",supervisor_name:"",patent_filed:""},
       ],
       employment:"",
       employmentList:[
        {organization_name:"",designation:"",from_date:"",from_to:""},
        {organization_name:"",designation:"",from_date:"",from_to:""},
       ],
       propsed_area:"",
       career_objectives:"",
       phd_programme:"",
       references_mobile:"",
       references_email:"",
       hostel:"",
       transport:""
    })
    const [currentStep, setCurrentStep] = useState(0);
    const handleSubmit=(e)=>{
        let payload={
         ...admissionForm,
         ...experiencePayload,
         ...qualification   
        }
        e.preventDefault()
        setIsLoading(true)
        if (Boolean(admissionForm.student_name.match(SpecialRegex))==false || Boolean(admissionForm?.student_email.match(EmailRegex))==false|| admissionForm?.student_phone<10) {
            setErrorStatus(true)
            setIsLoading(false)
            // var x = document.getElementById("snackbar");
            // x.className = "show";
            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
        }
        else {
            let url = `${baseURL}/phdadmissionForm`
            axios.post(url, payload, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
      
                }
            }).then((response) => {
                var x = document.getElementById("successsnackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
                setIsLoading(false)
                
            }).catch(error=>{
              setIsLoading(false)
            })
      
        }
    }
    return (<>
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-sm-12 col-md-5 col-md-5 phd_advertise">
                <img src='/assets/img/phd_advertise.jpg' />
                </div>
                <div className="col-sm-12 col-md-7 col-md-7">
                    <div className="card pt-4 pb-0 mt-3 mb-3 text-center">
                        <h2 id="phd-heading">Ph.D ADMISSION SESSION :2024-25 (WINTER)</h2>
                        <p>Fill all form field to go to next step</p>
                        <form id="msform">

                            <ul id="progressbar" >
                                {steps?.map((item, ind) => {
                                    return (
                                        <li className={ind <= currentStep && "active"} id={item.id}><strong>{item.level}</strong></li>
                                    )
                                })}
                            </ul>
                            {currentStep == 0
                                ? <PersonalInfo 
                                currentStep={currentStep} 
                                setCurrentStep={setCurrentStep} 
                                state={admissionForm} 
                                setState={setAdmissionForm} 
                                errorStatus={errorStatus} 
                                setErrorStatus={setErrorStatus}
                                setAddressCheck={setAddressCheck}
                                addressCheck={addressCheck}
                                />
                                // : currentStep == 1
                                //     ? <PermanentAddress currentStep={currentStep} setCurrentStep={setCurrentStep} state={addressInfo} setState={setAddressInfo}/>
                                    : currentStep == 1
                                        ? <Qualification currentStep={currentStep} setCurrentStep={setCurrentStep} state={qualification} setState={setQualification}/>
                                        : currentStep == 2 &&
                                            <ApplyCourse currentStep={currentStep} setCurrentStep={setCurrentStep} state={experiencePayload} setState={setExperiencePayload} handleSubmit={handleSubmit}/>
                                            // : <FinishScreen />
                            }

                        </form>
                    </div>
                </div>
            </div>
        </div>
        {isLoading&&<Loader/>}
    </>)
}

export default MultiStepForm;

import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function VisionMission() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="Vision & Mission" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>VISION</h3>
                                </div>
                                <p>
                                To Nurture the future business leader through Quality and Value based teaching, action research and tanning to meet the needs of Business World.
                                To enrich passion for learning, creative thinking entrepreneurial skills.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>MISSION</h3>
                                </div>

                                <p>                                    
                                    SBMC emphasis the all-around development of its students. It aims at developing not only good management and commerce professionals with analytical skill but also good and worthy entrepreneur of country who can help in overall development of the country.
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default VisionMission
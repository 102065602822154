import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
const Placements=()=>{
    const navigate=useNavigate()
    useEffect(() => {
        // URL of the PDF
        const pdfUrl = '/mvn_placement_brochure_2024.pdf';
        
        // Open the PDF in a new tab
        window.open(pdfUrl, '_blank');
        navigate(-1)
      }, []); // Empty dependency array ensures this runs only once when the component mounts

}
export default Placements;
import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function MscMicrobiology() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="M.Sc. (Microbiology)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                M.Sc. (Microbiology) is a Postgraduate course the duration of the course is 2 years and this consists of 4 semesters. This discipline consists of chemistry and biology for the investigation of the living system and the relationship with the environment. This course contains a deeper insight into microbiology which includes the study of all living organisms that are too small to be visible to the naked eye such as bacteria and fungi which are collectively known as microbes. The job opportunities for a graduate of M.Sc. course are plenty in the public and private sectors.
                                </p>

                                <p>
                                The course aims to provide an advanced understanding of the core principles and topics of Microbiology and their experimental basis to enable students in acquiring a specialized knowledge by means of dedicated lecture series and subject-oriented research project.
                                </p>

                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Course Details</h3>
                                </div>
                                <p>
                                M.Sc. (Microbiology) is a Post-graduate course. Duration of this Academic program is 2 years (Exam Pattern - Semester System).
                                </p>

                                
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Eligibility Criteria</h3>
                                </div>
                                <p>
                                Candidates should have passed Bachelor’s degree in Microbiology and related streams from a recognized university.
                                </p>    
                                

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Syllabus Content</h3>
                                </div>
                                <p>
                                The Syllabus includes the study of microorganisms, basic and applied aspects of microbiology. The different subjects covered are Cell Biology, Molecular Biology and Microbial Genetics, Immunology, Medical Microbiology, Industrial Microbiology, Food and Dairy Microbiology, Agricultural Microbiology, Environmental Microbiology, Pharmaceutical Microbiology, Recombinant DNA Technology. The contents are designed to develop skills and in-depth knowledge of subject. The final semester includes a mandatory dissertation.
                                </p> 

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Scope</h3>
                                </div>
                                <p>
                                The course has tremendous scope and a very bright future. The scope of microbiology is immense since it is applied in many fields like Medical, Dairy, Pharmaceutical, Industrial, Clinical, research, water industry, agriculture, nanotechnology, chemical etc. The students, who pursue their career as a Microbiologist, get golden opportunities in government, research and industries (cosmetic/food/pharmaceutical).
                                </p> 

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default MscMicrobiology
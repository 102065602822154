import { Grid, Typography, Card, Box } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSStudentshub=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Allied Health Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Student’s Hub" />
    </Grid>

    <Grid item xs={12} sm={12} md={12}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_student_hub.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    A student's hub serves as the vibrant nucleus of academic life, fostering collaboration, learning, and growth. It's a dynamic space where students converge to share ideas, seek guidance, and engage in enriching activities. The hub encapsulates the essence of educational community, offering resources, support, and opportunities for personal and intellectual development. It's not merely a place to study, but a haven where students cultivate friendships, explore new interests, and discover their passions. From study groups and workshops to cultural events and extracurricular activities, the student's hub is the beating heart of campus life, nurturing a sense of belonging and inspiring lifelong learning. It's a place where creativity flourishes, fuelled by the diverse perspectives and talents of its inhabitants. In this dynamic environment, innovation takes root, sparking breakthroughs that shape the future. The student hub is not just a destination but a journey—a journey of growth, discovery, and transformation. It embodies the spirit of community, fostering inclusivity and fostering a culture of excellence.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSStudentshub;
import React from 'react';
import Slider from 'react-slick';

function Redslider() {
  const images = [
    '/assets/img/brand/b-logo1.png',
    '/assets/img/brand/b-logo2.png',
    '/assets/img/brand/b-logo3.png',
    '/assets/img/brand/b-logo4.png',
    '/assets/img/brand/b-logo5.png'
  ];

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1500,
    arrows: false,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <section id="company-hiring" className="section">
      <div className="container">
        {/* <a href="placements.html#our-recruiters" className="btn button3 view-all">View all companies...</a> */}
        <h2>Companies Hiring</h2>
        <p className="lead">Top companies are hiring from MVN University</p>
        <div className="stats-bg clearfix">
          <div className="animate">
            <ul className="clearfix">
              <li className='list-round' style={{ marginLeft: '-56px', marginTop: '-38px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/hcl.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-220px', marginTop: '-20px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/syntel2.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-375px', marginTop: '69px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/amarujala.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-472px', marginTop: '198px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/Vodafone_logo.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-532px', marginTop: '347px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/cmc-logo_0.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-533px', marginTop: '497px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/FutureGroup.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-491px', marginTop: '641px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/sleepwell.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-396px', marginTop: '771px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/Tech_Mahindra_New_Logo1.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-273px', marginTop: '863px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/Amazon.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '-112px', marginTop: '911px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/zenpact.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '62px', marginTop: '897px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/adani_wilmar.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '217px', marginTop: '827px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/Dish_TV_2.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '348px', marginTop: '682px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/niit_logo_03.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '423px', marginTop: '504px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/icici_securities.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '414px', marginTop: '315px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/Pepsi.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '94px', marginTop: '-39px' }}><img className="img" src="https://www.sharda.ac.in/attachments/testimonial_images/Zee_TV_2011.png" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '351px', marginTop: '158px' }}><img className="img" src="https://www.top-employers.com/contentassets/ed7e1b2cb1fd40cab269b2f1839ce5ce/oid00d200000000wi7ids0683y00000jvywbda3y000000iazmsgikkcyh5cad4etln20g6hz3b1gdurj453kqqoqlxgaaspdffalse5/?format=webp&bgcolor=white&quality=75&height=75" alt="logo" /></li >
              <li className='list-round' style={{ marginLeft: '237px', marginTop: '43px' }}><img className="img" src="https://www.freeiconspng.com/uploads/dell-icon-6.jpg" alt="logo" /></li >
            </ul>
          </div>
          <div className="round round1 col-sm-6">
            <span className="sm-round"></span>
            <div className="content">
              <figure>
                <img className="img" src="https://www.sharda.ac.in/assets/imgs/icon2.png" alt="icon" />
              </figure>
              <h4>65% to 100% </h4>
              <p>Placement program wise</p>
              {/* <!--<h4>Upto 100%</h4>
								<p>Placement Assistance</p>--> */}
            </div>
          </div>
          <div className="round round2 col-sm-6">
            <span className="sm-round"></span>
            <div className="content">
              <figure>
                <img className="img" src="https://www.sharda.ac.in/assets/imgs/icon3.png" alt="icon" />
              </figure>
              <h4>23000+</h4>

              <p>Successful Alumni worldwide</p>
            </div>
          </div>
          <div className="round round3 col-sm-6">
            <span className="sm-round"></span>
            <div className="content">
              <figure>
                <img className="img" src="https://www.sharda.ac.in/assets/imgs/icon1.png" alt="icon" />
              </figure>
              <h4>450+</h4>

              <p>companies hiring world wide</p>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-sm-12 our-recuiter" >
                                   <center> <img src='https://admission.mvn.edu.in/wp-content/uploads/2021/09/our-recruiters.png' alt="img" className='industryImg' /></center>
                        </div>
      </div>
    </section>
  )

  // return (
  //   <div className="brand-area pt-60 pb-60" style={{ backgroundColor: '#ED1E24' }}>
  //     <div className="container">
  //       <Slider className="row brand-active" {...settings}>
  //         {images.map((image, index) => (
  //           <div key={index} className="col-xl-2">
  //             <div className="single-brand">
  //               <img src={image} alt="img" />
  //             </div>
  //           </div>
  //         ))}
  //       </Slider>
  //     </div>
  //   </div>
  // );
}

export default Redslider;

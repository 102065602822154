const First = () => {
    return (
        <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="animations-02">
                <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
            </div>
            <div className="container" style={{padding:'0px 180px 0px 180px'}} >
                <div className="row" >
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <img src='https://admission.mvn.edu.in/wp-content/uploads/2021/09/certification-cover-page.png' style={{ width: '100%'}} />
                        </div>
                </div>
            </div>
        </section>
    )
}
export default First;
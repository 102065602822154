
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../headerone/Main'

function CSEPublication() {
    const location = useLocation()
    console.log('location:::::::::::::', location)
    return (
        <>
            <Header />
            <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-left">
                                <div className="breadcrumb-title">
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb-wrap2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/computer-science-engineering">Computer Science Engineering</Link>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="project-detail">
                <div className="container">
                    <div className="row">
                        {/* <div className="btn-group col-lg-12 col-md-12 col-sm-12">
                            <button className='btn-course active'>Grants</button>
                            <button className='btn-course'>Consultancy</button>
                            <button className='btn-course'>Patents</button>
                            <button className='btn-course'>Publications</button>
                            <button className='btn-course'>Technology Transfers</button>
                            <button className='btn-course'>collaborations</button>
                            <button className='btn-course'>PhD Program</button>
                            <button className='btn-course'>Team</button>

                        </div> */}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className='text-center'>List of Publications: 2017 - 2023</h2>
                            <p><span><b>Faculty Name :</b></span><span>Ms.Babita Yadav </span></p>
                            <p><span><b>Designation  :</b></span><span>Assistant Professor </span></p>
                            <h4>Patent Information :</h4>
                            <p>1.	Patent Granted - SYSTEM FOR SECURE AUTHENTICATION AND INTER-COMMUNICATION AMONG NETWORKED IOT DEVICES Link to  Innovation Patent Details</p>
                            <h5>Research Contributions for 2022 (accepted / published and under publication)</h5>
                            <table id="admission">
                                <tr>
                                    <th>#</th>
                                    <th>Authors</th>
                                    <th>Title</th>
                                    <th>Conference/Book/ Journal</th>
                                    <th>Status</th>
                                </tr>
                                <tr>

                                    <td>1</td>
                                    <td>Sachin Gupta, Babita Yadav</td>
                                    <td>Comparative cost analysis of on-chain and off-chain immutable data storage using blockchain for healthcare data</td>
                                    <td>6th International Conference on ICT for Intelligent Systems (ICTIS – 2022)</td>
                                    <td>[PUBLISHED]
                                        <p><a href='https://link.springer.com/chapter/10.1007/978-981-19-3575-6_74' target='_blank'>https://link.springer.com/chapter/10.1007/978-981-19-3575-6_74</a></p>
                                    </td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Sachin Gupta, Neeraj Garg, Divyanshu Sinha, Babita Yadav, Bhoomi Gupta, Shahajan Miah</td>
                                    <td>The Emerging Role of Implementing Machine learning in Food recommendation for Chronic Kidney Diseases using Correlation analysis</td>
                                    <td>Journal of Food Quality
                                        Special Issue

                                        Artificial Intelligence in Food Quality Improvement

                                        SCIE
                                    </td>
                                    <td>[PUBLISHED]
                                        <p><a href='https://doi.org/10.1155/2022/7176261' target='_blank'>https://doi.org/10.1155/2022/7176261</a></p>
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Neeraj Garg, Divyanshu Sinha, Babita Yadav, Bhoomi Gupta, Sachin Gupta, Shahajan Miah</td>
                                    <td>ML based Texture and Wavelets Features extraction Technique to Predict Gastric Mesothelioma Cancer</td>
                                    <td>Biomed Research International
                                        Special Issue

                                        Computer-Aided Diagnosis of Pleural Mesothelioma: Recent Trends and Future Research Perspectives
                                        SCIE
                                    </td>
                                    <td>[PUBLISHED]
                                        <p><a href='https://doi.org/10.1155/2022/1012684' target='_blank'>https://doi.org/10.1155/2022/1012684</a></p>
                                    </td>

                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Piyush Garg, Sachin Gupta, Babita Yadav and Bhoomi Gupta</td>
                                    <td>Performance Analysis and Optimization of Cross Platform Application Development Using React Native</td>
                                    <td>7th International Conference on Information and Communication Technology for Competitive Strategies (ICTCS-2022)
                                        Chandigarh India
                                    </td>
                                    <td>[Accepted]
                                        Springer Conference
                                    </td>

                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Bhoomi Gupta, Sachin Gupta, Babita Yadav and Divyanshu Sinha</td>
                                    <td>Security Aspects of Blockchain Technology – A Review</td>
                                    <td>7th International Conference on Information and Communication Technology for Competitive Strategies (ICTCS-2022)
                                        Chandigarh India
                                    </td>
                                    <td>[Accepted]
                                        Springer Conference
                                    </td>

                                </tr>
                                <h5 style={{ width: '100%' }}>Book Chapter :</h5>
                                <tr>
                                    <td>1</td>
                                    <td>Sachin Gupta, Babita Yadav and Bhoomi Gupta</td>
                                    <td>Security of IoT based e-Healthcare Applications using Blockchain</td>
                                    <td>Book Chapter -Advances in Blockchain Technology for Cyber Physical Systems</td>
                                    <td>[PUBLISHED]
                                        Advances in Blockchain Technology for Cyber Physical Systems | SpringerLink
                                    </td>

                                </tr>

                            </table>
                            <p>Research Contributions for 2021 (accepted / published and under publication)</p>
                            <table id="admission">
                                <tr>
                                    <th>#</th>
                                    <th>Authors</th>
                                    <th>Title</th>
                                    <th>Conference/Book</th>
                                    <th>Status</th>
                                </tr>
                                <tr>

                                    <td>1</td>
                                    <td>Sachin Gupta and Babita Yadav</td>
                                    <td>Economics of immutability preserving streaming healthcare data storage using aggregation in blockchain technology</td>
                                    <td>Conference SmartCom 2021 - Springer - Jaipur</td>
                                    <td>Published</td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Babita Yadav and Sachin Gupta</td>
                                    <td>Health Care Transformation Traditional to Telemedicine : A Portrayal</td>
                                    <td>Conference ETCCS 2021</td>
                                    <td>[PUBLISHED]
                                        <p><a href='https://link.springer.com/chapter/10.1007/978-981-19-0284-0_1' target='_blank'>https://link.springer.com/chapter/10.1007/978-981-19-0284-0_1</a></p>
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Sachin Gupta and Babita Yadav</td>
                                    <td>Economics of immutability preserving streaming healthcare data storage using aggregation in blockchain technology</td>
                                    <td>Conference SmartCom 2021 - Springer - Jaipur</td>
                                    <td>[PUBLISHED]
                                        <p><a href='https://link.springer.com/book/9789811699689 ' target='_blank'>https://link.springer.com/book/9789811699689 </a></p>
                                    </td>

                                </tr>

                            </table>
                            <h5>Research Contributions for 2018(accepted / published and under publication)</h5>
                            <table id="admission">
                                <tr>
                                    <th>#</th>
                                    <th>Authors</th>
                                    <th>Title</th>
                                    <th>Conference/Book</th>
                                    <th>Status</th>
                                </tr>
                                <tr>

                                    <td>1</td>
                                    <td>Babita Yadav and Madhurima</td>
                                    <td>Software Testing Tools and Techniques in Indian Universities: An analysis using SPSS accepted</td>
                                    <td>CRTES2018 International Conference on Recent Trends in Engineering & Sciences to be held on 10th & 11th February 2018 at Andhra Pradesh.</td>
                                    <td>Published
                                    </td>

                                </tr>
                            </table>
                            <p>Research Contributions for 2017(accepted / published and under publication)</p>
                            <table id="admission">
                                <tr>
                                    <th>#</th>
                                    <th>Authors</th>
                                    <th>Title</th>
                                    <th>Conference/Book</th>
                                    <th>Status</th>
                                </tr>
                                <tr>

                                    <td>1</td>
                                    <td>Madhurima Hooda ,Shashwat Pathak,Dr.Madhulika,Babita Yadav</td>
                                    <td>Pervasive Security of Internet Enabled Host Services  Approach </td>
                                    <td>CCTCEEC 2017 (International Conference on Current Trends in Computer, Electrical, Electronics and Communication) held on Sept 8-9, 2017 at Karnataka.</td>
                                    <td>Published</td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Madhurima Hooda,Dr Madhulika,Babita Yadav,AAshie Roy</td>
                                    <td>A Study and Comparison of Prediction Algorithms for Depression Detection among Millennials: A Machine Learning </td>
                                    <td>CCTCEEC 2017 (International Conference on Current Trends in Computer, Electrical, Electronics and Communication) held on Sept 8-9, 2017 at Karnataka.</td>
                                    <td>Published
                                    </td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Madhurima,DrMadhulika,Babita Yadav</td>
                                    <td>Perceptions of Millennial towards social media privacy issues: A survey</td>
                                    <td>CCTCEEC 2017 (International Conference on Current Trends in Computer, Electrical, Electronics and Communication) held on Sept 8-9, 2017 at Karnataka.</td>
                                    <td>Published
                                    </td>

                                </tr>

                            </table>
                            <div style={{ marginTop: '10vh' }}>
                                <p><span><b>Faculty Name :</b></span><span>Dr Alok Srivastava</span></p>
                                <p><span><b>Designation  :</b></span><span>Assistant Professor </span></p>
                            </div>
                            <p>Research Contributions for 2022 (accepted / published and under publication)</p>
                            <div>
                                <table id="admission">
                                    <tr>
                                        <th>#</th>
                                        <th>Authors</th>
                                        <th>Title</th>
                                        <th>Conference/Book/Journal</th>
                                        <th>Status</th>
                                    </tr>
                                    <tr>

                                        <td>1</td>
                                        <td>Joginder Singh and Alok Srivastava</td>
                                        <td>Comprehensive Survey on Various Technology on Water Quality Monitoring Systems.</td>
                                        <td>International Conference on Computational Intelligence and Smart Communication (ICCISC-2022)</td>
                                        <td>[Accepted ]</td>

                                    </tr>
                                </table>
                            </div>
                            <p>Patent Information :</p>
                            <p>Design patent applied for “AN APPARATUS FOR SECURE AND CONSISTENT PATH SELECTION IN WIRELESS COOPERATIVE COMMUNICATION AND IMPROVED METHOD THEREOF” Application no 202211015718 Date 22/03/2022 Patent Details
                                Germany Utility Patent Application of the invention for “INTELLIGENT SYSTEM FOR DETECTING EDITED IMAGES USING ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING” on Feb 11, 2022, result awaited Patent Details
                            </p>
                            <p>Research Contributions for 2021 (accepted/published and under publication)</p>
                            <p>Patent Information:</p>
                            <p>Design patent applied for “OXYGEN CONCENTRATOR” on july 14th 2021 Published Application no 346278-001 Cbr Number:205244 Patent Details</p>
                            <p>Design patent published for “A NOVEL SIMPLIFIED EQUIVALENT MODELING METHOD FOR MICROSTRIP LINE” Journal no 19/2021 on date 07/05/2021. Patent Details
                                Research Contributions for 2020 (accepted/published and under publication)
                            </p>
                            <p>Patent Information:</p>
                            <p>Innovation Patent granted by the Australian govt. for “AN ARTIFICIAL INTELLIGENCE BASED SYSTEM FOR COVID PREVENTION” w.e.f 23/12/2020 Application no 2020102382 Patent Details</p>
                            <p>Research Contributions for 2019 (accepted/published and under publication)</p>
                            <div>
                                <table id="admission">
                                    <tr>
                                        <th>#</th>
                                        <th>Authors</th>
                                        <th>Title</th>
                                        <th>Conference/Book/ Journal</th>
                                        <th>Status</th>
                                    </tr>
                                    <tr>

                                        <td>1</td>
                                        <td>Alok Srivastava, Aditya Tandon, Prashant Mani</td>
                                        <td>Monitoring your Fleets on the Go with RTDFMS [Real time Distributed Fleet Monitoring System]</td>
                                        <td>IJERT SCOPUS Index, ISSN:2277-3878Volume-7Issue-6S5, January-February (2019) pp 489-501</td>
                                        <td>Published Link </td>

                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Alok Srivastava, Dr. Rajeev Ratan</td>
                                        <td>Flag Com: Energy Efficient Secure Routing Protocol</td>
                                        <td>J.Mech. Cont. & Math. Sci., ISSN 2454 -7190 Vol.-14, No.-1, January-February (2019) pp 489-501  </td>
                                        <td>Published DOI</td>

                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Alok Srivastava and R. Ratan</td>
                                        <td>Study the effect on BIT error rate (BER) for elevated  signal to noise ratio (SNR) in IoT based systems using cooperative communication</td>
                                        <td>International Conference on Robotics, Communication Technology, Electronics, and Electrical Engineering (ICRRCTEEE), Noida, India Oct. 2019 </td>
                                        <td>Published Link </td>

                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Alok Srivastava and R. Ratan</td>
                                        <td>Study & analysis of Energy consumption at nodes in cooperative communication</td>
                                        <td>Innovative Research In Language, Science & Management (IRLSM), Faridabad, India Oct. 2019</td>
                                        <td>Published Link</td>

                                    </tr>
                                </table>
                            </div>
                            <p>Patent Information :</p>
                            <p>Design patent published for “Air Quality Sensing Tag” Journal no 34/2019 on date 23/08/2019. (Application no 318705-001) Patent Details
                                Research Contributions for 2018 (accepted / published and under publication)
                            </p>
                            <div>
                            <table id="admission">
                                    <tr>
                                        <th>#</th>
                                        <th>Authors</th>
                                        <th>Title</th>
                                        <th>Conference/Book/ Journal</th>
                                        <th>Status</th>
                                    </tr>
                                    <tr>

                                        <td>1</td>
                                        <td>Alok Srivastava, Dr. Rajeev Ratan</td>
                                        <td>Development of a Fresh Approach To Use Cooperative Diversity for Efficient & Effective Communication in Modern Wireless Systems</td>
                                        <td>International Journal of Engineering and Technology (UAE) SCOPUS Index E-ISSN: 2227-524X Vol. 7 No 3.24 (2018): 430-433</td>
                                        <td>Published DOI: 10.14419/ijet.v7i3.24.22787</td>

                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Neelima Gupta & Alok srivastava</td>
                                        <td>A Noval Approach for Optimization of Physical Layer for Wireless and Mobile Communication</td>
                                        <td>Volume 05 Issue 04 February 2018 International Journal of Research e-ISSN: 2348-6848</td>
                                        <td>Published  [Link]</td>

                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Neelima Gupta & Alok srivastava</td>
                                        <td>End To End Physical Layer Design for Efficient Data Transmission in Wireless Communication</td>
                                        <td>Volume 05 Issue 07March 2018 International Journal of Research e-ISSN: 2348-6848</td>
                                        <td>Published [Link]</td>

                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Ekta Gupta Alok Srivastava</td>
                                        <td>Survey On Optimization Techniques of Wireless Networks</td>
                                        <td>Volume 05 Issue 04  oct-dec 2018 International Journal of Research and Analytical Reviews E ISSN 2348 –1269  </td>
                                        <td>Published [Link]</td>

                                    </tr>
                                </table>

                            </div>
                            <div style={{ marginTop: '10vh' }}>
                                <p><span><b>Faculty Name :</b></span><span>Dr. Rajeev Rattan </span></p>
                                <p><span><b>Designation  :</b></span><span>Professor </span></p>
                            </div>
                            <p>Research Contributions for 2021 (accepted / published) </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CSEPublication;
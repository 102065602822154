import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation } from "../../../utils/utils";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETMEHeader from "./header";
const MEBTechLab=()=>{
    const Lab=[
{listName:'Engineering Mechanics Lab'},
{listName:'Material Science and Testing Lab'},
{listName:'Heat and Mass Transfer Lab'},
{listName:'Refrigeration and Air Conditioning Lab'},
{listName:'Measurement & Metrology Lab'},
{listName:'Automobile Lab'},
{listName:'Strength of Material Lab'},
{listName:'Mechanical Workshop and Machine Lab'},
{listName:'Fluid Mechanics & Machinery Lab'},
{listName:'Advance Software Lab'},
{listName:'Theory of Machines Lab'},
{listName:'Robotics and FMS Lab'},
{listName:'Mechatronics Lab'},
{listName:'Manufacturing Science Lab etc.'},


 ]
return (
<Grid container spacing={2}>
{/* <SOETMEHeader/>
        <BreadcrumbsBanner bannerUrl={"https://uploads.sarvgyan.com/2014/06/mechanical-engineering-image.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Mechanical Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="ME Laboratories" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Mechanical Engineering Department is proud to present its fully-equipped state of arts laboratories. This collection is designed to cater to a broad spectrum of educational and research needs within our department. 
    <br/><br/>
Some of the prominent labs in the department are:
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={Lab}/>
    </Grid>


    </Grid>

)
}
export default MEBTechLab;
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PharmaActivityVideos() {

    const Data = [
        
        {video : "https://www.youtube.com/embed/zuvtzKXS4Vo?si=j03looW-qmZA3e5E", category : "Financial"},
        {video : "https://www.youtube.com/embed/1cp0VZ-pSFA?si=qLzZ8h_ALzCLilkI", category : "Financial"},
        {video : "https://www.youtube.com/embed/Tkp-3X7VKdc?si=yjYdfBUsZtejftK9", category : "Financial"},
        {video : "https://www.youtube.com/embed/3Y0M0QRrJUM?si=_bpSIW9LcOFBn4Ri", category : "Financial"},
        {video : "https://www.youtube.com/embed/mG1NmJqsjdo?si=_74kmj5VC8wv59Qe", category : "Financial"},
        {video : "https://www.youtube.com/embed/_JdMOcx7oW8?si=uuReKgGWNBZ92m8y", category : "Financial"},
        
      ]

      const [current, setcurrent] = useState(Data);


    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Pharmaceutical Sciences" subtitle="Activity Videos" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className='row'>
                                    {current.map( (item, Index)=> ( 
                                        <div className="col-md-4 col-sm-12" style={{padding:'15px'}} key={Index}>
                                                <iframe className='student-testimonial' src={item.video} title="MVN students selected AON-Hewit Testimonial" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                        </div>
                                    ))}  
                                </div>                                                     
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PharmaActivityVideos
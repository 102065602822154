import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function LawLegalActivities() {

    const Data = [        
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/adhikaron-ka-mela-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/adhikaron-ka-mela-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/adhikaron-ka-mela-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/adhikaron-ka-mela-4.jpg", category : "Financial"},
        
      ]

      const [current, setcurrent] = useState(Data);

    const aids = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/moot-court-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/moot-court-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/moot-court-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/moot-court-4.jpg", category : "Financial"},
        
    ];
    
    const vita = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/lsa-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/lsa-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/lsa-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/lsa-4.jpg", category : "Financial"},
       
    ];

    


    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Law" subtitle="Legal Activities" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>ADHIKARON KA MELA</h3>
                                </div>
                                <p>
                                MVN University, School of Law has recently organised ADHIKARON KA MELA . It has been organised with the collaboration of DALSA, to resolve the problems of general public and awaken them about their rights. In ADHIKARON KA MELA all the departments of the Haryana government came up with their stalls to provide remedies to the general public. The stalls included license authority, ration card, civil treasury, Aadhar card, marriage registrar, passport authority, etc.
                                </p>

                                <p>
                                    Hon’ble Justice Mr. Ajay Kumar Mittal, Punjab and Haryana High Court, was the chief guest of the day. Hon’ble Justice Avanishjhingan, Punjab and Haryana High Court, District & Sessions Judge and Member Secretary, HLSA Mr. Puneesh Jindia, District & Sessions Judge and Chairman, DLSA Palwal Mr. Ashok Kumar VermaAddl. District & Sessions Judge, Palwal Mr.Anubhav Sharma Chief Judicial Magistrate, Palwal Mr. Sanjay Sharma Chief Judicial Magistrate & Joint Secretary, HLSA Mr. Sunil Kumar DiwanChief Judicial Magistrate & Secretary, DLSA Palwal Dr. Kavita Kamboj Chief Judicial Magistrate & Secretary, DLSA Hisar Mr. Surender Singh Chief Judicial Magistrate & Secretary, DLSA Mewat Ms. Neeru Kanboj Chief Judicial Magistrate & Secretary, DLSA Rewari Ms. Kirti Jain Chief Judicial Magistrate & Secretary, DLSA Faridabad Ms. Mona Singh Chief Judicial Magistrate & Secretary, DLSA Charkhi Dadri Mr. Sunil Kumar were the other guests present in the event. It proved to be a successful event with a gathering of around 5000 people who came from the nearby places.
                                </p>


                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {current.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>MOOT COURT</h3>
                                </div>

                                <p>
                                Moot Court Competition is a mock drill practice for the budding lawyers which inculcate practical knowledge about the court proceedings to the students. MVN University SOL organises Inter Moot Court Competitions in every semester on the regular basis. All the participants and winning teams are validated with the trophies, cash prizes and certificates.
                                </p>
                               
                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {aids.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>WORKSHOP ON LEGAL RIGHTS OF WOMEN AND CHILDREN</h3>
                                </div>

                                <p>                                   
                                MVN University, School of Law, has organised a workshop under the aegis of District Legal Services Authority, Palwal. The esteemed speaker of the workshop was Mrs. Kavita Kamboj, CJM, Palwal. She was accompanied by two other practising senior advocates. Mrs. Kamboj delivered a speech on legal rights of women and children. Her speech also spread awareness about the DLSA and HLSA, Haryana, being the state legal service authority, which provides legal remedies to all sections of society. The students and faculty members have put forward their questions and queries on powers and functioning of these State Legal Service Authorities.
                                </p>
                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {vita.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LawLegalActivities
import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function DMLT() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="Diploma in Medical Laboratory Technology (DMLT)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>What is DMLT</h3>
                                </div>
                                <p>
                                Diploma in Medical Laboratory Technology (DMLT) is a two-year diploma program. This is meticulously designed for students to gain knowledge and technical skills about various subjects like pathology, biochemistry, microbiology, and haematology. The course also trains them to handle advanced lab equipment, perform accurate medical laboratory tests, and eventually work as Laboratory Technicians.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Why Pursue DMLT</h3>
                                </div>
                                <p>
                                Among the many emerging medical courses, students find it difficult as to which course to pursue. Here are a few reasons to pursue Diploma in Medical Laboratory Technology.
                                </p>
                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            Laboratory Technicians and technicians play a crucial role in the healthcare system, by diagnosing and treating diseases.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        DMLT is one of the emerging courses and is being preferred by many recruiters in India and abroad.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        With the rise of diseases and the onset of COVID-19, students who have completed courses like DMLT have multiple opportunities in the medical industry (both private and government sectors).
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Students who find it difficult to compete in medical courses like MBBS or BHMS, can do DMLT and pursue their career in the medical field.
                                        </div>    
                                    </li>
                                </ul>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Eligibility Criteria</h3>
                                </div>
                                
                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Applicant should have completed 12th class from a recognized board (Some colleges also give admission after completion of 10th class with a minimum of 50% in boards as the criteria)
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        45% marks are required for applicants from SC/ST categories
                                        </div>    
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DMLT
import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import BredcomSlider from '../Bredcom/MainSlider'
import NewMain from '../headerone/newMain'
function QrPayment() { 
  return (
    <>
      <NewMain /> 
       
      <section>
          <div className='container'>
              <div className='mvn_imse qr_code'> 
                    <h6>Please scan this QR Code and Make Fees Payment</h6>
                  <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/qr_code_s.jpg`}  alt="img" className="vc-img" />
              </div>
   
              
          </div>
      </section>




    </>
  )
}

export default QrPayment
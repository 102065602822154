import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {
    return (
        <>
            <section id="work" className="pt-150 pb-105">
                <div className="container">
                    <div className="portfolio ">
                        <div className="row  fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                        <div className="elementor-widget-wrap elementor-element-populated">
								<div className="elementor-element elementor-element-9d7d089 elementor-widget elementor-widget-text-editor" data-id="9d7d089" data-element_type="widget" data-widget_type="text-editor.default">
				<div className="elementor-widget-container">
			<ul><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2021/09/guidelines-for-preparation-of-research-proposal.pdf" target="_blank" rel="noopener">Guidelines for preparation of Research Proposal</a></li><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2021/09/guidelines-for-preparation-of-synopsis-pre-thesis-submission.pdf" target="_blank" rel="noopener">Guidelines for preparation of synopsis-Pre thesis submission</a></li><li><span className='circle-dot'/><a href="https://admission.mvn.edu.in/wp-content/uploads/2023/01/Guidelines-for-Thesis-Submission.pdf" target="_blank" rel="noopener">Guidelines for Thesis Submission</a></li></ul>						</div>
				</div>
					</div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
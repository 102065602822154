
const First = () => {
    return (
        <div className="container">
            <div className="row bank-details">
                <div className="col">
                <img src='/assets/img/bank_detail/bank.jpeg'/>
                </div>
                {/* <table id="admission">
                    <tr>
                        <td class="column-1" colSpan={2}>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-sm-12 col-md-5 col-lg-5">
                                        <img src='/assets/img/logo/mvn-university-logo.png' />
                                    </div>
                                    <div className="col-sm-12 col-md-7 col-lg-7"><h2>Bank Details for Fee Payment</h2></div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td style={{width:'200px',textAlign:'center'}}><h5>Payment Mode</h5></td>
                        <td><strong style={{marginLeft:'10px'}}>Internet Banking (IMPS/RTGS/NEFT)</strong></td>
                    </tr>
                    <tr>
                        <td style={{width:'200px',textAlign:'center'}}><h5>Beneficiary Name</h5></td>
                        <td><strong style={{marginLeft:'10px'}}>MVN UNIVERSITY</strong></td>
                    </tr>
                    <tr>
                        <td style={{width:'200px',textAlign:'center'}}><h5>Saving A/C No.</h5></td>
                        <td><strong style={{marginLeft:'10px'}}>0167000101273059</strong></td>
                    </tr>
                    <tr>
                        <td style={{width:'200px',textAlign:'center'}}><h5>IFSC Code</h5></td>
                        <td><strong style={{marginLeft:'10px'}}>PUNB0016700</strong></td>
                    </tr>
                    <tr>
                        <td style={{width:'200px',textAlign:'center'}}><h5>Branch Address</h5></td>
                        <td><strong style={{marginLeft:'10px'}}>Punjab National Bank (PNB), N.I.T, Faridabad</strong></td>
                    </tr>

                </table> */}
            </div>
        </div>
    )
}
export default First;
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography,Divider } from '@mui/material';
import NewMain from '../headerone/newMain';
function SOETHeader() {

    const [Btnshow, setBtnshow] = useState(false);
    const [mobile, setmobile] = useState(false);
    const [Events, setEvent] = useState(false);
    const [News, setNews] = useState(false);
    const [Services, setServices] = useState(false);
    const [Blog, setblog] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerMenus = [
        { mainHeader: 'Home ', path: '/mui-school-of-allied-health-sciences', subHeader: [] },
        { mainHeader: 'SOET Insights', path: '', subHeader: [
            { title: 'Insights', path: '/soet/insights', },
            { title: 'Vision & Mission', path: '/soet/vision-mission', },
            { title: 'Knowledge Partners', path: '/soet/knowledge-partners', },
            { title: 'Pedagogical Advances – MOOCs', path: '/soet/mcoos', },
        ] },
        { mainHeader: 'SOET Departments', subHeader: [
            { title: 'Computer Science & Engineering', path: '/soet-cse', },
            { title: 'Mechanical Engineering', path: '/soet-me' },
            { title: 'Civil Engineering', path: '/soet-civil' },
            { title: 'Electronic & Communication Engineering', path: '#' },
        ] },
        { mainHeader: 'MVN Times', path: '#', subHeader: [] },
        { mainHeader: 'Scholarly Work ', path: '#', subHeader: [] },
    ]

    return (
        <>
        <NewMain/>
            <header className="header-area update-header-three" >
            <Divider orientation="vertical" variant="middle" flexItem />
                <div id="header-sticky" className={`menu-area-update menu-area sticky-menu`} style={{marginTop:isScrolled?'83px':'70px'}}>
                    <div className="container">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-2 col-lg-2">
                                </div>
                                <div className="col-xl-8 col-lg-8">
                                    <div className="main-menu text-center" style={{marginRight:'auto'}}>
                                        {/* <nav id='mobile-menu'> */}
                                            <ul >
                                                {headerMenus?.map((menus) => {
                                                    return (
                                                        <li className="has-sub" >
                                                            <Link to={menus?.path}><Typography fontSize="15px" fontWeight="600">{menus?.mainHeader} </Typography>
                                                                {/* <i className={menus?.subHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                            </Link>
                                                            <ul style={{ display: menus?.subHeader?.length == 0 && 'none' }}>
                                                                {menus?.subHeader.map((subhead) => {
                                                                    return (
                                                                        <li className="has-sub">
                                                                            <Link to={subhead?.path}><Typography fontSize="14px" fontWeight="600">{subhead?.title}</Typography>  
                                                                                {/* <i className={subhead?.lastHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                            </Link>
                                                                            <ul style={{ display: !subhead?.lastHeader && 'none' }}>
                                                                                {subhead?.lastHeader?.map((lasthead) => {
                                                                                    return (
                                                                                        <li className="has-sub" >
                                                                                            <Link to={lasthead?.path}><Typography fontSize="14px" fontWeight="600">{lasthead?.title}  </Typography>
                                                                                                {/* <i className={lasthead?.childHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                            </Link>
                                                                                            <ul style={{ display: !lasthead?.childHeader && 'none',overflow: 'auto' }}>
                                                                                                {lasthead?.childHeader?.map((childhead) => {
                                                                                                    return (
                                                                                                        <li>
                                                                                                            <Link to={childhead?.path}><Typography fontSize="14px" fontWeight="600">{childhead?.title}</Typography></Link>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        {/* </nav> */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar" >
                                            <a href="#nav" onClick={() => setmobile(!mobile)} className={`meanmenu-reveal ${mobile && "meanclose"}`} style={{ right: 0, left: "auto", textAlign: "center", textIndent: 0, fontSize: 18 }} >
                                                {mobile ? "X" : <span><span><span></span></span></span>}
                                            </a>
                                            {mobile &&
                                                <nav className="mean-nav" style={{height: '600px',overflow: 'auto'}}>
                                                    <ul >
                                                        {headerMenus?.map((menus) => {
                                                            return (
                                                                <li className="has-sub">
                                                                    <Link to={menus?.path}>{menus?.mainHeader} 
                                                                        {/* <i className={menus?.subHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                      </Link>
                                                                    <ul style={{ display: menus?.subHeader?.length == 0 && 'none' }}>
                                                                        {/* {menus?.subHeader.map((subhead) => {
                                                                            return (
                                                                                <li>
                                                                                    <Link to={childhead?.path}>{subhead?.title}</Link>
                                                                                </li>
                                                                            )
                                                                        })} */}
                                                                        {menus?.subHeader.map((subhead) => {
                                                                            return (
                                                                                <li className="has-sub" >
                                                                                    <Link to={subhead?.path}>{subhead?.title}  
                                                                                        {/* <i className={subhead?.lastHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                    </Link>
                                                                                    <ul style={{ display: !subhead?.lastHeader && 'none' }}>
                                                                                        {subhead?.lastHeader?.map((lasthead) => {
                                                                                            return (
                                                                                                <li className="has-sub">
                                                                                                    <Link to={lasthead?.path}>{lasthead?.title} 
                                                                                                        {/* <i className={lasthead?.childHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                                    </Link>
                                                                                                    <ul style={{ display: !lasthead?.childHeader && 'none' }}>
                                                                                                        {lasthead?.childHeader?.map((childhead) => {
                                                                                                            return (
                                                                                                                <li>
                                                                                                                    <Link to={childhead?.path}>{childhead?.title}</Link>
                                                                                                                </li>
                                                                                                            )
                                                                                                        })}
                                                                                                    </ul>
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {Btnshow &&
                <>
                    <div className={`offcanvas-menu ${"active"}`}>
                        <span className="menu-close" onClick={() => setBtnshow(false)}>
                            <i className="fas fa-times" onClick={() => setBtnshow(false)} />
                        </span>
                        <form role="search" method="get" id="searchform" className="searchform" action="#" >
                            <input type="text" name="s" id="search" placeholder="Search" />
                            <button>
                                <i className="fa fa-search" />
                            </button>
                        </form>
                    </div>
                    <div className={`offcanvas-overly ${"active"}`}> </div>
                </>
            }
        </>
    )
}

export default SOETHeader;
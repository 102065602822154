import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {
    return (
        <>
            <section id="work" className="pt-150 pb-105">
                <div className="container">
                    <div className="portfolio ">
                        <div className="row  fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-ada8c66 elementor-widget elementor-widget-shortcode" data-id="ada8c66" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-shortcode">
                                            <div id="tablepress-1_wrapper" className="dataTables_wrapper no-footer"><div className="dataTables_scrollBody" style={{ position: 'relative', overflow: 'auto', width: "100%" }}><table id="tablepress-1" className="tablepress tablepress-id-1 dataTable no-footer" style={{ width: '100%' }}><thead style={{background:'#d9edf7',padding:'15px'}}>
                                                <tr className="row-1 odd" >
                                                    <th style={{width:'3%'}}>Sr. No.</th>
                                                    <th style={{width:'10%'}}>Reg No.</th>
                                                    <th style={{width:'15%'}}>Student Name</th>
                                                    <th style={{width:'23%'}}>Father Name</th>
                                                    <th style={{width:'49%'}}>Thesis Title</th>
                                                </tr>
                                            </thead>

                                                <tbody className="row-hover" style={{ width: '100%',padding:'15px' }}>
                                                    <tr className="row-2 even">
                                                        <td className="column-1">1</td><td className="column-2">12CS9001</td><td className="column-3">Mr. Ankit Verma</td><td className="column-4">RAJESH KUMAR VERMA</td><td className="column-5">Performance Improvement by Optimum Path Routing in Wireless Networks</td><td className="column-6"></td>
                                                    </tr><tr className="row-3 odd">
                                                        <td className="column-1">2</td><td className="column-2">13MS9002</td><td className="column-3">Ms. Ashu</td><td className="column-4">ISHWAR DASS BHUTANI</td><td className="column-5">Assessment of Retail Service Quality and Challenges faced by Retail Outlets - ( A Descriptive Study of Delhi/NCR )</td><td className="column-6"></td>
                                                    </tr><tr className="row-4 even">
                                                        <td className="column-1">3</td><td className="column-2">13EC9001</td><td className="column-3">Ms. Manju Kaushik</td><td className="column-4">Sh. Toshraj Kaushik</td><td className="column-5">"Less Drop More Crop" by Auto Irrigation System Using<br />
                                                            Microcontroller and Soil Moisture Sensor</td><td className="column-6"></td>
                                                    </tr><tr className="row-5 odd">
                                                        <td className="column-1">4</td><td className="column-2">15BS/MT/9001</td><td className="column-3">Mr. Ashok Kumar</td><td className="column-4">Sh. Sukhpal Singh</td><td className="column-5">Study of Free and Mixed Convection Heat and Mass Flow in<br />
                                                            Real Fluids through Porous Medium</td><td className="column-6"></td>
                                                    </tr><tr className="row-6 even">
                                                        <td className="column-1">5</td><td className="column-2">14EC9002</td><td className="column-3">Mr. Devvart</td><td className="column-4">Sh. Vir Singh</td><td className="column-5">Design &amp; Development of Reliable &amp; Automated <br />
                                                            Hydroponics System</td><td className="column-6"></td>
                                                    </tr><tr className="row-7 odd">
                                                        <td className="column-1">6</td><td className="column-2">15PS9003</td><td className="column-3">Mr. Tarun Virmani</td><td className="column-4">Sh. Ashok Vimani</td><td className="column-5">In Vitro and In Vivo Evaluation Studies for Some <br />
                                                            Antifungal Agents as Microspheres</td><td className="column-6"></td>
                                                    </tr><tr className="row-8 even">
                                                        <td className="column-1">7</td><td className="column-2">12CA9001</td><td className="column-3">Ms. Kavita Goel</td><td className="column-4">Sh. L.R. Goel</td><td className="column-5">Performance Optimization of Web Crawler</td><td className="column-6"></td>
                                                    </tr><tr className="row-9 odd">
                                                        <td className="column-1">8</td><td className="column-2">13CA9002</td><td className="column-3">Mr. Vinod Jain</td><td className="column-4">Sh. Virendra Kumar Jain</td><td className="column-5">Solving NP- Complete Problems using Modified Genetic <br />
                                                            Algorithm Operators</td><td className="column-6"></td>
                                                    </tr><tr className="row-10 even">
                                                        <td className="column-1">9</td><td className="column-2">15SL9001</td><td className="column-3">Mr. Ramveer</td><td className="column-4">Sh. Hari Singh</td><td className="column-5">The Law of Bail in India: An Analysis of Legislative and <br />
                                                            Judicial Perspective</td><td className="column-6"></td>
                                                    </tr><tr className="row-11 odd">
                                                        <td className="column-1">10</td><td className="column-2">12MS9002</td><td className="column-3">Ms. Krity Gulati Mehta</td><td className="column-4">Sh. Mohinder Pal Gulati</td><td className="column-5">Shoppers’ Perception, Satisfaction &amp; Loyalty Towards Online Buying -A Study with Reference to National Capital Region</td><td className="column-6"></td>
                                                    </tr><tr className="row-12 even">
                                                        <td className="column-1">11</td><td className="column-2">12MS9003</td><td className="column-3">Mr. Neelkamal</td><td className="column-4">Sh. K.K. Sharma</td><td className="column-5">A Study on the Espousal of Electronic Vehicles in Faridabad: Gauging the Consumer Attitude–Action Gap</td><td className="column-6"></td>
                                                    </tr><tr className="row-13 odd">
                                                        <td className="column-1">12</td><td className="column-2">12MS9001</td><td className="column-3">Ms. Dhrity Gulati Ahuja</td><td className="column-4">Sh. M. P. GULATI</td><td className="column-5">"A Study on the Job Satisfaction among Academicians in Organised Management Institutes Specifically based on Delhi (NCR) Region"</td><td className="column-6"></td>
                                                    </tr><tr className="row-14 even">
                                                        <td className="column-1">13</td><td className="column-2">12CS9002</td><td className="column-3">Mr. Manpreet Singh</td><td className="column-4">Sh. Darshan Singh</td><td className="column-5">"Novel Hidden Web Data Extraction Tool Design and Comparative Study"</td><td className="column-6"></td>
                                                    </tr><tr className="row-15 odd">
                                                        <td className="column-1">14</td><td className="column-2">14CS9001</td><td className="column-3">Ms. Sneh Kumari</td><td className="column-4">Sh. Bal Krishan</td><td className="column-5">"Evaluating the Performance of Data Mining Algorithms for Analysis of Big Data"</td><td className="column-6"></td>
                                                    </tr><tr className="row-16 even">
                                                        <td className="column-1">15</td><td className="column-2">14CS9002</td><td className="column-3">Ms. Vrinda</td><td className="column-4">Sh. Jagdish Chand</td><td className="column-5">"Study of Attack Taxonomy and Novel Security Framework in NOSQL Database"</td><td className="column-6"></td>
                                                    </tr><tr className="row-17 odd">
                                                        <td className="column-1">16</td><td className="column-2">14EC9003</td><td className="column-3">Mr. Yogesh Kumar</td><td className="column-4">Sh. V. S. Chauhan</td><td className="column-5">"Green House Automation with Fertigation Optimization"</td><td className="column-6"></td>
                                                    </tr><tr className="row-18 even">
                                                        <td className="column-1">17</td><td className="column-2">16EC9001</td><td className="column-3">Mr. Alok Srivastava</td><td className="column-4">Sh. Sharvan Kumar Srivastava</td><td className="column-5">"Design &amp; Analysis of Efficient Co-Operative Communication System"</td><td className="column-6"></td>
                                                    </tr><tr className="row-19 odd">
                                                        <td className="column-1">18</td><td className="column-2">12CA9002</td><td className="column-3">MALA YADAV</td><td className="column-4">SH. RAM SINGH YADAV</td><td className="column-5">GENETIC LEARNING AND PARTICLE SWARM OPTIMIZED LOAD BALANCING FOR VIRTUAL MACHINES IN DATA CENTER</td><td className="column-6"></td>
                                                    </tr><tr className="row-20 even">
                                                        <td className="column-1">19</td><td className="column-2">12CS9003</td><td className="column-3">RITU SACHDEVA<br />
                                                        </td><td className="column-4">SH. SUBHASH CHANDER</td><td className="column-5">NOVEL FOCUSED WEB CRAWLER FOR ANTI SPAMMING APPROACH AND FAST QUERY RETRIEVAL</td><td className="column-6"></td>
                                                    </tr><tr className="row-21 odd">
                                                        <td className="column-1">20</td><td className="column-2">15EC9001</td><td className="column-3">AMIT KUMAR</td><td className="column-4">SH. JAGMAL SINGH</td><td className="column-5">SEED QUALITY ASSESSMENT BASED ON INFERENTIAL SENSING TECHNIQUES</td><td className="column-6"></td>
                                                    </tr><tr className="row-22 even">
                                                        <td className="column-1">21</td><td className="column-2">16PS9001</td><td className="column-3">MOHIT SANDUJA</td><td className="column-4">SH. OM PARKASH SANDUJA</td><td className="column-5">DESIGN, SYNTHESIS, CHARACTERISATION AND BIOLOGICAL EVALUATION OF NOVEL URACIL-COUMARIN HYBRIDS AS CYTOTOXIC AGENTS</td><td className="column-6"></td>
                                                    </tr><tr className="row-23 odd">
                                                        <td className="column-1">22</td><td className="column-2">16PS9003</td><td className="column-3">POOJA SHARMA</td><td className="column-4">SH. BANWARI LAL SHARMA</td><td className="column-5">DESIGN, SYNTHESIS AND BIOLOGICAL EVALUATION OF NOVEL IMIDAZOLE DERIVATIVES</td><td className="column-6"></td>
                                                    </tr><tr className="row-24 even">
                                                        <td className="column-1">23</td><td className="column-2">16PS9002</td><td className="column-3">SANGEETA SINGH<br />
                                                        </td><td className="column-4">SH. NARENDRA SINGH</td><td className="column-5">DEVELOPMENT OF NANO- PARTICULATE <br />
                                                            FORMULATION OF RASPBERRY KETONES AND STUDY OF ITS THERAPEUTIC EFFECTS</td><td className="column-6"></td>
                                                    </tr><tr className="row-25 odd">
                                                        <td className="column-1">24</td><td className="column-2">17MS9002</td><td className="column-3">MADHU KHANNA</td><td className="column-4">SH. VIDYA PARKASH</td><td className="column-5">CONSUMER BUYING BEHAVIOR TOWARDS HOUSEHOLD DURABLE ITEMS WITH SPECIAL REFRENCE TO DELHI/NCR</td><td className="column-6"></td>
                                                    </tr><tr className="row-26 even">
                                                        <td className="column-1">25</td><td className="column-2">17MS9001</td><td className="column-3">KAMAL GUPTA<br />
                                                        </td><td className="column-4">SH. SURESH CHANDER GUPTA</td><td className="column-5">RISK RESILIENT SUPPLY CHAIN MANAGEMENT <br />
                                                            USING IoT AND BIG DATA ANALYTICS<br />
                                                        </td><td className="column-6"></td>
                                                    </tr><tr className="row-27 odd">
                                                        <td className="column-1">26</td><td className="column-2">17CS9001</td><td className="column-3">VINAY KUMAR SAINI<br />
                                                            <br />
                                                        </td><td className="column-4">SH. RAMESH CHAND SAINI</td><td className="column-5">TRUST – BASED BLOCKCHAIN RECORDS FOR <br />
                                                            IMPROVING SUPPY CHAIN PROCESS<br />
                                                        </td><td className="column-6"></td>
                                                    </tr><tr className="row-28 even">
                                                        <td className="column-1">27</td><td className="column-2">18PS9004</td><td className="column-3">VIKAS JOGPAL</td><td className="column-4">SH. SHRIRAM JOGPAL</td><td className="column-5">EVALUATION OF ANTIEPILEPTIC ACTIVITY OF SOME INDIAN MEDICINAL PLANTS</td><td className="column-6"></td>
                                                    </tr><tr className="row-29 odd">
                                                        <td className="column-1">28</td><td className="column-2">17EC9003/W</td><td className="column-3">PREETI ARORA</td><td className="column-4">RATTAN LAL ARORA</td><td className="column-5">Designing of a Supervised Framework for Detection of Malignancy Using Feature Extraction</td><td className="column-6"></td>
                                                    </tr><tr className="row-30 even">
                                                        <td className="column-1">29</td><td className="column-2">17SL9005</td><td className="column-3">NITA</td><td className="column-4">GAURI SHANKAR RAI</td><td className="column-5">Corruption and Good Governance in India: A Critical Study</td><td className="column-6"></td>
                                                    </tr><tr className="row-31 odd">
                                                        <td className="column-1">30</td><td className="column-2">17EC9001</td><td className="column-3">HARENDRA SINGH</td><td className="column-4">KANHAIYA LAL</td><td className="column-5">Design &amp; Implementation of Image Processing Techniques for Diagnosing Benign Tumor Using Knowledge Based Tools</td><td className="column-6"></td>
                                                    </tr><tr className="row-32 even">
                                                        <td className="column-1">31</td><td className="column-2">16PS9004/W</td><td className="column-3">MADHURI GROVER</td><td className="column-4">Y L GROVER</td><td className="column-5">Exploration of Synergistic Inhibition of Cancer Cell Proliferation by Isolated Compounds  of Chrysopogon Zizanioides &amp; Curcuma Longa on Different Cancer Cell Lines</td><td className="column-6"></td>
                                                    </tr><tr className="row-33 odd">
                                                        <td className="column-1">32</td><td className="column-2">18CS9001/W</td><td className="column-3">PRIYA </td><td className="column-4">P D GUPTA</td><td className="column-5">An Exploratory Data Analysis Perspective of Hate Speech in Political Context</td><td className="column-6"></td>
                                                    </tr><tr className="row-34 even">
                                                        <td className="column-1">33</td><td className="column-2">18PS9003</td><td className="column-3">MOHIT KOTNALA</td><td className="column-4">S P KOTNALA</td><td className="column-5">Investigation of Targeted Anti-Inflammatory and Hepatoprotective Potential of Novel Standardized Enriched Extracts </td><td className="column-6"></td>
                                                    </tr><tr className="row-35 odd">
                                                        <td className="column-1">34</td><td className="column-2">19EC9002</td><td className="column-3">SANDEEP KUMAR</td><td className="column-4">RANGRAO SINGH</td><td className="column-5">Study the Impact of Artificial Intelligence and Machine Learning on Agriculture using iOS Mobile App </td><td className="column-6"></td>
                                                    </tr><tr className="row-36 even">
                                                        <td className="column-1">35</td><td className="column-2">17EC9002</td><td className="column-3">PRIYANKA KAUSHIK</td><td className="column-4">BRAHM PRAKASH KAUSHIK</td><td className="column-5">Design &amp; Implementation of an Efficient Low Contrast Images Segmentation Novel Technique for Tumor Detection</td><td className="column-6"></td>
                                                    </tr><tr className="row-37 odd">
                                                        <td className="column-1">36</td><td className="column-2">17MS9007</td><td className="column-3">SARASWATI</td><td className="column-4">DEVI SINGH</td><td className="column-5">The Impact of E-Banking on Customer Satisfaction: A Comparative Analysis of Public Sector Banks and Private Sector Banks</td><td className="column-6"></td>
                                                    </tr><tr className="row-38 even">
                                                        <td className="column-1">37</td><td className="column-2">17MS9006</td><td className="column-3">RUBY MITTAL</td><td className="column-4">RAKESH KUMAR MITTAL</td><td className="column-5">Working Capital Management of Some Selected Companies in Software Industry in India: A Study</td><td className="column-6"></td>
                                                    </tr><tr className="row-39 odd">
                                                        <td className="column-1">38</td><td className="column-2">18PS9002</td><td className="column-3">SANJAY KUMAR YADAV</td><td className="column-4">GAGAN DEV YADAV</td><td className="column-5">Protective Effect of Hydroethanolic Extract of Clematis Buchananiana Leaf on Diabetic Induced Neuropathy</td><td className="column-6"></td>
                                                    </tr><tr className="row-40 even">
                                                        <td className="column-1">39</td><td className="column-2">19PS9002</td><td className="column-3">GIRISH KUMAR</td><td className="column-4">TEJ PAL</td><td className="column-5">Design, Development and Optimization of Nanoemulsion Formulation of Antihypertensive Drug</td><td className="column-6"></td>
                                                    </tr><tr className="row-41 odd">
                                                        <td className="column-1">40</td><td className="column-2">19PS9001</td><td className="column-3">GEETA MAHLAWAT</td><td className="column-4">YUVRAJ MAHLAWAT</td><td className="column-5">In Vitro and In Vivo Evaluation Release of Nanosuspension Containing Antilipidemic Drugs</td><td className="column-6"></td>
                                                    </tr><tr className="row-42 even">
                                                        <td className="column-1">41</td><td className="column-2">19PS9003</td><td className="column-3">KM MEENU</td><td className="column-4">MOMRAJ SINGH</td><td className="column-5">Evaluation of the Neuroprotective Effect of Drug alone and in Combination on Bilateral Common Carotid Artery Occlusion Induced Vascular Dementia in Rats</td><td className="column-6"></td>
                                                    </tr><tr className="row-43 odd">
                                                        <td className="column-1">42</td><td className="column-2">16SL9002</td><td className="column-3">NARESH KUMAR</td><td className="column-4">DEVI CHAND</td><td className="column-5">Legalising Freedom of Press: A Critical Review</td><td className="column-6"></td>
                                                    </tr><tr className="row-44 even">
                                                        <td className="column-1">43</td><td className="column-2">16SL9001</td><td className="column-3">DIPU SINGH</td><td className="column-4">DIWAN SINGH</td><td className="column-5">Right to Life with Respect to Human Dignity: A Comparative Study</td><td className="column-6"></td>
                                                    </tr><tr className="row-45 odd">
                                                        <td className="column-1">44</td><td className="column-2">18EC9001/W</td><td className="column-3">VINOD KUMAR</td><td className="column-4">DALIP SINGH</td><td className="column-5">Design And Implementation of Efficient Grid Connected Solar Photovoltaic System</td><td className="column-6"></td>
                                                    </tr><tr className="row-46 even">
                                                        <td className="column-1">45</td><td className="column-2">19EC9001</td><td className="column-3">PRINCE HOODA</td><td className="column-4">SUNEHARI HOODA</td><td className="column-5">Impact of PV Penetration on Grid And Power Quality Analysis </td><td className="column-6"></td>
                                                    </tr><tr className="row-47 odd">
                                                        <td className="column-1">46</td><td className="column-2">19MS9001</td><td className="column-3">J. ERNEST SAMUEL RATNAKUMAR</td><td className="column-4">G. JOSEPH</td><td className="column-5">Quality Related Issues in Higher Education: A Case Study on the Role of Private Universities in Rajasthan</td><td className="column-6"></td>
                                                    </tr><tr className="row-48 even">
                                                        <td className="column-1">47</td><td className="column-2">19MS9002/W</td><td className="column-3">ROHIT RASTOGI</td><td className="column-4">RAMA NAND RASTOGI</td><td className="column-5">A Critical Analysis: Quality of Higher  Education in the State of Uttarakhand (Private Universities)</td><td className="column-6"></td>
                                                    </tr><tr className="row-49 odd">
                                                        <td className="column-1">48</td><td className="column-2">19BS/PH/9001</td><td className="column-3">NEERAJ VERMA</td><td className="column-4">JAI NARYAN VERMA</td><td className="column-5">Modeling And Simulation of Photovoltaic (PV) Array And Maximum Power Point Tracker For a PV System</td><td className="column-6"></td>
                                                    </tr></tbody>
                                            </table></div></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             
            </section>
        </>
    )
}

export default First
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const BPT=()=>{
    const progHiglight=[
{listName:'Four advanced laboratories with full equipment.'},
{listName:'Curriculum aligning with HCPC standards and offering industry-relevant elective courses.'},
{listName:'Curriculum crafted by medical professionals and distinguished academics.'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
{listName:'Access to e-learning platforms.'},
{listName:'Tailored training programs to prepare students for placement opportunities'},
{listName:'Publication of over 70 research papers in both national and international journals and conferences.'},
{listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and'},
{listName:'launching student startups'},
{listName:'Organizing conferences, short-term training, workshops, webinars, online webinar, online and offline various competitions, contests, project and seminars at district, state and national level. '},
{listName:'Emphasis on hands on training, case assessment and treatment. '},
{listName:'Flexible credit system enabling students for higher post graduation national and international studies.'},
{listName:'Successful job placement in various hospitals and colleges.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions.'},

    ]
    const feeStructureData=[
        {duration:'1st Year Fees','4.5 Years':'₹130200/- (Tuition Fee - ₹105000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','4.5 Years':'₹114200/- ( Tuition Fee – ₹105000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='4.5 years'
    const fee_1st= '1,30,200'
    const fee_2nd= '1,14,200'
    const eligibility='Pass in 10+2 (PCB/PCM) examination. Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title=" Bachelor’s of Physiotherapy" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Physiotherapy is a merger science of Life Sciences and Technology</b>

Professionals that can competently handle and deliver healthcare services independently using sophisticated machinery and advanced protocols are now in high demand. In fact, diagnosis and management is now so dependent on technology, that healthcare professionals are vital to successful healthcare delivery.  As the Indian government aims for Universal Health Coverage, the lack of skilled human resource may prove to be the biggest impediment in its path to achieve targeted goals. The benefits of having healthcare professionals in the healthcare system are still unexplored in India. Although an enormous amount of evidence suggests that the benefits of healthcare professionals range from improving access to healthcare services to significant reduction in the cost of care, the Indian healthcare system still revolves around the doctor-centric approach. The privatization of healthcare has also led to an ever-increasing out-of-pocket expenditure by the population.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Department" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Bachelor of Physiotherapy and rehabilitation of the individuals from various spheres of injury or disability covering a wide range of spectrum from cardiopulmonary, IPD, orthopaedics, paediatrics, neurological, sports and many others offering bachelor’s in physiotherapy based on evidence based and research based informed teaching opening new doors of opportunities for the students. The OPD is well equipped with separate specialized departments of electrotherapy, exercise therapy, gym, rehabilitation and occupational therapy departments. Various departments and equipment are separate for students and patients under specialized training and learning with experienced faculty.
    <ul>
1.<li>State of the OPD facilities with specialization in Sports, Neurological, Paediatrics,
Cardiology, Geriatrics problems and sports injuries.</li>
2.<li>Department of Physiotherapy, School of Allied Health Sciences have tie ups with
various Hospitals of Palwal and Faridabad.</li>
3.<li>Alumni students are well placed in different hospitals, clinics or have started their private practices.</li>
</ul>
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 (PCB/PCM) examination.
    <br/>
Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their 12th-grade final examination scores. Aggregate in PCB/PCM will only be considered for merit list.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Bachelor’s of Physiotherapy
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BPT;
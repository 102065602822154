import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

const AlumniConnect=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="Alumni Connect" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >MVN University's Alumni Connect program is designed to foster a lifelong relationship between the institution and its graduates. Through this program, we aim to create a vibrant and engaged alumni community that supports and inspires both current students and fellow alumni. We offer a range of activities and benefits, including networking events, mentorship opportunities, professional development workshops, and exclusive access to university resources. By staying connected, our alumni can continue to contribute to MVN University's growth, share their experiences, and provide valuable guidance to the next generation of students. Our Alumni Connect program celebrates the achievements of our graduates and strengthens the bond between them and their alma mater.</Typography>
    </Grid>
    </Grid>

)
}
export default AlumniConnect;
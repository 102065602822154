import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSInitiative=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Allied Health Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Within the School of Allied Health Sciences, our endeavors echo our dedication to innovation and academic excellence converge to shape the business leaders of tomorrow. One of our key initiatives is the integration of specialization courses within our graduate and postgraduate programs. Recognizing the evolving needs of the Allied Health Profession, we offer a diverse range of specialization options that allow students to tailor their education to their interests and career goals.
    <br/><br/>
Allied health science is a broad field that includes a variety of healthcare professionals who work to prevent, diagnose, and treat diseases and illnesses. Allied health professionals also use scientific principles and evidence-based practices to assist patients and apply management and administration skills to support healthcare systems.
<br/><br/>
A bachelor of Allied Health Science focuses on health, including social aspects of health, to provide students with an interdisciplinary background and skill set. Students develop the knowledge and prerequisites necessary for entry into health-related careers.
<br/><br/>
We are committed to providing our students with a supportive and inclusive learning environment that promotes diversity, equity, and inclusion. Our faculty and staff are dedicated to helping you achieve your academic and professional goals, and we offer a range of support services to help you succeed.

    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSInitiative;
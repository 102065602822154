import React from "react";
import { Typography, Grid, List, ListItem, ListItemText } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

function Home() {
  const key_highlight = [
    "State of arts laboratories with full equipment",
    "Flexible choice-based credit system (CBCS) enabling students to pursue interdisciplinary minors",
    "Course curriculum designed with the input of Industry persons",
    "Outstanding placements records",
    "Project based teaching and learning",
    "Curriculum aligning with AICTE standards and offering industry-relevant elective courses",
    "Curriculum crafted by industry professionals and distinguished academics",
    "Partnerships providing skill-enhancement training to boost job readiness",
    "Access to e-learning platforms like MOOC, NPTEL, Coursera etc.",
    "Tailored training programs to prepare students for placement opportunities",
    "Publication of over 100 research papers in both national and international journals and conferences",
    "Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups",
    "Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships",
    "Courses on Design Thinking and Innovation, focusing on originality, startup viability, and the documentation of outcomes such as research papers, patents, products, start-ups, or copyrights",
    "Employment opportunities in various sectors like Automobile, Chemical, Aerospace and Defence, construction as well as Electronics industry. So its scope is equally in Government sector as well as in private sectors." ,
    "Prospects for advanced studies or research in both domestic and international institutions",
    "Availability of scholarships covering up to 100% of expenses.",
];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Department Of Civil Engineering" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
          Welcome to the Civil Engineering (CE) Department at MVN University!
          <br/>
          <br/>
          The vision of the Department of Civil Engineering is to strive for
          excellence in education and targeted research themes in emerging
          areas, with an aim to become one of the leaders in the education field
          for civil engineering students. To do this, the department works to
          produce highly qualified graduates through demanding curriculum and
          cutting-edge research projects. The course contents are periodically
          updated for introducing new scientific and technological developments.
          The School maintains active research groups for carrying out
          collaborative and interdisciplinary research. In addition to the
          regular academic work, the students of the department are encouraged
          to participate in co-curricular activities.
          <br/>
          <br/>
          Civil engineering is a field dedicated to designing, constructing, and maintaining
          infrastructure essential for modern society. Civil engineers play a
          crucial role in shaping the built environment, from roads, bridges,
          and buildings to water supply systems, dams, and airports. They apply
          principles of mathematics, physics, and materials science to solve
          complex problems and ensure the safety, sustainability, and
          functionality of infrastructure projects. Civil engineering
          encompasses various specialized areas, including structural
          engineering, transportation engineering, environmental engineering,
          geotechnical engineering, and water resources engineering. As stewards
          of public safety and welfare, civil engineers work collaboratively
          with architects, urban planners, and policymakers to create resilient
          and sustainable solutions that meet the needs of communities while
          considering factors such as economic feasibility, environmental
          impact, and social equity.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Key Highlights" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {key_highlight.map((item, index) => (
          <List sx={{ margin: 0, padding: 0 }}>
            <ListItem disableGutters key={index}>
              <ListItemText
                primary={
                  <Typography component="span" variant="body1">
                    &#8226; {item}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        ))}
      </Grid>
    </Grid>
  );
}

export default Home;

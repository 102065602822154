import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Container, Typography } from '@mui/material'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import { TableDataPresentation, ListDataPresentation } from '../../../utils/utils'
import MainTitle from '../../muiHome/mainTitle'
import NewMain from '../../headerone/newMain'
function FeeStructure() {
	const SOETfeeStructureData=[
        {'Courses':'B.Tech CSE','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
        {'Courses':'B.Tech CSE (Artificial Intelligence)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'120000' ,'1st Year Fees':'145200','2nd Year Onwards':'129200'},
        {'Courses':'B.Tech CSE (Machine Learning)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'120000' ,'1st Year Fees':'145200','2nd Year Onwards':'129200'},
        {'Courses':'B.Tech CSE (Cyber Security)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'120000' ,'1st Year Fees':'145200','2nd Year Onwards':'129200'},
        {'Courses':'B.Tech Civil Engineering','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'75000' ,'1st Year Fees':'100200','2nd Year Onwards':'84200'},
        {'Courses':'B.Tech Electronic & Communication Engineering','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'75000' ,'1st Year Fees':'100200','2nd Year Onwards':'84200'},
        {'Courses':'B.Tech ME (Robotics & Automation)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'4','Tution Fee':'75000' ,'1st Year Fees':'100200','2nd Year Onwards':'84200'},
        {'Courses':'B.Sc Computer Science','Eligiblity':'10+2 with 50% marks in PCM','Duration':'3','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
        {'Courses':'BCA','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'55000' ,'1st Year Fees':'85200','2nd Year Onwards':'64200'},
        {'Courses':'BCA (Data Science)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'BCA (Artificial Intelligence)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'BCA (Machine Learning)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'MCA','Eligiblity':'Graduate in related stream with 50% marks','Duration':'2','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
        {'Courses':'M.Tech CSE ','Eligiblity':'B.Tech CSE with 50% marks','Duration':'2','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
    
	]
	const SBMCfeeStructureData=[
        {'Courses':'B.Com (H)','Eligiblity':'10+2 with 50% marks in Commerce','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Courses':'BBA','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
        {'Courses':'BBA (Digital Marketing)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'BBA (Business Analytics)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'MBA (HR, Marketing, Finance)','Eligiblity':'Any Graduate with 50%','Duration':'2','Tution Fee':'75000' ,'1st Year Fees':'100200','2nd Year Onwards':'84200'},
        {'Courses':'MBA (Business Analytics)','Eligiblity':'Any Graduate with 50%','Duration':'2','Tution Fee':'75000' ,'1st Year Fees':'100200','2nd Year Onwards':'84200'},
        {'Courses':'MBA (Pharm Management)','Eligiblity':'Any Graduate with 50%','Duration':'2','Tution Fee':'75000' ,'1st Year Fees':'100200','2nd Year Onwards':'84200'},
        {'Courses':'MBA (Hospital Management)','Eligiblity':'Any Graduate with 50%','Duration':'2','Tution Fee':'75000' ,'1st Year Fees':'100200','2nd Year Onwards':'84200'},
	]
	const SOPSfeeStructureData=[
        {'Courses':'D. Pharmancy','Eligiblity':'10+2 with 50% marks in PCB/M & Minimum age 17 years','Duration':'2','Tution Fee':'85000' ,'1st Year Fees':'110200','2nd Year Onwards':'94200'},
        {'Courses':'B. Pharmancy','Eligiblity':'10+2 with 50% marks in PCB/M & Minimum age 17 years','Duration':'4','Tution Fee':'125000' ,'1st Year Fees':'150200','2nd Year Onwards':'134200'},
        {'Courses':'Pharm D*','Eligiblity':'10+2 with 50% marks in PCB/M & Minimum age 17 years','Duration':'6','Tution Fee':'175000' ,'1st Year Fees':'200200','2nd Year Onwards':'184200'},
        {'Courses':'M. Pharmancy (Pharmaceutics)','Eligiblity':'B. Pharmacy with 50% marks','Duration':'2','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
        {'Courses':'M. Pharmancy (Pharmacology)','Eligiblity':'B. Pharmacy with 50% marks','Duration':'2','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
        {'Courses':'M. Pharmancy (Drug Regulatory Affairs)','Eligiblity':'B. Pharmacy with 50% marks','Duration':'2','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
       
	]
	const SOLfeeStructureData=[
        {'Courses':'BA LLB','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'5','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'BBA LLB','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'5','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'LLB','Eligiblity':'Any Graduate with 45% marks','Duration':'3','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
        {'Courses':'LLM','Eligiblity':'LLB with 50% marks','Duration':'1','Tution Fee':'70000' ,'1st Year Fees':'95200','2nd Year Onwards':''},
	]
	const SAHSfeeStructureData=[
        {'Courses':'Diploma in Heath Assistant-DHA (After 10th Class)','Eligiblity':'10th/10+2 with 50% marks','Duration':'3','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
        {'Courses':'Diploma in Medical Lab Technology (DMLT)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'2','Tution Fee':'40000' ,'1st Year Fees':'65200','2nd Year Onwards':'49200'},
        {'Courses':'B.Sc in Medical Lab Technology (BMLT) ','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'3','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
        {'Courses':'B.sc (Biotechnology)','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'3','Tution Fee':'45000' ,'1st Year Fees':'70200','2nd Year Onwards':'54200'},
        {'Courses':'Bachelor of Physiotherapy (BPT)','Eligiblity':'10+2 with 50% marks in PCB/M & Minimum age 17 years','Duration':'4.5','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
        {'Courses':'M.Sc in Medical Lab Technology (M.Sc. MLT)','Eligiblity':'Graduate in Related Stream with 50% marks','Duration':'2','Tution Fee':'70000' ,'1st Year Fees':'95200','2nd Year Onwards':'79200'},
		{'Courses':'M.Sc (Microbiology)','Eligiblity':'Graduate in Related Stream with 50% marks','Duration':'2','Tution Fee':'65000' ,'1st Year Fees':'90200','2nd Year Onwards':'79200'},
       
	]
	const SSHfeeStructureData=[
        {'Courses':'B.Sc (Medical)','Eligiblity':'10+2 with 50% marks in PCB','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Courses':'B.Sc (Non Medical)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Courses':'BA (Pass)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Courses':'BA (Economics-Honours)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Courses':'BA (Plotical Science)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Courses':'BA (Journalism & Mass Communication)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
	]
	const LEAfeeStructureData=[
        {'Courses':'B.Tech CSE (Leet)','Eligiblity':'Diploma/B.Sc (Maths) with 50% marks','Duration':'3','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
        {'Courses':'B.Pharmacy (Leet)','Eligiblity':'D.Pharm with 50% marks','Duration':'3','Tution Fee':'125000' ,'1st Year Fees':'150200','2nd Year Onwards':'134200'},
        {'Courses':'B.Sc inMedical Lab Technology (BMLT) (Leet)','Eligiblity':'DMLT with 50% marks','Duration':'2','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
	]
	const DoctoralfeeStructureData=[
        {'Courses':'Ph.D (All)','Eligiblity':'PG with 55% marks','Duration':'Mini 3 Years','Tution Fee':'85000' ,'1st Year Fees':'105200','2nd Year Onwards':'89200'},
	]
	const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
	const noteList=[
		{listName:'1. Uniform fee Rs. 4500/- will be charged extra.'},
		{listName:'2. HSTES Counselling fee (B.Pharm) will be charged extra.'},
		{listName:'3. For Pharmacy courses eligibility verification is mandotory as per PCI norms and charges for the same will be charged extra.'},
		{listName:'4. UGC norms will be adhered for fee refund'},
		{listName:'5. Hostel will be provided for full Academic year only.'},
		{listName:'6. All the dispute are subjected to Palwal judiciary.'},
		{listName:'7. *Approval awaiting.'},
	]
	return (
		<>
			<NewMain />
			<Bredcom title="Home" subtitle="Fee Structure With Eligibility 2024-25" />
			<Container>
			<Grid container spacing={2} marginTop={10}>
				<Grid item xs={12}>
					<MainTitle title='Fee Structure With Eligibility 2024-25'/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of engineering & Technology'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SOETfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of business management and commerce'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SBMCfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Pharmaceutical science'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SOPSfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Law'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SOLfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Allied health sciences'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SAHSfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='School of Science and humanities'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SSHfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='Lateral Entery Admissions Fee Structure'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={LEAfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='Doctoral Programmes'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={DoctoralfeeStructureData}/>
				</Grid>
				<Grid item xs={12}>
					<MainTitle title='Hostel Fees'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={hostalFeeData}/>
				</Grid>
				<Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
	<Grid item xs={12}>
    <MainTitle title="Notes" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={noteList}/>
    </Grid>
			</Grid>
			</Container>
			

		</>
	)
}

export default FeeStructure
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSResearch=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Business Management and Commerce" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Research & Development" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    In the School of Business Management and Commerce at MVN University, we prioritize research and development as fundamental pillars of our academic and professional philosophy. Our faculty actively organizes research conferences and workshops, providing a lively platform for experts, students, and faculty to exchange pioneering ideas and advancements in business and commerce. Our research encompasses various domains, including but not limited to Business Analytics, Marketing Strategies, Financial Management, and E-commerce, with a focus on addressing contemporary challenges in the business world.
<br/><br/>
We are dedicated to the ongoing growth of our faculty, ensuring they stay abreast of the latest technological and pedagogical trends. Our department actively collaborates with industry leaders and academic institutions on joint projects, aiming to drive innovation and offer practical solutions to real-world business issues. Through these collaborative efforts, we aim to cultivate a dynamic environment where exploration leads to innovation, and education extends beyond the classroom to make a tangible impact on society and the business landscape.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSResearch;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import ImagesSlider from "../../muiCommonComponent/imageSlider";

const Agri_extension=()=>{

  const eventOrgnized=[
    {img:'/assets/img/footer_pages/agri1.png'},
    {img:'/assets/img/footer_pages/agri2.png'},
    {img:'/assets/img/footer_pages/agri3.png'},
    {img:'/assets/img/footer_pages/agri4.png'},
  ]
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="Agriculture Extension Activities:" />
    </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={eventOrgnized} />
      </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Welcome to our Agriculture plays a vital role in India’s economy. As per the Census of 2011, 54.6% of the total workforce is engaged in agricultural and allied sector activities and accounts for 20.19% of the country’s Gross Value Added (GVA) for the year 2020-21 (at current prices) with INR 36.16 Lakh Crore. The agriculture sector's contribution to the Indian economy is much higher than the world's average (6.4%). India is the world's largest producer of milk, jute, and pulses. India is also the world second largest producer of rice, wheat, sugarcane, fruit, vegetables, cotton, and groundnuts. India has huge scope to improve crop yield and production of food grains, oil crops, fruits, vegetables, etc. But it requires multiple interventions at soil health management, nutrition management, crop protection, farm mechanization, crop harvesting, etc.
    <br/><br/>
MVN University, Palwal (Haryana) gives a platform to develop complementary skills for becoming successful agri-entrepreneurs. The University also has an agricultural farm for practical training by growing different field crops as well as vegetables in Kharif, Rabi & Zaid seasons. MVN University is regularly involved in agriculture extension activities such as organizing Farmer’s fair from last three years to motivate nearby farmers and to trained farmers about various modern farming tools and techniques. University was also organized various training program for farmer’s like organic farming training, vermi-compost training, judicious use of glyphoset, mushroom cultivation, Poultry  and fisheries cultivation, Nursery preparation etc.


</Typography>
    </Grid>
    </Grid>

)
}
export default Agri_extension;
import React, { useState } from 'react'
import Bredcom from '../Bredcom/Main'
import Header from '../headerone/Main'
import axios from 'axios'
import { baseURL } from '../../config'
import Loader from './Loader/Loader'

const SpecialRegex=/^[A-Za-z\s]*$/
function Careers() {
    const [errorStatus, setErrorStatus] = useState(false)
    const [isLoading,setIsLoading]=useState(false)
    const [payload, setPayload] = useState({
        position: "",
        location: "",
        name: "",
        total_experience: "",
        qualification: "",
        current_salary: "",
        expected_salary: "",
        notice_join: "",
        contact_number: "",
        profile: {
            fileName: '',
            url: '',
            type: ''
        },
        resume: {
            fileName: '',
            url: '',
            type: ''
        }
    })
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPayload((values) => ({ ...values, [name]: value }))
    }
    const handlFile = (e, key) => {
        const { files } = e.target;
        if (files?.length > 0) {
            let name = key == 'image' ? 'profile' : 'resume';
            let file_name = files[0].name
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            }).then((res) => {
                setPayload((values) => ({
                    ...values,
                    [name]: {
                        fileName: file_name,
                        url: res,
                        type: file_name?.split('.')?.pop()
                    }
                }))
            });
        }
    }
    const handleremove = (name) => {
        let fileUrl = name == 'image' ? 'profile' : 'resume';
        setPayload((values) => ({
            ...values,
            [fileUrl]: {
                fileName: "",
                url: "",
                type: ""
            }
        }))
    }
    const handleSubmit = (e) => {
        setIsLoading(true)
        e.preventDefault()
        if(payload?.name==""||Boolean(payload?.name.match(SpecialRegex))==false||payload?.contact_number<10||Boolean(payload?.name.match(SpecialRegex))==false){
            setErrorStatus(true)
        setIsLoading(false)

            // var x = document.getElementById("snackbar");
            // x.className = "show";
            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
        }else{
        let url = `${baseURL}/addCareer`
        axios.post(url, payload, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            }
        }).then((response) => {
            var x = document.getElementById("successsnackbar");
            x.className = "show";
            setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
            setErrorStatus(false)
        setIsLoading(false)

            setPayload({
                position: "",
                location: "",
                name: "",
                total_experience: "",
                qualification: "",
                current_salary: "",
                expected_salary: "",
                notice_join: "",
                contact_number: "",
                profile: {
                    fileName: '',
                    url: '',
                    type: ''
                },
                resume: {
                    fileName: '',
                    url: '',
                    type: ''
                }
            })
            console.log('response:::', response)
        }).catch(error=>{
            setErrorStatus(false)
        setIsLoading(false)

        })
    }
    }
    console.log('payload::', payload)
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="Careers" />
            <section className="project-detail">
                <div className="container">

                    <div className='industryImg'>
                        <center> <img src="https://admission.mvn.edu.in/wp-content/uploads/2023/07/Hiring-MVN.jpg" alt="" /></center>

                    </div>
                </div>
            </section>
            <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix" style={{ background: "#e7f0f8" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 order-2">
                            <div className="contact-bg">
                                <div className="section-title center-align text-center mb-50">
                                    <h2>APPLY FOR THE JOB</h2>
                                </div>
                                <form className="contact-form mt-30 text-center" >
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-name mb-30">
                                                <input type="text" id="PositionApplyingFor" name="position" placeholder="Position Applying For" value={payload.position}
                                                    onChange={handleInput} required="" />
                                               {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.position.match(SpecialRegex))==false?"Special character not allowed !":""}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="Location" name="location"
                                                    value={payload.location} onChange={handleInput} placeholder="Location" required="" />
                                                  {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.location.match(SpecialRegex))==false?"Special character not allowed !":""}</span>}

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="Name" name="name" placeholder="Name *"
                                                    value={payload.name} onChange={handleInput} required="" />
                                                {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.name.match(SpecialRegex))==false?"Special character not allowed !":payload.name==""&&"Please input a valid position !"}</span>}

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="CurrentOrganizationandTotalExp" name="total_experience" placeholder="Current Organization and Total Exp." value={payload.total_experience}
                                                    onChange={handleInput} required="" />
                                                    {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.total_experience.match(SpecialRegex))==false?"Special character not allowed !":""}</span>}

                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="AcademicandProfessionalQualifications" name="qualification" placeholder="Academic and Professional Qualifications" value={payload.qualification}
                                                    onChange={handleInput} required="" />
                                                {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.qualification.match(SpecialRegex))==false?"Special character not allowed !":""}</span>}
                                           </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-name mb-30">
                                                <input type="text" id="CurrentSalary" name="current_salary" placeholder="Current Salary" value={payload.current_salary}
                                                    onChange={handleInput} required="" />
                                                {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.current_salary.match(SpecialRegex))==false?"Special character not allowed !":""}</span>}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="ExpectedSalary" name="expected_salary" placeholder="Expected Salary" value={payload.expected_salary}
                                                    onChange={handleInput} required="" />
                                                {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.expected_salary.match(SpecialRegex))==false?"Special character not allowed !":""}</span>}

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="text" id="NoticePeriodtoJoin" name="notice_join" placeholder="Notice Period" value={payload.notice_join}
                                                    onChange={handleInput} required="" />
                                                {errorStatus&&<span style={{ color: "red" }}>{Boolean(payload.notice_join.match(SpecialRegex))==false?"Special character not allowed !":""}</span>}

                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="contact-field p-relative c-subject mb-30">
                                                <input type="number" maxLength={10} id="ContactNo" name="contact_number" placeholder="Contact Number *" value={payload.contact_number}
                                                    onChange={handleInput} required="" />

                                                {errorStatus&&payload?.contact_number?.length<10&&<span style={{ color: "red" }}>Phone number should be 10 digit !</span>}
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            {payload?.profile?.url == "" ?
                                                <div className="file-upload">
                                                    <button className="file-upload-btn" type="button" onClick={() => {
                                                        document
                                                            .getElementById("profile-avatar")
                                                            .click()
                                                    }}>Upload Image</button>

                                                    {/* 
                                                        <div className="drag-text">
                                                            <h3>Drag and drop a file or select add Image</h3>
                                                        </div>
                                                    </div> */}
                                                    <div className="image-upload-wrap">
                                                        <input id="profile-avatar" className="file-upload-input" type='file' onChange={(e) => handlFile(e, 'image')} accept="image/*" />

                                                        <img src="/profile-avatar.png" alt="Avatar" className="career-avatar"></img>
                                                    </div>
                                                </div>
                                                : <div className="file-upload-content">
                                                    {payload?.profile?.type == 'jpg' || payload?.profile?.type == 'png' ?
                                                        <img className="career-avatar" src={payload?.profile?.url} alt="your image" />
                                                        : <iframe className="file-upload-image" src={payload?.profile?.url} alt="your image" />
                                                    }
                                                    <div className="image-title-wrap">
                                                        <button type="button" onClick={() => handleremove('image')} className="remove-image">Remove <span className="image-title">Uploaded Image</span></button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-lg-6'>
                                            {payload?.resume?.url == "" ?
                                                <div className="file-upload">
                                                    <button className="file-upload-btn" type="button" onClick={(e) => {
                                                        document.getElementById("upload-resume").click(e)
                                                    }}>Upload Resume</button>

                                                    <div className="image-upload-wrap">
                                                        <input id="upload-resume" className="file-upload-input" type='file' onChange={(e) => handlFile(e, 'resume')} accept="pdf/*" />
                                                        <div className="drag-text">
                                                            <h3>Drag and drop a file or select upload Resume</h3>
                                                        </div>
                                                    </div>

                                                </div>
                                                : <div className="file-upload-content">
                                                    {payload?.resume?.type == 'jpg' || payload?.resume?.type == 'png' ?
                                                        <img className="file-upload-image" src={payload?.resume?.url} alt="your image" />
                                                        : <iframe className="file-upload-image" src={payload?.resume?.url} alt="your image" />
                                                    }
                                                    <div className="image-title-wrap">
                                                        <button type="button" onClick={() => handleremove('resume')} className="remove-image">Remove <span className="image-title">Uploaded Resume</span></button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-lg-12 notes-career" >
                                            <h6 className='heading'>Notes :</h6>
                                            <p className='para'>1. Profile image type accept .png, .jpg, .jpeg .</p>
                                            <p className='para'>2. Resume type accept .pdf, .doc .</p>
                                        </div>
                                        <div className="col-lg-12" style={{ marginTop: 10 }}>

                                            <div className="slider-btn  text-center">
                                                <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s" onClick={handleSubmit} >
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="snackbar">Please fill * required  field..</div>
            <div id="successsnackbar">Career form data submit successfully !</div>
            {isLoading&&<Loader/>}
        </>
    )
}

export default Careers 
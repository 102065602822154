import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function LLM() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="LLM" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p>
                                The LL.M. programme culminates in the awarding of a Post-Graduate degree in Law, which is known as “Master of Laws.” Students will acquire conceptual methods to understand a specific law’s objective, enforcement, and significance. This shift in approach prepares the students to be good research scholars and motivates them to participate in various research initiatives to find solutions to legal challenges. The completion of this programme qualifies the student to appear in University Grant Commission National Eligibility Test for Lecturer, known as the UGC NET, and passing the UGC NET qualifies the person to pursue a career in higher education teaching. Students can also continue their studies by enrolling in Ph.D. programme.
                                </p>

                                <p>
                                The MVN University Centre for Post Graduate Legal Studies was founded to provide advanced and interdisciplinary teaching, learning, and research opportunities in law for Master’s and Doctoral students in the year 2015. The MVNU CPGLS is dedicated to providing an intellectually engaging academic atmosphere with highly qualified, experienced, and dedicated family members who are determined to providing an exceptional and world-class legal education. Comparative methods to learning and research are incorporated into the postgraduate programmes.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LLM
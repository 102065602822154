import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSInitiative=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Pharmaceutical Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Fostering Excellence: Faculty and Student Initiatives at the School of Pharmaceutical Sciences, MVN University</b>
        <br/>
        Welcome to the digital gateway of innovation and empowerment at the School of Pharmaceutical Sciences, MVN University. Here, we take great pride in the initiatives undertaken by both our esteemed faculty members and our talented students, all aimed at fostering a culture of excellence and innovation in pharmaceutical research.
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Faculty Initiatives:</b>
        <br/>
        Our faculty members are at the forefront of driving academic and research excellence at the School of Pharmaceutical Sciences. Through their unwavering dedication and commitment, they have spearheaded numerous initiatives to support and mentor our students in their research endeavors.
        <ol>
        <li><b>Research Mentorship Programs:</b> Our faculty members actively engage with students through research mentorship programs, providing guidance, support, and invaluable insights to help students navigate the complexities of scientific research.</li>
        <li><b>Interdisciplinary Collaborations:</b> Recognizing the importance of interdisciplinary collaboration in advancing scientific knowledge, our faculty members foster collaborations across different departments and disciplines, encouraging students to explore diverse research areas and perspectives.</li>
        <li><b>Funding and Grants:</b> Faculty members at SOPS, MVNU actively pursue external funding opportunities and grants to support student research projects. By securing financial resources, they ensure that students have access to the necessary tools and resources to conduct meaningful research.</li>
        </ol>
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        <b>Student Initiatives:</b>
        <br/>
        Empowering our students to take ownership of their academic and research journey is central to our mission at the School of Pharmaceutical Sciences. Through various student-led initiatives, we provide platforms for students to showcase their talents, collaborate with peers, and make meaningful contributions to the field of pharmaceutical sciences.
        <ol>
        <li><b>Student Research Club:</b> Our student research clubs serve as vibrant hubs of creativity and collaboration, where students come together to brainstorm ideas, plan research projects, and share their findings with the wider academic community.</li>
        <li><b>Annual Research Symposium:</b> Each year, our students organize and participate in an annual research symposium, where they have the opportunity to present their research findings, network with peers and faculty members, and gain valuable feedback to further refine their work.</li>
        <li><b>Community Outreach Program:</b> Recognizing the importance of giving back to the community, our students actively engage in various outreach programs aimed at raising awareness about important healthcare issues, promoting health literacy, and making a positive impact on society.</li>
        </ol>
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    As you explore our website, we invite you to learn more about the faculty and student initiatives at the School of Pharmaceutical Sciences, MVN University. Together, let us continue to foster a culture of excellence, innovation, and impact in pharmaceutical research.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSInitiative;
import { Grid, Typography, Card, Box } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";
const CSEBTechLab=()=>{

return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School Of Business Management and Commerce" />
    </Grid>

    <Grid item xs={12}>
        <MainTitle title="Computer Labs" />
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab1.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
            <br/>
            The School of Business Management and Commerce is proud to present its fully-equipped computer laboratory, which houses a diverse array of computers. This collection is designed to cater to a broad spectrum of educational and research needs within our school. Our lab features a range of systems, starting from basic models equipped with dual-core processors, sufficient for fundamental computing tasks and learning the basics of programming. These basic setups come with essential RAM and storage capabilities, ensuring reliable performance for introductory courses and projects.
<br/><br/>
Advancing from the foundational models, our lab also boasts a selection of more sophisticated computers. These are powered by modern i3 and i5 processors, marking a significant step up in terms of computing power and efficiency. To complement these processors, the advanced systems are fitted with higher RAM capacities and Solid-State Drives (SSDs). The inclusion of SSDs is crucial as they offer faster data access speeds, significantly reducing boot times and loading times for software and applications. This is particularly beneficial for software development tasks, complex simulations, and data-intensive projects that require more robust computing resources.
<br/><br/>
At the heart of modern education lies the integration of technology into the learning process. Recognizing this, the School of Management and Commerce at MVN University has invested significantly in state-of-the-art computer laboratories. These laboratories serve as the nerve center for innovation, research, and skill development, aligning perfectly with the university's commitment to nurturing future-ready professionals.
        </Typography>
      </Grid>

      <Grid item xs={12} sm={8} md={8}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
            <ol>
<li><b>Cutting-Edge Infrastructure:</b> Equipped with the latest computing technology, our computer laboratories boast advanced hardware and software resources tailored to meet the diverse needs of students. From programming and data analysis to graphic design and financial modeling, our labs cater to a wide spectrum of academic disciplines within management and commerce.</li>
<li><b>Specialized Software:</b> Students have access to a comprehensive suite of industry-standard software applications, facilitating hands-on learning experiences. Whether it's mastering accounting software, honing programming skills, or exploring data visualization tools, our labs provide the ideal environment for practical skill development.</li>
<li><b>High-Speed Connectivity:</b> In today's interconnected world, seamless internet connectivity is indispensable for academic pursuits. Our computer laboratories are equipped with high-speed internet access, enabling students to leverage online resources, participate in virtual collaborations, and stay abreast of the latest developments in their field.</li>
<li><b>Innovation Hub:</b> Beyond serving as mere facilities for academic activities, our computer laboratories double as innovation hubs where students can experiment, innovate, and push the boundaries of conventional thinking. Whether it's developing a cutting-edge app, conducting market research using big data analytics, or simulating real-world business scenarios, our labs provide the tools and resources to turn ideas into reality.</li>
            </ol>
            Computer laboratories at the School of Management and Commerce, MVN University, represent more than just physical spaces with computers; they embody our commitment to empowering students with the technological skills and mindset needed to thrive in today's dynamic business landscape. By providing access to cutting-edge infrastructure, specialized software, round-the-clock support, and collaborative opportunities, we are shaping the next generation of leaders, innovators, and changemakers poised to make a meaningful impact on the world.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab2.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
        <br/>
        <Card
          sx={{
            height: "auto",
            padding: "10px",
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderRadius: "10px",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            <img
              alt={"NP"}
              src={"/assets/img/SOL/sol_lab3.png"}
              sx={{ width: 150, height: 150, marginBottom: 2 }}
            />
          </Box>
        </Card>
      </Grid>
    </Grid>

)
}
export default CSEBTechLab;
import React, { useState } from 'react'
import axios from 'axios'
import { baseURL } from '../../config'
import Loader from '../Pages/Loader/Loader'
import ScriptLoader from './scriptLoader'
const SpecialRegex=/^[\w-\]+[A-Za-z\s]*$/g
const EmailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
function Form() {
    const [enquiryForm, setEnquiryForm] = useState({
        name: '',
        email: '',
        phone_number: '',
        comments: ''
    })
    const [isLoading,setIsLoading]=useState(false)
    const [errorStatus, setErrorStatus] = useState(false)
    const handleSubmit = (e) => {
        e.preventDefault()
        setIsLoading(true)
        if (enquiryForm?.name==""||Boolean(enquiryForm?.name.match(SpecialRegex))==false || Boolean(enquiryForm?.email.match(EmailRegex))==false|| enquiryForm?.phone_number<10) {
            setErrorStatus(true)
            setIsLoading(false)
            // var x = document.getElementById("snackbar");
            // x.className = "show";
            // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
        }
        else {
            let url = `${baseURL}/enquiry`
            axios.post(url, enquiryForm, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",

                }
            }).then((response) => {
                var x = document.getElementById("successsnackbar");
                x.className = "show";
                setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
                setIsLoading(false)
                setEnquiryForm({
                    name: '',
                    email: '',
                    phone_number: '',
                    comments: ''
                })
            }).catch(error=>{
                setIsLoading(false)
            })

        }
    }
    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setEnquiryForm((values) => ({ ...values, [name]: value }))
    }
    return (
        <>
        <ScriptLoader/>
            <section id="contact" className="contact-area after-none contact-bg pt-120 pb-120 p-relative fix" style={{ background: "#e7f0f8" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 order-2">
                            <div className="contact-bg">
                                <div className="section-title center-align text-center mb-50">
                                    <h2>Enquiry Form</h2>
                                </div>
                                <div className="npf_wgts" data-height="538px" data-w="dfc8352494c1e0ec69cfdd4fec2fbdfd"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div id="snackbar">Please fill * required  field..</div>
            <div id="successsnackbar">Enquiry form submit successfully !</div>
            {isLoading&&<Loader/>}
        </>
    )
}

export default Form
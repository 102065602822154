import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import First from './mengallery/First'
import NewMain from '../headerone/newMain'

const faqItems = [
    {
        question: 'Classrooms',
        answer: 'Our air conditioned classrooms are spacious and IT enabled. They have been designed to ensure maximum interactivity. Our faculty members make regular use of audio-visual teaching aids to provide greater clarity on concepts, increase recall and make the sessions more interesting and enjoyable.',
    },
    {
        question: 'Conference Rooms',
        answer: 'We lay great emphasis on widening the exposure of our students. We organize regular interactions with prominent personalities as well as host educative seminars. Our air conditioned theaters and conference rooms, with the latest projection and audio equipment, enable knowledge sharing and personality development in a comfortable environment.',
    },
    {
        question: 'Library',
        answer: 'Our library has an extensive collection of relevant books and online journals. We have a Book Bank facility, which means that our students can issue books for the entire semester. The library is subscribed to DELNET and INDEST, which ensures that our students have access to a plethora of eBooks, online journals, newspapers and magazines.',
    },
    {
        question: 'Laboratories',
        answer: 'For hands-on industry experience and to encourage the spirit of innovation, we have several labs with the latest equipment.',
    },
    {
        question: 'Recreation',
        answer: 'All work and no play? That’s not MVN University! You can spend time sweating it out in the basket ball court or football field or you can cool off at the swimming pool. You can share a story with your friend at the skating rink or have fun beating him in a game of table tennis. For times when hunger calls, we have a large and exciting cafeteria. Meals are prepared hygienically, with a focus on the food being nourishing. Of course, there is some fun “junk” stuff tool.',
    },
    {
        question: 'Hostels',
        answer: 'Comfortable, safe and homely… that’s what our hostels are like. The rooms are spacious, air conditioned and Internet enabled. We have separate hostels for boys and girls. To add to your convenience, the hostels have mess facility, 100% power backup, round-the-clock water supply and indoor gym.',
    },
    {
        question: 'Medical Facility',
        answer: 'The campus has a well-equipped medical facility, with a full-time qualified nurse. We have the arrangement for calling in doctors for exigencies and consultation. We also have an arrangement with a premier hospital, located within the vicinity, for medical care at subsidized charges.',
    },
];

function Infrastructure() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <>
            <NewMain />
            <Bredcom title="Home" subtitle="Academic Infrastructure" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Academic Infrastructure</h3>
                                </div>
                                <p>
                                MVN University represents a combination of the most modern facilities amidst the most natural environs. Spread over 23 acres, the campus is green and sprawling, providing a tranquil backdrop for the highly motivated students and faculty of our university.
                                </p>
                                <p>
                                The world-class campus is Wi-Fi enabled and houses state-of-the-art facilities so that our students have exposure to the latest technologies that aid education. The MVN University campus is home to three schools that offer different courses and degrees:
                                </p>

                                <div className="faq-wrap mt-30 pr-30">
                                    <div className="accordion" id="accordionExample">
                                        {faqItems.map((item, index) => (
                                            <div className="card" key={index}>
                                                <div className="card-header" id={`heading${index}`}>
                                                    <h2 className="mb-0">
                                                        <button className={`faq-btn${activeIndex === index ? '' : ' collapsed'}`}
                                                            type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} onClick={() => handleToggle(index)} >
                                                            {item.question}
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div id={`collapse${index}`} className={`collapse${activeIndex === index ? ' show' : ''}`} data-bs-parent="#accordionExample" >
                                                    <div className="card-body">{item.answer}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <First/>

        </>
    )
}

export default Infrastructure
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const BSCBiotech=()=>{
    const progHiglight=[
{listName:'NEP-2020 recommended curriculum offering multidisciplinary, holistic, and skill-based learning.'},
{listName:'Flexible credit system enabling students to pursue interdisciplinary minors.'},
{listName:'Curriculum crafted by industry professionals and distinguished academics.'},
{listName:'Availability of scholarships covering up to 100% of expenses in eligible cases.'},
{listName:'Successful job placement across diverse fields like medical biotechnology, Agriculture Biotechnology, Environment Biotechnology, Industrial Biotechnology, Bioinfromatics, Genomics, Vaccinology and Allied Life Sciences streams such as Biochemistry, Genetics, Microbiology etc. showcasing a variety of opportunities with leading global corporations.'},
{listName:'Employment opportunities in prestigious organizations across defence, research, government, healthcare, etc.'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Modern laboratories equipped with sophisticated instruments.'},
{listName:'Emphasis on project-based and experiential learning methodologies'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, and Coursera'},
{listName:'Tailored training programs to prepare students for placement opportunities.'},
{listName:'Highly motivated faculties expert of the domain has vast research experience for experiential learning.'},
{listName:'Faculties have publications in SCI-E/WoS and Scopus indexed Journals of high impact factor.'},
{listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups.'},
{listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},

        
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹70200/- (Tuition Fee - ₹45000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹54200/- ( Tuition Fee – ₹45000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '70,200'
    const fee_2nd= '54,200'
    const eligibility='Pass in 10+2 (PCB/PCM) examination. Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="B.Sc. Biotechnology" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Biotechnology is a merger science of Life Sciences and Technology</b>

B.Sc. Biotechnology is a 3–4-year program (As per NEW EDUCATION POLICY-2020) with an enriched curriculum comprising of basic courses (cell biology, biochemistry, animal and plant physiology, developmental biology) and applied courses (plant and animal biotechnology, bioinformatics, bioprocess technology and others). Specific courses target skill development, employability, and entrepreneurship potential of biotechnology.
<br/><br/>
Biotechnology is futuristic science which merges technology for and to life. Life, refer to organisms from highly evolved human to microorganisms. Technology use to animal, plant, and microbial species for their improvement (qualitative as well as quantitative) along with development of process and products w.r.t health, environment, agriculture, industry, etc. is the hour’s need. In recent past biotechnology has been identified as a prominent field of life Sciences owing to its interdisciplinary approach and widespread applications.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 (PCB/PCM) examination.
    <br/>
Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their 12th-grade final examination scores. Aggregate in PCB/PCM will only be considered for merit list.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    School of Allied Health Sciences
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BSCBiotech;
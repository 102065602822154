import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";
const CSEBTechLab=()=>{

return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Pharmaceutical Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Elevating Learning and Research: Laboratory Facilities at School of Pharmaceutical Sciences" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Welcome to the School of Pharmaceutical Sciences at MVN University, Palwal – a dynamic hub of academic excellence and innovation. We are thrilled to introduce you to our cutting-edge laboratory facilities, meticulously designed to empower students and researchers alike in their pursuit of knowledge and discovery. Aligned with the rigorous standards set by the Pharmacy Council of India, New Delhi, our 20 state-of-the-art laboratories serve as the cornerstone of our educational and research endeavors. Whether you are enrolled in the D.Pharm, B.Pharm, & M.Pharm programs, each lab is thoughtfully equipped with all necessary glassware, chemicals, and instruments such as UV Visible spectrophotometer, High Performance Liquid Chromatography, Brookfield Viscometer, Karl fischer Titrimeter,  Nephelo Turbidity meter etc. to facilitate hands-on learning experiences.
<br/><br/>
What sets our laboratories apart is not just their compliance with regulatory standards, but also the immersive learning environment they offer. From pharmaceutical analysis to formulation development and pharmacology, our labs cover a diverse spectrum of disciplines, providing students with the opportunity to explore their interests and develop essential practical skills.
<br/><br/>
Furthermore, our strategic partnerships through Memorandums of Understanding (MOUs) with leading research labs and hospitals enrich the academic experience at SOPS, MVN University. These collaborations open doors to invaluable research opportunities, allowing students and faculty members to engage in cutting-edge research projects, clinical trials, and collaborative studies. By bridging the gap between academia and industry, these partnerships not only enhance the quality of education but also provide students with real-world insights and experiences that are invaluable in today's competitive landscape. Whether you aspire to pursue a career in academia, research, or the pharmaceutical industry, SOPS, MVN University, provides the perfect platform to nurture your talents and ambitions.
<br/><br/>
Thank you for considering the School of Pharmaceutical Sciences at MVN University, Palwal, as your partner in education and research. We look forward to welcoming you and embarking on this journey of discovery together.

    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBTechLab;
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import { baseURL } from '../../config';
import DialogTitle from '@mui/material/DialogTitle';
import Headerone from '../headerone/newMain'
import About from '../menhomenew/About'
import Courses from '../menhomenew/Courses'
import Event from '../menhomenew/Event'
import Scholarship from '../menhomenew/Scholarship'
import Shep from '../menhomenew/Shep'
import Slider from '../menhomenew/Sliderone'
import Frequently from '../menhomenew/Frequently'
import Video from '../menhomenew/Video'
import Testimonial from '../menhomenew/Testimonial'
import Search from '../menhomenew/Search'
import Admission from '../menhomenew/Admission'
import Redslider from '../menhomenew/Redslider'
import Blog from '../menhomenew/Blog'
import Subscribe from '../menhomenew/Subscribe'
import Loader from '../Pages/Loader/Loader';

const SpecialRegex=/^[\w-\]+[A-Za-z\s]*$/g
const EmailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g

function Home() {

  const [open, setOpen] = useState(true);
 const [isLoading,setIsLoading]=useState(false)
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const [enquiryForm, setEnquiryForm] = useState({
    name: '',
    email: '',
    phone_number: '',
    comments: ''
})
const [errorStatus, setErrorStatus] = useState(false)

const handleSubmit = (e) => {
  e.preventDefault()
  setIsLoading(true)
  if (Boolean(enquiryForm?.name.match(SpecialRegex))==false || Boolean(enquiryForm?.email.match(EmailRegex))==false|| enquiryForm?.phone_number<10) {
      setErrorStatus(true)
      setIsLoading(false)
      // var x = document.getElementById("snackbar");
      // x.className = "show";
      // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
  }
  else {
      let url = `${baseURL}/enquiry`
      axios.post(url, enquiryForm, {
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",

          }
      }).then((response) => {
          var x = document.getElementById("successsnackbar");
          x.className = "show";
          setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
          setIsLoading(false)
          setEnquiryForm({
              name: '',
              email: '',
              phone_number: '',
              comments: ''
          })
      }).catch(error=>{
        setIsLoading(false)
      })

  }
}
const handleInput = (e) => {
  const name = e.target.name;
  const value = e.target.value;
  setEnquiryForm((values) => ({ ...values, [name]: value }))
}
  return (
    <>  
        <Headerone/>
        <Slider/>
        <About/>
        <Courses/>
        <Shep/>
        <Event/>
        <Scholarship/>
        <Frequently/>
        <Video/>
        <Testimonial/>
        {/* <Search/> */}
        {/* <Admission/> */}
        <Redslider/>
        {/* <Blog/> */}
        {/* <Subscribe/> */}

        <Dialog open={open} onClose={handleClose}>
              
          <div className="contact-bg01">
            <div className="section-title wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s">
              <h2>Get in Touch Today</h2>
            </div>
            <form className="contact-form mt-30 wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" >
              <div className="row">
                <div className="col-lg-12">
                  <div className="contact-field p-relative c-name mb-20">
                    <input type="text" id="firstn" name="name" value={enquiryForm?.name} onChange={handleInput} placeholder="Name *" required />
                    {errorStatus&&<span style={{ color: "red" }}>{Boolean(enquiryForm?.name.match(SpecialRegex))==false?"Special character not allowed !":enquiryForm?.name==""&&"Please input a valid name !"}</span>}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="contact-field p-relative c-subject mb-20">
                    <input type="email" id="email" name="email" value={enquiryForm?.email} onChange={handleInput} placeholder="Email *" required />
                    {errorStatus&&Boolean(enquiryForm?.email.match(EmailRegex))==false&&<span style={{ color: "red" }}>Please input a valid email !</span>}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="contact-field p-relative c-subject mb-20">
                    <input type="number" maxLength={10} id="phone" name="phone_number" value={enquiryForm?.phone_number} onChange={handleInput} placeholder="Phone No. *" required />
                    {errorStatus&&enquiryForm?.phone_number?.length<10&&<span style={{ color: "red" }}>Phone number should be 10 digit !</span>}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="contact-field p-relative c-message mb-30">
                    <textarea name="comments" id="message" value={enquiryForm?.comments} onChange={handleInput} cols={30} rows={10} placeholder="Write comments" defaultValue={""} />
                  </div>
                  <div className="slider-btn">
                    <button className="btn ss-btn" data-animation="fadeInRight" data-delay=".8s" onClick={handleSubmit}>
                      <span>Submit Now</span>{" "}
                      <i className="fal fa-long-arrow-right" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <IconButton
            edge="end"            
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              color:'#fff',             
              top: 8,
              right: 25
            }}
          >
            <CloseIcon />
          </IconButton>
          
        {isLoading&&<Loader/>}
        </Dialog>
    </>
  )
}

export default Home
import React, { useState } from "react";
import { Stack, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const DepartmentWidgetsMenu = ({headerMenus}) => {
  const menuStyle = {
    display: 'flex',
    justifyContent: 'center', // Align menus at the center horizontally
    alignItems: 'center', // Align menus at the center vertically
  };

  const btn_style = {
    padding: '10px 20px',
    background: '#F8F8F8',
    minWidth: '100px',
    color: '#000',
    border: '1px outset gray',
    position: 'relative', // To position the submenu absolutely
    display: 'inline-block', // To align the submenu horizontally
  };

  const submenuStyle = {
    position: 'relative',
    top: '100%',
    backgroundColor: '#F8F8F8',
    // padding: '10px',
    listStyleType: 'none',
    width: '100%',
  };
  
  const dividerStyle = {
    margin: '4px 0',
    borderBottom: '1px solid #000',
  };



  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [hoveredSubIndex, setHoveredSubIndex] = useState(null);

  const handleMenuItemClick = () => {
    setHoveredIndex(null); // Close submenu on menu item click
  };

  return (
    <Grid container>
        <Grid item xs={12}>

       
    <div style={menuStyle}>
      <Stack direction="row">
        <ul>
          {headerMenus.map((item, index) => (
            <li
              key={index}
              style={btn_style}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            >
              <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleMenuItemClick}>
                {item.mainHeader}
              </Link>
              {item.subHeader && hoveredIndex === index && (
                <ul style={submenuStyle}>
                  {item.subHeader.map((subItem, subIndex) => (
                    <React.Fragment key={subIndex}>
                      {subIndex > 0 && <li style={dividerStyle}></li>}
                      <li
                        onMouseEnter={() => setHoveredSubIndex(subIndex)}
                        onMouseLeave={() => setHoveredSubIndex(null)}
                        style={{ width: '100%' }} // Set submenu item width to 100%
                      >
                        <Link
                          to={subItem.path}
                          style={{
                            textDecoration: 'none',
                            color: hoveredSubIndex === subIndex ? '#d32f2f' : 'inherit',
                          }}
                          onClick={handleMenuItemClick}
                        >
                          {subItem.title}
                        </Link>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </Stack>
    </div>
    </Grid>
    </Grid>
  );
};

export default DepartmentWidgetsMenu;

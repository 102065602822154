import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PharmaActivity() {

    const Data = [        
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/palwal-sugar-mill-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/palwal-sugar-mill-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/palwal-sugar-mill-1.jpg", category : "Financial"},
      ]

      const [current, setcurrent] = useState(Data);

    const aids = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/aids-awareness-program-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/aids-awareness-program-1.jpg", category : "Financial"},
      
    ];
    
    const vita = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/visit-to-vita-dairy-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/visit-to-vita-dairy-1.jpg", category : "Financial"},
       
    ];

    const cancer = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/international-childhood-cancer-day-2022-1.jpg", category : "Financial"},
    ];

    const affairs = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/career-pathways-in-regulatory-affairs-1.jpg", category : "Financial"},
    ];


    const fresher = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2022/03/fresher-party-aarambh-2022-1.jpg", category : "Financial"},
        
    ];

    const worldCancer = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/10/World-Cancer-Day-2021-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/10/World-Cancer-Day-2021-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/10/World-Cancer-Day-2021-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/10/World-Cancer-Day-2021-4.jpg", category : "Financial"},
    ]; 

    const blood = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/blood-donation-camp-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/blood-donation-camp-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/blood-donation-camp-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/blood-donation-camp-4.jpg", category : "Financial"},
    ];

    const conference = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/conference-attended-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/conference-attended-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/conference-attended-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/conference-attended-4.jpg", category : "Financial"},
    ];


    const placement = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/cryoviva-placement-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/cryoviva-placement-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/cryoviva-placement-3.jpg", category : "Financial"},
        {img :"https://admission.mvn.edu.in/wp-content/uploads/2021/09/cryoviva-placement-4.jpg" , category : "Financial"},
    ];
    const fire = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/fire-training-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/fire-training-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/fire-training-3.jpg", category : "Financial"},
    ];

    const free = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/free-health-check-up-camp-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/free-health-check-up-camp-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/free-health-check-up-camp-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/free-health-check-up-camp-4.jpg", category : "Financial"},
    ];


    const healthAwards = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/health-awards-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/health-awards-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/health-awards-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/health-awards-4.jpg", category : "Financial"},
    ];


    const health = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/health-check-up-at-voice-of-india-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/health-check-up-at-voice-of-india-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/health-check-up-at-voice-of-india-3.jpg", category : "Financial"},
    ];

    const pharmacist = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/pharmacist-day-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/pharmacist-day-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/pharmacist-day-3.jpg", category : "Financial"},
    ];


    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Pharmaceutical Sciences" subtitle="Activity" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Industrial Visit to Palwal Sugar Mill</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {current.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>AIDS Awareness Program</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {aids.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Industrial Visit to Vita Dairy</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {vita.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>International Childhood Cancer Day 2022</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {cancer.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Expert Lecture on the Topic Career Pathways in Regulatory Affairs</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {affairs.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Fresher Party Aarambh 2022</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {fresher.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25">                                    
                                    <h3>World Cancer Day 2021</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {worldCancer.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Blood Donation Camp</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {blood.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Conference Attended</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {conference.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Cryoviva Placement</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {placement.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Fire Training</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {fire.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Free Health Check Up Camp</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {free.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Health Awards</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {healthAwards.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Health Check Up at Voice of India</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {health.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Pharmacist Day</h3>
                                </div>
                                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                                    {pharmacist.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PharmaActivity
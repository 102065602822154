import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCIVILHeader from "./header";
const CIVILBTechPlacement=()=>{
return (
<Grid container spacing={2}>
{/* <SOETCIVILHeader/>
        <BreadcrumbsBanner bannerUrl={"https://www.dreamdesigninc.com/wp-content/uploads/2022/09/civilengineering_hero.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Civil Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Training and Placement " />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Our Training and Placement cell is dedicated to unlocking the full potential of our students in the Department of Civil Engineering, ensuring they are not just ready for the job market but poised to excel in it. Through a comprehensive suite of training programs, workshops, and industry interactions, we equip our students with the skills, knowledge, and confidence needed to navigate the competitive tech landscape. Our collaborations with leading tech companies and organizations offer students invaluable opportunities for internships, live projects, and direct exposure to industry practices and innovations. The placement cell works tirelessly to forge strong industry connections, bringing a wide array of recruiters to campus for placements that often result in prestigious job offers. With a strong emphasis on career counselling, soft skills development, and technical proficiency, we prepare our students to make impactful contributions to their chosen fields from the moment they graduate. Our commitment to their success is reflected in our impressive placement records and the thriving careers of our alumni.

    </Typography>
    </Grid>
    </Grid>

)
}
export default CIVILBTechPlacement;
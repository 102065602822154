import React, { useEffect } from "react";

const TrackingScript = () => {
  useEffect(() => {
    // Setting global variables
    window.npf_d = 'https://apply.mvn.edu.in';
    window.npf_c = '6093';
    window.npf_m = '1';

    // Create the script element
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src = "https://track.nopaperforms.com/js/track.js";

    // Function to handle the redirection
    const handleRedirect = () => {
      window.location.href = 'https://apply.mvn.edu.in';
    };

    // Check if the page is being loaded or refreshed
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      // Page is being refreshed, trigger the redirect
      handleRedirect();
    }

    // Redirect after the script has loaded on the first load
    script.onload = handleRedirect;

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array means this will run once after the component mounts

  return null; // This component only handles the script injection and redirect
};

export default TrackingScript;

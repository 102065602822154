import React from 'react'
import { Link } from 'react-router-dom'
// import { Card } from '@mui/material';

const CardStyle = {

  border: 0,
  minHeight: 90,
  margin: 'auto',
  textAlign: 'center',
  padding: '10px',
  backgroundColor: '#fff',
  height: '220px',
  width: '100%',
  marginTop: '50px',
  color:'#000',
  //   [theme.breakpoints.up('md')]: {
  //     boxShadow: 'none',      
  //     backgroundColor: '#fff',
  //     padding: 10,
  //   },
  '& img': {
    width: 'unset',
    margin: '0 auto'
  },

}
function About() {
  const CARDS = [
    {
      icon: 'Logo_of_Bar_Council_of_India.svg',
      title: 'Bar Council of India',

    },
    {
      icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Emblem_of_Haryana.svg/300px-Emblem_of_Haryana.svg.png',
      title: 'Government of Haryana',

    },
    {
      icon: '/Association_of_Indian_Universities_Logo.svg',
      title: 'Association of Indian Universities',

    },

    {
      icon: 'Pharmacy_Council_of_India_Logo.svg',
      title: 'Pharmacy Council of India',

    },
    {
      icon: 'tiap.svg',
      title: 'The Indian Association of Physiotherapists',

    },
    {
      icon: 'ugc.svg',
      title: 'University of Grants Comission',

    },
  ];
  return (
    <>
      <section className="about-area about-p pt-120 pb-30 p-relative fix"  >
        <div className="animations-02">
          <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>

        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-6 col-md-12 col-sm-12">
              {/* <div className="p-relative  wow fadeInLeft animated"  data-animation="fadeInLeft" data-delay=".4s" >
                <div className='row'>
                  <div className="col-lg-6 col-md-12 col-sm-12"> */}
                {/* <img src="/assets/homepage/about1.png" alt="img" style={{ height: '500px',borderRadius:'10px' }} />

                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                  <div  className="about-upper second-about-upper">
                 
                  <p>Join the Excellence</p>
                </div>
                <div className="about2-img">
                <img src="/assets/homepage/about2.png" alt="img" className='about2-img'/>
                </div> */}
                <div className="video-banner">
                <video src="/video_banner.mp4" autoPlay loop muted controls={false}>
                    Your browser does not support the video tag.
                </video>
            </div>
                  </div>
                {/* </div>
               
              </div>
            </div> */}

                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="about-content update-s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                    <div className="about-title second-title pb-25 text-center">
                        {/* <h5>
                        <i className="fal fa-graduation-cap" /> About MVN University
                        </h5> */}
                        <h3>About MVN University </h3>
                    </div>
                    <p >
                    MVN University is a prestigious educational institution committed to providing high-quality education and fostering holistic development in its students. Located in Haryana, India, MVN University offers a diverse range of undergraduate, postgraduate, and doctoral programs across various disciplines.
                    </p>
                    <p>
                    Our Vision: At MVN University, our vision is to be a globally recognised institution that excels in teaching, research, and innovation. We aim to produce highly skilled professionals who can contribute effectively to the society and meet the challenges of the ever-evolving world.
                    </p>
                    {/* <div className="about-content2">
                        <div className="row">
                        <div className="col-md-12">
                            <ul className="green2">
                            <li>
                                <div className="abcontent">
                                <div className="ano">
                                    <span>01</span>
                                </div>{" "}
                                <div className="text">
                                    <h3>Doctoral Degrees</h3>{" "}
                                    <p>
                                    consectetur adipiscing elit sed do eiusmod tem incid
                                    idunt.
                                    </p>
                                </div>
                                </div>
                            </li>
                            <li>
                                <div className="abcontent">
                                <div className="ano">
                                    <span>02</span>
                                </div>{" "}
                                <div className="text">
                                    <h3>Global Students</h3>{" "}
                                    <p>
                                    consectetur adipiscing elit sed do eiusmod tem incid
                                    idunt.
                                    </p>
                                </div>
                                </div>
                            </li>
                            </ul>
                        </div>
                        </div>
                    </div> */}
                {/*<div className="slider-btn mt-20">
                  <Link to="/chancellors-message" className="btn ss-btn smoth-scroll">
                    Read More <i className="fal fa-long-arrow-right" />
                  </Link>
                  </div> */}
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className='row'>
                {CARDS.map((card) => {
                  return (

                    <div className="col-lg-2 col-md-2 col-sm-12">
                      <div className='government-recognization'>
                        <img
                          src={card.icon}
                          alt={card.title}
                          style={{
                            // ...CardStyle,
                            width: 100,
                            height: 120,
                            margin: "0 auto",
                          }}
                        />
                        <p className='update-government-recognization-para'>{card.title}</p>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default About
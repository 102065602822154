import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import First from './first'
import TrackingScript from "./TrackingScript";

import NewMain from '../../headerone/newMain'
function ExaminationPolicy() {
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Admission Form" />
      {/*<First/>  */} 
      <TrackingScript />
      
    </>
  )
}

export default ExaminationPolicy
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBca() {
    return (
        <>
            <Header />
            <Bredcom title="Home/ Computer Science Engineering" subtitle="PEO, PO & PSO – BCA" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                                               
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p>
                                Graduates will be able to apply their knowledge of computing and problem-solving skills to design, develop and maintain software systems that meet customer needs.
                                </p>

                                <p>
                                Graduates will possess professional and ethical values and effective communication skills to work collaboratively in a team-based environment.
                                </p>

                                <p>
                                Graduates will exhibit leadership qualities and take responsibility for their actions, adapt to emerging technologies and learn continuously to contribute to society.
                                </p>
                                <p>
                                Graduates will be able to analyze and interpret data to make informed decisions and address complex issues in the computing domain.
                                </p>
                                <p>
                                Graduates will recognize the importance of interdisciplinary learning, understand the impact of computing on society, and engage in lifelong learning and professional development.
                                </p>

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                Apply mathematical and computational concepts to design, develop and implement software systems that meet customer needs.
                                </p>

                                <p>
                                Analyze problems and use critical thinking skills to develop solutions for complex computing issues.
                                </p>

                                <p>
                                Design, develop and test software systems using appropriate tools and techniques to meet industry standards.
                                </p>

                                <p>
                                Demonstrate knowledge of programming languages, operating systems, and database management systems.
                                </p>

                                <p>
                                Understand the ethical and professional responsibility of computing professionals and apply them to real-world scenarios.
                                </p>

                                <p>
                                Work collaboratively in a team-based environment to design and develop software projects.
                                </p>

                                <p>Effectively communicate technical information to both technical and non-technical audiences.</p>
                                <p>Demonstrate leadership qualities and take responsibility for personal and team actions in project development and management.</p>
                                <p>Adapt to emerging technologies and learn continuously to contribute to the computing domain.</p>
                                <p>Analyze and interpret data using appropriate tools and techniques to make informed decisions.</p>
                                <p>Recognize the impact of computing on society and apply computing solutions to solve social and environmental problems.</p>
                                <p>Engage in interdisciplinary learning and integrate knowledge from different fields to solve complex computing problems.</p>
                                <p>Pursue lifelong learning and professional development opportunities to keep up with evolving technologies and industry trends.</p>
                               

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                Graduates will be able to write efficient, maintainable, and well-documented code in different programming languages.
                                </p>

                                <p>
                                Graduates will possess the ability to analyze, design, and develop software solutions for real-world problems using software engineering principles and practices.
                                </p>

                                <p>
                                Graduates will be able to apply their knowledge of algorithms, data structures, and software design patterns to develop efficient and scalable software systems.
                                </p>

                                                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBca
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSAlumni=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Business Management and Commerce" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Our Alumni" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Our alumni stand as living testaments to the unwavering spirit and excellence embodied by the School of Business Management and Commerce at MVN University. Spanning the globe, they have established themselves as pioneers in every corner of the business world, from leading multinational corporations to pioneering startups. This section is dedicated to celebrating their remarkable achievements, sharing their inspiring journeys, and fostering connections within our expanding network of alumni. Through their accomplishments, our graduates not only showcase the caliber of education and guidance provided by our school but also serve as inspirational figures for current and future students. Their impact on business, leadership in diverse industries, and ongoing involvement with our institution enrich our community, cultivating a legacy of innovation and distinction. Here, we also offer details on alumni gatherings, networking opportunities, and avenues for giving back, ensuring that our graduates remain an integral part of our evolving narrative.
    <br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSAlumni;
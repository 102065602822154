import React from 'react'
import Slider from "react-slick";
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={ `slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-right"></i></button>
    );
}
  
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
    <button type="button" className={ `slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="fal fa-angle-left"></i></button>
      
    );
}
// import { Avatar } from '@mui/material';
function Testimonial() {

  

    let settings = {
        dots: true,
        infinite: true,
        arrows: true,
        prevArrow: <SamplePrevArrow/>,
        nextArrow: <SampleNextArrow/>,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 2,
        autoplay: true, 
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true,
                    arrows: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    
    return (
        <>
            <section className="testimonial-area pt-60 pb-0 p-relative fix">
                <div className="animations-01">
                    <img src="/assets/img/bg/an-img-03.png" alt="an-img-01" />
                </div>
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-04.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center mb-50 wow fadeInDown animated" data-animation="fadeInDown" data-delay=".4s" >
                                {/* <h5>
                                    <i className="fal fa-graduation-cap" /> Student Testimonial
                                </h5> */}

                                <h3>“Student testimonial or Alumni speech”</h3>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <Slider className="testimonial-active wow fadeInUp animated" data-animation="fadeInUp" data-delay=".4s" {...settings}>

                                <div className="single-testimonial text-center">
                                    <div className=" color color-theme1"></div>
                                    {/* <div className="qt-img">
                                    <img src="/assets/img/testimonial/qt-icon.png" alt="img" />
                                </div> */}
                                    <div className="testi-author">
                                        <img className='avatar' style={{ borderRadius: '50%' }} src="https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Ravi_Kumar.jpg" alt="img" />

                                    </div>
                                    <div className="ta-info">
                                        <h6>Ravi Kumar</h6>
                                    </div>
                                    <cite>
                                        <p>
                                            MBA, 2022-23												</p>
                                        <p><strong>Placed In :</strong> Somany Impresa (Hindware)  </p>
                                    </cite>
                                    <p className='update-testimonial-para'>
                                        I am delighted to share that I am all set to join Deloitte USI as Tax Consultant 1. I would like to thank whole placement team for giving me this opportunity.

                                        Credit of my success goes to my parents and all other family members.

                                        I owe a debt of gratitude to all factuality members of MVN Universit,  it would not have been possible without their continuous support. I am thankful to all teaching as well non-teaching staff for adding value in my life. Apart from studies, environment of MVN University helped me to become a responsible man from an irresponsible boy.

                                        Last but not the least I would like to thank my friends and all other person who supported me and believed in me when I was down.
                                    </p>
                                </div>

                                <div className="single-testimonial text-center">
                                    <div className=" color color-theme3"></div>

                                    <div className="testi-author ">
                                        <img className='avatar' style={{ borderRadius: '50%' }} src="	https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Satish_Kumar.jpg" alt="img" />

                                    </div>
                                    <div className="ta-info">
                                        <h6>Satyam Kumar</h6>
                                    </div>
                                    <cite>
                                        <p>
                                            MBA – Banking & Finance ..., 2022-23	</p>
                                        <p><strong>Placed In :</strong> ICICI Bank Ltd.  </p>
                                    </cite>
                                    <p className='update-testimonial-para'>
                                        It was great experience studying at MVN University and lifetime memory. Being at a global institution allows me to interact with a variety of international students. The MVN University mentors assisted us in improving our academic and social abilities. I also miss my university , my faculty members and my friends.
                                    </p>
                                </div>

                                <div className="single-testimonial text-center">
                                    <div className=" color color-theme1"></div>

                                    <div className="testi-author ">
                                        <img className='avatar' style={{ borderRadius: '50%' }} src="https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Aadhya_Chaturvedi.jpg" alt="img" />

                                    </div>
                                    <div className="ta-info">
                                        <h6>Aadhya Chaturvedi</h6>
                                    </div>
                                    <cite>
                                        <p>
                                            MBA – Business Analytics, 2022-23												</p>
                                        <p><strong>Placed In :</strong> ICICI Bank Ltd.  </p>
                                    </cite>
                                    <p className='update-testimonial-para'>
                                        In my experience all the teachers are very supportive and friendly and the placement process has been very smooth throughout. I would always be very grateful for the lifelong connections I made
                                    </p>
                                </div>

                                <div className="single-testimonial text-center">
                                    <div className=" color color-theme3"></div>

                                    <div className="testi-author ">
                                        <img className='avatar' style={{ borderRadius: '50%' }} src="https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Shoib_Nabi_Wani.JPG" alt="img" />

                                    </div>
                                    <div className="ta-info">
                                        <h6>Shoaib Wani</h6>
                                    </div>
                                    <cite>
                                        <p>
                                            MBA – Banking & Finance ..., 2022-23												</p>
                                        <p><strong>Placed In :</strong> YES BANK Ltd.  </p>
                                    </cite>
                                    <p className='update-testimonial-para'>
                                        My name is Shoaib Wani & Im from Kashmir. Im studying MBA at MVN University. MVN is one of the best private institutions in India. I must say that MVN University has world class infrastructure for experience and learning. My teachers are well qualified, inspirational and are there as mentors to guide me. MVN University is a secure and happening campus, apart from the academic lifestyle there is recreation activities inside the campus that has helped me evolve. I have met my fellow students from different countries and have got to learn about Diversified culture. Im thankful to Training & Placement cell for providing a platform to enhance my skills and an opportunity to showcase them

                                        The world is here at MVN University.
                                    </p>
                                </div>

                                <div className="single-testimonial text-center">
                                    <div className=" color color-theme1"></div>

                                    <div className="testi-author ">
                                        <img className='avatar' style={{ borderRadius: '50%' }} src="https://www.sharda.ac.in/attachments/testimonial_images/thumbnail/Satyam_Yadav.jpg" alt="img" />

                                    </div>
                                    <div className="ta-info">
                                        <h6>Satyam Yadav</h6>
                                    </div>
                                    <cite>
                                        <p>
                                            MBA – Marketing & Advisor  2022-23												</p>
                                        <p><strong>Placed In :</strong> Somany Impresa (Hindware)  </p>
                                    </cite>
                                    <p className='update-testimonial-para'>
                                    works towards placement and a lot of renowned companies come to campus for placements. Most of the students get placement with a good package (CTC) and at good positions where they can perform well which helps in growth of the firm as well as the career. I got placed at Somany Impressa at the package of 6.5 LPA and I'm thankful to the placement department and the professors for making me capable of this.
                                    </p>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonial
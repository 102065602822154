import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography } from '@mui/material';
import MenuHeaderTop from './newTopHeader';
import MenuWidgets from './MenuWidgets';
function NewMain() {

    const [Btnshow, setBtnshow] = useState(false);
    const [mobile, setmobile] = useState(false);
    const [Events, setEvent] = useState(false);
    const [News, setNews] = useState(false);
    const [Services, setServices] = useState(false);
    const [Blog, setblog] = useState(false);

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            setIsScrolled(scrollTop > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerMenus = [
        {
            mainHeader: 'Home', path: '/', subHeader: []
        },

        {
            mainHeader: 'About', subHeader: [
                { title: 'Chancellor’s Message', path: '/chancellors-message', },
            { title: 'Vice Chancellor’s Message', path: '/vice-chancellor-message' },
            { title: 'Pro Vice Chancellor Message', path: '/pro-vice-chancellor-message' },
            { title: 'Why MVN', path: '/why-mvn' },
            { title: 'Government recognitions', path: '/govermentReconigations' },
            { title: 'Infrastructure', path: '/infrastructure' },
            ], lastHeader: []

        },
        {
            mainHeader: 'Academics', subHeader: [
                { title: 'School of Engineering & Technology', path: '/school-of-engineering-technology' },
                { title: 'School of Law', path: '/school-of-law/home' },
                { title: 'School of Allied Health Sciences', path: '/school-of-allied-health-sciences/home' },
                { title: 'School of Pharmaceutical Sciences', path: '/school-Of-pharmaceutical-sciences/home' },
                { title: 'School of Business Management & Commerce', path: '/school-of-business-management-and-commerce/home' },
                { title: 'School of Science & Humanities', path: '/school-of-arts-science-humanities/home' },
                { title: 'Faculty Accomplishments/Certifications', path: '/faculty-accomplishments-certifications' },
            ]
        },

        // { mainHeader: 'IQAC', path: '/iqac', subHeader: [] },
        {
            mainHeader: 'Admissions',
            subHeader: [
                { title: 'Admission policy', path: '/application-policy' },
                { title: 'Application Form', path: '/admission-form' },
                { title: 'Fee Structure', path: '/fee-structure' },
                { title: 'Bank Account Details', path: '/bank-account-details' }]
        },

        {
            mainHeader: 'Fee Payment',
            subHeader: [
                { title: 'Fee Payment', path: 'https://feeportal.mvn.edu.in/' },
                { title: 'QR Code', path: '/qr_payment' }]
        }, 
        {
            mainHeader: 'Student Activities', path: '/sudent_activities', subHeader: []
        },
        {
            mainHeader: 'Research & Development', path: '/research_development/home', subHeader: [
                 { title: 'About RDC', path: '/research_development/home' },
                { title: 'About Dean RDC', path: '/research_development/about_dean_rdc' },
                { title: 'List of Ph.D. Students', path: '/research_development/list-of-ph-d-research-scholars' },
                /* { title: 'Publications', path: '#' }, */
                {
                    title: 'Publications', path: '/publications/school_alied_health_sciences' , lastHeader: [
                        { title: 'Working paper series', path: '/publications/working-paper-series' }, 
                        { title: 'Call for Papers', path: '/publications/call-for-papers' },  
                    ]
                },

                { title: 'Patents', path: '#' },
                { title: 'Downloads', path: '/research_development/downloads' }, 
                /*{
                    title: 'Doctorate Research', lastHeader: [
                        { title: 'Ph.D Program', path: '/doctoral-programs' }, 
                        { title: 'Ph.D Guidelines', path: '/ph-d-guidelines' }, 
                        { title: 'Ph.D. Awarded till March 2023', path: '/ph-d-awarded' },
                        { title: 'List of Ph.D Research Scholars', path: '/list-of-ph-d-research-scholars' }
                    ]
                },
                { title: 'Faculty Research', path: '/faculty-research' },
                { title: 'R & D Cell', path: '/r-d-cell' },
                { title: 'Ph.D Admission Form', path: '/phd-admission-form' },  */
            ]
        },
        {
           /* mainHeader: 'Life at MVN', path: '#', subHeader: []*/
           mainHeader: 'Life at MVN', path: '/life-at-mvn', subHeader: []
        },
        {
            mainHeader: 'Placements', path: '' , subHeader: [
                { title: 'The Corporate Resource Centre ', path: '/the-corporate-resource-centre' },
                { title: 'Team CRC ', path: '/team-crc' },
                { title: 'Students’ Centre - Upcoming Job Openings', path: '/students-centre-upcoming-job-openings' },
                { title: 'Corporate Laison', path: '/corporate-laison' },
                
            ]
        },
        {
            mainHeader: 'Examination', path: '/examination' , subHeader: [
                { title: 'Examination scheme', path: '/examination-scheme' },
                { title: 'Examination Committee', path: '/examination-committee' },
                { title: 'Notices', path: '/examnotice' },
                { title: 'Examination Date sheet', path: '/examination-date-sheet' },
                { title: 'Re-Evaluation ', path: '/examination-re-evaluation' },
                { title: 'Results', path: '/examination-results' },
                { title: 'National Academic Depository (NAD)', path: '/national-academic-depository-nad' },
                { title: 'Acedamic bank of Credit', path: '/acedamic-bank-credit' },
                /*{ title: 'Examination Policy', path: '/examination-policy' },
                
                { title: 'Branch of Examination Cell', path: '/branch-examination-cell' } */
                // { title: 'Evaluation Scheme', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/05/Evaluation-Scheme.pdf' },
                // { title: 'Branch of Examination Cell ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/07/Branches-of-Examination-Cell.pdf' },
            ]
        },
        {
            mainHeader: 'Mandatory Disclosure', subHeader: [

                { title: 'Info submitted to UGC', path: 'https://admission.mvn.edu.in/wp-content/uploads/2021/09/information-submitted-to-ugc.pdf' },
                // { title: 'Student Grievance Committee', path: '/' },                     

                { title: 'Statutes ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Statutes.pdf' },
                { title: 'Establishment & Act', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Establishment-Act.pdf' },
                { title: 'Anti-Ragging Information', path: '/anti-ragging-information' },

            ]

        },
        {
            mainHeader: 'IQAC', path: '', subHeader: [
                { title: 'IQAC', path: '/iqac' },

                { title: 'NEP ', path: '/nep' },
            ]
        },

        // { mainHeader: 'Ombudsman ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/06/Ombudsman.pdf', subHeader: [] },
        // {
        //     mainHeader: 'Committees', subHeader: [
        //         { title: 'Student Discipline Committee', path: '/StudentDisciplineCommittee' },
        //         { title: 'Student Grievance Form', path: '/student-grievance-form' },
        //         { title: 'University Students’ Grievances Redressal Committee', path: '/UniversityStudentsGrievancesRedressalCommittee' },
        //         { title: 'Internal Complaints Committee', path: '/InternalComplaintsCommittee' },
        //         { title: 'Women Cell', path: '/WomenCell' },
        //         { title: 'Games and Sports Committee', path: '/GamesandSportsCommittee' },
        //         { title: 'Anti-Ragging Committees', path: '/AntiRaggingCommittees' },
        //         { title: 'Cultural Committee', path: '/CulturalCommittee' },
        //         { title: 'Canteen Committee', path: '/CanteenCommittee' },
        //         { title: 'Purchase Committee', path: '/PurchaseCommittee' },
        //     ]
        // },





        // { mainHeader: 'Careers', path:'/career' , subHeader: [] },
        // { mainHeader: 'Club@mvnu', path:'/' , subHeader: [] },
        // { mainHeader: 'Mandatory Compliances', path:'/' , subHeader: [] },
        // {
        //     mainHeader: 'Others', subHeader: [                
        //         {
        //             title: 'CRC/Placement', lastHeader: [
        //                 { title: 'Corporate Resource Center', path: '/crc' },
        //                 { title: 'Health Camp / Industrial Visits', path: '/health-campus' },
        //                 { title: 'Industry Expert Talks', path: '/industry-export' },
        //                 // { title: 'Our Proud Alumni', path: '/' },
        //                 { title: 'Our Recruiters', path: '/our-recruiters' },
        //                 { title: 'Student Testimonials', path: '/students-testimonials' },
        //                 { title: 'Placement at Glance', path: '/placement-at-a-glance' },
        //             ]
        //         },
        //         {
        //             title: 'Mandatory Disclosure', lastHeader: [
        //                 { title: 'Info submitted to UGC', path: 'https://admission.mvn.edu.in/wp-content/uploads/2021/09/information-submitted-to-ugc.pdf' },
        //                 // { title: 'Student Grievance Committee', path: '/' },                     

        //                 { title: 'Statutes ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Statutes.pdf' },
        //                 { title: 'Establishment & Act', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Establishment-Act.pdf' },
        //             ]
        //         },
        //         {
        //             title: 'Mandatory Compliances', path: '/'
        //         },
        //         {
        //             title: 'Research ', lastHeader: [
        //                 {
        //                     title: 'Doctorate Research', childHeader: [
        //                         // { title: 'Ph.D Program', path: '/doctoral-programs' },
        //                         // { title: 'Ph.D. Admissions 2023-24', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Ph.D.-2023-24-SUMMER-ADVERT.pdf' },
        //                         { title: 'Ph.D Guidelines', path: '/ph-d-guidelines' },
        //                         // { title: 'Ph.D Admission Form 2023-24 (SUMMER)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/07/Ph.D-Admission-Form-2023-24-SUMMER.pdf' },
        //                         { title: 'Ph.D. Awarded till March 2023', path: '/ph-d-awarded' },
        //                         { title: 'List of Ph.D Research Scholars', path: '/list-of-ph-d-research-scholars' }
        //                     ]
        //                 },

        //                 { title: 'Faculty Research', path: '/faculty-research' },
        //                 { title: 'R & D Cell', path: '/r-d-cell' },
        //                 { title: 'Ph.D Admission Form', path: '/phd-admission-form' },
        //             ]
        //         },
        //         { title: 'IQAC', path: '/iqac' },
        //         { title: 'CUET', path: '/cuet-scholarship' },
        //         { title: 'NEP Scheme ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/05/FYUGP-BSC-CS-HONS-NEP-2020.pdf' },
        //         { title: 'Ordinances ', path: 'https://admission.mvn.edu.in/wp-content/uploads/2021/09/ordinances-and-regulations-of-undergraduate-programmes.pdf' },
        //         {
        //             title: 'NIRF', lastHeader: [
        //                 { title: 'MVN University,Palwal(2022-23)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/02/MVN-University-Palwal.pdf' },
        //                 { title: 'MVN University,Palwa — Pharmal(2022-23)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2023/02/MVN-University-Palwal-Pharmacy.pdf' },
        //                 { title: 'MVN University,Palwal(2021-22)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal.pdf' },
        //                 { title: 'MVN University,Palwal -- Pharma(2021-22)', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal-Pharmacy.pdf' },
        //                 { title: 'MVN University,Palwal - Law', path: 'https://admission.mvn.edu.in/wp-content/uploads/2022/03/M-V-N-University-Palwal-Law.pdf' },
        //             ]
        //         },
        //     ]
        // },
        // {
        //     mainHeader: 'Contact Us', path: '/contact', subHeader: []
        // }

    ]

    return (
        <>
            <MenuHeaderTop />
            <header className="header-area update-header-three">
                <div id="header-sticky" className={`menu-area-update${isScrolled ? 'menu-area sticky-menu' : ''}`}>
                    <div className="container-fluid">
                        <div className="second-menu">
                            <div className="row align-items-center">
                                <div className="col-xl-12 col-lg-12 new_heaad_width">
                                    <MenuWidgets headerMenus={headerMenus} color="#12265a" />
                                </div>
                                {/* <div className="col-xl-2 col-lg-2">
                                    <div className="logo">
                                        <Link to="/mui-home">
                                            <img src="/assets/img/logo/mvn-university-logo.png" alt="logo" height='48px' width='auto' />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-8 col-lg-8">
                                    <div className="main-menu text-center" style={{marginRight:'auto'}}>
                                        <nav id='mobile-menu'>
                                            <ul>
                                                {headerMenus?.map((menus) => {
                                                    return (
                                                        <li className="has-sub">
                                                            <Link to={menus?.path}><Typography fontSize="15px" fontWeight="600">{menus?.mainHeader} </Typography>
                                                               
                                                            </Link>
                                                            <ul style={{ display: menus?.subHeader?.length == 0 && 'none' }}>
                                                                {menus?.subHeader.map((subhead) => {
                                                                    return (
                                                                        <li className="has-sub">
                                                                            <Link to={subhead?.path}><Typography fontSize="14px" fontWeight="600">{subhead?.title}</Typography>  
                                                                              
                                                                            </Link>
                                                                            <ul style={{ display: !subhead?.lastHeader && 'none' }}>
                                                                                {subhead?.lastHeader?.map((lasthead) => {
                                                                                    return (
                                                                                        <li className="has-sub" >
                                                                                            <Link to={lasthead?.path}><Typography fontSize="14px" fontWeight="600">{lasthead?.title}  </Typography>
                                                                                               
                                                                                            </Link>
                                                                                            <ul style={{ display: !lasthead?.childHeader && 'none',overflow: 'auto' }}>
                                                                                                {lasthead?.childHeader?.map((childhead) => {
                                                                                                    return (
                                                                                                        <li>
                                                                                                            <Link to={childhead?.path}><Typography fontSize="14px" fontWeight="600">{childhead?.title}</Typography></Link>
                                                                                                        </li>
                                                                                                    )
                                                                                                })}
                                                                                            </ul>
                                                                                        </li>
                                                                                    )
                                                                                })}
                                                                            </ul>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-lg-2  d-none d-lg-block">
                                    <div className="login">
                                        <ul>
                                            <li>
                                            <Link to="/admission-form" >  <Button size="medium" variant="contained" color='error'>admission open </Button></Link>
                                             
                                            </li>
                                        </ul>
                                    </div>
                                </div> */}
                                <div className="col-12">
                                    <div className="mobile-menu mean-container">
                                        <div className="mean-bar" >
                                            <a href="#nav" onClick={() => setmobile(!mobile)} className={`meanmenu-reveal ${mobile && "meanclose"}`} style={{ right: 0, left: "auto", textAlign: "center", textIndent: 0, fontSize: 18 }} >
                                                {mobile ? "X" : <span><span><span></span></span></span>}
                                            </a>
                                            {mobile &&
                                                <nav className="mean-nav" style={{ height: '600px', overflow: 'auto' }}>
                                                    <ul >
                                                        {headerMenus?.map((menus) => {
                                                            return (
                                                                <li className="has-sub">
                                                                    <Link to={menus?.path}>{menus?.mainHeader}
                                                                        {/* <i className={menus?.subHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                    </Link>
                                                                    <ul style={{ display: menus?.subHeader?.length == 0 && 'none' }}>
                                                                        {/* {menus?.subHeader.map((subhead) => {
                                                                            return (
                                                                                <li>
                                                                                    <Link to={childhead?.path}>{subhead?.title}</Link>
                                                                                </li>
                                                                            )
                                                                        })} */}
                                                                        {menus?.subHeader.map((subhead) => {
                                                                            return (
                                                                                <li className="has-sub" >
                                                                                    <Link to={subhead?.path}>{subhead?.title}
                                                                                        {/* <i className={subhead?.lastHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                    </Link>
                                                                                    <ul style={{ display: !subhead?.lastHeader && 'none' }}>
                                                                                        {subhead?.lastHeader?.map((lasthead) => {
                                                                                            return (
                                                                                                <li className="has-sub">
                                                                                                    <Link to={lasthead?.path}>{lasthead?.title}
                                                                                                        {/* <i className={lasthead?.childHeader?.length > 0 && "fa fa-angle-down"}></i> */}
                                                                                                    </Link>
                                                                                                    <ul style={{ display: !lasthead?.childHeader && 'none' }}>
                                                                                                        {lasthead?.childHeader?.map((childhead) => {
                                                                                                            return (
                                                                                                                <li>
                                                                                                                    <Link to={childhead?.path}>{childhead?.title}</Link>
                                                                                                                </li>
                                                                                                            )
                                                                                                        })}
                                                                                                    </ul>
                                                                                                </li>
                                                                                            )
                                                                                        })}
                                                                                    </ul>
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                </nav>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {Btnshow &&
                <>
                    <div className={`offcanvas-menu ${"active"}`}>
                        <span className="menu-close" onClick={() => setBtnshow(false)}>
                            <i className="fas fa-times" onClick={() => setBtnshow(false)} />
                        </span>
                        <form role="search" method="get" id="searchform" className="searchform" action="#" >
                            <input type="text" name="s" id="search" placeholder="Search" />
                            <button>
                                <i className="fa fa-search" />
                            </button>
                        </form>

                    </div>
                    <div className={`offcanvas-overly ${"active"}`}> </div>
                </>
            }
        </>
    )
}

export default NewMain;
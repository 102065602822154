import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AcademicButton from '../academicButton';

const faqItems = [
    {
        id: 'headingThree',
        question: '01 What academic programs are offered by the School of Pharmaceutical Sciences, and how do they prepare students for careers in the pharmaceutical industry?',
        answer: 'The School of Pharmaceutical Sciences offers comprehensive degree programs that cover various aspects of pharmaceutical science, including pharmaceutical technology, medicinal chemistry, pharmacology, and regulatory affairs. Our curriculum combines foundational knowledge with hands-on laboratory experiences, equipping students with a strong scientific background and practical skills. Graduates are well-prepared to pursue careers in drug development, research, quality control, and pharmaceutical regulation.',
    },
    {
        id: 'headingOne',
        question: '02 How does the School of Pharmaceutical Sciences contribute to cutting-edge research and innovation in the field of pharmaceuticals?',
        answer: ' Our School is committed to fostering a culture of research and innovation. Faculty members actively engage in groundbreaking research projects, ranging from drug discovery to formulation optimization. Students have the opportunity to collaborate on research initiatives, access state-of-the-art laboratories, and contribute to advancements in pharmaceutical science. This exposure empowers graduates to contribute to the development of new therapies, drug delivery systems, and healthcare solutions.',
    },
    {
        id: 'headingTwo',
        question: '03 Are there opportunities for students to gain practical experience through internships or industry partnerships?',
        answer: 'Absolutely. The School of Pharmaceutical Sciences prioritizes practical training and industry engagement. Students have access to internships with pharmaceutical companies, research institutions, and regulatory agencies. These hands-on experiences provide valuable insights into real-world pharmaceutical operations, regulatory compliance, and quality assurance. Through our industry partnerships, students also benefit from guest lectures, workshops, and networking opportunities that bridge academia and the pharmaceutical sector.',
    },
];

function First() {
    return (
        <>
            <section className="project-detail">
                <div className="container">
                    <AcademicButton path='/school-of-pharmaceutical-sciences' pageName="School of Pharmaceutical Sciences"/>
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                {/* <h2>School of Pharmaceutical</h2> */}
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme industryImg">
                                        <img src="https://admission.mvn.edu.in/wp-content/uploads/2021/08/sps-banner-1.jpg" alt="" />
                                    </div>
                                </div>
                                <div className="inner-column">

                                    <h3>Course Overview</h3>
                                    <p>
                                        School of Pharmaceutical Sciences (SPS) started its first batch of B.Pharm course( Duration 4 Years ) in the year 2015 & D.Pharm course ( Duration 2 Years) in 2016 and has been successfully marching ahead since inception. It is one of the top fastest growing leading pharmacy institution in North India providing Air conditioned class rooms, Wi fi enabled campus & more than 5000 books in pharmacy library with special emphasizes on practical exposure to the students with facilities available such as Industrial Pharmacy Lab, Pharmacology labs, Pharmacognosy labs & Pharmaceutical chemistry labs fully loaded with latest models of machineries and instruments which is very useful from the research point of view along with 125 plants available in the world class medicinal herbal garden. SPS is one amongst the many successful ventures of MVN UNIVERSITY, PALWAL (HARYANA) which has a reputation for pioneering in pursuit of academic excellence. SPS in pursuit of its vision will provide outstanding educational experience, in Pharmacy and Practice in a supportive environment of scholarship, research and integrity.
                                    </p>
                                    <p>
                                        Pharmacy profession demands strict adherence to a set of ethical principles. Our aim is to ensure the provision of high quality value – based education with an international focus while maintaining utmost professionalism, integrity and ethics. Our faculty best from all over the field are present here to offer their total commitment for the benefit of the student community. A host of amenities are made available for academic pursuers. Ideally located college and hostels help to foster a conducive atmosphere for studies.
                                        The students of SPS are sure to have a rich learning experience, good research environs, and valuable guidance by renowned faculty and support by various national and international forums and pharmaceutical industry.
                                    </p>
                                    <p>
                                        The students of SPS are sure to have a rich learning experience, good research environs, and valuable guidance by renowned faculty and support by various national and international forums and pharmaceutical industry.
                                    </p>

                                    <p>
                                        With extreme pleasure, I on behalf of School of Pharmaceutical sciences, MVN UNIVERSITY, Palwal welcome all the aspirants to our University. Pharmaceutical Science as a field is known to be highly competitive and demanding and is always on a lookout for professionals equipped with contemporary skills. In order to meet the new age demands, SPS is continuously upgrading the facilities and infrastructure so as to enable the faculty to tune the students to prepare themselves for the challenging opportunities available in pharmaceutical sector. The faculty boasts of highly qualified and experienced teachers and the department has a very conductive academic environment. Our students are continuously gaining recognition in various facets of the profession and are making their family, institution and the society proud. I hope they continue to strive harder and perform better in the years to come to complete with the current needs in pharmaceutical industry.
                                    </p>

                                    <p>
                                        I extend a warm invitation to all aspirants of pharmacy education to be a part of this endeavor.
                                    </p>
                                    <h4>Vision & Mission</h4>
                                    <p>
                                        To be a leader in pharmaceutical sciences & pharmacy practice education, training, research and continuous professional development for pharmacists and pharmaceutical scientists providing competent patient care and nurturing drug discovery and development.
                                    </p>
                                    <p>
                                        To impart knowledge, develop skills and competencies in students in pharmaceutical sciences and pharmacy practice To develop and advance the knowledge, attitude and skills of pharmacists and faculty members who can provide comprehensive pharmaceutical care to patients, improve patient outcomes, and meet societal needs for safe and effective drug therapy To develop, promote and nurture research activities in pharmaceutical sciences and pharmacy practice and translating research into healthcare.
                                    </p>
                                    <ul className="pr-ul">
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Pharmacology
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Pharmaceutical Chemistry
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Pharmaceutics
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Clinical Pharmacy
                                            </div>
                                        </li>
                                    </ul>

                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Courses Offered</th>
                                                <th>Length</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Link to="/bpharm">B Pharm</Link></td>
                                                <td>4 Years full time</td>
                                            </tr>
                                            <tr>
                                                <td><Link to="/dpharm">D Pharm</Link></td>
                                                <td>2 years full time</td>
                                            </tr>
                                            <tr>
                                                <td><Link to="/mpharm">M Pharm</Link></td>
                                                <td>2 years full time</td>
                                            </tr>

                                        </tbody>
                                    </table>

                                    <h3>Students Details</h3>

                                    <p><Link to="https://www.admission.mvn.edu.in/wp-content/uploads/2022/03/D.-Pharmacy-2020-2021.pdf"> <strong>D. Pharmacy (2020-2021)</strong> </Link></p>
                                    <p><Link to="https://www.admission.mvn.edu.in/wp-content/uploads/2022/03/D.-Pharmacy-2021-2022.pdf"> <strong>D. Pharmacy (2021-2022)</strong> </Link></p>
                                    <p><Link to="https://www.admission.mvn.edu.in/wp-content/uploads/2022/03/B.-Pharm-2020-2021.pdf"> <strong>B. Pharm (2020-2021)</strong> </Link></p>
                                    <p><Link to="https://www.admission.mvn.edu.in/wp-content/uploads/2022/03/B.-Pharmacy-2021-2022.pdf"> <strong>B. Pharmacy (2021-2022)</strong> </Link></p>
                                    <p><Link to="https://www.admission.mvn.edu.in/wp-content/uploads/2022/03/B.-Pharm-Leet-2021-2022.pdf"> <strong>B. Pharm Leet (2021-2022)</strong> </Link></p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3 ">
                                        <h3>About Us</h3>
                                        <ul className="project-info">
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-pharmaceutical-sciences-vision-mission"> <strong>Vision & Mission</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/08/peo-po-b-pharm.pdf"><strong>PEO & PO B.Pharm</strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/08/peo-po-d-pharm.pdf"> <strong>PEO & PO D.Pharm</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-pharmaceutical-sciences-scope-of-pharmacy"> <strong>Scope of Pharmacy</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-pharmaceutical-sciences-faculty"> <strong>Faculty</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/03/Committees.pdf"><strong>Committees</strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/08/curriculum-of-b-pharm.pdf"><strong>SOS/Curriculum of B.Pharm</strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/08/curriculum-of-d-pharm.pdf"><strong>SOS/Curriculum of D.Pharm</strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-pharmaceutical-sciences-activity"> <strong>Activity</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-pharmaceutical-sciences-media"> <strong>Media</strong> </Link>
                                            </li>

                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-pharmaceutical-sciences-activity-videos"> <strong>Activity Videos</strong> </Link>
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                        Enroll <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
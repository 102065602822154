import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BPharm() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B Pharm" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                School of Pharmaceutical Sciences (SPS) started its first batch of B.Pharm course( Duration 4 Years ) in the year 2015 & D.Pharm course ( Duration 2 Years) in 2016 and has been successfully marching ahead since inception. It is one of the top fastest-growing leading pharmacy institution in North India providing Air-conditioned classrooms, Wi-fi enabled campus & more than 5000 books in pharmacy library with special emphasis on practical exposure to the students with facilities available such as Industrial Pharmacy Lab, Pharmacology labs, Pharmacognosy labs & Pharmaceutical chemistry labs fully loaded with latest models of machinery and instruments which is very useful from the research point of view along with 125 plants available in the world-class medicinal herbal garden. SPS is one amongst the many successful ventures of MVN UNIVERSITY, PALWAL (HARYANA) which has a reputation for pioneering in pursuit of academic excellence. SPS in pursuit of its vision will provide an outstanding educational experience, in Pharmacy and Practice in a supportive environment of scholarship, research and integrity.
                                </p>

                                <p>
                                Pharmacy profession demands strict adherence to a set of ethical principles. Our aim is to ensure the provision of high-quality value-based education with an international focus while maintaining utmost professionalism, integrity, and ethics. Our faculty best from all over the field are present here to offer their total commitment for the benefit of the student community. A host of amenities are made available for academic pursuers. Ideally located college and hostels help to foster a conducive atmosphere for studies.
                                </p>

                                <p>
                                The students of SPS are sure to have a rich learning experience, good research environs, and valuable guidance by renowned faculty and support by various national and international forums and pharmaceutical industry.
                                </p>
                                
                               
                                <p>
                                MVN UNIVERSITY, Palwal welcome all the aspirants to our University. Pharmaceutical Science as a field is known to be highly competitive and demanding and is always on a lookout for professionals equipped with contemporary skills. In order to meet the new age demands, SPS is continuously upgrading the facilities and infrastructure so as to enable the faculty to tune the students to prepare themselves for the challenging opportunities available in the pharmaceutical sector. The faculty boasts of highly qualified and experienced teachers and the department has a very conducive academic environment. Our students are continuously gaining recognition in various facets of the profession and are making their family, institution and the society proud. I hope they continue to strive harder and perform better in the years to come to complete with the current needs in the pharmaceutical industry. I extend a warm invitation to all aspirants of pharmacy education to be a part of this endeavor.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BPharm
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

const Legal_aid=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="Legal Aid Clinic" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Welcome to our The primary objective of the Legal Aid Clinic at the School of Law is to empower the communities residing near the campus. It seeks to address their issues by utilizing the law as a means of social engineering, to find long-lasting solutions. Both societies are well-organized and divided into different initiatives that try to address a wide variety of challenges faced by community members.
</Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Our objective is to implement the policy and guidance provided by the National Legal Services Authority. The Clinic's objective is to offer pro bono legal assistance to those in need and those belonging to marginalized communities. Additionally, it seeks to promote social welfare by raising awareness among people about their legal rights and responsibilities. The organization runs multiple Legal Literacy Camps to promote legal knowledge among marginalized communities through effective engagement. The objective is to provide practical legal knowledge to students by offering them opportunities to understand the practical aspects of the field.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Goals:" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
      <ul>
<li>Promote legal education by organizing legal Aid/ Awareness Camps. </li>
<li>Paralegal services by the faculty and the students. </li>
<li>Providing legal assistance to marginalized individuals and communities who have challenges in accessing the legal system.</li>
</ul>
<br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default Legal_aid;
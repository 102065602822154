import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';


const HomegalleryCompo = ({ notificationList }) => {


  const options = {
    margin: 10,
    responsiveClass: true,
    nav: false,
    dots: true,
    loop: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };


  return (
    <>
      

<OwlCarousel className='owl-theme' loop margin={10} nav {...options}>
     

    {notificationList.map((item, index) => (
        <div className="item" key={index}>
          <div className="one_home_gallery">
              <div class="one_gal_image">
                  <img src={item.src} alt={item.title} className="img-fluid" />
              </div>

              <div class="one_gal_cntent">
                <h4><Link to={item.link}>{item.title} </Link> </h4>
              </div>
 
          </div>
        </div>
      ))}

    
    </OwlCarousel>;
    </>
  );
};

export default HomegalleryCompo;

import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import { Link } from 'react-router-dom'
import NewMain from '../headerone/newMain'
function IQAC() {
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="IQAC" />
      <section className="about-area about-p pt-120 pb-120 p-relative fix">
        <div className="animations-02">
          <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row">
            
            <div className="text-column col-lg-8 col-md-12 col-sm-12">
              <div className="about-content s-about-content pl-15 wow fadeInLeft  animated" data-animation="fadeInLeft" data-delay=".4s" >
                <div className="about-title second-title pb-25">
                  <h3>About Internal Quality Assurance Cell (IQAC)</h3>
                </div>
                <p >
                In pursuance of the National Action Plan of the National Assessment and Accreditation Council (NAAC), Bangalore, the Internal Quality Assurance Cell (IQAC) was constituted in MVN University with the aim of working towards realization of the goals of quality enhancement through internalization of quality culture and institutionalization of best practices. It is an integral part of the University which works towards realizing the goals of quality enhancement by developing a system for conscious, consistent and catalytic improvement in different aspects of functioning of the University.
                </p>
                <p>One of the prime tasks of IQAC is to develop realistic and attainable quality benchmarks for every Academic and Administrative activity. Benchmarking means fixing standards for all activities on the basis of its own practices and the experiences of others.</p>
                <h3>Objective</h3>
                <h5>The primary aim of IQAC is</h5>
                <ul className="pr-ul pt-25" >
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> 
                      To develop a system for conscious, consistent and catalytic action to improve the academic and administrative performance of the institution.
                    </div>
                  </li>
                  <li> <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> 
                      To promote measures for institutional functioning towards quality enhancement through internalization of quality culture and institutionalization of best practices.</div></li>
                </ul>

                <h3>Strategies</h3>
                <h5>IQAC shall evolve mechanisms and procedures for</h5>
                <ul className="pr-ul pt-25" >
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Ensuring timely, efficient and progressive performance of academic, administrative and financial tasks;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">The relevance and quality of academic and research programmes;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Equitable access to and affordability of academic programmes for various sections of society;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Optimization and integration of modern methods of teaching and learning;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">The credibility of evaluation procedures;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Ensuring the adequacy, maintenance and proper allocation of support structure and services;</div>
                  </li>
                </ul>

                <h3>Functions</h3>
                <h5>Some of the functions expected of the IQAC are:</h5>
                <ul className="pr-ul pt-25" >
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Development and application of quality benchmarks/parameters for various academic and administrative activities of the institution;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Facilitating the creation of a learner-centric environment conducive to quality education and faculty maturation to adopt the required knowledge and technology for participatory teaching and learning process;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Arrangement for feedback response from students, parents and other stakeholders on quality-related institutional processes;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Dissemination of information on various quality parameters of higher education;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Organization of inter and intra institutional workshops, seminars on quality related themes and promotion of quality circles;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Documentation of the various programmes/activities leading to quality improvement;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Acting as a nodal agency of the Institution for coordinating quality-related activities, including adoption and dissemination of best practices;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Development and maintenance of institutional database through MIS for the purpose of maintaining /enhancing the institutional quality;</div>
                  </li>
                  <li><div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text"> Development of Quality Culture in the institution;</div></li>
                </ul>

                <h3>Benefits</h3>
                <h5>IQAC will facilitate / contribute</h5>
                <ul className="pr-ul pt-25" >
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Ensure heightened level of clarity and focus in institutional functioning towards quality enhancement;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Ensure internalization of the quality culture;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Ensure enhancement and coordination among various activities of the institution and institutionalize all good practices;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Provide a sound basis for decision-making to improve institutional functioning;</div>
                  </li>
                  <li>
                    <div className="icon">
                        <i className="fal fa-check" />
                    </div>
                    <div className="text">Act as a dynamic system for quality changes in HEIs;</div>
                  </li>
                </ul>                
              </div>
            </div>
            <div className="info-column col-lg-4 col-md-12 col-sm-12">
                <div className="inner-column2">                    
                    <ul className="project-info ">                        
                        <li>
                            <div className="slider-btn">
                                <Link to="/compositionofiqac" className="btn ss-btn smoth-scroll">
                                    Composition of IQAC <i className="fal fa-long-arrow-right" />
                                </Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default IQAC
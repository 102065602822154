import React from 'react';
import { Typography, Grid, Card, Avatar, Box } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import CourseGrid from '../muiCommonComponent/coursesButtonGrid';
import { ListDataPresentation, ReactSlider, TableDataPresentation } from '../../utils/utils';
import ImagesSlider from '../muiCommonComponent/imageSlider';

function SOETContent() {
  const courses = [
    {name:'B.Sc. Biotechnology',url:'/school-of-allied-health-sciences/bsc-biotech'},
    {name:'B.Sc. Med. Lab. Technology',url:'/school-of-allied-health-sciences/bsc-mlt'},
    {name:'Bachelor of Physiotherapy',url:'/school-of-allied-health-sciences/bpt'},
    {name:'Diploma Health Assistance',url:'/school-of-allied-health-sciences/dha'},
    {name:'Diploma Med. Lab. Technology',url:'/school-of-allied-health-sciences/dmlt'},
    {name:'M.Sc. Microbiology',url:'/school-of-allied-health-sciences/msc-micro-bio'},
    {name:'M.Sc. MLT',url:'/school-of-allied-health-sciences/msc-mlt'}
  ]; 
  const progHiglight=[
    {listName:'Availability of scholarships covering up to 100% of expenses for eligible students.'},
    {listName:'Successful job placement across diverse fields like medical biotechnology, Agriculture Biotechnology, Environment Biotechnology, Industrial Biotechnology, Bioinformatics, Genomics, Vaccinology and Allied Life Sciences streams such as Biochemistry, Genetics, Microbiology etc. showcasing a variety of opportunities with leading global corporations.'},
    {listName:'Employment opportunities in prestigious organizations across defence, research, government, healthcare, etc.'},
    {listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
    {listName:'Prospects for advanced studies or research in both domestic and international institutions'},
    {listName:'Modern laboratories equipped with sophisticated instruments.'},
    {listName:'Choice-based Credit System in the curriculum.'},
    {listName:'Curriculum crafted by industry professionals and distinguished academics.'},
    {listName:'Emphasis on project-based and experiential learning methodologies'},
    {listName:'Access to e-learning platforms like MOOC, NPTEL, and Coursera'},
    {listName:'Tailored training programs to prepare students for placement opportunities.'},
    {listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},
    {listName:'Advanced laboratories with full equipment.'},
    {listName:'Curriculum aligning with HCPC standards and offering industry-relevant elective.'},
    {listName:'courses.'},
    {listName:'Curriculum crafted by medical professionals and distinguished academics.'},
    {listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
    {listName:'Access to e-learning platforms.'},
    {listName:'Tailored training programs to prepare students for placement opportunities.'},
    {listName:'Publication of over 70 research papers in both national and international journals and conferences.'},
    {listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, And launching student startups'},
    {listName:'Organizing conferences, short-term training, workshops, webinars, online webinar, online and offline various competitions, contests, project, and seminars at district, state and national level.'},
    {listName:'Emphasis on hands on training, case assessment and treatment.'},
    {listName:'Flexible credit system enabling students for higher post-graduation national and international studies.'},
    {listName:'Successful job placement in various hospitals and colleges.'},
    {listName:'Prospects for advanced studies or research in both domestic and international institutions.'},
    
   ]
   const departmentPoints=[
    {listName:'The department is well conceptualized and planned.'},
    {listName:'The department is abundantly enabled with the provision of rich resource centre in the form of -'},
   ]
   const departmentSubPoints=[
    {listName:'Modern laboratories'},
    {listName:'Experienced and well qualified faculty'},
    {listName:'Departmental library'},
    {listName:'Innovative teaching methodology.'},
   ]
   const eventGallery = [
    { img: '/assets/img/SAHS/event1.jpg' },
    { img: '/assets/img/SAHS/event2.jpg' },
    { img: '/assets/img/SAHS/event3.jpg' },
    { img: '/assets/img/SAHS/event4.jpg' },
    { img: '/assets/img/SAHS/event5.jpg' },
    { img: '/assets/img/SAHS/event6.jpg' },
    
    // Add more logo URLs as needed
  ];
  const mediaGallery = [
    { img: '/assets/img/SAHS/media1.png' },
    { img: '/assets/img/SAHS/media2.png' },
    { img: '/assets/img/SAHS/media3.jpg' },
    { img: '/assets/img/SAHS/media4.jpg' },
    { img: '/assets/img/SAHS/media5.jpg' },
    // Add more logo URLs as needed
  ];
  const awardGallery = [
    { img: '/assets/img/SAHS/award.jpg' },
  ];
  const strength=[
    {listName:'Frequent Seminars/ Webinars/ Conference or Workshops led by the speakers of national and international repute.'},
    {listName:'Over 100 publications of our faculty at various national and international Journals indexed in SCI/ SCI-E/ Scopus/ PubMed and WoS'},
    {listName:'Exceptional placement records with a 90% placement rate among academia and Industry, globally.'},
    {listName:'Faculty representation at various scientific societies, academic and examination boards, further enriching our students learning experiences.'},
    {listName:'Vast research exposure of students in various laboratory/ industries/ research institute’s visit in frequent such as ICGEB, New Delhi, NBPGR, New Delhi & THSTI, Faridabad with reputed hospitals and early intervention centres.'},
    
    
   ]
   const partnerGallery = [
    { img: '/assets/img/SAHS/partner1.png' },
    { img: '/assets/img/SAHS/partner2.jpg' },
  ];
  const companyLogos = [
    {img:'/assets/img/SOPS/company1.png'},
    {img:'/assets/img/SOPS/company2.png'},
    {img:'/assets/img/SOPS/company3.png'},
    {img:'/assets/img/SOPS/company4.png'},
    {img:'/assets/img/SOPS/company5.jpg'},
    {img:'/assets/img/SOPS/company6.png'},
    {img:'/assets/img/SOPS/company7.png'},
    {img:'/assets/img/SOPS/company8.png'},
    {img:'/assets/img/SOPS/company9.jpg'},
    {img:'/assets/img/SOPS/company10.png'},
    // Add more logo URLs as needed
];
const studentsList = [
  {
      img: '',
      name: 'Vanshika',
      degree: 'B.Sc. Biotechnology Semester III',
      placement: '',
      description: 'My time at the Department of Biotechnology and Microbiology has consistently been engaging, enlightening, and enjoyable, enriched by excellent learning resources. The teaching faculty within the department is not only highly educated but also remarkably confident during lectures. Their approach makes learning a seamless, accessible, and applicable experience, incorporating futuristic and advanced learning tools. The department organizes a variety of events that blend fun and education seamlessly, providing an educational journey filled with joy and interest. I wholeheartedly recommend others to immerse themselves in the educational environment of MVN University to pave the way for a successful career.',
  },
  {
      img: '',
      name: 'Anjali',
      degree: 'M.Sc. Microbiology, Semester III',
      placement: '',
      description: 'MVN University is a vibrant institution offering abundant opportunities to embrace both the joys of life and the cutting-edge realms of biotechnology and microbiology. As a student with a keen interest in vaccine sciences, I consider myself fortunate to have gained admission to the microbiology program. This academic journey has allowed me to delve into the intricate workings of viruses and bacteria. The Department of Biotechnology and Microbiology, equipped with state-of-the-art labs, has turned my aspirations in medical microbiology into a reality. Engaging in advanced practical experiments in these well-equipped laboratories has been instrumental in shaping me into a confident microbiologist poised to contribute to our nation & well-being. I extend an invitation for you to be a part of this transformative experience.',
  },
  {
      img: '',
      name: 'Amrita',
      degree: 'BMLT, Semester 6',
      placement: '',
      description: "Hi! I am Amrita, a final-year BMLT student at MVN University. Being a BMLT student at MVN University has been an enriching journey. The comprehensive training in laboratory diagnostic procedures has equipped me with the skills needed to analyze medical specimens. I've learned to perform critical tests that contribute to accurate diagnoses. The faculty members are knowledgeable and supportive, guiding us through both theoretical concepts and practical applications. The state-of-the-art lab facilities provide an excellent learning environment. I'm confident that my education here will prepare me for a successful career in the field of medical laboratory technology.",
  },
  {
      img: '',
      name: 'Mohan ',
      degree: 'Mangla, BPT, Year 2nd',
      placement: '',
      description: 'I, Mohan Mangla, a second year BPT student at MVN University. I am happy in writing a testimonial for MVN Univesity! Here at MVN University learning is an exciting and rewarding experience. The Department of Physiotherapy here provides a holistic learning environment. The highly qualified faculty members are dedicated to imparting knowledge and practical skills. From understanding the intricacies of human anatomy to mastering therapeutic techniques, the curriculum is well-structured. The internship opportunities in multispecialty hospitals have given me valuable clinical exposure. I’m confident that my education at MVN University will prepare me for a successful career in physiotherapy.',
  },
  
];
const SAHSfeeStructureData=[
  {'Courses':'Diploma in Heath Assistant-DHA (After 10th Class)','Eligiblity':'10th/10+2 with 50% marks','Duration':'3','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
  {'Courses':'Diploma in Medical Lab Technology (DMLT)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'2','Tution Fee':'40000' ,'1st Year Fees':'65200','2nd Year Onwards':'49200'},
  {'Courses':'B.Sc in Medical Lab Technology (BMLT) ','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'3','Tution Fee':'60000' ,'1st Year Fees':'85200','2nd Year Onwards':'69200'},
  {'Courses':'B.sc (Biotechnology)','Eligiblity':'10+2 with 50% marks in PCB/M','Duration':'3','Tution Fee':'45000' ,'1st Year Fees':'70200','2nd Year Onwards':'54200'},
  {'Courses':'Bachelor of Physiotherapy (BPT)','Eligiblity':'10+2 with 50% marks in PCB/M & Minimum age 17 years','Duration':'4.5','Tution Fee':'105000' ,'1st Year Fees':'130200','2nd Year Onwards':'114200'},
  {'Courses':'M.Sc in Medical Lab Technology (M.Sc. MLT)','Eligiblity':'Graduate in Related Stream with 50% marks','Duration':'2','Tution Fee':'70000' ,'1st Year Fees':'95200','2nd Year Onwards':'79200'},
{'Courses':'M.Sc (Microbiology)','Eligiblity':'Graduate in Related Stream with 50% marks','Duration':'2','Tution Fee':'65000' ,'1st Year Fees':'90200','2nd Year Onwards':'79200'},
 
]
const hostalFeeData=[
  {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
  {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
]
const noteList=[
{listName:'1. Uniform fee Rs. 4500/- will be charged extra.'},
{listName:'2. HSTES Counselling fee (B.Pharm) will be charged extra.'},
{listName:'3. For Pharmacy courses eligibility verification is mandotory as per PCI norms and charges for the same will be charged extra.'},
{listName:'4. UGC norms will be adhered for fee refund'},
{listName:'5. Hostel will be provided for full Academic year only.'},
{listName:'6. All the dispute are subjected to Palwal judiciary.'},
{listName:'7. *Approval awaiting.'},
]
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Allied Health Sciences" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >The School of Allied Health Sciences at MVN University is committed to cultivating exceptionally proficient professionals across diverse domains of allied health professions including physiotherapy, biotechnology, microbiology, and allied health sciences. Our mission is to elevate and fortify modern and futuristic healthcare services across the globe through the training of allied health experts, emerging them from various social backgrounds with the attainment of the aim of equity and inclusiveness in holistic education for all. These professionals will serve as the cornerstone of the medical and health sectors of India and cross boundaries of countries for human welfare, playing an indispensable role in their advancement and efficacy of health sciences and towards the goals of sustainable development.
        <br/><br/>
        <b>A Noble Duty: Towards the service of humanity!</b>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="DEPARTMENTS" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Department of Physiotherapy" />
      </Grid>
      <Grid item xs={12} sm={12} md={ 12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Department of Physiotherapy and rehabilitation of the individuals from various spheres of injuries or disabilities covering a wide range of spectrum from cardiopulmonary, IPD, orthopaedics, paediatrics, neurological, sports and many others offering bachelor’s in physiotherapy based on evidence based and research based informed teaching opening new doors of opportunities for the students. The OPD is well equipped with separate specialized departments of electrotherapy, exercise therapy, gym, rehabilitation, and occupational therapy departments. Various departments and equipment are separate for students and patients under specialized training and learning with experienced faculty. State of the OPD facilities with specialization in Sports, Neurological, Paediatrics, Cardiology, Geriatrics problems and sports injuries. Department of Physiotherapy, School of Allied Health Sciences have tie ups with various Hospitals of Palwal and Faridabad. Alumni students are well placed in different hospitals, clinics or have started their private practices.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Department of Medical Laboratory Technology" />
      </Grid>
      <Grid item xs={12} sm={12} md={ 12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Department of Medical Lab Technology offers a comprehensive range of programs aimed at preparing students for careers in medical laboratory science. The curriculum covers a broad spectrum of topics including clinical laboratory tests, analysis of body matter, micro-organism screening, chemical analyses, and cell count. The practical training provided in laboratories gives students hands-on experience with various tests conducted in different laboratory settings such as Microbiology, Haematology, Cytology, Blood bank, Biochemistry, and Toxicology. This exposure ensures that students are well-equipped with the skills and knowledge needed to excel in their field upon graduation. The role of medical lab technicians and technologists in aiding doctors and medical microbiologists in detecting, diagnosing, and preventing diseases through clinical laboratory tests is crucial in the healthcare system. Their contribution helps in providing accurate and timely diagnoses, which is essential for effective treatment and disease prevention. Moreover, the emphasis on all-round development through a balanced blend of curricular and extracurricular activities ensures that students not only excel academically but also develop essential skills such as teamwork, communication, and leadership. The use of modern educational technology in classroom teaching enhances the learning experience and keeps students abreast of the latest advancements in the field. Overall, it seems like the Department of Medical Lab Technology is committed to providing high-quality education and training to produce competent and skilled professionals in the field of medical laboratory science.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Department of Biotechnology and Microbiology" />
      </Grid>
      <Grid item xs={12} sm={12} md={ 12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Since 2020, The Department of Biotechnology and Microbiology at MVN University is dedicated generating skilled human resources in biotechnology and microbiology, MVN University initiated undergraduate and postgraduate courses to cater to the educational needs of a large population in the National Capital Region (NCR) and surrounding areas, including the rural belt of southern Haryana in response to the pressing need for education. Since its inception, the department has undergone rapid evolution, attracting highly qualified faculty from prestigious universities to deliver quality education. The department boasts world-class infrastructure, featuring modern classrooms and well-equipped laboratories. These state-of-the-art laboratories house sophisticated instruments and various other essential tools for advanced research purposes.
        <br/>
        <br/>
        The Department is committed to creating and grooming the students to deal with the demanding challenges in the health care system at the National and International levels.
       <br/>
       Salient feature
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={departmentPoints}/>
      <Typography marginLeft={20}>
      <ListDataPresentation DataList={departmentSubPoints}/>
      </Typography>
      <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        We cherish the ideals of providing value-based education of modern era that would enable our students to achieve empowerment, cognitive capacity, 20th centuries skills, ability for research and innovation. Our special focus is on student placements; hence we subject our students to rigorous placement training.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Allied Health Sciences Courses" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
        <CourseGrid courses={courses}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="PROGRAM HIGHLIGHTS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={progHiglight}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Event & News Gallery" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <ImagesSlider imgArr={eventGallery} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="MEDIA COVERAGE" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <ImagesSlider imgArr={mediaGallery} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="AWARD HIGHLIGHTS" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <ImagesSlider imgArr={awardGallery} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="STRENGTHS OF SCHOOL OF ALLIED HEALTH SCIENCES" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
      <ListDataPresentation DataList={strength}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="KNOWLEDGE PARTNERS" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
      <ImagesSlider imgArr={partnerGallery} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="TOP RECRUITERS" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
      <ImagesSlider imgArr={companyLogos} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
          <MainTitle title="STUDENT TESTIMONIALS"/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
          <ReactSlider cardList={studentsList}/>
      </Grid>
      <Grid item xs={12}>
					<MainTitle title='FEE STRUCTURE – 2024-25'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={SAHSfeeStructureData}/>
				</Grid>
        <Grid item xs={12}>
					<MainTitle title='Hostel Fees'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={hostalFeeData}/>
				</Grid>
				<Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
	<Grid item xs={12}>
    <MainTitle title="Notes" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={noteList}/>
    </Grid>
    </Grid>
  );
}

export default SOETContent;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

const OPD=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="Physiotherapy Outpatient Department (OPD)" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >Welcome to our Physiotherapy Outpatient Department (OPD), where we focus on restoring and enhancing patient physical well-being through specialized treatment and rehabilitation programs. Our dedicated team of physiotherapists is committed to provide personalized care.</Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="What to Expect:" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
      <ul>
<li>Our experienced physiotherapists conducts a thorough assessment of patient condition, taking into account patient medical history, symptoms, and individual goals.</li>
<li>Based on the assessment, a customized treatment plan is devised and delivered to address patient concerns and optimize patient recovery.</li>
<li>Our state-of-the-art facilities are equipped with advanced equipment and modalities to deliver effective therapy sessions.</li>
<li>Throughout patient journey with us, we closely monitor patient progress and adjust the treatment plan as needed to ensure the best possible outcomes.</li>
</ul>
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Our Treatment Options:" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Manual therapy techniques, Therapeutic exercises, Electrotherapy modalities, Postural correction, Gait training, Pain management, Sports injury rehabilitation, Neurological rehabilitation, Paediatric physiotherapy, Geriatric physiotherapy, tapping, and in all rehabilitative and preventive measures.
    <br/><br/>
Our Goal is to helping you regain mobility, alleviate pain, and improve patient overall quality of life. We look forward to assisting you on patient journey to optimal health and wellness. We are proud to extend this valuable service to our community and remain dedicated to promoting health equity and accessibility for all. Join us in our mission to make quality physiotherapy care accessible to everyone
<br/><br/>
<b>FREE OF CHARGE.</b>
<br/>
<b>TIMMINGS – 9:30 AM – 4:00 PM</b>
    </Typography>
    </Grid>
    </Grid>

)
}
export default OPD;
import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import BreadcrumbsBanner from './breadcrumbs';
import SOETHeader from './header';
import WidgetsMenu from '../muiCommonComponent/menuWidgets';
import NewMain from "../headerone/newMain";
function SOETMCOOS() {
  const headerMenus = [
    { mainHeader: 'Home', path: '/school-of-engineering-technology', subHeader: [] },
    { mainHeader: 'SOET Insights', path: '', subHeader: [
        { title: 'Insights', path: '/soet/insights', },
        { title: 'Vision & Mission', path: '/soet/vision-mission', },
        { title: 'Knowledge Partners', path: '/soet/knowledge-partners', },
        { title: 'Pedagogical Advances – MOOCs', path: '/soet/mcoos', },
    ] },
    { mainHeader: 'SOET Departments', path: '', subHeader: [
        { title: 'Computer Science & Engineering', path: '/soet-cse/home', },
        { title: 'Mechanical Engineering', path: '/soet-me/home' },
        { title: 'Civil Engineering', path: '/soet-civil/home' },
        { title: 'Electronic & Communication Engineering', path: '/soet-ece/home' },
    ] },
    { mainHeader: 'MVN Times', path: '#', subHeader: [] },
    { mainHeader: 'Scholarly Work ', path: '#', subHeader: [] },
  ];
  return (<>
   <NewMain/>
    {/* <Navbar/> */}
    <WidgetsMenu headerMenus={headerMenus}/>
         <BreadcrumbsBanner bannerUrl={"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0QYsZugGaAqNtbITVJFo_A9QIPQSDskkXcjrVqOf9ZJn_PP5VoBQGxB7K4AVyk__fDxk&usqp=CAU"}/>
    <Container>
    <Grid container spacing={3}>
         {/* <Grid item xs={12} sm={12} md={12} lg={12} >
      </Grid> */}
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Pedagogical Advances – MOOCs" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        The Indian Institutes of Technology (IITs) and Indian Institute of Science (IISc) are the initiators of this program. Via online courses, National Programme on Technology Enhanced Learning (NPTEL) offers top-notch instructional materials in scientific and engineering fields. These courses span a wide range of subjects, including computer science, electronics, mechanical engineering, mathematics, and more, and are created and taught by experts from the IITs and IISc. NPTEL gives certification upon course completion in addition to video lectures and supplemental materials.
SWAYAM: The Indian government's Ministry of Education launched  Study Webs of Active–Learning for Young Aspiring Minds (SWAYAM) to offer open online courses to students all throughout the country. It seeks to close the digital gap for learners who are deprived of a top-notch education. SWAYAM provides courses at many levels, such as undergraduate, graduate, and school-based courses. Similar to NPTEL, SWAYAM provides courses in a variety of fields, such as technology, engineering, social sciences, natural sciences, and humanities. Along with reading materials, quizzes, assignments, and video lectures, it also gives certification for successful completion of the course.
In MVN University, several courses are offered to the students as Massive Open Online Course (MOOC). 

        </Typography>
       </Grid>
      </Grid>
      </Container>
      </> )
}
export default SOETMCOOS;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSPlacement=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Allied Health Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Training and Placement" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Training and Placement Cell (TPC) of School of Allied Health Sciences bridges the gap between campus and corporate. With the objectives of providing quality education in all spheres of life, and allied health profession, acquaint students with the current developments in the medical science and life sciences, providing holistic education beyond the boundaries of classroom and to explore the real world of sciences in various scientific exhibitions, research institutes and R&D and production units of various industries time to time.
    <br/><br/>
To fit them into the corporate world we are providing them with opportunities for internships, projects, and placements. Training and Placement Cell (TPC) of School of Allied Health Sciences is well coordinated with University’s Training and Placement Cell (TPC) for efficient functioning. Our TPC has MOUs and Collaborations with more than 30 companies in the specialized sector to facilitate internship opportunities for students. The internships opportunity not only allows students to gain practical exposure rather develop their personality and 20th century skills to accelerate their growth in perspective industrial careers, apply theoretical knowledge in real-world scenarios, and develop corporate disposition required in the corporate environment.
<br/><br/>
TPC organize placement drives, conducting mock interviews, and offering career guidance and counseling, along with organization guest lectures, skill developmental program, workshops, seminars, and industry visits to provide students with insights into current industry trends, challenges, and best practices. These programs focus on enhancing students' communication skills, presentation skills, problem-solving abilities, and interpersonal skills, making them more marketable to potential employers. The TPC offers personalized career guidance and counseling to students to help them make informed decisions about their career paths. Our TPC maintains strong connections with alumni and may involve them in mentoring programs, networking events, and industry panels to share their experiences and insights with students.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSPlacement;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSPlacement=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Law" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Training and Placement" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    An essential component of the work of the Training and Placement Cell (TPC) in the School of Law is to connect academics with corporate and legal field. By arranging for students to participate in internships, projects, and placements, its principal goal is to ease their transition from academia to the corporate/ legal sector. Through MOUs with firms and nonprofits, our TPC is able to provide students with internship opportunities. Internships provide students with the opportunity to learn by doing, apply classroom theory to real-world situations, and hone soft skills that are valuable in any business setting. Through our TPC, students can connect with a vast network of recruiters, participate in placement drives, practise interviews, and receive career counselling and advice. Students can gain valuable insights into the latest trends, difficulties, and best practices in the sector through the organisation of guest lectures, workshops, seminars, and even legal visits. There are several training programmes and seminars put on by our TPC to help members hone their soft skills and other professional attributes. The goal of these programmes is to make students more professional by improving their communication, presentation, problem-solving, and interpersonal skills. To assist students in making well-informed decisions on their future careers, the TPC provides them with individualised career counselling and guidance. Alumni are actively involved in our TPC's mentorship programme, networking events, and panels, where they may offer their knowledge and advice with current students. 
<br/><br/>
As a conclusion, our Training and Placement Cell is an essential component of the school of law, serving to equip students with the necessary skills for thriving in the corporate and legal realm. 

<br/><br/>

    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSPlacement;
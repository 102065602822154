import SOETHeader from "./header";
import BreadcrumbsBanner from "./breadcrumbs";
import Content from "./home";
import { Container } from "@mui/material";
import Departments from "./departments";
import EventNewsGallary from "./eventNews";
import RecuiterCompany from "./recuiterCompany";
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
import NewMain from "../headerone/newMain";
import Studentshub from "./studentshub";
import Initiative from "./initiative";
import Alumni from "./alumni";
import Placement from "./training&placement";
import Research from "./r&d";
import Lab from "./lab";
import { useParams } from "react-router-dom";
import BAP from "./SSH/bap";
import BAE from "./SSH/bae";
import BPS from "./SSH/bps";
import BSC from "./SSH/bsc";
import BJMC from "./SSH/jmc";
import Home from "./home";
import FacultyList from "./faculties/facultyList";
import FacultyDetail from "../facultydetail/main";
const SAHSHome=()=>{
    const { screenname } = useParams();
    console.log('screenname::::::::::::',screenname)
    const screensName=()=>{
        let screen=''
        if(screenname=='home'){
            screen=Home()
        }
       else if(screenname=='student-hub'){
            screen=Studentshub()
        }else if(screenname=='intiative'){
            screen=Initiative()
        }
        else if(screenname=="our-alumi"){
            screen=Alumni()
        }
        else if(screenname=='t-p'){
            screen=Placement()
        }else if(screenname=='r-d'){
            screen=Research()
        }
        else if(screenname=="laboratory"){
            screen=Lab()
        }
        else if(screenname=='bae'){
            screen=BAE()
        }else if(screenname=='bap'){
            screen=BAP()
        }
        else if(screenname=="bps"){
            screen=BPS()
        }
        else if(screenname=='bsc'){
            screen=BSC()
        }
        else if(screenname=='bjmc'){
            screen=BJMC()
        }
        else if(screenname=="faculties"){
            screen=FacultyList()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
       
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/school-of-arts-science-humanities/home', subHeader: [] },
        { mainHeader: 'About SSH', path: '/school-of-arts-science-humanities/home', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [
            {title:'B.Sc',path:'/school-of-arts-science-humanities/bsc'},
            {title:'B.A. Economics',path:'/school-of-arts-science-humanities/bae'},
            {title:'B.A. Journalism and Mass Communication',path:'/school-of-arts-science-humanities/bjmc'},
            {title:'B.A. Political Science',path:'/school-of-arts-science-humanities/bps'},
            {title:'B.A.',path:'/school-of-arts-science-humanities/bap'},
        ] },
        { mainHeader: 'Laboratories ', path: '/school-of-arts-science-humanities/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/school-of-arts-science-humanities/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/school-of-arts-science-humanities/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/school-of-arts-science-humanities/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/school-of-arts-science-humanities/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/school-of-arts-science-humanities/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/school-of-arts-science-humanities/intiative', subHeader: [] },
    ]
return(<>
<NewMain/>
<WidgetsMenu headerMenus={headerMenus}/>
<BreadcrumbsBanner bannerUrl={"/assets/img/SSH/ssh.jpg"}/>
<Container>
    {screensName()}
</Container>
</>)
}
export default SAHSHome;
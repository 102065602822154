import React from 'react';
import { Grid,Stack, Typography, Breadcrumbs, Link } from '@mui/material';

const  banner= {
    backgroundImage: '',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    height: '500px', // Set your desired height
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    marginBottom:'20px',
    // padding: theme.spacing(2),
  }
const breadcrumb={
    position: 'absolute',
    left: 0,
    width: '100%',
    textAlign: 'center',
    // bottom: '-25px',
    zIndex: 1,
    display: 'inline-block',
    flexWrap: 'unset',
    padding: 0,
    marginBottom: 10,
    listStyle: 'none',
    background: 'unset',
    borderRadius: '30px',
    background: '#ED1E24',
    padding: '15px 35px',
    // position: 'relative',
}
function BreadcrumbsBanner({bannerUrl}) {

  return (
    <Grid container>
        <Grid item xs={12}>
        <Typography sx={{...banner,backgroundImage:`url(${bannerUrl})`}}></Typography>

        </Grid>
      {/* <Stack className='p-realtive'>
          <Breadcrumbs aria-label="breadcrumb" sx={breadcrumb}>
            <Link color="inherit" href="/">
              Home
            </Link>
            <Typography color="textPrimary">SOET</Typography>
          </Breadcrumbs>
       </Stack> */}
    </Grid>
  );
}

export default BreadcrumbsBanner;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCIVILHeader from "./header";
const CIVILBTechResearch=()=>{
return (
<Grid container spacing={2}>
{/* <SOETCIVILHeader/>
        <BreadcrumbsBanner bannerUrl={"https://www.dreamdesigninc.com/wp-content/uploads/2022/09/civilengineering_hero.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Civil Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Research & Development" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    In the Civil Engineering Department, research and development are keystones of our academic and professional ethos. We actively engage in organizing research conferences and workshops, creating a vibrant platform for experts, students, and faculty to exchange groundbreaking ideas and technological advancements. This culture of continuous learning is further supported by our commitment to faculty development, ensuring that our educators remain at the cutting edge of technological and teaching methodologies. Our department's initiatives extend into collaborative projects with industry leaders and academic institutions, aiming to drive innovation and solve real-world problems. Through these endeavours, we strive to foster a dynamic environment where exploration leads to discovery, and education transcends the classroom to make a meaningful impact on society and the technological landscape.

    </Typography>
    </Grid>
    </Grid>

)
}
export default CIVILBTechResearch;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSStudentshub=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Pharmaceutical Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Welcome to the Student's Hub" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Student's Hub stands as the vibrant nucleus of academic life, pulsating with energy and possibilities. It's not just a physical space; it's a dynamic environment where students converge to share ideas, seek guidance, and embark on journeys of growth and discovery.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
<b>A Haven for Collaboration and Growth</b>
<br/>
At the Student's Hub, collaboration thrives, and learning knows no bounds. Here, students find a supportive community, rich with resources and opportunities for personal and intellectual development. It's a place where friendships are forged, interests are explored, and passions are ignited.
<br/><br/>
<b>Embracing Campus Life</b>
<br/>
From study groups and workshops to cultural events and extracurricular activities, the Student's Hub is the beating heart of campus life. It's where students come together to nurture a sense of belonging and inspire lifelong learning. Here, creativity flourishes, driven by the diverse perspectives and talents of our community.
<br/><br/>
<b>Fostering Innovation and Excellence</b>
<br/>
In this dynamic environment, innovation takes root, sparking breakthroughs that shape the future. The Student's Hub is not just a destination but a journey—a journey of growth, discovery, and transformation. It embodies the spirit of community, fostering inclusivity and nurturing a culture of excellence.
<br/><br/>
<b>Join Us in the Journey</b>
<br/>
We invite you to explore the Student's Hub, where every corner is filled with opportunities to learn, connect, and thrive. Whether you're seeking academic support, looking to get involved in campus activities, or simply want to meet fellow students, the Student's Hub is here to welcome you with open arms.
<br/><br/>
<b>Let's Embark on This Journey Together</b>
    </Typography>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Welcome to the Student's Hub—a place where dreams take flight and futures are shaped. Join us as we embrace the spirit of community, celebrate diversity, and inspire greatness in each other.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSStudentshub;
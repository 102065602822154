import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {

    const Data = [
        
        {img : "/assets/img/infrastructure/1.JPG", category : "Financial"},
    
        {img : "/assets/img/infrastructure/2.JPG", category : "Financial"},

        {img : "/assets/img/infrastructure/3.JPG", category : "Analyzing"},
    
        {img : "/assets/img/infrastructure/4.JPG", category : "Insurance"},
    
        {img : "/assets/img/infrastructure/5.JPG", category : "Family"},

        {img : "/assets/img/infrastructure/6.JPG", category : "Business"},

        {img : "/assets/img/infrastructure/11.jpg", category : "Financial"},

        {img : "/assets/img/infrastructure/12.jpg", category : "Marketing"},
        {img : "/assets/img/infrastructure/13.jpg", category : "Marketing"},
        {img : "/assets/img/infrastructure/14.jpg", category : "Marketing"},
        {img : "/assets/img/infrastructure/15.jpg", category : "Marketing"},
        {img : "/assets/img/infrastructure/16.jpg", category : "Marketing"},
        {img : "/assets/img/infrastructure/21.JPG", category : "Marketing"},
        {img : "/assets/img/infrastructure/22.JPG", category : "Marketing"},
        {img : "/assets/img/infrastructure/23.JPG", category : "Marketing"},
        {img : "/assets/img/infrastructure/24.jpeg", category : "Marketing"},

      ]

      const [current, setcurrent] = useState(Data);

      const Filter = (category) =>{
          const arr = [];
          if(category === "View All"){
          setcurrent(Data)
          }
          else{
          Data.map((item)=> {
              if(item.category === category){
              arr.push(item);
              }
          })
          setcurrent(arr);
          }
      };

  return (
    // <>
    //     <section id="work" className="pt-150 pb-105">
    //         <div className="container">
    //             <div className="portfolio ">
    //             <div className="row align-items-end">
    //                 <div className="col-lg-12">
    //                 <div className="my-masonry text-center mb-50 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
    //                     <div className="button-group filter-button-group ">
    //                     <button className="active" data-filter="*" onClick={()=> Filter("View All")} > View All </button>
    //                     <button data-filter=".financial" onClick={()=> Filter("Financial")} >Financial</button>
    //                     <button data-filter=".banking" onClick={()=> Filter("Analyzing")} >Analyzing</button>
    //                     <button data-filter=".insurance" onClick={()=> Filter("Marketing")} >Marketing </button>
    //                     <button data-filter=".family" onClick={()=> Filter("Business")} >Business</button>
    //                     </div>
    //                 </div>
    //                 </div>
    //             </div>
    //             <div className="grid col3 row wow fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
    //                 {current.map( (item, Index)=> ( 
    //                     <div className="grid-item financial col-4" key={Index}>
    //                         <Link to="/">
    //                             {/* <figure className="infrastructure-image"> */}
    //                                 <img src={item.img} alt="img" className="img" />
    //                             {/* </figure> */}
    //                         </Link>
    //                     </div>
    //                 ))}
    //             </div>
    //             </div>
    //         </div>
    //     </section>
    // </>

    <>
        <section id="work" className="pt-150 pb-105" style={{marginTop:'-35vh'}}>
            <div className="container">
                <div className="portfolio ">
                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                    {current.map( (item, Index)=> ( 
                        <div className="col-md-3 col-sm-12" style={{padding:'15px'}} key={Index}>
                                    <img src={item.img} alt="img" className='healthImg' />
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First
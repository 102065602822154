import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import NewMain from '../headerone/newMain'
function ViceChancellorMessage() {
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Vice Chancellor’s Message" />
      <section className="about-area about-p pt-60 pb-60 p-relative fix">
        <div className="animations-02">
          <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row justify-content-center ">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className="wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" > 
                <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/vc_chancellor_imge.jpg`}  alt="img" className="vc-img" />
                
                <div className="vc-about"> 
                  <p><strong>Prof. (Dr.) Arun Garg </strong> <br/>Vice Chancellor, MVN University   </p> 
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                <div className="about-title second-title pb-25">
                  {/* <h5>
                    <i className="fal fa-graduation-cap" /> Chancellor’s Message
                  </h5> */}
                  <h2>Vice Chancellor’s Message.</h2>
                </div>
                <p >
                At MVN University, we uphold a proud tradition of academic excellence. Our dedicated faculty, comprised of highly qualified and experienced educators, ensures the highest quality education. From the very first year, we prioritize student skill development, offering training in AI, ML, Data Analytics, as well as Drug Regulatory Affairs, Clinical Research, and Pharma Covigilance. Through industrial collaborations, we provide hands-on experience in Herbal Drug Technology and bioanalytical medical equipment for Allied Health students. Additionally, mandatory industry training is provided to students in Management, Law, Pharmacy, CSE, and Agriculture fields. Education extends beyond the classroom with our holistic approach, including communication and personality development classes, fostering well-rounded individuals poised to make meaningful contributions to society. Our ultimate aim is not only to produce top-notch professionals but also to cultivate responsible citizens ready to tackle global challenges. We take pride in our supportive community and eagerly support our students in their professional journey.
                
                <br/><br/>
                MVN University is committed to

                <ul className='liststyle_dots'>
                       <li> 	Increased Industry Exposure: Through collaborations and MoUs, students gain exposure to real-world industry practices, trends, and challenges.
                       </li> 
                       <li> 	Enhanced Employability: By staying updated with the latest industry developments, students become more competitive and better prepared for the job market.
                       </li> 
                       <li> Networking Opportunities: The Corporate to Campus Program provides students with valuable networking opportunities, allowing them to connect with industry professionals and potential employers.
                       </li> 
                       <li> 	Practical Experiences: Internships, industry projects, and expert lectures offer students hands-on experiences that complement their academic learning and prepare them for professional success.
                       </li> 

                </ul>

                <br/>

                At MVN University, we are committed to preparing our students for success in the corporate world. Through our Corporate to Campus Program, we provide students with unparalleled industry exposure, networking opportunities, and practical experiences that will set them apart in their careers. Join us in shaping future leaders and empowering the next generation of professionals.


                
                </p>
                                
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default ViceChancellorMessage
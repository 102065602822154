import React from 'react'
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './re-evalution-rules-first'
function ReEvalutionRules() {
  return (
    <>
      <Header />
      <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/examination-policy"> Examination Policy</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  Re-Evaluation Rules  
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <First />
    </>
  )
}

export default ReEvalutionRules
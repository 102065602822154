import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import NewMain from '../../headerone/newMain'




function Main() {

    return (
        <>
            <NewMain />
            <Bredcom title="Home" subtitle="Exam Notices" />


            <section className="project-detail">
                <div className="container">

                <h2 class="text-center"> Exam Notices
                </h2>

                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">
                                <div className="inner-column">
                                <ul class="details_list">

                                    
                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/AHS Dept. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener"> AHS Dept. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/B.Sc.(H) Agri  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener"> B.Sc.(H) Agri  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/B.Tech. CSE  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener">  B.Tech. CSE  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BA(POL.)Sc.  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener">  BA(POL.)Sc.  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BALL.B.  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener"> BALL.B.  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BBALL.B. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener"> BBALL.B. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024  </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/BCA 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener"> BCA 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/LL.B.  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener">  LL.B.  2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Pharmacy Dept. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener"> Pharmacy Dept. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/SBMC Dept. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024.pdf`}  target="_blank" rel="noopener">  SBMC Dept. 2nd Yr. Onwards Theory Date Sheet End Term Examinations December-2024</a></li>

 

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Notice for MTE Imp. Nov.-2024.pdf`}  target="_blank" rel="noopener"> Notice for 2nd year onwards MTE improvement examinations November - 07th Nov 2024 </a></li>


<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Notice for Submission of Regular Exam. form.pdf`}  target="_blank" rel="noopener"> Notice for Submission of Regular Exam. form    - 25th Sept 2024 </a></li>

<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Notice for Submission of Re-appear Exam. form.pdf`}  target="_blank" rel="noopener"> Notice for Submission of Re-appear Exam. form  - 25th Sept 2024</a></li>



                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Notice for MTE Imp. Nov.-2024.pdf`}  target="_blank" rel="noopener">Notice for 2nd year onwards MTE improvement examinations November - 07th Nov 2024 </a></li>



                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Notice for Submission of Regular Exam. form.pdf`}  target="_blank" rel="noopener"> Notice for Submission of Regular Exam. form    - 25th Sept 2024 </a></li>

                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Notice for Submission of Re-appear Exam. form.pdf`}  target="_blank" rel="noopener"> Notice for Submission of Re-appear Exam. form  - 25th Sept 2024</a></li>






                                <li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Notice for Submission of Regular and Re-appear Exam. Form June-2024 Examinations.pdf`}  target="_blank" rel="noopener">Notice for Submission of Regular and Re-appear Exam. Form June-2024 Examinations  - 01st Apr 2024</a></li>
                                {/*<li><a href={`${process.env.PUBLIC_URL}/assets/img/new_images/examination_datesheet/Revised Schedule of Exam.pdf`}  target="_blank" rel="noopener">Revised Schedule of Exam - 22 Aug  2024</a></li>
                                    <li><a href="https://admission.mvn.edu.in/wp-content/uploads/2022/04/Notification-for-Filling-Exam-Form-Regular-Students.pdf" target="_blank" rel="noopener">Notice For Regular Exam Form Submission</a></li>
                                    <li><a href="https://admission.mvn.edu.in/wp-content/uploads/2022/04/Notification-for-Filling-Exam-Form-Re-appear-Students.pdf" target="_blank" rel="noopener">Notice For Re-appear Exam Form Submission</a></li>
                                    <li><a href="https://admission.mvn.edu.in/wp-content/uploads/2022/07/Performance-Improvement-Examination.pdf" target="_blank" rel="noopener">Notice For Performance Improvement Examination</a></li>
                                    <li><a href="https://admission.mvn.edu.in/wp-content/uploads/2022/07/Re-evaluation.pdf" target="_blank" rel="noopener">Re-evaluation</a></li>
                                    <li><a href="https://admission.mvn.edu.in/wp-content/uploads/2022/08/Theory-Datesheet-for-Term-III-or-ETE-August.pdf" target="_blank" rel="noopener">Theory Datesheet for Term-III or ETE August</a></li>
                                    <li><a href="https://admission.mvn.edu.in/wp-content/uploads/2022/08/Ph.D-Viva-Voce-Examination-of-Ms.-Priya.jpeg" target="_blank" rel="noopener">Ph.D Viva Voce Examination of Ms. Priya</a></li>
                                    <li><a href="notification.pdf" target="_blank" rel="noopener">The Revised Schedule of the 22nd Jan 2024 Examination</a></li> 
                                    */ }
                                </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Main
const First = () => {
    return (
        <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="animations-02">
                <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
            </div>
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="text-justify">
                            (These rules will be applicable to all the examinations from the Academic Session 2023 -24)
                            Students seeking re-evaluation of their answer sheet(s) are advised to go through the following
                            rules before they apply for re-evaluation:
                        </p>
                        <ul>

                            <ol><div className="text-justify"><b>1.</b> When you apply for re-evaluation, you have to surrender your original Detailed Grades Card and
                                accept the revised result in which:</div>
                                <div>(i). there can be no change,</div>
                                <div>(ii). there can be increase in the marks,</div>
                                <div>(iii). there can be decrease in the marks.</div>

                            </ol>
                            <ol><div className="text-justify"><b>2.</b> Re-evaluation will be permitted only for the theory examinations conducted by this University.</div>
                            </ol>
                            <ol><div className="text-justify"><b>3.</b> No re-evaluation is allowed for examination in Sessionals, Practicals, Viva, Internal Assessment,
                                Project Report, Dissertations, Thesis, Viva-Voce, or any other subjects where there is a joint
                                evaluation by two examiners.</div>
                            </ol>
                            <ol><div className="text-justify"><b>4.</b> A student who wishes to seek re-evaluation of his or her answer sheet(s) may apply for re-
                                evaluation on the prescribed application form along with the original Detailed Grades card or
                                certificate and requisite fee within 15 days of the declaration of the result or the dispatch of the
                                detailed grades card to the Examination Department, whichever is later.</div>
                            </ol>
                            <ol><div className="text-justify"><b>5.</b> Before submitting the re-evaluation form to the University Examination Department, the
                                students should retain sufficient attested photocopies of their original Detailed Grades Card for
                                academic purposes, seeking a job, etc.</div>
                            </ol>
                            <ol><div className="text-justify"><b>6.</b> The application form, once submitted, may be withdrawn on written request in respect of one or
                                all subjects applied for re-evaluation, provided that the answer sheets have not been sent to the
                                re-evaluator. No fee refund, however, will be permissible in such a situation.</div>
                            </ol>
                            <ol><div className="text-justify"><b>7.</b> All entries in the application form for re-evaluation should be complete and correct in all
                                respects. The Examination Department will not be responsible for the delay or rejection of the
                                case if the form is not complete in all respects or is not accompanied by a full fee and/or original
                                Detailed Grades Card or Certificate. No change in the entries once made by the student shall be
                                allowed after the receipt of the application by the Examination Department. Ignorance of the
                                title of any subject or subject shall not be accepted as a plea for a wrong entry in the application.</div>
                            </ol>
                            <ol><div className="text-justify"><b>8.</b> No increase or decrease will be made in the marks of those failed students whose result remains
                                unchanged even after re-evaluation.</div>
                            </ol>
                            <ol><div className="text-justify"><b>9.</b> The re-evaluation will be done under the rules framed by the Academic Council from time to
                                time.</div>
                            </ol>
                            <ol><div className="text-justify"><b>10.</b> A refund of the fee will be admissible only in the case where re-evaluation is not possible due to
                                some administrative or technical reasons.</div>
                            </ol>
                            <ol><div className="text-justify"><b>11.</b> (i) If the difference between the re-evaluation score and the original score in a subject is less than
                                15% of the maximum marks of the concerned subject, then the average of the two scores will be
                                considered the final score.</div>
                                <div className="text-justify">(ii) If the difference is more than 15%, then the answer sheet will be sent to the second re-
                                    evaluator, and the average of the best two scores out of the three scores thus available will
                                    be considered the final score.</div>
                                <div className="text-justify">(iii) IIf, after re-evaluation, the grade of a student remains unchanged, the increase or decrease in
                                    marks, if any, will not be shown on the Detailed Grades Card. However, the original re-
                                    evaluated marks will be shown in the Result Sheet.</div>
                            </ol>
                            <ol><div className="text-justify"><b>12.</b> If the answer sheet is lost after the original evaluation and is not traceable for re-evaluation for
                                any reason, then the concerned student will be offered a re-examination of that subject at the
                                next examination. If the student does not want a re-examination, then his or her previous result
                                may remain unchanged. No re-examination fee shall be charged from a student who opts for re-
                                examination under this clause.</div>
                            </ol>
                            <ol><div className="text-justify"><b>13.</b> If a student who has re-appear in a subject(s) and has applied for re-evaluation but his or her
                                result of re-evaluation is not declared before the next examination and he or she appears in the
                                next examination in the subject(s) in which he or she had re-appear, the better of the two scores,
                                ‘Re-appear score’ or ‘Re-evaluation Score&#39;, would be considered.</div>
                            </ol>
                            <ol><div className="text-justify"><b>14.</b> The student should plan their future programme of taking examinations or admissions in
                                accordance with the original result already communicated by the university until it is actually
                                superseded by the re-evaluation result. The university will not take any responsibility for any
                                consequences arising out of the delay in the completion of the process of re-evaluation and the
                                declaration of the re-evaluated result.</div>
                            </ol>
                            <ol><div className="text-justify"><b>15.</b> In case the re-evaluation result has not been declared and the next supplementary or re-appear
                                examinations are held, a student may apply for such an examination without waiting for the re-
                                evaluation result.</div>
                            </ol>
                            <ol><div className="text-justify"><b>16.</b> Only one form will be used for one or more subjects. So be careful while selecting the subject(s)
                                for re-evaluation.</div>
                            </ol>
                            <ol><div className="text-justify"><b>17.</b> No appeal or review against the re-evaluation would be entertained.</div>
                            </ol>
                            <ol><div className="text-justify"><b>18.</b> If, due to re-evaluation, one or more student(s) secures more marks than the original student
                                securing first position in the merit list, he or she will also be eligible for the award of a gold
                                medal.</div>
                            </ol>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default First;
import React from 'react' 
import Bredcom from '../Bredcom/Main' 
import Header from '../headerone/Main' 


 
function GamesandSportsCommittee() { 
  return ( 
    <> 
        <Header/> 
        <Bredcom title="Home" subtitle="Games and Sports Committee" />
        <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">                               
                                <div className="inner-column">
                                    <h2>Games and Sports Committee</h2>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name</th>
                                                <th>Designation</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Kuldeep Tanwar <br/> (Nominated by Vice Chancellor’s) </td>
                                                <td>Chairmain</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Dr. Tarun Varmani</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>Mr Rahul Mongia</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Mr. Daya Shankar Prasad</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Ms. Madhu Bala</td>
                                                <td>Member</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Kapil, BPT (20BP1031)</td>
                                                <td>Student Representative</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Kunal, B-Pharm (20PS1031)</td>
                                                <td>Student Representative</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Partha Chakraborty, BBA LLB (20PS1009)</td>
                                                <td>Student Representative</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Aniket, MBA (22MS5020)</td>
                                                <td>Student Representative (PG)</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Mohit, B.A. (Political Science) (21BA/PS/1002)</td>
                                                <td>Student Representative</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>Sohil, BALLB (20SL2030)</td>
                                                <td>Student Representative</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>Mr. Ram Kumar (DPE)</td>
                                                <td>Member Secretary</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        
    </> 
  ) 
} 
 
export default GamesandSportsCommittee 
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function LawScope() {
   
    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Law" subtitle="Scope of LAW" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>SCOPE</h3>
                                </div>

                                <p>
                                The main purpose of law is not limited to secure justice or to resolve conflicts. But its scope is beyond the limits, which is required not only to maintain order and protect rights, but helps in introducing a peaceful change with stability, in order to maximize the happiness of the greatest number with minimum of friction. It defines relationship among the member of society, to satisfy the interest of the community and its members within the set of values.
                                </p>

                                <p>
                                Law is a social science as it grows and develops with the growth and development of the society. It has a social mission, it is neither right alone nor might alone, but the perfect union of two.
                                </p>

                                <p>
                                Occasionally law also plays a pioneer role in setting and strengthening social values as without religion there can be no morality and without morality there can be no law.
                                </p>

                                <p>
                                The essence of justice is not remote promise but ready performance. When mores are adequate laws are unnecessary and when mores are inadequate the laws are useless.
                                </p>


                                <div className='row pt-25'>
                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <h4>Career Prospects</h4>
                                        <ul className="pr-ul pt-25">
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Judge / Judicial Magistrate
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Public Officer
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Administrator
                                                </div>    
                                            </li>  
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Jurists
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">
                                                Public Prosecutor or services to Government Departments and Ministries
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">
                                                Advocates
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Legal Adviser in Different MNC’s
                                                </div>    
                                            </li> 
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Legal Adviser in different Govt. organization, Semi-Govt. Autonomous Bodies
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Prosecuting Officer
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Legal positions in banks and various commissions
                                                </div>    
                                            </li> 
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Legal expert in different fields as Civil Law, Criminal Law, Tax Law, Labour Law, Corporate Law, Intellectual Property Law, Cyber Law, Consumer Protection Law, etc.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Expert of Human Right Commission
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Legal Processing Outsourcing (LPO’s)
                                                </div>    
                                            </li>                                           
                                        </ul>
                                    </div>

                                    <div className='col-lg-6 col-md-6 col-sm-12'>
                                        <h4>Choose SOL as Alma Mater</h4>
                                        <ul className="pr-ul pt-25">
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                MVN University is located at Delhi-Agra highway
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                LL.B. three years course and BBA LL.B. five years course have been approved by Bar Council of India.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Curriculum based on high quality knowledge
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Rigorous class teaching and research.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Law Library is equipped with more than 5000 books, journals and reports of Supreme Court and all High Courts of India.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Computer Lab is equipped with Internet Facility.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Centrally Air conditioned Lecture rooms, tutorial rooms and library
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Highly qualified faculty.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Strong linkage with Industry, Law Firms, Corporate Business Houses, Senior Advocates of High Court and Supreme Court.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Emphasis on clinical legal education NGO’s etc in order to acquire insight into social and legal issues and challenges.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Visits to the Courts, State Commissions.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Programmes of Lok Adalat, Legal Literacy Camps, Nukkar Nataks.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Seminars, Conferences.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Student’s participation in National and International Moot-Court competitions.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Regular lectures by eminent Judges and Advocates of Supreme Court and High Court.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Individualized attention for our students.
                                                </div>    
                                            </li>
                                            <li> 
                                                <div className="icon">
                                                    <i className="fal fa-check" />
                                                </div>
                                                <div className="text">  
                                                Research centre of legal education
                                                </div>    
                                            </li>                                             
                                        </ul>
                                    </div>
                                </div>


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>CAREER OPTIONS AFTER LAW DEGREE</h3>
                                </div>

                                <p>
                                We are governed by law, and hence, legal education becomes inevitable to us. Hence, it has become the world’s largest and most influential governance in the country. In India, legal education is offered at different levels. Candidates intended to become legal professionals or want to enter in law field can choose B.A.LL.B. (Integrated Bachelor of Laws), LL.B. (Bachelor of Laws), LL.M. (Master of Laws), Ph.D. (Doctor of Philosophy in Law) Courses.</p>

                                <p>It is very important to ensure that legal education produces legal persons with the vision of social terms. Specially Lawyers are also called as social engineers. The scope of law has widened. It includes policymakers, administrators, law teachers, lawyers and much more.</p>

                                <p>Following are some of the good options for those who want to pursue law:</p>

                                <p>Government Service: Law graduates can also join government services if they prepare themselves for the exams held by the Public Service Commission. The Law Graduates would be then eligible for Indian Legal Services, which includes Legislative Counsel in the Legislative department and Legal Advisor in the Department of Legal Affairs.</p>

                                <p>Legal Advisor: Law graduates can also become Legal Advisor or join some Law Firms, Private Companies, Corporate Firms, or Banks. You can also provide your advice or various legal matters, or your legal expertise could also be used in NGOs.</p>

                                <p>Advocate/Legal Practitioner: Advocacy is one of the most opted professions among Law graduates. Students after completing their graduation in Law can practice in the Court of Law. However, the students first have to enroll in State Bar Council and qualify for the examination conducted by the Bar Council of India (All India Bar Exam) to qualify for their practice.</p>

                                <p>Civil Services or Judiciary: This course allows students to work for the Judicial machinery of the country as Judge or Magistrate. For this, the students are required to pass a Judicial Examination conducted by the Public Service Commission.</p>

                                <p>Legal Researcher: The Law graduates can also opt for Legal Researcher. A Legal Researcher is responsible for researching various cases and exploring ways to win a case. Moreover, they are hired by the lawyers to gain a deeper insight into the cases.</p>

                                <p>Law Firm/Agency: You could also join a law agency or firm as a fresher. There you may get to work with Senior Advocates, it will be stepping stone for you as a legal practitioner or advisor</p>

                                <p>LPO – Legal Process Outsourcing: It is quite easier for young and fresh talent to enter LPO industry in India. However, you will require excellent English communication and writing skills here. You get to work for law agencies in US, UK, and Europe. In an LPO, you largely deal in paperwork and other consulting work.</p>
                                
                                <p>These are the major categories, where you can find lucrative job. Apart from these, you could find jobs in various other verticals such as:</p>
                                <p>
                                    <strong>Media Industry</strong> <br/>
                                    <strong>Academics</strong>    <br/>
                                    <strong>Business and Financial Consulting</strong> <br/>  
                                    <strong>NGO and Social Work</strong>    <br/>
                                    <strong>Publishing</strong>    <br/>
                                    <strong>Politics</strong>    <br/>
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LawScope
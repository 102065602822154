import React from 'react' 
import Bredcom from '../Bredcom/Main' 
import Header from '../headerone/Main' 


 
function AntiRaggingCommittees() { 
  return ( 
    <> 
        <Header/> 
        <Bredcom title="Home" subtitle="Anti Ragging Committees" />
        <section className="project-detail">
                <div className="container">
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-12 col-md-12 col-sm-12">                               
                                <div className="inner-column">
                                    <h2>Anti Ragging Committees</h2>
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>S.No</th>
                                                <th>Name</th>
                                                <th>Designation</th>
                                                <th>Contact No.</th>
                                                <th>Mail. Id</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Dr. Ramveer Singh</td>
                                                <td>Chairperson</td>
                                                <td>9968795577</td>
                                                <td>ramveer.singh@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Tehsildar, Hodal</td>
                                                <td>Representative Of Civil Administration</td>
                                                <td>----</td>
                                                <td>----</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>SHO, Mundkati</td>
                                                <td>Representative Of Police Administration</td>
                                                <td>-----</td>
                                                <td>-----</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td>Dr. Tarun Virmani</td>
                                                <td>Member</td>
                                                <td>8397979273</td>
                                                <td>tarun.virmani@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td>Dr. Mayank Chaturvedi</td>
                                                <td>Member</td>
                                                <td>8887593189</td>
                                                <td>mayank.chaturvedi@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>6</td>
                                                <td>Dr. Sachin Gupta</td>
                                                <td>Member</td>
                                                <td>8397979269</td>
                                                <td>sachin.gupta@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>7</td>
                                                <td>Dr. Alok Srivastava</td>
                                                <td>Member</td>
                                                <td>8397979276</td>
                                                <td>alok.srivastava@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>8</td>
                                                <td>Dr. Mahesh Singh Danu</td>
                                                <td>Member</td>
                                                <td>9899042780</td>
                                                <td>maheshsingh.danu@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>9</td>
                                                <td>Mr. Daya Shankar Prasad</td>
                                                <td>Member</td>
                                                <td>9911541189</td>
                                                <td>dayashankar.prasad@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>10</td>
                                                <td>Ms. Babita Yadav</td>
                                                <td>Member</td>
                                                <td>9990123506</td>
                                                <td>babita.yadav@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>11</td>
                                                <td>Ms. Manju Mala H Roy</td>
                                                <td>Member</td>
                                                <td>8700492081</td>
                                                <td>manjumalah.roy@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>12</td>
                                                <td>Dr. Saraswati</td>
                                                <td>Member</td>
                                                <td>8683898646</td>
                                                <td>saraswati.sbmc@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>13</td>
                                                <td>Dr. Kuldeep Tanwar</td>
                                                <td>Member</td>
                                                <td>9050644274</td>
                                                <td>kuldeep.tanwar@mvn.edu.in</td>
                                            </tr>
                                            <tr>
                                                <td>14</td>
                                                <td>Mr. Kapil <br/> (Transport Incharge) </td>
                                                <td>Member</td>
                                                <td>9253306906</td>
                                                <td>kapilchauhan@mvn.edu.in</td>
                                            </tr>
                                           <tr>
                                                <td>15</td>
                                                <td>Mr. Ram Kumar (DPE)</td>
                                                <td>Member</td>
                                                <td>8397979266</td>
                                                <td>ram.kumar@mvn.edu.in</td>                                           
                                           </tr>
                                           <tr>
                                                <td>16</td>
                                                <td>Mr. Balwan Chauhan (22SL4002) </td>
                                                <td>Student Representative</td>
                                                <td>------</td>
                                                <td>------</td>   
                                           </tr>
                                           <tr>
                                                <td>17</td>
                                                <td>Aishwarya Verma (22MS5002)</td>
                                                <td>Student Representative</td>
                                                <td>------</td>
                                                <td>------</td>                                             
                                           </tr>
                                           <tr>
                                                <td>18</td>
                                                <td>Dy. Registrar</td>
                                                <td>Member Secretary</td>
                                                <td>9991978122</td>
                                                <td>dy.registrar@mvn.edu.in</td>   
                                           </tr>  
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> 
        
    </> 
  ) 
} 
 
export default AntiRaggingCommittees 
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {
    return (
        <>
            <section id="work" className="pt-150 pb-105">
                <div className="container">
                    <div className="portfolio ">
                        <div className="row  fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                            <div className="elementor-widget-wrap elementor-element-populated">
                                <div className="elementor-element elementor-element-ada8c66 elementor-widget elementor-widget-shortcode" data-id="ada8c66" data-element_type="widget" data-widget_type="shortcode.default">
                                    <div className="elementor-widget-container">
                                        <div className="elementor-shortcode">
                                            <div id="tablepress-1_wrapper" className="dataTables_wrapper no-footer"><div className="dataTables_scrollBody" style={{ position: 'relative', overflow: 'auto', width: "100%" }}><table id="tablepress-1" className="tablepress tablepress-id-1 dataTable no-footer" style={{ width: '100%' }}><thead style={{background:'#d9edf7',padding:'15px'}}>
                                                <tr className="row-1 odd">
                                                    <th colSpan={4}> List of Ph.D. Scholars at MVN Univrsity, Plawal</th>
                                                   
                                                </tr>
                                            </thead>

                                            <tbody class="row-hover" style={{ width: '100%',padding:'10px' }}>
<tr class="row-2 even">
	<td class="column-1" style={{width:'3%'}}><strong>S.N.</strong></td><td class="column-2" style={{width:'20%'}}><strong>School/ Department</strong></td><td class="column-3" style={{width:'20%'}}><strong>Student Name </strong></td><td class="column-4" style={{width:'57%'}}><strong>Research Topic</strong></td><td class="column-5"></td>
</tr>
<tr class="row-3 odd">
	<td class="column-1">1</td><td class="column-2">School of law</td><td class="column-3">BRAJENDRA SINGH KUSHWAHA</td><td class="column-4">New Dimension of Judicial Review in India: A Critical Legal Anlysis</td><td class="column-5"></td>
</tr>
<tr class="row-4 even">
	<td class="column-1">2</td><td class="column-2">School of law</td><td class="column-3">CHANDRANSHU PATERIA</td><td class="column-4">A Crticial Legal Analysis Right to Health and Hygienic Environment In India : A Need of Change</td><td class="column-5"></td>
</tr>
<tr class="row-5 odd">
	<td class="column-1">3</td><td class="column-2">School of law</td><td class="column-3">DOLLY RAJ BHADUR</td><td class="column-4">Misuse of Domestic Violence Act-A Crtical Analysis in Indian Scenario</td><td class="column-5"></td>
</tr>
<tr class="row-6 even">
	<td class="column-1">4</td><td class="column-2">School of law</td><td class="column-3">LAXHMAN ROHIT DUBEY</td><td class="column-4">A Critical Analysis on Adultery Laws in Indian  Scenario : Need for Introspection </td><td class="column-5"></td>
</tr>
<tr class="row-7 odd">
	<td class="column-1">5</td><td class="column-2">School of law</td><td class="column-3">SAPNA VERMA</td><td class="column-4">A Critical Legal Study of White Collar Crime with special reference ro Indian Exchequer</td><td class="column-5"></td>
</tr>
<tr class="row-8 even">
	<td class="column-1">6</td><td class="column-2">School of law</td><td class="column-3">SOHAN SINGH</td><td class="column-4">GST and its Impact on Buisness in India - A Critical Legal</td><td class="column-5"></td>
</tr>
<tr class="row-9 odd">
	<td class="column-1">7</td><td class="column-2">School of law</td><td class="column-3">RITU</td><td class="column-4">Addressing the Scocio-Legal Environment for Juveniles kept in Shelter Homes in the state of Haryana</td><td class="column-5"></td>
</tr>
<tr class="row-10 even">
	<td class="column-1">8</td><td class="column-2">School of law</td><td class="column-3">YASH PAL SHARMA</td><td class="column-4">New Dimenssions of Consumer Protection in India </td><td class="column-5"></td>
</tr>
<tr class="row-11 odd">
	<td class="column-1">9</td><td class="column-2">School of law</td><td class="column-3">DINESH MOHAN SINHA</td><td class="column-4">Concept and Constitutional Validity of Prosecution under Income Tax Act, 1961: A Study in Light of Recenet and Other Enactments</td><td class="column-5"></td>
</tr>
<tr class="row-12 even">
	<td class="column-1">10</td><td class="column-2">School of law</td><td class="column-3">RAVI CHADHA</td><td class="column-4">Defended his Topic on<br/>
(08/03/2022) required present his presentation again.</td><td class="column-5"></td>
</tr>
<tr class="row-13 odd">
	<td class="column-1">11</td><td class="column-2">School of law</td><td class="column-3">SANJAY AGGARWAL</td><td class="column-4">Defended his Topic on<br/>
(08/03/2022) required present his presentation again.</td><td class="column-5"></td>
</tr>
<tr class="row-14 even">
	<td class="column-1">12</td><td class="column-2">School of law</td><td class="column-3">VARUN KALRA</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-15 odd">
	<td class="column-1">13</td><td class="column-2">School of law</td><td class="column-3">ISHWAR KUMARI</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-16 even">
	<td class="column-1">14</td><td class="column-2">School of law</td><td class="column-3">RACHNA</td><td class="column-4">Defended her Topic on<br/>
(07/03/2022) required present her presentation again.</td><td class="column-5"></td>
</tr>
<tr class="row-17 odd">
	<td class="column-1">15</td><td class="column-2">School of law</td><td class="column-3">KARUNA</td><td class="column-4">On Some Studies of Comparision on the role of fundamental rights with other developed Countries</td><td class="column-5"></td>
</tr>
<tr class="row-18 even">
	<td class="column-1">16</td><td class="column-2">School of law</td><td class="column-3">SANJEEV TIWARI</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-19 odd">
	<td class="column-1">17</td><td class="column-2">School of law</td><td class="column-3">DEVESH GROVER</td><td class="column-4">Defended his Topic on<br/>
(08/03/2022) required present his presentation again.</td><td class="column-5"></td>
</tr>
<tr class="row-20 even">
	<td class="column-1">18</td><td class="column-2">School of law</td><td class="column-3">SURBHI BHARDWAJ</td><td class="column-4">Defended her Topic on<br/>
(07/03/2022) required present her presentation again.</td><td class="column-5"></td>
</tr>
<tr class="row-21 odd">
	<td class="column-1">19</td><td class="column-2">School of law</td><td class="column-3">NAND KISHORE AGGARWAL</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-22 even">
	<td class="column-1">20</td><td class="column-2">School of law</td><td class="column-3">PARVINDER</td><td class="column-4">Bail of an accused with special refrence to henous crime: A critical legL study</td><td class="column-5"></td>
</tr>
<tr class="row-23 odd">
	<td class="column-1">21</td><td class="column-2">School of law</td><td class="column-3">RAMA</td><td class="column-4">A comparative study on witness protection law in India with the existing international law</td><td class="column-5"></td>
</tr>
<tr class="row-24 even">
	<td class="column-1">22</td><td class="column-2">School of law</td><td class="column-3">RACHNA  / W21</td><td class="column-4">A legal study on right to privacy in contemproray digital times</td><td class="column-5"></td>
</tr>
<tr class="row-25 odd">
	<td class="column-1">23</td><td class="column-2">School of law</td><td class="column-3">PRINCE KUMAR</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-26 even">
	<td class="column-1">24</td><td class="column-2">School of law</td><td class="column-3">RAM KUMAR</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-27 odd">
	<td class="column-1">25</td><td class="column-2">School of law</td><td class="column-3">CHANCHAL VASHISHTHA</td><td class="column-4">An analysis of Domestic viloence Act, 2005 In the prospective of Man</td><td class="column-5"></td>
</tr>
<tr class="row-28 even">
	<td class="column-1">26</td><td class="column-2">School of law</td><td class="column-3">SUSHIL KUMAR</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-29 odd">
	<td class="column-1">27</td><td class="column-2">School of law</td><td class="column-3">NEERAJ MEHTA</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-30 even">
	<td class="column-1">28</td><td class="column-2">School of law</td><td class="column-3">VINEET CHAUHAN</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-31 odd">
	<td class="column-1">29</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">NIKHLESHWAR KUMAR RANA</td><td class="column-4">A Novel Method for Improved Delivery of Coenzyme Q10.</td><td class="column-5"></td>
</tr>
<tr class="row-32 even">
	<td class="column-1">30</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">SEEMA CHAHAR BENIWAL</td><td class="column-4">Design, Synthesis and Biological activity evaluation of Carbazole boronic aacid and Carbazole Sulfonamido Boronic acid derivatives as molecular hybrids  for effective cancer therapy</td><td class="column-5"></td>
</tr>
<tr class="row-33 odd">
	<td class="column-1">31</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">KM.SWATI SRIVASTAVA</td><td class="column-4">Quality Standards and Anti-Obesity Activity of Jatropha tanjorensis and Fraxinus micrantha</td><td class="column-5"></td>
</tr>
<tr class="row-34 even">
	<td class="column-1">32</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">VIJETA Gupta</td><td class="column-4">Standardization and biological evaluation of boerhavia diffusa Linn. Obtained from different geographical region</td><td class="column-5"></td>
</tr>
<tr class="row-35 odd">
	<td class="column-1">33</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">MAHESH KUMAR</td><td class="column-4">Synthesis, Formulation &amp; Characterization of Anti-<br/>
Tubercular Prodrug</td><td class="column-5"></td>
</tr>
<tr class="row-36 even">
	<td class="column-1">34</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">BHAWNA GOEL</td><td class="column-4">Novel Strategies for improving therapetic anticancer efficacy for the treatment of triple negative breast cancer </td><td class="column-5"></td>
</tr>
<tr class="row-37 odd">
	<td class="column-1">35</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">SWATI DUBEY</td><td class="column-4">To Synthesize, characterize ecofriendly, cost effective green metallic nanoparticles and analyse their biological activity</td><td class="column-5"></td>
</tr>
<tr class="row-38 even">
	<td class="column-1">36</td><td class="column-2">School of PHARMACEUTICAL SCIENCES</td><td class="column-3">NEHA BHAMBOO</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-39 odd">
	<td class="column-1">37</td><td class="column-2">School of Engineering and technology</td><td class="column-3">MAMTA SAXENA</td><td class="column-4">Predictive Analytics of Education Data Based Learning Patterns</td><td class="column-5"></td>
</tr>
<tr class="row-40 even">
	<td class="column-1">38</td><td class="column-2">School of Engineering and technology</td><td class="column-3">BABITA YADAV</td><td class="column-4">Securing Telemedicine Using Blockchain</td><td class="column-5"></td>
</tr>
<tr class="row-41 odd">
	<td class="column-1">39</td><td class="column-2">School of Engineering and technology</td><td class="column-3">MONIKA CHAWLA</td><td class="column-4">Zero Trust Network Security in Identity Access Management</td><td class="column-5"></td>
</tr>
<tr class="row-42 even">
	<td class="column-1">40</td><td class="column-2">School of Engineering and technology</td><td class="column-3">POONAM</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-43 odd">
	<td class="column-1">41</td><td class="column-2">School of Engineering and technology</td><td class="column-3">ATUL RANA</td><td class="column-4">Organizational Infrastructure Security through attack tree &amp; MITRE  ATTACK Framework</td><td class="column-5"></td>
</tr>
<tr class="row-44 even">
	<td class="column-1">42</td><td class="column-2">School of Engineering and technology</td><td class="column-3">SACHIN GARG</td><td class="column-4">A Decentralized Approach to online review based reputation management using Blockchain</td><td class="column-5"></td>
</tr>
<tr class="row-45 odd">
	<td class="column-1">43</td><td class="column-2">School of Engineering and technology</td><td class="column-3">SUDHA RANI</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-46 even">
	<td class="column-1">44</td><td class="column-2">School of Engineering and technology</td><td class="column-3">SUDHA </td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-47 odd">
	<td class="column-1">45</td><td class="column-2">School of Engineering and technology</td><td class="column-3">AMRITA KOUL</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-48 even">
	<td class="column-1">46</td><td class="column-2">School of Engineering and technology</td><td class="column-3">NEETU SINGH</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-49 odd">
	<td class="column-1">47</td><td class="column-2">School of Engineering and technology</td><td class="column-3">ANITA</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-50 even">
	<td class="column-1">48</td><td class="column-2">School of Engineering and technology</td><td class="column-3">AWNINDRA DAUTT TIWARI</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-51 odd">
	<td class="column-1">49</td><td class="column-2">School of Engineering and technology</td><td class="column-3">VISHAL SINGH</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-52 even">
	<td class="column-1">50</td><td class="column-2">School of Engineering and technology</td><td class="column-3">VIBHORE GOEL</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-53 odd">
	<td class="column-1">51</td><td class="column-2">School of Engineering and technology</td><td class="column-3">Ms Ruchika Gupta</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-54 even">
	<td class="column-1">52</td><td class="column-2">School of Engineering and technology</td><td class="column-3">Ms Suman Devi</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-55 odd">
	<td class="column-1">53</td><td class="column-2">School of Engineering and technology</td><td class="column-3">Deepika Yadav</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-56 even">
	<td class="column-1">54</td><td class="column-2">School of Engineering and technology</td><td class="column-3">ABHISHEK GUPTA</td><td class="column-4">Image Haze Removal Using Single Scale Retinex (SSR) Technique</td><td class="column-5"></td>
</tr>
<tr class="row-57 odd">
	<td class="column-1">55</td><td class="column-2">School of Engineering and technology</td><td class="column-3">JOGINDER SINGH</td><td class="column-4">Design &amp; implementation of self regulating control strategyfor desalination of water using IoT</td><td class="column-5"></td>
</tr>
<tr class="row-58 even">
	<td class="column-1">56</td><td class="column-2">School of Engineering and technology</td><td class="column-3">MOHIT BANSAL</td><td class="column-4">Two Level Color Image Security using Advance Image Cryptography &amp; Audio Watermarking</td><td class="column-5"></td>
</tr>
<tr class="row-59 odd">
	<td class="column-1">57</td><td class="column-2">School of Engineering and technology</td><td class="column-3">AMRESH KUMAR</td><td class="column-4">REAL TIME MONITORING AND CONTROL OF AGRICULTURE DATA USING IOT FOR IMPROVEMENT CROP PERFORMANCE</td><td class="column-5"></td>
</tr>
<tr class="row-60 even">
	<td class="column-1">58</td><td class="column-2">School of Business management and commerce</td><td class="column-3">ANKUR SHARAN</td><td class="column-4">Integration of Supply Chain Management in import oriented industries, inherent problem and probable solution: With special reference of Automotive Sectort</td><td class="column-5"></td>
</tr>
<tr class="row-61 odd">
	<td class="column-1">59</td><td class="column-2">School of Business management and commerce</td><td class="column-3">VIKRAM MANCHANDA</td><td class="column-4">Mobile Data Connevtivity as as Opportunity for Business Transformation - A Study </td><td class="column-5"></td>
</tr>
<tr class="row-62 even">
	<td class="column-1">60</td><td class="column-2">School of Business management and commerce</td><td class="column-3">NEHA DAGAR</td><td class="column-4">A Study on Work Life Balance Among Women in Public and Private Sector of Haryana</td><td class="column-5"></td>
</tr>
<tr class="row-63 odd">
	<td class="column-1">61</td><td class="column-2">School of Business management and commerce</td><td class="column-3">SNEHA BALYAN</td><td class="column-4">NPA'S as an Indicator of Oerformance of Banks - A Comparative study of Public Sector and Private Sector</td><td class="column-5"></td>
</tr>
<tr class="row-64 even">
	<td class="column-1">62</td><td class="column-2">School of Business management and commerce</td><td class="column-3">ALKA BABBER</td><td class="column-4">Impact of Capital Structure on Profitability: An Empirical Analysis of Selected Indian Companies</td><td class="column-5"></td>
</tr>
<tr class="row-65 odd">
	<td class="column-1">63</td><td class="column-2">School of Business management and commerce</td><td class="column-3">J.ERNEST SAMUEL RATNAKUMAR</td><td class="column-4">Quality Related issues in Higher Education: A case study on the role of Private Univesities in Rajasthan</td><td class="column-5"></td>
</tr>
<tr class="row-66 even">
	<td class="column-1">64</td><td class="column-2">School of Business management and commerce</td><td class="column-3">RASHMI</td><td class="column-4">Impact of Liquidity, Solvecny on Profitability of Four Wheeler Automobile Industry in India</td><td class="column-5"></td>
</tr>
<tr class="row-67 odd">
	<td class="column-1">65</td><td class="column-2">School of Business management and commerce</td><td class="column-3">PRIYANKA CHAUHAN</td><td class="column-4">Hazards o Eletronic waste management &amp; its probable solution: An impeccable reserach with special focus on Delhi NCR</td><td class="column-5"></td>
</tr>
<tr class="row-68 even">
	<td class="column-1">66</td><td class="column-2">School of Business management and commerce</td><td class="column-3">ROHIT RASTOGI</td><td class="column-4">Critical Analysis of Higher Education in the State of Uttarakhand</td><td class="column-5"></td>
</tr>
<tr class="row-69 odd">
	<td class="column-1">67</td><td class="column-2">School of Business management and commerce</td><td class="column-3">SANJANA ADHANA</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-70 even">
	<td class="column-1">68</td><td class="column-2">School of Business management and commerce</td><td class="column-3">MANUPRIYA SEHGAL</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-71 odd">
	<td class="column-1">69</td><td class="column-2">School of Business management and commerce</td><td class="column-3">SUMAN KHARBANDA</td><td class="column-4">Awarness, Purchase Intention and Buying behaviour or Urban Indian Consumer for Green Products</td><td class="column-5"></td>
</tr>
<tr class="row-72 even">
	<td class="column-1">70</td><td class="column-2">School of Business management and commerce</td><td class="column-3">GOVIND RAM</td><td class="column-4">Factors affecting voluntary blood donation among youth: A study of Semi urban area of palwal District, Harayana</td><td class="column-5"></td>
</tr>
<tr class="row-73 odd">
	<td class="column-1">71</td><td class="column-2">School of Business management and commerce</td><td class="column-3">RAHUL MONGIA</td><td class="column-4">MSME's preception of GST in India</td><td class="column-5"></td>
</tr>
<tr class="row-74 even">
	<td class="column-1">72</td><td class="column-2">School of Business management and commerce</td><td class="column-3">PALASH KAUSHIK</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-75 odd">
	<td class="column-1">73</td><td class="column-2">School of Business management and commerce</td><td class="column-3">REENA RANI BANSAL</td><td class="column-4">Private Commercial banks future in India: The case of lakshmi Vilas bank &amp; Yes Bank</td><td class="column-5"></td>
</tr>
<tr class="row-76 even">
	<td class="column-1">74</td><td class="column-2">School of Business management and commerce</td><td class="column-3">VIPLUV PATHAK</td><td class="column-4">Course Work in progress</td><td class="column-5"></td>
</tr>
<tr class="row-77 odd">
	<td class="column-1">75</td><td class="column-2">School of Business management and commerce</td><td class="column-3">MUKUL KHANNA</td><td class="column-4">Social Media Marketing Influence on Consumers' Purchase Intention of Electronics Goods</td><td class="column-5"></td>
</tr>
<tr class="row-78 even">
	<td class="column-1">76</td><td class="column-2">School of Business management and commerce</td><td class="column-3">RESHO</td><td class="column-4">To study the effectiveness of organizational cultural intelligence and communication for global leadership readiness</td><td class="column-5"></td>
</tr>
<tr class="row-79 odd">
	<td class="column-1">77</td><td class="column-2">School of Business management and commerce</td><td class="column-3">REKHA KUMARI</td><td class="column-4">Course Work in progress</td><td class="column-5"></td>
</tr>
<tr class="row-80 even">
	<td class="column-1">78</td><td class="column-2">School of Business management and commerce</td><td class="column-3">RAJAT BHARDWAJ</td><td class="column-4">Course Work in progress</td><td class="column-5"></td>
</tr>
<tr class="row-81 odd">
	<td class="column-1">79</td><td class="column-2">School of Business management and commerce</td><td class="column-3">Bharati Chaudhary</td><td class="column-4">Course Work in progress</td><td class="column-5"></td>
</tr>
<tr class="row-82 even">
	<td class="column-1">80</td><td class="column-2">School of Business management and commerce</td><td class="column-3">Anjali Gupta</td><td class="column-4">Course Work in progress</td><td class="column-5"></td>
</tr>
<tr class="row-83 odd">
	<td class="column-1">81</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">LALITA</td><td class="column-4">Studies on Pyrolysis of Commodity Plastics with Biomass</td><td class="column-5"></td>
</tr>
<tr class="row-84 even">
	<td class="column-1">82</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">Ram Kumar</td><td class="column-4">Entropy Generation Analysis in Heat and Mass Transport Characteristics of Nano Fluids over Stretching Surfaces</td><td class="column-5"></td>
</tr>
<tr class="row-85 odd">
	<td class="column-1">83</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">Mamta Sharma</td><td class="column-4">Topic Pending</td><td class="column-5"></td>
</tr>
<tr class="row-86 even">
	<td class="column-1">84</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">Sangita Sheron</td><td class="column-4">on some studies in Bulk Transporation system optimization</td><td class="column-5"></td>
</tr>
<tr class="row-87 odd">
	<td class="column-1">85</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">Vinnay Banga</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-88 even">
	<td class="column-1">86</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">SEEMA SHARMA</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-89 odd">
	<td class="column-1">87</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">BHAWNA</td><td class="column-4">Predictive Model Analysis for understanding Plasma Instabilities in Tokamak type</td><td class="column-5"></td>
</tr>
<tr class="row-90 even">
	<td class="column-1">88</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">SHIKHA MAURYA</td><td class="column-4">Investigation of Plasma Instabilities in duty Plasamas</td><td class="column-5"></td>
</tr>
<tr class="row-91 odd">
	<td class="column-1">89</td><td class="column-2">School of Science &amp; Humanities<br/>
POLITICAL SCIENCE</td><td class="column-3">Yogesh</td><td class="column-4">Course Work in Progress</td><td class="column-5"></td>
</tr>
<tr class="row-92 even">
	<td class="column-1">90</td><td class="column-2">School of Allied Health Science </td><td class="column-3">MAHESH SINGH DANU</td><td class="column-4">In Vitro Studies of Antidiabetics Potential of Herbal Microbiome</td><td class="column-5"></td>
</tr>
<tr class="row-93 odd">
	<td class="column-1">91</td><td class="column-2">School of Allied Health Science </td><td class="column-3">Priya</td><td class="column-4">Course work in progress</td><td class="column-5"></td>
</tr>

</tbody>
                                            </table></div></div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
             
            </section>
        </>
    )
}

export default First
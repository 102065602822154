import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBmm() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="PEO, PO & PSO – BMM" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                                               
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p> [PEO. 1] To provide thorough knowledge in Medical Microbiology subjects including theoretical and practical training for appropriate continuing education opportunities to microbiology professionals. Provide opportunities for technology enhanced experiential learning in both the classroom and the laboratory.</p>

                                <p>[PEO. 2] Prepare graduates who excel as entry-level technologists and researcher. Provide a leading-edge educational experience that provides academic depth and breadth to support professional advancement.</p>

                                <p>
                                    [PEO. 3] To prepare graduates for employment in a variety of settings at career entry level. To provide an equal opportunity for all the students to become an entrepreneur through various labs visit and internship.
                                </p>

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                     PO1: Have Knowledge and technical skills associated with microbiology laboratory for delivering quality clinical investigations.</p>
                                <p>  PO2: Perform safe use of basic laboratory glassware and equipment including the cell counter, microscope, centrifuge, incubator, colorimeter, analytical balance, and microtome.</p>
                                <p>  PO3: Conduct routine clinical laboratory procedures within acceptable quality control parameters in bacteriology, virology, mycology, parasitology and immunology.</p>
                                <p>  PO4: Learn Problem solving techniques in identification and correction of pre analytical, post analytical & analytical variables.</p>
                                <p>  PO5: Demonstrate technical skills, social behavior and professional awareness for functioning effectively as a microbiology technician.</p>
                                <p>  PO6: Maintain & operate laboratory equipment utilizing appropriate quality control and safety procedures.</p>
                                <p>  PO7: Identify the impact of laboratory tests in a global and environmental context.</p>
                                <p>  PO8: Perform as a leader/team member in diverse professional and industrial research areas.</p>
                                <p>  PO9: Use the fundamentals of research process to complete and present research studies that enrich the field of clinical laboratories.</p>
                                <p>  PO10: Gain practical knowledge through internship at various multi-specialty hospitals and various centers.</p>
                                <p>  PO11: Embodied in this expertise is the ability to adapt to the changes required by medical advances.</p>

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                PSO1. Comprehensive knowledge of structure, function and pathological changes of the organs and the clinical correlation of diseases and its pathology.</p>
                                <p>PSO2. Understand fundamentals of biochemistry including carbohydrates, lipids, proteins nucleotides, enzymes.</p>
                                <p>PSO3. Knowledge of epidemiology, surveillance and control of infections (community & hospital).</p>
                                <p>PSO4. Understand the basic microbial structure and function and characteristics of prokaryotes and eukaryotes.</p>
                                <p>PSO5. Demonstrate various culture media and microbial techniques for isolation of pure cultures of bacteria and fungi.</p>
                                <p>PSO6. Understand the basic principles of medical microbiology and infectious diseases including mechanism of disease transmission, principles of aseptic practices, and the role of normal micro flora.</p>
                                <p>PSO7. Acquire knowledge of Immune reactions and laboratory tests for detection of antigen and antibodies and its clinical</p>
                                <p>PSO8. Understand the different methods of viral cultivation such as tissue culture, embryonated egg and animal inoculations.</p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBmm
import React from 'react'
import Header from '../headerone/Main'
import { Link } from 'react-router-dom'

function CRCActivities() {
    return (
        <>
            <Header />
            <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-left">
                                <div className="breadcrumb-title">
                                    {/* <h2> {image?"":subtitle} </h2> */}
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb-wrap2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/crc"> Corporate Resource Center </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        CRC Activities
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Bredcom title="Home" subtitle="Corporate Resource Center" /> */}
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">
                                    <h3>MVN University - Corporate Resource Center (CRC) Activities</h3>
                                </div>

                            </div>

                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <p>The sole objective of the Corporate Resource Center is to make the student’s industry ready and capable to face the multiple challenges of the corporate world and have a smooth transition from campus to the corporate world. The CRC Department plays an important role in shaping the careers of students from their induction, summer internships, final placements, and even beyond.

                                </p><p>Following are the tasks of the CRC Department:</p>
                                <ul>
                                    <ol>1.Enhancing student employability</ol>
                                    <ol> 2.Pre-placement Talk, Guest Lecture Workshop, etc</ol>
                                    <ol>3.Continuous improvement in the systems and processes</ol>
                                    <ol>4.Industrial Visits. Summer Internship</ol>
                                    <ol>5.Higher and better job placements with a wider industry reach</ol>
                                    <ol>6.Obtaining industry feedback for improving the academic curriculum</ol>
                                </ul>
                                <p><b>Contact details :</b></p>
                                <p style={{ marginTop: '-20px' }}><b>Name :</b> Pankaj Bhardwaj</p>
                                <p style={{ marginTop: '-20px' }}><b>Email :</b> pankajbhardwaj.crc@mvn.edu.in </p>
                                <p style={{ marginTop: '-20px' }}><b>Designation :</b> Training and Placement Officer </p>
                                <p style={{ marginTop: '-20px' }}><b>Phone :  </b>9811035688</p>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                        
<div className="image-container">
  <img src="/assets/crc-activity/activity1.jpeg" alt="Description" clasName="responsive-image"/>
  <img src="/assets/crc-activity/activity2.jpeg" alt="Description" clasName="responsive-image"/>
  <img src="/assets/crc-activity/activity3.jpg" alt="Description" clasName="responsive-image"/>
  <img src="/assets/crc-activity/activity4.jpeg" alt="Description" clasName="responsive-image"/>
  <img src="/assets/crc-activity/activity5.jpeg" alt="Description" clasName="responsive-image"/>
  <img src="/assets/crc-activity/activity6.jpeg" alt="Description" clasName="responsive-image"/>
  <img src="/assets/crc-activity/activity7.jpeg" alt="Description" clasName="responsive-image"/>
  <img src="/assets/crc-activity/activity8.jpg" alt="Description" clasName="responsive-image"/>
</div>
                            {/* <div className="row justify-content-center">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity1.jpeg" alt="img"  />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity2.jpeg" alt="img" className='healthImg' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity3.jpg" alt="img" className='healthImg' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity4.jpeg" alt="img" className='healthImg' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity5.jpeg" alt="img" className='healthImg' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity6.jpeg" alt="img" className='healthImg' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity7.jpeg" alt="img" className='healthImg' />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <img src="/assets/crc-activity/activity8.jpg" alt="img" className='healthImg' />
                                </div>
                            </div> */}


                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}

export default CRCActivities;
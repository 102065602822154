import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import BredcomSlider from '../Bredcom/MainSlider'
import NewMain from '../headerone/newMain'
function LifeAtMvn() { 
  return (
    <>
      <NewMain /> 
      <BredcomSlider title="Home" subtitle="Life At MVN" />
      
      <section>
          <div className='container'>
              <div className='mvn_imse'> 
                  <img src={`${process.env.PUBLIC_URL}/assets/img/new_images/life_mvn_banner.jpg`}  alt="img" className="vc-img" />
              </div>

              <div className='mvn_imse_omse'> 
                  <p>  Welcome to MVN University, where the journey of learning is intertwined with enriching experiences, personal growth, and lifelong memories. Life at MVN University is more than just academics; it’s about creating a well-rounded and fulfilling experience for every student. Here’s what you can expect during your time here: </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Vibrant Campus Life  </h3>
                  <p>  Our campus is a hub of activity, offering a perfect blend of academic. From lively student gatherings to serene study spots, there’s a place for everyone. Our green lawns, modern infrastructure, and welcoming environment make MVN University a home away from home. </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Student Accommodation </h3>
                  <p>  We provide comfortable and secure accommodation options for our students. Our hostels are equipped with all the necessary amenities to ensure a safe and conducive living environment. With 24/7 security, Wi-Fi connectivity, and common areas for socializing, students can focus on their studies while enjoying a community living experience.</p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Academic Excellence  </h3>
                  <p>  At MVN University, we are committed to academic excellence. Our state-of-the-art classrooms, well-equipped laboratories, and extensive library resources create an ideal learning environment. Our experienced faculty members are dedicated to mentoring and guiding students, helping them achieve their academic and professional goals.
                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Extracurricular Activities  </h3>
                  <p>  We believe in nurturing the holistic development of our students. Our university offers a wide range of extracurricular activities, including sports, cultural events, and club activities. Whether you are a budding artist, a passionate athlete, or a tech enthusiast, you will find numerous opportunities to explore and develop your talents.
                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Cultural Diversity  </h3>
                  <p> MVN University is a melting pot of cultures and traditions. Our diverse student body brings together individuals from different backgrounds, fostering a rich cultural exchange. Festivals, cultural nights, and international student events are an integral part of campus life, celebrating the uniqueness and unity of our community.
                  </p>
              </div>


              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Sports and Wellness
                  </h3>
                  <p> Physical fitness and well-being are essential components of student life at MVN University. Our sports facilities include grounds for football, cricket courts, and indoor games. Regular fitness programs, yoga sessions, and wellness workshops help students maintain a healthy lifestyle.
                  </p>
              </div>

              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Innovation and Research  </h3>
                  <p> We encourage our students to push the boundaries of knowledge and innovation. Our research centers and innovation labs provide a platform for students to work on cutting-edge projects and collaborate with industry experts. We support entrepreneurial ventures and innovative ideas through mentorship and resources </p>
              </div>

              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>  Community Engagement </h3>
                  <p> At MVN University, we are committed to making a positive impact on society. Our students actively participate in community service and outreach programs, contributing to social causes and community development. These experiences develops a sense of responsibility and empathy, preparing students to be compassionate leaders.
                  </p>
              </div>

              <div className='mvn_imse_omse'>
                  <h3 className='new_title'> Career Development  </h3>
                  <p> Preparing students for successful careers is a top priority at MVN University. Our Corporate Resource Centre (CRC) offers comprehensive support, including career counselling, resume building, and interview preparation. We organize job fairs, internship opportunities, and campus recruitment drives to connect students with leading employers.
                  </p>
              </div>

              <div className='mvn_imse_omse'>
                  <h3 className='new_title'>Student Support Services   </h3>
                  <p>  We are dedicated to providing a supportive environment for all our students. Our counselling centre, health services, and academic support programs ensure that students have access to the resources they need to thrive. Whether it’s academic guidance or personal counselling, our support services are always available.
                  </p>
              </div>

              <div className='mvn_imse_omse'>
                  <h3 className='new_title'> Events and Celebrations
                  </h3>
                  <p>  Life at MVN University is filled with exciting events and celebrations. From our annual cultural fest to sports meets and academic conferences, there’s always something happening on campus. These events provide a platform for students to showcase their talents, network with peers, and create lasting memories.
                  </p>
              </div>

              <div className='mvn_imse_omse'>
                  
                  <p>  Experience the vibrant and dynamic life at MVN University, where every day brings new opportunities for learning, growth, and fun. Join us and be a part of a community that values excellence, innovation, and holistic development.
                  </p>
              </div>

              
          </div>
      </section>




    </>
  )
}

export default LifeAtMvn
import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import Brand from '../../menhomethree/Brand'
import First from '../menteacher/First'
import Expert from '../../menhomethree/Expert'
import NewMain from '../../headerone/newMain'
const CardStyle = {
    
  }

function Main() {
    const CARDS = [
        {
          icon: 'Logo_of_Bar_Council_of_India.svg',
          title: 'Bar Council of India',
          
        },
        {
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/Emblem_of_Haryana.svg/300px-Emblem_of_Haryana.svg.png',
          title: 'Government of Haryana',
          
        },
        {
          icon: '/Association_of_Indian_Universities_Logo.svg',
          title: 'Association of Indian Universities',
         
        },
      
        {
          icon: 'Pharmacy_Council_of_India_Logo.svg',
          title: 'Pharmacy Council of India',
          
        },
        {
          icon: 'tiap.svg',
          title: 'The Indian Association of Physiotherapists',
          
        },
        {
          icon: 'ugc.svg',
          title: 'University of Grants Comission',
         
        },
      ];
  return (
    <>
        <NewMain/>
        <Bredcom title="Home" subtitle="Goverment Reconigations"/>
        <div className="container">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className='row'>
                {CARDS.map((card) => {
                    return (

                    <div className="col-lg-2 col-md-2 col-sm-12">
                        <div className='government-recognization'>
                        <img
                            src={card.icon}
                            alt={card.title}
                            style={{
                            // ...CardStyle,
                            width: 100,
                            height: 120,
                            margin: "0 auto",
                            }}
                        />
                        <p>{card.title}</p>
                        </div>
                    </div>
                    )
                })}
                </div>
            </div>
        </div>
        
        {/* <First/>
        <Brand/>
        <Expert/> */}
    </>
  )
}

export default Main
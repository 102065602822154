import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSPlacement=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Science & Humanities" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Training and Placement" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Training and Placement Cell within the School of Science and Humanities, is committed to creating pathways for students towards successful professional endeavors. The Training and Placement Cell stands as the cornerstone of nurturing talent and potential within the School of Science and Humanities. With a meticulous blend of industry insights and academic prowess, the cell orchestrates a comprehensive suite of programs aimed at honing students' skills and enhancing their employability. Through strategic partnerships with leading corporations and organizations, the cell facilitates internships, campus placements, guest lectures, and industry interactions, providing students with invaluable exposure to real-world scenarios and professional networks. Tailored workshops, mock interviews, and resume-building sessions equip students with the tools and confidence needed to navigate the competitive job market. The cell operates as a guiding beacon, offering personalized career counseling and mentorship to students at every stage of their academic journey. By understanding individual aspirations and aptitudes, advisors assist students in charting personalized career paths and seizing opportunities aligned with their interests and goals.
<br/><br/>
Driven by a passion for nurturing talent and facilitating meaningful connections, the Training and Placement Cell of School of Science and Humanities serves as a gateway to endless possibilities, enabling students to embark on rewarding careers and have a lasting impact on society.
<br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSPlacement;
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AcademicButton from '../academicButton';

const faqItems = [
    {
        id: 'headingThree',
        question: '01  What business disciplines and specializations are covered within the School of Business Management, and how do they prepare students for dynamic career paths in the business world?',
        answer: 'The School of Business Management offers a diverse range of programs encompassing fields such as marketing, finance, entrepreneurship, supply chain management, and more. Our curriculum is designed to equip students with a comprehensive understanding of business principles, strategic thinking, and leadership skills. Graduates emerge well-prepared to navigate the evolving business landscape and pursue careers in corporate management, consulting, startups, and beyond.',
    },
    {
        id: 'headingOne',
        question: '02 How does the School of Business Management foster practical learning and experiential opportunities, such as internships or industry projects?',
        answer: 'Practical learning is integral to our approach. The School emphasizes hands-on experiences through internships, industry projects, and case studies. Students collaborate with industry professionals, work on real-world business challenges, and gain insights into effective decision-making and problem-solving. These opportunities not only bridge the gap between theory and practice but also cultivate the skills needed to thrive in competitive business environments.',
    },
    {
        id: 'headingTwo',
        question: '03 Can students in the School of Business Management access networking events, mentorship, and connections with business leaders and alumni?',
        answer: 'Networking and mentorship are cornerstones of our School mission. We organize networking events, workshops, and guest speaker sessions featuring prominent business leaders and successful alumni. Through these interactions, students can expand their professional networks, gain valuable insights into industry trends, and receive guidance from experienced mentors. Our strong alumni community also offers ongoing support and opportunities for career advancement.',
    },
];

function First() {
    return (
        <>
            <section className="project-detail">
                <div className="container">
                <AcademicButton path='/school-of-business-management' pageName="School of Business Management & Commerce"/>
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                {/* <h2>School of Business Management & Commerce</h2> */}
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        {/* <figure className="industryImg">
                                            <img src="https://admission.mvn.edu.in/wp-content/uploads/2021/09/sms-banner-1.jpg" alt=""  />
                                        </figure> */}
                                    </div>
                                </div>
                                <div className="inner-column">
                                    
                                    <h3>Course Overview</h3>
                                    <p>
                                    The School of Business Management & Commerce has been imparting quality management and commerce education under the aegis of MVN University since its inception. The MVN University’s School of Management & Commerce has been established with a mandate to impart education to young generation who in turn will transform and redefine the process of business. The School of Business Management & Commerce is running four programs, i.e., three-year B. Com (H), three years BBA, two years MBA, and Ph. D. in Business Management & Commerce. The courses are run by University as per UGC norms.
                                    </p>
                                    <p>
                                    The MVN University’s School of Business Management team of faculty members is highly dedicated and experienced and take initiatives to guide the students in academic, industrial, business and extra co-curricular activities. The School of Business Management & Commerce encourages the students to participate in industry & academic events such as summer training, field work studies in the context of rural entrepreneurs & skill development, business competitions, debates on contemporary business problems, seminars, conferences and workshops. The School of Business Management & Commerce extends consulting services to industry and conduct short duration management development program to academia and industry.
                                    </p>
                                    <h4>Objectives of School of Business Management & Commerce</h4>
                                    <p>
                                    The core objective of the School of Business Management & Commerce is acquaint students to the professional, business, and academic environment by enhancing their inherent talent and skills.
                                    </p>
                                    <p>
                                    SBMC faculty team takes utmost care in imparting the knowledge interwoven with wisdom in an effective manner. No effort is spared to ensure state of art teaching methodology for imparting technology assisted knowledge in the class room.
                                    </p>
                                    
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Courses Offered</th>
                                                <th>Length</th>
                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Link to="/bcom">B.Com (Hons.)</Link></td>
                                                <td>3 Years full time</td>
                                            </tr>
                                            <tr>
                                                <td><Link to="/bba">Bachelor of Business Administration</Link></td>
                                                <td>3 years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/bbahons">BBA (Hons.)</Link></td>
                                                <td>3 years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/mba">MBA (Marketing/Finance/HR/Business Analytics)</Link></td>
                                                <td>2 years full time</td>                                                
                                            </tr>
                                            
                                        </tbody>
                                    </table>

                                    <h4>Message of Dean</h4>

                                    <p>
                                    I, on behalf of School of Business Management & Business of MVN University extend a warm and hearty welcome to all the students joining courses of B. Com (H) (3 Years), BBA (3 Years), MBA (2 Year) and Ph.D. in Business Management & Commerce.
                                    </p>

                                    <p>
                                    The School of Business Management & Commerce is fully concerned with Post Covid-19 new challenges faced by the Indian economy and new threats & opportunities created by pandemic. The usage of technology that has helped individuals, industry, academic and government organizations in carrying out day to day activities. The focus of the School of Management Studies & Commerce is to equip the young generation to face such challenges and make good use of new opportunities if any by imparting skills and knowledge in the post covid-19 era which is full of disruptions and disruptive technologies. At SBMC, it is achieved through classroom teaching, case studies, brainstorming sessions, practical field experiences etc.
                                    </p>

                                    <p>
                                    The School of Business Management & Commerce (SBMC) systematically cultivate skills of all functional areas of business & skills of being a future unicorn entrepreneur to compete and match the futuristic needs of business world & Indian Society.
                                    </p>

                                    <p>
                                    SBMC assures the guidance to all at all levels in shaping your future dreams. A warm welcome to you again from SMBC.
                                    </p>

                                    <h4> School of Business Management & Commerce </h4>

                                    <p>
                                    Ever-changing economy and technological changes have posed new challenges in the business world and given new opportunities for example, in the present-day world manufacturing an item may not be that important, more important is how to sell it and earn more revenue in a competitive world. Thus, evolving various business strategies to make it sell and sustainability of the enterprise over time has become a key issue. The focus of the School of Management Studies & Commerce is to equip a fresher with the necessary skills and knowledge in the above direction to cope with such changes. This is achieved through classroom teaching, case studies, brainstorming sessions, practical field experiences etc so that the students finally get good placements or cultivate entrepreneurial skills to match the futuristic needs of business world.
                                    </p>

                                    <p>
                                    The core objective of the School of Business Management & Commerce is to introduce a student to the professional world by exploring their inherent talent and skills. To bring the best out of a student, we have strategically designed courseware & teaching methodology. The implementation and retention of knowledge by the students are of paramount importance for securing a successful future, our academia takes utmost care to in imparting the knowledge interwoven with wisdom in an effective manner. No effort is spared to ensure teaching methodology is delivered in its best manner. The journey of a student at SBMC, from student life to an aspiring professional gets concluded after they go through a joyful day of journey wherein each of the following teaching methodologies contributes to his success.  The demand for Industries in the various specialised sector is increasing posing opportunities and challenges to academic institutions to accept and flourish.
                                    </p>

                                    <h4>
                                    Public Speaking for MBA Students
                                    </h4>

                                    <p>
                                    In order to build confidence while the students face an interview, the department has started sessions on Public speaking for MBA students. Public speaking modules include pick & speak, Group discussion & extempore. Extempore speech of 1-2 minutes before the personal interview in top MBA colleges is based on instantly given topic on any current affair, social or abstract topic without giving you time enough to think and prepare with a view to judge how coherently and effectively you could think and speak without ambiguity in your thought. Hardly 10-15 seconds are given by the panelists to organize your thoughts before speaking.
                                    </p>



                                    
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                <div className="inner-column3 ">
                                        <h3>About Us</h3>
                                        <ul className="project-info ">
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-business-management-vision-mission"> <strong>Vision & Mission</strong> </Link> 
                                            </li>                                            
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-business-management-peo-po-pso-b-com"><strong>PEO, PO & PSO – B.Com</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-business-management-peo-po-pso-mba"> <strong>PEO, PO & PSO – MBA</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-business-management-peo-po-pso-bba"> <strong>PEO, PO & PSO – BBA</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-business-management-peo-po-pso-bba-hons"> <strong>PEO, PO & PSO – BBA Hons</strong> </Link> 
                                            </li>                                             
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-B.Com_.pdf"><strong>SOS B.Com</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/sos-curriculum-mba-I-year.pdf"><strong>SOS – MBA-I Year</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/sos-curriculum-mba-II-year.pdf"><strong>SOS – MBA-II Year</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/10/SOS-MBA-2020-Batch.pdf"> <strong>SOS – MBA (2020 Batch)</strong> </Link> 
                                            </li>  
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/10/SOS-MBA-2021-Batch.pdf"> <strong>SOS - MBA (2021 Batch)</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/05/BBA-SOS-2021-Onwards.pdf"> <strong>SOS - BBA (2021 Onwards)</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/05/BBA-SOS-2022-Onwards.pdf"> <strong>SOS - BBA (2022 Onwards)</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2023/05/SOS-BBA-Hons.pdf"> <strong>SOS - BBA (Hons.)</strong> </Link> 
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                        Enroll <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
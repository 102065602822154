import { Grid, Typography } from "@mui/material";
import MainTitle from "../../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../../utils/utils";
import BannerCoursesDurationFee from "../../../muiCommonComponent/bannerCoursesDurationFee";
const CSEBSC=()=>{
    const progHiglight=[
        {listName:'Choice-Based Credit System with Core, Foundation, Domain and Open elective baskets.'},
        {listName:'Association with knowledge partners'},
        {listName:'Project Based Learning in mentorship of Faculty and Industry experts.'},
        {listName:'Dedicated Innovation'},
        {listName:'Latest curriculum as per Industry Standards in cutting edge specializations.'},
        {listName:'More than 80% faculty members are doctorate with strong industry and research background'},
        {listName:'The presence of a strong mentoring System and active involvement of faculty provides a conducive environment for holistic development of the students.'},
        {listName:'Student-led Workshops, Synergism, Alumni Talk, participation in national and international project competitions, guidance and funding support for StartUps.'},
        
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹75200/- (Tuition Fee - ₹50000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹59200/- ( Tuition Fee – ₹50000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '75,200'
    const fee_2nd= '59,200'
    const eligibility='Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
</Grid>
<Grid item xs={12}>
    <MainTitle title="Bachelor of Science (Computer Science)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The Bachelor of Science (Computer Science) program is designed to provide comprehensive
knowledge in the field of computer applications, with a strong emphasis on practical, application-
oriented learning. This undergraduate course introduces students to the world of computer science
and its applications, blending theoretical knowledge with practical skills. Key areas of study in the
B.Sc. program include programming languages (such as Java, Python, and C), database management
systems, web technology, software engineering, and operating systems. The program also delves into
emerging areas like mobile artificial Intelligence, machine learning, and cloud computing ensuring
that students are equipped with knowledge of the latest technological trends.
In addition to technical skills, the B.Sc. (Computer Science) program focuses on developing
analytical and problem-solving abilities. Students are encouraged to work on various assignments
throughout the course, which helps them apply their theoretical knowledge to practical scenarios.
This hands-on approach is complemented by courses in soft skills, enhancing students&#39;
communication abilities, teamwork capabilities, and overall professionalism. The blend of technical
knowledge, practical skills, and soft skills prepares students for a variety of roles in the IT industry,
including software development, systems analysis, database administration, and more.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 Examination / Equivalent Examination with at least 50% marks in aggregate in 5 subjects including English as compulsory subject along with 4 subjects with the highest score out of the remaining subjects
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Bachelor of Science (Computer Science)
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you'll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBSC;
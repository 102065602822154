const First = () => {
    return (
        <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="animations-02">
                <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
            </div>
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        <p className="text-justify">
                            It is notified for the information of all concerned that, honorable Vice Chancellor, in anticipation of
                            the approval of Academic Council, has approved the following examination scheme(applicable for all
                            batches from the academic year 2024-25 onwards except D. Pharm, B. Pharm, M. Pharm and BPT
                            programs)
                        </p>
                        <p>
                            The examination scheme has three components, i.e., Mid-Term Examination, End-Term Examination,
                            and Continuous Evaluation by the faculty.
                        </p>
                        <ul>
                            <ol><b>1. Pattern of Mid Term Examination</b>
                                <p className="text-justify">The Mid-Term examination shall be of 30 marks covering first three units of the syllabus of the
                                    course. There shall be nine questions in total, three from each unit. It is compulsory to attempt at
                                    least one question from each unit. In total, students shall be required to attempt six questions. Each
                                    question will carry five marks. Duration of the examination shall be 2 hours.</p>
                            </ol>
                            <ol><b>2. Pattern of End Term Examination</b>
                                <p className="text-justify">The End Term examination shall be of 50 marks covering the entire syllabus. There shall be 10
                                    MCQs/True-False/Fill in the Blanks/Very Short Answer Type Questions from the last three units. This
                                    question will be compulsory and of 10 marks. There shall be two questions from each unit, and it is
                                    compulsory to attempt at least one question from each of the last three units. In total, students shall
                                    be required to attempt nine questions. Each question will carry 5 marks except the first question.
                                    Duration of the examination shall be 3:00 hours.</p>
                            </ol>
                            <ol><b>3. Continuous Evaluation</b>

                                <p className="text-justify">There shall be continuous evaluation by the faculty of 20 marks for class performance, attendance,
                                    assignment, and innovative activities relating sustainability goals etc. Out of 20, 10 marks will be
                                    allocated for group project/assignment/seminar/practical files (as applicable).
                                    The twenty marks of continuous evaluation for theory subjects shall be awarded on the following
                                    basis:</p>
                                <div>(i). Attendance and Innovative Activities relating sustainability     (10 Marks)</div>
                                <div>(ii). Group Projects, Assignment, Seminar, class presentation etc. (as applicable)     (10 Marks)</div>
                                <p>The twenty marks of continuous evaluation for practical subjects shall be awarded on the following
                                    basis:</p>
                                <div>(i) Practical Files (10 marks)</div>
                                <div>(ii) Attendance and Day to Day Performance in Practical Class (10 marks)</div>
                            </ol>
                            <ol><b>4. Minimum Marks for Passing the Examination</b>
                                <p className="text-justify">A student shall be declared to have passed the examinations of a particular semester/year only if
                                    he/she secures at least 4.00 SGPA in each of the subjects separately in the theory as well as the
                                    practical examinations, including marks of Mid Term Examinations and Continuous Evaluation.</p>
                            </ol>
                            <ol><b>5. Re-Mid Term Examination</b>
                                <p className="text-justify">In case of absence or failure in Mid Term Exams(theory or practical), a student may apply for a re-
                                    examination of Mid Term before End Term Examinations. The fee for the Re- Mid Term Examination
                                    shall be Rs. 500 per paper.</p>
                            </ol>
                            <ol><b>6. Reappearing End-Term Theory Examinations</b>
                                <p className="text-justify">In case a student is re-appearing in some theory examination (s), the marks earlier secured by
                                    him/her in Theory Mid Term Examinations and Continuous Evaluation shall be carried forward. Re-
                                    appearing End Term Theory Examination shall be conducted for 50 marks from the entire syllabus.
                                    The pattern of re-appearing examination shall be the same as that of regular End Term Examinations.
                                    In case a student did not pass after re-appearing examinations (Mid Term &amp; End Term both), he or
                                    she may be allowed to reappear in the subsequent semester(s). The fee for the reappearing End
                                    Term Theory Examinations will be as per MVNU rules.</p>
                            </ol>
                            <ol><b>7. Practical Examinations</b>
                                <p className="text-justify">The pattern of practical examinations shall be the same as that of theory examinations. Mid Term
                                    Examination of practical subjects shall be conducted by internal faculty. End Term Practical
                                    Examinations must be completed before the commencement of the End Term Theory Examinations.</p>
                            </ol>
                            <ol><b>8. Reappearing Practical Examinations</b>
                                <p className="text-justify">In case a student is re-appearing in End Term Practical Examination(s), the marks earlier secured by
                                    him/her in Practical Mid Term Examinations and Continuous Evaluation shall be carried forward. Re-
                                    appearing practical examinations shall be conducted in the subsequent semester(s). The fee for the
                                    reappearing End Term Practical Examinations will be as per MVNU rules.</p>
                            </ol>
                        </ul>
                </div>
            </div>
            </div>
        </section>
    )
}
export default First;
import { Container } from "@mui/material";
import BreadcrumbsBanner from "../breadcrumbs";
import { useParams } from "react-router-dom";
import Home from "./home";
import NewMain from "../../headerone/newMain";
import DepartmentWidgetsMenu from "../departmentWidgetsMenu";
import Studentshub from "../studentshub";
import Initiative from "../initiative";
import Alumni from "../alumni";
import Placement from "../training&placement";
import Research from "../r&d";
import Lab from "../lab";
// import BBA from './bba'
import BBABA from './bbaba'
import BBADM from './bbadm'
import Bcom from './bcom'
import MBA from './mba'
import MBAHM from './mbahm'
import MBAPM from './mbapm'
import BBA from './bba'
// import BBA from './bba'
import PHDMC from "./phdmc";
const SBMCCourses=()=>{
    const { course } = useParams();
    console.log('SBMCCourses scrennnnn:::::::',course)
    const screensName=()=>{
        let screen=''
        if(course=='bcom'){
            screen=Bcom()
        }else if(course=='student-hub'){
            screen=Studentshub()
        }else if(course=='intiative'){
            screen=Initiative()
        }
        else if(course=="our-alumi"){
            screen=Alumni()
        }
        else if(course=='t-p'){
            screen=Placement()
        }else if(course=='r-d'){
            screen=Research()
        }
        else if(course=="laboratory"){
            screen=Lab()
        }
        else if(course=='bba-general'){
            screen=BBA()
        }
        else if(course=='bba-ba'){
            screen=BBABA()
        }else if(course=='bba-dm'){
            screen=BBADM()
        }
        else if(course=="bba-ba"){
            screen=BBA()
        }
        else if(course=='mba-marketing'){
            screen=MBA()
        }
        else if(course=="mba-finance"){
            screen=MBA()
        }
        else if(course=="mba-hrc"){
            screen=MBAHM()
        }
        else if(course=="mba-ba"){
            screen=MBA()
        }
        else if(course=="mba-pm"){
            screen=MBAPM()
        }
        else if(course=="mba-hs"){
            screen=MBA()
        }
     
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/soet-cse/home', subHeader: [] },
        { mainHeader: 'About SBMC', path: '#', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [
            {title:'B.Com',path:'/sbmc/course/bcom'},
            {title:'BBA- General',path:'/sbmc/course/bba-general'},
            {title:'BBA- Digital Marketing',path:'/sbmc/course/bba-dm'},
            {title:'BBA- Business Analytics',path:'/sbmc/course/bba-ba'},
            {title:'MBA- Marketing',path:'/sbmc/course/mba-marketing'},
            {title:'MBA- Finance',path:'/sbmc/course/mba-finance'},
            {title:'MBA- Human Resource Management',path:'/sbmc/course/mba-hrc'},
            {title:'MBA- Business Analytics',path:'/sbmc/course/mba-ba'},
            {title:'MBA- Pharm Management',path:'/sbmc/course/mba-pm'},
            {title:'MBA- Hospital Management',path:'/sbmc/course/mba-hs'},
        ] },
        { mainHeader: 'Laboratories ', path: '/soet-cse/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '#', subHeader: [] },
        { mainHeader: 'R & D', path: '/soet-cse/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/soet-cse/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/soet-cse/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/soet-cse/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/soet-cse/intiative', subHeader: [] },
    ]
    return(<>
        <NewMain/>
        <DepartmentWidgetsMenu headerMenus={headerMenus}/>
        <BreadcrumbsBanner bannerUrl={"/assets/img/SAHS/sahs.jpg"}/> 
        <Container>
        {screensName()}
            {/* <Home/> */}
        </Container>
    </>)
}
export default SBMCCourses;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const MPharma=()=>{
    const progHiglight=[
{listName:'16 World Class laboratories with full loaded latest Instruments Such as UV, HPLC, Brookfield Viscometer and many more'},
{listName:'Curriculum aligning with PCI and offering industry-relevant elective courses'},
{listName:'Curriculum constructed by various industrial experts and eminent academicians from PCI'},
{listName:'GPAT qualified Faculty Members'},
{listName:'Industry Oriented Projects in Ph.D, M.Pharm & B.Pharm.'},
{listName:'Foster a strong research culture among its faculty and students'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness'},
{listName:'Publication of over 100 research papers in both national and international journals and conferences'},
{listName:'Publication of about 20 books published by national and international publishing house. '},
{listName:'Organising health camps and diseases awareness rally on regular basis in nearby villages or towns to impart practical exposure to the students and social welfare'},
{listName:'Celebration of various special days associated with health like world heart day, world diabetes day, world tuberculosis day, and more'},
{listName:'Support from government departments like DST to organise the programmes aiming at innovations in field of science and technology  '},
{listName:'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups'},
{listName:'Organizing conferences, short-term training, workshops, and webinars, best out of waste contest, project contests, and industry internships. '},
{listName:'Emphasis on experimental based learning. '},
{listName:'Conduct hospital training and industrial training to impart practical exposure to the students '},
{listName:'Successful job placement across diverse fields like research & development, regulatory affairs, manufacturing, pharmaceutical marketing, hospital pharmacist, drug inspector and more.'},   
{listName:'Availability of scholarships covering up to 100% of expenses.'},

    
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','2 Years':'₹130200/- (Tuition Fee - ₹105000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','2 Years':'₹114200/- ( Tuition Fee – ₹105000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='2 years'
    const fee_1st= '1,30,200'
    const fee_2nd= '1,14,200'
    const eligibility='Candidate shall have passed 10+2 examination conducted by the respective state/central government authorities recognized as equivalent to 10+2 examination by the Association of Indian Universities (AIU) with English as one of the subjects and Physics, Chemistry, Mathematics (P.C.M) and or Biology (P.C.B / P.C.M.B.) as optional subjects individually. Any other qualification approved by the Pharmacy Council of India as equivalent to any of the above examinations. '
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="M.Pharm" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The M.Pharm program at the School of Pharmaceutical Sciences, MVN University, Palwal, has quickly become a cornerstone for aspiring researchers in the pharmaceutical field since its inception in 2021. Designed to meet the growing demands of the research community, the program has consistently fostered an environment conducive to excellent research work.
<br/><br/>
One of the key strengths of the program lies in its dedicated faculty members who bring a wealth of knowledge and experience to the classroom and research labs. Their guidance and mentorship play a pivotal role in nurturing the talents of students and facilitating ground breaking research. Moreover, the program's strategic tie-ups with top-notch companies further enhance the learning experience by providing students with opportunities for practical exposure and industry-relevant projects. These collaborations not only enrich the curriculum but also open doors to potential career pathways for students.
<br/><br/>
Overall, the M.Pharm program at the School of Pharmaceutical Sciences, MVN University, Palwal, stands out as a beacon of excellence in pharmaceutical education and research, shaping the future leaders and innovators of the field.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Minimum qualification for admission in M.Pharm</b>
 A Pass in the following examinations 
 <br/>
 B. Pharm Degree examination of an Indian university established by law in India from an institution approved by Pharmacy Council of India and has scored not less than 55 % of the maximum marks (aggregate of 4 years of B.Pharm.) 
<br/>
 Every student, selected for admission to post graduate pharmacy program in any PCI approved institution should have obtained registration with the State Pharmacy Council or should obtain the same within one month from the date of his/her admission, failing which the admission of the candidate shall be cancelled.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    M.Pharma
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default MPharma;
import React from 'react'
import { Link } from 'react-router-dom'

function Scholarship() {
  return (
    <>
        <section className="cta-area update-cta-bg pt-50 pb-50" style={{ backgroundColor: "rgb(242 242 242)" }} >
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <div className="section-title update-cta-title wow fadeInLeft animated" data-animation="fadeInDown animated" data-delay=".2s" >
                    <h3>Financial Aid</h3>
                    <p>
                    At MVN University, we are committed to ensuring your educational aspirations are met. Our supportive, creative, and professional environment is designed not just for academic growth, but also for easing the financial pressures of higher education. Dive into your career path with the peace of mind that comes from a strong network of industry contacts and a robust financial support system behind you.
                    </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-right">
                    <div className="cta-btn s-cta-btn wow fadeInRight animated mt-30" data-animation="fadeInDown animated" data-delay=".2s" >
                    <Link to="/fee-structure" className="update-btn ss-btn smoth-scroll">
                        Financial Aid <i className="fal fa-long-arrow-right" />
                    </Link>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Scholarship
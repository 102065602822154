import React from 'react'
import { Link } from 'react-router-dom'
import Slider from './SlidebreadCrum'

function MainSlider({subtitle, title, image }) {
  return (
    <>
        
        <section className="breadcrumb-area breadcrumSlider   p-relative align-items-center"   >
            <div className=" "> 
                <div className="breadcrumSlider_imges">
                <Slider />
                </div>
                 
                <div className="breadcrumb-wrap2">
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                        <Link to="/"> { title } </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {subtitle}
                        </li>
                    </ol>
                    </nav>
                </div>
                
            </div>
        </section>
    </>
  )
}

export default MainSlider
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function LawVisionMission() {
    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Law" subtitle="Vision & Mission" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>VISION</h3>
                                </div>
                                <p>
                                To be a premier institution of excellence in legal education, research and services by creating competent legal human resources for serving the society.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>MISSION</h3>
                                </div>

                               <p>
                               The Mission of the School of Law is to develop legal professionalism in the students while making them intellectually alive, morally upright, socially responsible citizens with a positive legal attitude for the betterment of self and society. 
                               </p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LawVisionMission
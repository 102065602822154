import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BscMedicalMicrobiology() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.Sc. (Medical Microbiology)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                Bachelor of Science in Medical microbiology is both a branch of medicine and microbiology which deals with the study of microorganisms including bacteria, viruses, fungi and parasites which are of medical importance and are capable of causing diseases in human beings. It includes the study of microbial pathogenesis and epidemiology and is related to the study of disease pathology and Immunology. You will also cover aspects of the biochemistry, physiology and genetics of microorganisms.
                                </p>

                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Vision</h3>
                                </div>
                                <p>
                                The knowledge in theory and practical aspects of microbiology is imperative for the development of the country.
                                </p>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Mission</h3>
                                </div>
                                <p>
                                Mission of the Medical Microbiology program at MVN University is to prepare graduates with the knowledge, skills and professional behaviors needed to function effectively in a wide range of microbial laboratories and advance research facilities. Medical Microbiology Program is to prepare professionals to demonstrate the highest quality of technical and clinical competence in serving their patients and the laboratory profession.
                                </p>
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Eligibility Criteria</h3>
                                </div>
                                <p>
                                10+2 Pass (PCB/M) with English as one of the subject with minimum 45% marks (40% In case of SC/ST candidates of Haryana State).
                                </p>    
                                

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Career Options</h3>
                                </div>
                                <p>
                                    The scope of Medical Microbiology is huge because of the involvement of microbiology in various fields such as Pharmacy, Medicine, clinical research, Agriculture, dairy industry, water industry, nanotechnology & chemical technology. Microbiologists work in many different job areas, in a variety of different job roles like:
                                </p> 

                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Microbiology Technicians
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Assistant Microbiologists
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Medical Research Technicians or Assistants
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Assistants in the Immunology or Clinical Microbiology Laboratories
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Biological Scientists
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Biology Teachers
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Medical Representative – Pharmaceutical Industries
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Educational Assistant Consultant
                                        </div>    
                                    </li>
                                    
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Medical Coder
                                        </div>    
                                    </li>
                                </ul>   
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BscMedicalMicrobiology
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {
	return (
		<>
			<section id="work" className="pt-150 pb-105">
				<div className="container">
					<div className="portfolio ">
						<div className="row  fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
							<div className="elementor-widget-wrap elementor-element-populated">
								<div className="elementor-element elementor-element-ada8c66 elementor-widget elementor-widget-shortcode" data-id="ada8c66" data-element_type="widget" data-widget_type="shortcode.default">
									<div className="elementor-widget-container">
										<div className="elementor-shortcode">
											<div id="tablepress-1_wrapper" className="dataTables_wrapper no-footer"><div className="dataTables_scrollBody" style={{ position: 'relative', overflow: 'auto', width: "100%" }}><table id="tablepress-1" className="tablepress tablepress-id-1 dataTable no-footer" style={{ width: '100%' }}><thead style={{ background: '#d9edf7', padding: '15px' }}>
												<tr className="row-1 odd">
													<th colSpan={4}> List of Ph.D. Scholars at MVN Univrsity, Plawal</th>

												</tr>
											</thead>

												<tbody class="row-hover">
													<tr class="row-2 even">
														<td class="column-1" style={{ width: '3%' }}><strong>S.N.</strong></td><td class="column-2" style={{ width: '20%' }}><strong>Name of Guide</strong></td><td class="column-3" style={{ width: '77%' }}><strong>Area of research</strong></td>
													</tr>
													<tr>
														<td class="column-1">1</td><td class="column-2">Dr. Arun Garg</td><td class="column-3"></td>
													</tr>
													<tr class="row-4 even">
														<td class="column-1">2</td><td class="column-2">Dr. N P Singh</td><td class="column-3">Banking, Business analytics, Development Economics, Economics, Finance , Financial economics, Human resource management and organizational behaviour, International Business, Marketing</td>
													</tr>
													<tr class="row-5 odd">
														<td class="column-1">3</td><td class="column-2">Dr. Sachin Gupta</td><td class="column-3">Artificial Intelligence, Big Data Analytics, Block Chain, Cyber Security, Machine Learning , Network Security , Social Media analytics, Natural language processing</td>
													</tr>
													<tr class="row-6 even">
														<td class="column-1">4</td><td class="column-2">Dr. Rajeev Ratan</td><td class="column-3">Image Processing, Multirate DSP, Wireless Sensor Network, Agri Electronics</td>
													</tr>
													<tr class="row-7 odd">
														<td class="column-1">5</td><td class="column-2">Dr. Alok Srivastava</td><td class="column-3">Internet of things, Wireless Sensor Network, Digital Communication, Computer Networks, Mobile Communication, Ad-hoc networking, Agri Electronics</td>
													</tr>
													<tr class="row-8 even">
														<td class="column-1">6</td><td class="column-2">Dr. Mukesh Saini</td><td class="column-3">Renewable energy, Solar Energy, Power grid system</td>
													</tr>
													<tr class="row-9 odd">
														<td class="column-1">7</td><td class="column-2">Dr. Mayank Chaturvedi</td><td class="column-3">Agricultural Bio chemistry , Agricultural economics, Agronomy, Abiotic stress, Horticulture, Climate change</td>
													</tr>
													<tr class="row-10 even">
														<td class="column-1">8</td><td class="column-2">Dr. Arpita Sharma</td><td class="column-3"></td>
													</tr>
													<tr class="row-11 odd">
														<td class="column-1">9</td><td class="column-2">Dr. Tarun Virmani</td><td class="column-3">Pharmaceutical sciences</td>
													</tr>
													<tr class="row-12 even">
														<td class="column-1">10</td><td class="column-2">Dr. Mohit Mangla</td><td class="column-3"></td>
													</tr>
													<tr class="row-13 odd">
														<td class="column-1">11</td><td class="column-2">Dr. Shrivardhan Dheeman</td><td class="column-3">   </td>
													</tr>
													<tr class="row-14 even">
														<td class="column-1">12</td><td class="column-2">Dr. Swati Chitranshi</td><td class="column-3">Aquatic Microbiology, Plant Biochemistry, Plant Physiology , Plant microbes interaction, Plant Pathology</td>
													</tr>
													<tr class="row-15 odd">
														<td class="column-1">13</td><td class="column-2">Dr. Chetan Chauhan</td><td class="column-3">Bio Technology, Bio remediation, Gene regulations, Genomics</td>
													</tr>
													<tr class="row-16 even">
														<td class="column-1">14</td><td class="column-2">Dr. Rahul Varshney</td><td class="column-3">Consumer law, Corporate law, Environmental Law, Competition law, Constitutional law, Revenue law</td>
													</tr>
													<tr class="row-17 odd">
														<td class="column-1">15</td><td class="column-2">Dr. Ramveer</td><td class="column-3">Criminal law</td>
													</tr>
													<tr class="row-18 even">
														<td class="column-1">16</td><td class="column-2">Dr. Kuldeep Tanwar</td><td class="column-3">Operation research</td>
													</tr>
												</tbody>
											</table></div></div></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</section>
		</>
	)
}

export default First
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function PEOPOPSOBpt() {
    return (
        <>
            <Header />
            <Bredcom title="Home/School of Business Management & Commerce" subtitle="PEO, PO & PSO – BPT" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                
                                                               
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM EDUCATIONAL OBJECTIVES</h3>
                                </div>
                                <p>
                                PEO1. To apply knowledge and skills of physiotherapeutic education and facilitate their overall professional development for rehabilitation through proper practical and theoretical learning and undertake health care projects and camps for extending awareness in society about correct physiotherapeutic movements.
                                </p>

                                <p>
                                PEO2. To execute ethical practice as physiotherapist in professional and social contexts that govern decision making and respect for the dignity of the patient and lead a team in an inter-disciplinary or multi-disciplinary environment in order to ensure clinical productivity and patient satisfaction.
                                </p>

                                <p>
                                PEO3. To prepare the graduates for patient therapist relationship by adapting empathy in their clinical practice. To open doors of employment by inculcating core values of patient care empathy and patience through frequent Physiotherapy postings and internships at various leading hospitals and clinics.
                                </p>
                                

                               

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM OUTCOMES</h3>
                                </div>

                                <p>                                    
                                [PO.1]. To acquire the knowledge for detection and evaluation of the anatomical, patho-physiological impairments, resulting in dysfunction of various age groups & occupation; as well as epidemiological sectors in the population & arrive at appropriate diagnosis.
                                </p>

                                <p>
                                [PO.2]. To be a competent and reflective physiotherapy practitioner who can function safely and effectively while adhering to legal, ethical and professional standards of practice in a multitude of physiotherapy settings
                                </p>

                                <p>
                                [PO.3]. To understand the rationale & basic investigative approach to the medical system and surgical intervention regimens & accordingly plan & implement specific Physiotherapy measures effectively.
                                </p>

                                <p>
                                [PO.4]. To be able to select strategies for cure and care; adopt restorative & rehabilitative measures for maximum possible independence of a client at home, work place & in the community.
                                </p>

                                <p>
                                [PO.5]. To ensure quality assurance & motivate the client & her/his family for a desirable client compliance. To develop communication skills for the purpose of transfer of suitable technique to be used creatively at various stages of treatment, compatible with psychological status of the beneficiary.
                                </p>

                                <p>
                                [PO.6]. To promote health in general in Geriatrics, Women’s health, Industrial medicine as well as at competitive level, such as sports, keeping in mind National Health Policies.
                                </p>

                                <p>
                                [PO.7]. To practice professional autonomy & ethical principles with referral as well as first contact clients in conformity with ethical code for physiotherapists.
                                </p>

                                


                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>PROGRAM SPECIFIC OUTCOMES</h3>
                                </div>

                                <p>
                                [PSO.1]. To develop a comprehensive approach towards healthcare and to understand the core of anatomy, physiology, biomechanics of human body and its application in physical therapy practice.
                                </p>

                                <p>
                                [PSO.2]. Apply knowledge and skills of physiotherapeutic education to facilitate their overall professional development for rehabilitation .Organize and implement the preventive, investigative and management plans; and will offer appropriate follow-up services.
                                </p>

                                <p>
                                [PSO.3]. Identify priorities and effectively manage time and resources to ensure the maintenance or enhancement of the quality of care. Undertake health care projects and camps for extending awareness in society about correct physiotherapeutic movements.
                                </p>

                                <p>
                                [PSO.4]. Aim to lead a team in an inter-disciplinary or multi-disciplinary environment in order to ensure clinical productivity and patient satisfaction. Understand core concepts of clinical ethics and law so that they may apply these to their practice as physicians.
                                </p>

                                <p>
                                [PSO.5]. Categorically, able to communicate with patients/clients, care-givers, other health professionals and other members of the community effectively and appropriately. Understand the role of using modern tools and techniques for better patient compliance.
                                </p>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PEOPOPSOBpt
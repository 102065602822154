import React, { useState } from 'react';
import { Card, Grid, Stack, CardMedia, Dialog, DialogContent, IconButton, DialogTitle } from '@mui/material';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import CloseIcon from '@mui/icons-material/Close';



const ImagesSlider = ({imgArr}) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [openImg, setOpenImg] = useState(false)
  const [previewImg, setPreviewImg] = useState('')
  const settings = {
    autoplay: true,
    autoplaySpeed: 2000, // Adjust the autoplay speed in milliseconds
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: (i) => (
      <div
        style={{
          width: '8px',
          height: '8px',
          background: i === currentSlide ? 'red' : 'gray',
          //   borderRadius: '5px',
          marginRight: '5px', // Adjust the spacing between dots
          marginTop: '10px'
        }}
      />
    ),
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const handleImgPreview = (img) => {
    setOpenImg(true)
    setPreviewImg(img)
  }
  return (
    <>
      <Stack sx={{ marginBottom: '30px' }}>
        <Stack sx={{ marginBottom: '30px' }}>
          <Slider  {...settings} sx={{ marginBottom: '20px' }}>
            {imgArr.map((item, index) => (
              <Grid container spacing={2} >
                <Grid item xs={12} textAlign="center">
                  <Card key={index} sx={{ height: '300px', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px', cursor: 'pointer' }} onClick={() => handleImgPreview(item.img)}>
                    <CardMedia
                      component="img"
                      height="100%"
                      image={item.img}
                      alt={'Image'}
                      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
                    />
                  </Card>
                </Grid>
              </Grid>
            ))}
          </Slider>
        </Stack>
      </Stack>
      <Dialog open={openImg} onClose={() => setOpenImg(false)} >
        <DialogTitle>
          <IconButton
            edge="end"
            onClick={() => setOpenImg(false)}
            aria-label="close"
            sx={{
              position: 'absolute',
              color: '#000',
              top: 0,
              right: 25
            }}
          >
            <CloseIcon />
          </IconButton></DialogTitle>
        <DialogContent>
          <img src={previewImg} height={'100%'} width={'auto'} />
        </DialogContent>
      </Dialog>
    </>);
};
export default ImagesSlider;
import { Container } from "@mui/material";
import BreadcrumbsBanner from "../breadcrumbs";
import { useParams } from "react-router-dom";
import SOETCSEHeader from "./header";
import Home from "./home";
import NewMain from "../../headerone/newMain";
import DepartmentWidgetsMenu from "../departmentWidgetsMenu";
import CSEBTechStudentshub from "./studentshub";
import CSEBTechInitiative from "./initiative";
import CSEBTechAlumni from "./alumni";
import CSEBTechPlacement from "./training&placement";
import CSEBTechResearch from "./r&d";
import CSEBTechLab from "./lab";
import FacultyList from "./faculties/facultyList";
import WidgetsMenu from "../../muiCommonComponent/menuWidgets";
import FacultyDetail from "../../facultydetail/main";
const SOETCSE=()=>{
    const { screenname } = useParams();
    console.log('CSE scrennnnn:::::::',screenname)
    const screensName=()=>{
        let screen=''
        if(screenname=='home'){
            screen=Home()
        }else if(screenname=='student-hub'){
            screen=CSEBTechStudentshub()
        }else if(screenname=='intiative'){
            screen=CSEBTechInitiative()
        }
        else if(screenname=="our-alumi"){
            screen=CSEBTechAlumni()
        }
        else if(screenname=='t-p'){
            screen=CSEBTechPlacement()
        }else if(screenname=='r-d'){
            screen=CSEBTechResearch()
        }
        else if(screenname=="laboratory"){
            screen=CSEBTechLab()
        }
        else if(screenname=="faculties"){
            screen=FacultyList()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
        // else if(screenname=='bca-ai'){
        //     screen=CSEBCAAI()
        // }else if(screenname=='bca-ml'){
        //     screen=CSEBCAML()
        // }
        // else if(screenname=="mca"){
        //     screen=CSEMCA()
        // }
        // else if(screenname=='m-tech'){
        //     screen=CSEMTech()
        // }
        // else if(screenname=="mca"){
        //     screen=CSEMCA()
        // }
        // else if(screenname=="b-tech-cs"){
        //     screen=CSEBTechCyberSecurity()
        // }
     
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/soet-cse/home', subHeader: [] },
        { mainHeader: 'About CSE', path: '#', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [
            { title: 'B.Tech Computer science & Engineering', path: '/soet-cse/course/b-tech', },
            { title: 'B.Tech CSE with specilisation in Artificial Intelligence', path: '/soet-cse/course/b-tech-ai', },
            { title: 'B.Tech CSE with specilisation in Machine Learning', path: '/soet-cse/course/b-tech-ml', },
            { title: 'B.Tech CSE with specilisation in Cyber Security', path: '/soet-cse/course/b-tech-cs', },
            { title: 'B.Tech civil Engineering',path:'/soet-civil/btech-civil'},
            { title: 'B.Tech CSE with specilisation in Robotics & Automation',path: '/soet-me/btech-me'},
            { title: 'B.Tech Electronics and Comunnication Engineering',path: '/soet-ece/btech-ece'},
            { title: 'M.Tech Computer science & Engineering', path: '/soet-cse/course/m-tech', },
            { title: 'B.Sc Computer science', path: '/soet-cse/course/bsc-it', },
            { title: 'BCA', path: '/soet-cse/course/bca', },
            { title: 'BCA with Specilisation in Data Science', path: '/soet-cse/course/bca-ds', },
            { title: 'BCA with Specilisation in Artificial Intelligence', path: '/soet-cse/course/bca-ai', },
            { title: 'BCA with Specilisation in Machine Learning', path: '/soet-cse/course/bca-ml', },
            { title: 'MCA', path: '/soet-cse/course/mca', },
        ] },
        { mainHeader: 'Laboratories ', path: '/soet-cse/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/soet-cse/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/soet-cse/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/soet-cse/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/soet-cse/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/soet-cse/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/soet-cse/intiative', subHeader: [] },
    ]
    return(<>
        <NewMain/>
        <WidgetsMenu headerMenus={headerMenus}/>
        <BreadcrumbsBanner bannerUrl={"/assets/img/SOET/cse.jpg"}/> 
    <Container>
    {screensName()}
        {/* <Home/> */}
    </Container>
    </>)
}
export default SOETCSE;

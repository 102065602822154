import React from 'react'
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Main from '../headerone/Main';
import { Container, Grid, Typography, Card, CardContent, Stack, Avatar, Box } from '@mui/material';
// import Background from '..//assets/homepage/banner1.jpg'
// import Backgroundtwo from '..//assets/homepage/banner2.jpg'

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-next ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-right"></i></button>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button type="button" className={`slick-prev ${className}`} style={{ ...style }} onClick={onClick} ><i className="far fa-angle-left"></i></button>
    );
}

function Sliderone() {

    const cards = [
        {
            icon_url: '/assets/img/icon/sve-icon4.png',
            title: 'NAD - ABC Scheme',
            description: 'As per National Education Policy 2020, the Academic Bank of Credits (ABC) has been envisaged to facilitate the academic mobility of students with the freedom to study across the Higher Education Institutions in the country with an appropriate “credit transfer” mechanism from one programme to another, leading to attain a Degree/ Diploma/PG-diploma, etc.,'
        },
        {
            icon_url: '/assets/img/icon/sve-icon5.png',
            title: 'Eligibility for admission aspirants',
            description: 'The candidates need to check the eligibility criteria of their desired programme while applying for MVN  University admission 2023. The application fee of MVN  University is Rs 1100 and can be paid through debit card or credit card.'
        },
        {
            icon_url: '/assets/img/icon/sve-icon6.png',
            title: 'Program for Winter Session 2024-25',
            description: 'Providing all the necessary information will help prospective applicants understand the application process and requirements, leading to a higher likelihood of qualified candidates applying to your Ph.D. program.'
        }
    ]

    const settings = {
        //     dots: true,
        //   infinite: true,
        //   speed: 500,
        //   slidesToShow: 1,
        //   slidesToScroll: 1
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        fade: true,
        arrows: true,
        prevArrow: <SamplePrevArrow />,
        nextArrow: <SampleNextArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
const sliderImg=[
    {img:'/assets/homepage/banner11.jpg'},
    {img:'/assets/homepage/banner10.jpg'},
    {img:'/assets/homepage/banner7.jpg'},
    {img:'/assets/homepage/banner8.jpg'},
    {img:'/assets/homepage/banner12.jpg'},
    {img:'/assets/homepage/banner24.jpg'},
    {img:'/assets/homepage/banner23.jpg'},
    {img:'/assets/homepage/banner31.jpg'}, 
    {img:'/assets/homepage/banner26.jpg'},



    /* {img:'/assets/homepage/banner25.jpg'}, */
    

    /*{img:'/assets/homepage/new_slider01.jpg'},
    {img:'/assets/homepage/banner13.jpg'},
    {img:'/assets/homepage/banner6.jpg'},*/
    /* {img:'/assets/homepage/banner21.jpg'}, */
   
    

    
   
    /*{img:'/assets/homepage/banner9.jpg'},*/
    
    
    
    

]

        return (
            <>
                <Slider className="slider-active" style={{ background: "#141b22" }} {...settings}>
                    {sliderImg.map((item)=>(
                        <div>
                        <img src={item.img} className='slider-height' />
                    </div>
                    ))}
                    
                </Slider>
                <marquee className='mt-1 py-1' style={{backgroundColor:'#07123d', color:'#fff', fontWeight:'bold', fontSize:'22px'}}>
                    {/*<a style={{color:'#fff'}} href='/phd-admission-form'>Ph.D ADMISSION SESSION : 2024-25 (WINTER)</a> |
                    <a style={{color:'#fff'}} href='/International_conference.pdf'> 2nd International conference on campus</a> */}
                    
                    <a style={{color:'#fff'}} href='https://docs.google.com/forms/d/e/1FAIpQLSdPhHt-sD9CkvFeL8Dj0Jv8mGDOyu5eNtO-3E3Ryk1peAI8_g/viewform?usp=send_form'>3rd International Conference on Recent Advancement and innovations in Healthcare</a>
                    </marquee>                

                {/* <section className="service-details-two p-relative">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12" style={{background:'#000',marginTop:'10px'}}>
                <marquee width="100%" direction="left" height="18px">
                <Link to="/phd-admission-form"><h5 style={{color:"#fff"}} className='update-government-recognization-para'>Ph.D ADMISSION SESSION : 2023-24 (WINTER)</h5></Link>
    </marquee>
                </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="update-services-box07">
                                    <div className="sr-contner">
                                        <div className="icon">
                                            <img src="/assets/img/icon/sve-icon4.png" alt="icon01" />
                                        </div>
                                        <div className="text">
                                            <h5 className='update-government-recognization-para'>
                                                <Link to="/">NAD - ABC Scheme</Link>
                                            </h5>
                                            <p className='update-government-recognization-para'>
                                                As per National Education Policy 2020, the Academic Bank of Credits (ABC) has been envisaged to facilitate the academic mobility of students with the freedom to study across the Higher Education Institutions in the country with an appropriate “credit transfer” mechanism from one programme to another, leading to attain a Degree/ Diploma/PG-diploma, etc.,
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="update-services-box07 active mobile-spacing">
                                    <div className="sr-contner">
                                        <div className="icon">
                                            <img src="/assets/img/icon/sve-icon5.png" alt="icon01" />
                                        </div>
                                        <div className="text">
                                            <h5 className='update-government-recognization-para'>
                                                <Link to="/">Eligibility for admission aspirants</Link>
                                            </h5>
                                            <p className='update-government-recognization-para'>
                                                The candidates need to check the eligibility criteria of their desired programme while applying for MVN  University admission 2023. The application fee of MVN  University is Rs 1100 and can be paid through debit card or credit card.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="update-services-box07 mobile-spacing">
                                    <div className="sr-contner">
                                        <div className="icon">
                                            <img src="/assets/img/icon/sve-icon6.png" alt="icon01" />
                                        </div>
                                        <div className="text">
                                            <h5 className='update-government-recognization-para'>
                                            <Link to="/phd-admission-form">Inviting applications for Ph.D
                                                    Program for Winter Session 2023-24</Link>
                                            </h5>
                                            <p className='update-government-recognization-para'>
                                                Providing all the necessary information will help prospective applicants understand the application process and requirements, leading to a higher likelihood of qualified candidates applying to your Ph.D. program.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </>
        )

    // return (<>


    //     <Slider className="slider-active" style={{ background: "#141b22" }} {...settings}>
    //         <img src="/top-banner.png" className='slider-height' />
    //     </Slider>
    //     <Container sx={{ padding: '15px' }}>
    //         <Grid container spacing={3} >
    //             <Grid item xs={12} sm={12} md={12} lg={12}>
    //                 <Box  sx={{ background: '#cecece',marginTop:'10px', padding:'15px', boxShadow:'4px 4px 20px #cecece', opacity:'0.8' }}>
    //                 <marquee width="100%" direction="left" height="18px">
    //                 <Stack flexDirection={'row'} justifyContent={'space-between'} marginRight={'50px'}>
    //                     <Link to="/phd-admission-form"><Typography color="#000" fontSize="18px" fontWeight="600" fontFamily="sans-serif">Ph.D ADMISSION SESSION : 2023-24 (WINTER)</Typography></Link>
    //                     <Link><h4 style={{color:"#000",marginLeft:'20px'}}>|</h4></Link>
    //                     <Link to="/International_conference.pdf"><Typography color="#000" fontSize="18px" fontWeight="600" fontFamily="sans-serif">2nd International conference on campus to corporate industry 4.0 (14-03-2024)</Typography></Link>
    //                </Stack>
    //                 </marquee>
    //                 </Box>
    //             </Grid>
    //             {cards?.map((card, index) => {
    //                 return (
    //                     <Grid item xs={12} sm={12} md={4} lg={4} >
    //                         <Card sx={{ background: index !== 1 ? '#ececec' : '#fff', padding: '15px', height: '400px',boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',borderRadius:'10px' }}>
    //                             <CardContent sx={{justifyContent:'center'}}>
    //                                 <Stack justifyContent="center">
    //                                 <Avatar src={card?.icon_url} sx={{background:'#000',height:'50px',width:'50px',padding:'10px', margin:'auto'}}/>
    //                                 </Stack>
    //                                 <Stack direction="row" spacing={1}>
    //                                     {/* <Typography marginRight="20px">
    //                                         <img src={card?.icon_url} alt="icon01" height="20px" width="20px" />
    //                                     </Typography> */}
    //                                     <Typography textAlign="center" fontSize="20px" fontWeight={600} letterSpacing="0.5px" textTransform="uppercase" padding="20px 0px">
    //                                         <Link to={index == 2 && "/phd-admission-form"}> {card?.title}</Link>
    //                                     </Typography>
    //                                 </Stack>
    //                                 <Typography fontSize="14px" letterSpacing="0.5px" fontWeight={600} textAlign="center" lineHeight="28px">
    //                                     {card?.description}
    //                                 </Typography>
    //                             </CardContent>
    //                         </Card>
    //                     </Grid>
    //                 )
    //             })}
    //         </Grid>
    //     </Container>
    // </>)
}

export default Sliderone
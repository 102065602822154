import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSInitiative=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Law" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    In response to the varying necessities of the legal realm, we provide a wide array of specialisation choices that enable students to customise their education according to their individual interests and professional objectives. Furthermore, alongside our emphasis on specialisation, we are committed to cultivating a culture of research and innovation inside our educational institution. We repeatedly organise research conferences, workshops, legal & educational visits, legal awareness camps and Moot Court activities, Faculty development programmes that convene academics, legal experts, and students to deliberate on the most recent trends and advancements in the legal field. We have effectively coordinated MVN University's first and second international conference, workshops, FDPs, and are currently actively planning for several upcoming initiatives.
<br/><br/>
We at School of Law, demonstrate our dedication to focussed programs and research activities as a testament to our belief in equipping students with a comprehensive education that adequately qualifies them for triumph in the ever-changing and fiercely competitive legal environment. 

<br/><br/>

    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSInitiative;
import React from 'react'

function Shep() {
    
  const features = [
    {
      icon: '/assets/img/icon/fea-icon01.png',
      title: 'Skilled Teachers',
      description:
        'Special wedding garments are often worn, and the ceremony is sometimes followed by a wedding reception. Music, poetry, prayers, or readings from.',
    },
    {
      icon: '/assets/img/icon/fea-icon02.png',
      title: 'Affordable Courses',
      description:
        'Special wedding garments are often worn, and the ceremony is sometimes followed by a wedding reception. Music, poetry, prayers, or readings from.',
    },
    {
      icon: '/assets/img/icon/fea-icon03.png',
      title: 'Efficient & Flexible',
      description:
        'Special wedding garments are often worn, and the ceremony is sometimes followed by a wedding reception. Music, poetry, prayers, or readings from.',
    },
  ];

  return (
    <>
      <section className="update-steps-area p-relative" style={{ backgroundColor: '#000'}}>
        <div className="animations-10">
          <img src="/assets/img/bg/an-img-10.png" alt="an-img-01" />
        </div>
        <div className="container" style ={{ marginTop: '45px' }}>
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="section-title mb-35 wow fadeInDown animated"
                data-animation="fadeInDown"
                data-delay=".4s">
                <h3 className='text-center'>Our Faculty</h3>
                <p className='text-justify'>
                At MVN University, we take immense pride in our exceptional faculty members who stand as pillars of knowledge, innovation, and mentorship. Our commitment to providing a transformative educational experience is embodied by the dedication and expertise of our esteemed faculty. These educators are more than teachers; they are catalysts for growth, igniting curiosity and shaping the leaders of tomorrow.
                </p>
                {/* <br/> */}
                <p className='text-justify'>
                Our faculty members are renowned experts in their respective fields. Their academic accomplishments, research contributions, and industry experience bring a wealth of knowledge directly into the classroom, enriching the learning journey for our students.
                </p>
                <p className='text-justify'>
                From groundbreaking research to innovative teaching methodologies, our faculty members are at the forefront of educational innovation. They continuously explore new ways to bridge theory and practice, ensuring that students are prepared for the dynamic challenges of the modern world.
                </p>
              </div>
              {/* <ul className="pr-20">
                {features.map((feature) => (
                  <li key={feature.icon}>
                    <div className="step-box wow fadeInUp animated"
                      data-animation="fadeInUp"
                      data-delay=".4s">
                      <div className="dnumber">
                        <div className="date-box">
                          <img src={feature.icon} alt="icon" />
                        </div>
                      </div>
                      <div className="text">
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul> */}
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="step-img wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s">
                <img src="/assets/homepage/group-faculty.jpg" alt="class image"  style={{height:'400px',width:'auto'}}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Shep;

import React, { useEffect } from 'react';

function ScriptLoader({ src }) {
  useEffect(() => {
    const script = document.createElement('script');
    script.type="text/javascript";
    script.async=true;
    script.src="https://widgets.in8.nopaperforms.com/emwgts.js"
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);

  return null; // This component doesn't render anything
}

export default ScriptLoader;

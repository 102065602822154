import React from "react";
import { Container } from "@mui/material";
import Home from "./home";
import NewMain from "../headerone/newMain";
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
import Studentshub from "./studentshub";
import Initiative from "./initiative";
import Alumni from "./alumni";
import Faculty from "./faculties/facultyList";
import Placement from "./training&placement";
import Research from "./r&d";
import Lab from "./lab";
import { useParams } from "react-router-dom";
import BPharma from "./SPS/bpharma";
import DPharma from "./SPS/dpharma";
import MPharma from "./SPS/mpharma";
import BreadcrumbsBanner from "./breadcrumbs";
import FacultyDetail from "../facultydetail/main";
const Index=()=>{
    const { screenname } = useParams();
    console.log('screenname::::::::::::',screenname)
    const screensName=()=>{
        let screen=''
        if(screenname=='home'){
            screen=Home()
        }
       else if(screenname=='student-hub'){
            screen=Studentshub()
        }else if(screenname=='intiative'){
            screen=Initiative()
        }
        else if(screenname=="our-alumi"){
            screen=Alumni()
        }
        else if(screenname=='t-p'){
            screen=Placement()
        }else if(screenname=='r-d'){
            screen=Research()
        }
        else if(screenname=="laboratory"){
            screen=Lab()
        }
        else if(screenname=='b-pharma'){
            screen=BPharma()
        }else if(screenname=='d-pharma'){
            screen=DPharma()
        }
        else if(screenname=="m-pharma"){
            screen=MPharma()
        }
        else if(screenname=="faculties"){
            screen=Faculty()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
       
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/school-Of-pharmaceutical-sciences/home', subHeader: [] },
        { mainHeader: 'About SOPS', path: '/school-Of-pharmaceutical-sciences/home', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [
            {title:'B.Pharm',path:'/school-Of-pharmaceutical-sciences/b-pharma'},
            {title:'D.Pharm',path:'/school-Of-pharmaceutical-sciences/d-pharma'},
            {title:'M.Pharm',path:'/school-Of-pharmaceutical-sciences/m-pharma'},
        ] },
        { mainHeader: 'Laboratories ', path: '/school-Of-pharmaceutical-sciences/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/school-Of-pharmaceutical-sciences/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/school-Of-pharmaceutical-sciences/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/school-Of-pharmaceutical-sciences/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/school-Of-pharmaceutical-sciences/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/school-Of-pharmaceutical-sciences/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/school-Of-pharmaceutical-sciences/intiative', subHeader: [] },
    ]
return(<>
<NewMain/>
<WidgetsMenu headerMenus={headerMenus}/>
<BreadcrumbsBanner bannerUrl={"/assets/img/SPS/sps.jpg"}/>
<Container>
    {screensName()}

</Container>
</>)
}
export default Index;
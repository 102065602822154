import SOETHeader from "./header";
import BreadcrumbsBanner from "./breadcrumbs";
import Content from "./home";
import { Container } from "@mui/material";
import Departments from "./departments";
import EventNewsGallary from "./eventNews";
import RecuiterCompany from "./recuiterCompany";
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
import NewMain from "../headerone/newMain";
import Studentshub from "./studentshub";
import Initiative from "./initiative";
import Alumni from "./alumni";
import FacultyList from "./faculties/facultyList";
import Placement from "./training&placement";
import Research from "./r&d";
import Lab from "./lab";
import { useParams } from "react-router-dom";
import BMLT from './AHS/bmlt'
import BPT from "./AHS/bpt";
import BSCBiotech from "./AHS/bsc_biotech";
import DHA from "./AHS/dha";
import DMLT from "./AHS/dmlt";
import MSCMicrobiology from "./AHS/msc_microbiology";
import MSCMLT from "./AHS/msc_mlt";
import FacultyDetail from "../facultydetail/main";

const SAHSHome=()=>{
    const { screenname } = useParams();
    console.log('screenname::::::::::::',screenname)
    const screensName=()=>{
        let screen=''
       if(screenname=='student-hub'){
            screen=Studentshub()
        }else if(screenname=='intiative'){
            screen=Initiative()
        }
        else if(screenname=="our-alumi"){
            screen=Alumni()
        }
        else if(screenname=='t-p'){
            screen=Placement()
        }else if(screenname=='r-d'){
            screen=Research()
        }
        else if(screenname=="laboratory"){
            screen=Lab()
        }
        else if(screenname=='bsc-biotech'){
            screen=BSCBiotech()
        }else if(screenname=='bsc-mlt'){
            screen=BMLT()
        }
        else if(screenname=="dha"){
            screen=DHA()
        }
        else if(screenname=='dmlt'){
            screen=DMLT()
        }
        else if(screenname=="msc-micro-bio"){
            screen=MSCMicrobiology()
        }
        else if(screenname=="msc-mlt"){
            screen=MSCMLT()
        }
        else if(screenname=="bpt"){
            screen=BPT()
        }
        else if(screenname=="faculties"){
            screen=FacultyList()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
     
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/school-of-allied-health-sciences/home', subHeader: [] },
        { mainHeader: 'About AHS', path: '/school-of-allied-health-sciences/home', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [
            { title: 'B.Sc. Biotechnology ', path: '/school-of-allied-health-sciences/bsc-biotech', },
            { title: 'B.Sc. Medical Laboratory Technology', path: '/school-of-allied-health-sciences/bsc-mlt', },
            { title: 'Diploma in Health Assistance', path: '/school-of-allied-health-sciences/dha', },
            { title: 'Diploma Medical Lab Technology', path: '/school-of-allied-health-sciences/dmlt', },
            { title: 'M.Sc. Microbiology',path:'/school-of-allied-health-sciences/msc-micro-bio'},
            { title: 'M.Sc. Medical Laboratory Technology',path: '/school-of-allied-health-sciences/msc-mlt'},
            { title: 'Bachelor’s of Physiotherapy',path: '/school-of-allied-health-sciences/bpt'},
        ] },
        { mainHeader: 'Laboratories ', path: '/school-of-allied-health-sciences/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/school-of-allied-health-sciences/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/school-of-allied-health-sciences/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/school-of-allied-health-sciences/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/school-of-allied-health-sciences/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/school-of-allied-health-sciences/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/school-of-allied-health-sciences/intiative', subHeader: [] },
    ]
return(<>
<NewMain/>
{/* <Navbar/> */}
<WidgetsMenu headerMenus={headerMenus}/>
<BreadcrumbsBanner bannerUrl={"/assets/img/SAHS/sahs.jpg"}/>
<Container>
    {screenname=='home'?(<>
    <Content/>
    {/* <Departments/>
    <EventNewsGallary/>
    <RecuiterCompany/> */}
    </>):screensName()}

</Container>
</>)
}
export default SAHSHome;
import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function ComputerMedia() {
    return (
        <>
            <Header />
            <Bredcom title="Home/ Computer Science Engineering" subtitle="Media" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Under Construction</h3>
                                </div>
                                                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ComputerMedia
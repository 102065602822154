import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {

    const Data = [
        
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/jyoti-tewatiya.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/ritu-bala-1536x1091.jpg", category : "Financial"},
      ]

    
      for(let i=1;i<=89;i++){
Data.push({img :`https://admission.mvn.edu.in/wp-content/uploads/2021/09/placement-at-a-glance-${i+1}.jpg`,category : "Financial"})
      }

      for(let i=0;i<=12;i++){
        Data.push({img :`assets/img/placement/${i+1}.jpg`,category : "Financial"})
              }
      const [current, setcurrent] = useState(Data);

      const Filter = (category) =>{
          const arr = [];
          if(category === "View All"){
          setcurrent(Data)
          }
          else{
          Data.map((item)=> {
              if(item.category === category){
              arr.push(item);
              }
          })
          setcurrent(arr);
          }
      };

  return (
    <>
        <section id="work" className="pt-150 pb-105">
            <div className="container">
                <div className="portfolio ">
                <div className="row fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                    {current.map( (item, Index)=> ( 
                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                    <img src={item.img} alt="img" className='healthImg' />
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First
import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BBAHons() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.B.A Hons" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p> 
                                    New age businesses are witnessing increasing technological innovation and their implementation in all functional areas of the businesses. For young men and women who want to become successful executives in business, industry, or non-profit organizations or who want to pursue entrepreneurship or self-employment, 4 Years Honour's Program in Bachelor of Business Administration (BBA(H)) of MVN University offers high quality professional education in business administration. Innovative and different type of training programs of MVN University aids in igniting brains of young students for the demanding managerial assignments of the corporate sector. In order to assure great performance and devoted service to both enterprises and the general public, our training programme places a strong emphasis on providing each participant with the conceptual, analytical, communication, and attitude skills they need.
                                </p>

                                <p>
                                    Students are equipped with the required business acumen in addition to learning application of digital technologies, changing strategies (finance, operations and marketing), and computational business analytics. Leadership qualities will be inculcated in students through different training programs so as to make them capable to face & succeed in this fast-paced competitive world. It is known fact that in the present-day context, even large companies with very high market capitalization struggle to level their net incomes. Hiring and laying off employees is now a routine activity in these companies to fulfil the need of new business knowledge workers who are talented and imbibed the quality of being a leader, strategic thinker, innovative mind, and problem-solvers. This gap if any will be filled by BBA qualified manager in days to come. 
                                </p>

                                <p>
                                    MVN University's 4 Years Honour's program in Bachelor of Business Administration (BBA)program is one of the most sought-after Bachelor's degree programs for students after Class 12. This program is the gateway of job opportunities in business domains such as Sales, Marketing, Education, Finance, digital technologies, computational business analytics, human resources, business communication, Government sectors etc., Further elaborating, BBA(H) of MVN university is a four-year professional Undergraduate course in Business Management. It is open to students from all the streams. The BBA course offers knowledge and training in management and leadership skills to prepare them for managerial roles and entrepreneurship. The program teaching methods are oriented to meet the aspirations of country with emphasis on girls and boys from rural and urban youth who can develop self-sustainability and become a torch bearer to the upcoming generations. This program is cultivated with motto of professionals to excel at either employee or business portfolio. The curriculum is developed in lieu of required skill set being desired at major corporates and business world. This program will endure the candidate to possess the knowledge of techno-commercial aspects of Indian Business Segment.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BBAHons
import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BALLB() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="BA LLB" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p>
                                MVN University School of Law has offering a five-year integrated Bachelor of Arts, Bachelor of Laws (B.A. LL.B.) programme since 2016, the. The curriculum is designed in accordance with the latest Bar Council of India rules and University Grants Commission directions, and it strives to foster a culture of research and academic achievement among students through a blend of arts and legal courses.
                                </p>

                                <p>
                                B.A.LL.B. gives a comprehensive grasp of the interrelationships between the topics of Arts and Law rather than in specific areas. The course covers a broad range of contemporary art-related topics and allows students to concentrate on social, legal, and political concerns, with a particular emphasis on the influence of technology on commerce, corporate governance, and growing market challenges in globalising human and institutional interactions.
                                </p>
                                <p>
                                This programme focuses on giving a comprehensive grasp of legislation in Economics, Forensic Science, Administration, Mass Media Communication, Intellectual Property Rights, and Cybernetics. It also gives the information required to get a certificate of registration with the Bar Council of India and practice in any law-related profession.
                                </p>
                                <p>
                                Lectures and seminar series involving both professor and student presentations are being used to teach the courses. Students prepare for classroom interaction by reading and analysing examples, as well as conducting their own research.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BALLB
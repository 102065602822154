import React from 'react';
import { Card, Grid, Stack, CardMedia, Typography, CardContent } from '@mui/material';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MainTitle from '../muiHome/mainTitle';
import { Link } from 'react-router-dom';

const departmentsList = [
    { img: '/assets/img/SOET/cse.jpg', title: 'Computer Science and Engineering',link:'/soet-cse/home' },
    { img: '/assets/img/SOET/me.jpg', title: 'Mechanical engineering', link:'/soet-me/home' },
    { img: '/assets/img/SOET/civil.png', title: 'Civil Engineering', link:'/soet-civil/home' },
    { img: '/assets/img/SOET/ece.jpg', title: 'Electronics and Communications Engineering', link:'/soet-ece/home' },
    // Add more logo URLs as needed
];

const SOETDepartments = () => {
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const settings = {
        autoplay: true,
        autoplaySpeed: 2000, // Adjust the autoplay speed in milliseconds
        dots: true,
        infinite: true,
        arrows: false,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        customPaging: (i) => (
            <div
                style={{
                    width: '8px',
                    height: '8px',
                    background: i === currentSlide ? 'red' : 'gray',
                    //   borderRadius: '5px',
                    marginRight: '5px', // Adjust the spacing between dots
                    marginTop: '10px'
                }}
            />
        ),
        beforeChange: (current, next) => setCurrentSlide(next),
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <MainTitle title="Engineering & Technology Departments" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <Stack sx={{ marginBottom: '30px' }}>
                    <Stack sx={{ marginBottom: '30px' }}>
                        <Slider  {...settings} sx={{ marginBottom: '20px' }}>
                            {departmentsList.map((dept, index) => (
                                <Grid container spacing={2} >
                                    <Grid item xs={12} textAlign="center">
                                            <Link to={dept.link}>
                                        <Card key={index} sx={{ height: '250px', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px' }}>
                                            <CardMedia
                                                component="img"
                                                height="150"
                                                image={dept.img}
                                                alt={dept.title}
                                            />
                                                <CardContent >
                                                    <Typography fontSize="18px" letterSpacing={0.5} textAlign="center" height={'0px'} fontWeight="600">
                                                        {dept.title}
                                                    </Typography>

                                                    {/* <Link >
                                        <Button color='error' variant='contained'>

                                            Read More...
                                        </Button>
                                    </Link> */}
                                                </CardContent>
                                        </Card>
                                            </Link>
                                    </Grid>
                                </Grid>
                            ))}
                        </Slider>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>

    );
};

export default SOETDepartments;

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../headerone/Main' 
import Bredcom from '../../Bredcom/Main'
import First from './first'
import NewMain from '../../headerone/newMain'
function BankDetails() {

    return (
        <>
        <NewMain />
        <Bredcom title="Home" subtitle="Bank Account Details"/>
    <First/>
            {/* <div className="page-image">
                <img src='https://admission.mvn.edu.in/wp-content/uploads/2021/08/bank-account-details.jpg' alt='' />
            </div> */}
        </>
    )
}

export default BankDetails
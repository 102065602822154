import React from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'
import { Link } from 'react-router-dom'
import NewMain from '../headerone/newMain'
function CompositionOfIqac() {
    return (
        <>
            <NewMain />
            <Bredcom title="Home" subtitle="Composition of IQAC" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row">

                        <div className="text-column col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInLeft  animated" data-animation="fadeInLeft" data-delay=".4s" >
                                <table className="table table-bordered mb-40">
                                    <thead style={{background:'#ED1E24',color:'#fff'}}>
                                        <tr>
                                            <th>
                                                S. No.
                                            </th>
                                            <th>
                                                Name of Member
                                            </th>
                                            <th>
                                                IQAC Designation
                                            </th>
                                        </tr>
                                        </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <p>1.&nbsp;&nbsp;&nbsp; &nbsp;</p>
                                            </td>
                                            <td>
                                                <p>Prof. (Dr.) Arun Garg</p>
                                                <p>Vice Chancellor, MVN University</p>
                                            </td>
                                            <td>
                                                <p>Chairperson</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p>2.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Prof. (Dr.) N.P. Singh</p><p>Pro Vice Chancellor &amp; Dean SBMC, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>3.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Prof. (Dr.) Vineet Kumar</p><p>Dean Research, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>4.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Prof. (Dr.) Rajeev Ratan</p><p>Professor &amp; Registrar, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>5.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Prof. (Dr.) Rahul Varshney</p><p>Dean, SOL, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>6.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Prof. (Dr.) Tarun Virmani</p><p>Dean, SOPS, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>7.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Prof. (Dr.) Mayank Chaturvedi</p><p>Dean, SOA, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>8.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Dr. Alok Srivastava</p><p>Associate Professor</p><p>Deputy Dean Research, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>9.&nbsp;&nbsp;&nbsp; &nbsp;</p></td>
                                            <td><p>Dr. Kuldeep Tanwar</p><p>Associate Professor &amp; COE, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>10. &nbsp;</p></td>
                                            <td><p>Mr. Mahesh Singh Danu</p><p>Assistant Professor</p><p>Head, SAHS, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>11. &nbsp;</p></td>
                                            <td><p>Ms. Aradhana Sarout</p><p>Assistant professor, SBMC</p></td>
                                            <td>Member</td>
                                        </tr>
                                        <tr>
                                            <td><p>12. &nbsp;</p></td>
                                            <td><p>Mr. Rahul Mongia</p><p>Assistant Professor, SOL, MVN University</p></td>
                                            <td><p>Member</p></td>
                                        </tr>
                                        <tr>
                                            <td><p>13. &nbsp;</p></td>
                                            <td><p>Mr. R.P. Singh</p><p>Assistant Registrar (Regulatory Compliance), MVN University</p></td><td><p>Member</p></td></tr><tr><td><p>14. &nbsp;</p></td><td><p>Mukkt Foundation, Palwal</p><p>Local Society/Trust</p></td><td><p>Member</p></td></tr><tr><td><p>15. &nbsp;</p></td><td><p>Ms. Chitra Sharma</p><p>Student Representative Female</p><p>Roll No. – 19BSAG1008, SOA</p></td><td><p>Member</p></td></tr><tr><td><p>16. &nbsp;</p></td><td><p>Mr. Manoj Sharma</p><p>Student Representative Male</p><p>Roll No. – 19PS1064, SOPS</p></td><td><p>Member</p></td></tr><tr><td><p>17. &nbsp;</p></td><td><p>Ms. Swati Vasisht</p><p>Alumni Female</p><p>Pharmacovigilance Officer, Wipro Pvt. Ltd.</p></td><td><p>Member</p></td></tr><tr><td><p>18. &nbsp;</p></td><td><p>Mr. Bhagat Singh</p><p>Alumni Male</p><p>IT Engineer, Aequor Information IT Solutions</p></td><td><p>Member</p></td></tr>
                                            <tr>
                                                {/* <td><p>19. &nbsp;</p></td> */}
                                            {/* <td><p>Dr. Yogender Singh</p><p>Director IQAC</p></td> */}
                                            {/* <td><p>Member Secretary</p></td></tr><tr><td><p>20. &nbsp;</p></td> */}
                                            <td><p>19. &nbsp;</p></td>
                                            <td><p>Dr. Rajiv Chawla</p><p>Employer/ Industrialists/ Stakeholder</p></td>
                                            <td><p>Member</p></td></tr>
                                            <tr><td><p>20. &nbsp;</p></td><td><p>Mr. Daya Shankar Prasad</p><p>Deputy Director, IQAC</p><p>Assistant Professor, SOET</p></td>
                                            <td><p>Member</p></td></tr><tr>
                                                <td><p>21. &nbsp;</p></td>
                                            <td><p>Dr. Yogender Singh</p><p>Director IQAC</p></td>
                                            <td><p>Member Secretary</p></td></tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default CompositionOfIqac
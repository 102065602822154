import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../headerone/Main'

function CSEPatents() {
    const location = useLocation()
    console.log('location:::::::::::::', location)
    return (
        <>
            <Header />
            <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-left">
                                <div className="breadcrumb-title">
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb-wrap2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <Link to="/computer-science-engineering">Computer Science Engineering</Link>
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="project-detail">
                <div className="container">
                    <div className="row">
                        {/* <div className="btn-group col-lg-12 col-md-12 col-sm-12">
                            <button className='btn-course active'>Grants</button>
                            <button className='btn-course'>Consultancy</button>
                            <button className='btn-course'>Patents</button>
                            <button className='btn-course'>Publications</button>
                            <button className='btn-course'>Technology Transfers</button>
                            <button className='btn-course'>collaborations</button>
                            <button className='btn-course'>PhD Program</button>
                            <button className='btn-course'>Team</button>

                        </div> */}
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className='text-center'>List of Patents: 2017 - 2023</h2>
                            <div className="table-scroll">
                        <table id="admission">
                            <tr>
                                <th>Patent number</th>
                                <th>Patent title</th>
                                <th>Name of faculty</th>
                                <th>Date of publication/ grant</th>
                                <th>Published/grant</th>
                            </tr>
					<tr>
						<td>202211031970</td>
                        <td>5G Industrial IoT enabled Cloud Manufacturing Security Service Architecture based on Fog Computing and Blockchain</td>
                        <td>Daya Shankar Prasad</td>
                        <td>06/03/2022</td>
                        <td>Published</td>

					</tr>
                    <tr>
						<td>2021102117</td>
                        <td>SYSTEM FOR SECURE AUTHENTICATION AND INTER-COMMUNICATION AMONG NETWORKED IOT DEVICES</td>
                        <td>Dr Sachin Gupta & Mrs Babita Yadav</td>
                        <td>21/04/2021</td>
                        <td>Granted</td>

					</tr>
                    <tr>
						<td>202111019468</td>
                        <td>A NOVEL SIMPLIFIED EQUIVALENT MODELING METHOD FOR MICROSTRIP LINE INTERCONNECTS</td>
                        <td>Alok Srivastava</td>
                        <td>28/04/2021</td>
                        <td>Published</td>

					</tr>
                    <tr>
						<td>2020102382</td>
                        <td>AN ARTIFICIAL INTELLIGENCE BASED SYSTEM FOR COVID PREVENTION</td>
                        <td>Alok Srivastava</td>
                        <td>23/09/2020</td>
                        <td>Granted</td>

					</tr>
                    <tr>
						<td>202141007545</td>
                        <td>VOICE CONTROLLED WRITING INSTRUMENT USING MACHINE LEARNING</td>
                        <td>Dr Sachin Gupta</td>
                        <td>23/02/2021</td>
                        <td>Published</td>

					</tr>
                    <tr>
						<td>368860-001</td>
                        <td>PORTABLE SMART EXTERNAL HARD DISK DRIVE</td>
                        <td>Dr Alok Srivastava</td>
                        <td>05/08/2022</td>
                        <td>Published</td>

					</tr>
                    <tr>
						<td>202211015718</td>
                        <td>AN APPARATUS FOR SECURE AND CONSISTENT PATH SELECTION IN WIRELESS COOPERATIVE COMMUNICATION AND IMPROVED METHOD THEREOF</td>
                        <td>Dr Alok Srivastava</td>
                        <td>06/05/2022</td>
                        <td>Published</td>

					</tr>
                    <tr>
						<td>2020221007861</td>
                        <td>INTELLIGENT SYSTEM FOR DETECTING EDITED IMAGES USING ARTIFICIAL INTELLIGENCE AND MACHINE LEARNING.</td>
                        <td>Dr Alok Srivastava</td>
                        <td>25/04/2022</td>
                        <td>Granted</td>

					</tr>
                    <tr>
						<td>346278-001</td>
                        <td>OXYGEN CONCENTRATOR</td>
                        <td>Dr Alok Srivastava</td>
                        <td>13/05/2022</td>
                        <td>Published</td>

					</tr>
                    <tr>
						<td>318705-001</td>
                        <td>Air Quality Sensing Tag</td>
                        <td>Dr Alok Srivastava</td>
                        <td>23/08/2019</td>
                        <td>Published</td>

					</tr>
                    </table>
                    </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CSEPatents;
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCSEHeader from "./header";

const CSEBTechAlumni=()=>{
return (
<Grid container spacing={2}>
{/* <SOETCSEHeader/>
        <BreadcrumbsBanner bannerUrl={"https://uploads.sarvgyan.com/2014/06/mechanical-engineering-image.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Computer Science Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Our Alumni" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Our alumni are the true testament to the spirit and excellence of the Department of Computer Science and Engineering. Spanning across the globe, they have carved niches for themselves in every corner of the tech industry, from leading multinational corporations to groundbreaking startups. This section is dedicated to celebrating their achievements, sharing their inspiring journeys, and staying connected with our growing network of alums. Through their successes, our alumni not only reflect the quality of education and mentorship provided at our department but also serve as role models for present and future students. Their contributions to technology, leadership in their respective fields, and ongoing engagement with our department enrich our community, fostering a legacy of innovation and excellence. Here, we also provide information on alumni events, networking opportunities, and ways to give back, ensuring that our alumni remain an integral part of our ever-evolving story.

    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBTechAlumni;
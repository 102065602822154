import React from 'react';
import { Typography, Grid, Card, Avatar, Box } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import { TableDataPresentation, ListDataPresentation } from '../../utils/utils';
import ImagesSlider from '../muiCommonComponent/imageSlider';
import CourseGrid from '../muiCommonComponent/coursesButtonGrid';
const companyLogos = [
    {img:'/assets/img/SOET/google.png'},
    {img:'/assets/img/SOET/tech_mahindra.png'},
    {img:'/assets/img/SOET/infosys.png'},
    {img:'/assets/img/SOET/wipro.png'},
    {img:'/assets/img/SOET/eicher.png'},
    {img:'/assets/img/SOET/jcb.png'},
    {img:'/assets/img/SOET/escorts.png'},
    {img:'/assets/img/SOET/adobe.png'},
    // Add more logo URLs as needed
];
function Home() {
  const courses = [
    /*{name:'Bachelor of Commerce (Honors)',url:'/school-of-business-management-and-commerce/bcom'}, */ 
    {name:'Bachelor of Commerce (Honors)',url:'/school-of-business-management-and-commerce/bcom_hons'},
    {name:'Bachelor of Business Administration (BBA)- General',url:'/school-of-business-management-and-commerce/bba-general'},
    {name:'Bachelor of Business Administration (BBA)- Digital Marketing',url:'/school-of-business-management-and-commerce/bba-dm'},
    {name:'Bachelor of Business Administration (BBA)- Business Analytics',url:'/school-of-business-management-and-commerce/bba-ba'},
    {name:'Master of Business Administration (MBA)- Marketing',url:'/school-of-business-management-and-commerce/mba-marketing'},
    {name:'Master of Business Administration (MBA)- Finance',url:'/school-of-business-management-and-commerce/mba-finance'},
    {name:'Master of Business Administration (MBA)- Human Resource Management',url:'/school-of-business-management-and-commerce/mba-hrc'},
    {name:'Master of Business Administration (MBA)- Business Analytics',url:'/school-of-business-management-and-commerce/mba-ba'},
    {name:'Master of Business Administration (MBA)- Pharm Management',url:'/school-of-business-management-and-commerce/mba-pm'},
    {name:'Master of Business Administration (MBA)- Hospital Management',url:'/school-of-business-management-and-commerce/mba-hs'},
  ]; 
  const offeredProgramme=[
    {'program':'B.A.LL.B.','duration':'5','Eligibility Criteria':'Senior Secondary or 10+2 or 12 th or equivalent from a recognised Board/ University with the minimum percentage as per BCI norms. Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'B.B.A.LL.B','duration':'5','Eligibility Criteria':'Senior Secondary or 10+2 or 12 th or quivalent from a recognised Board/ University with the minimum percentage as per BCI norms.Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'LL.B.','duration':'3','Eligibility Criteria':'Graduation or post-graduation from a recognised University with the minimum percentage as per BCI norms.Minimum 45% (GEN), 42% (OBC), and 40% (SC/ST).'},
    {'program':'LL.M.','duration':'1','Eligibility Criteria':'Law graduation from a recognised University with the minimum percentage as per UGC/ University norms.'},
]
const progHiglight=[
 {listName:'Availability of scholarships covering up to 100% of expenses for eligible students.'},
 {listName:'Prospects for advanced studies or research in both domestic and international institutions.'},
 {listName:'Access to e-learning platforms like MOOC, NPTEL, and Coursera.'},
  {listName:'Successful job placement across diverse fields like finance, marketing, human resources and Business analytics.'},
  {listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
  {listName:'Prospects for advanced studies or research in both domestic and international institutions.'},
  {listName:'Industry-driven Internship Opportunities with Leading Corporations.'},
  {listName:'Choice-based credit system with basket of Foundation, Core and basket of Elective courses.'},
  {listName:'Latest Curriculum as per industry standards, aligning with the current demands.'},
  {listName:'Project and experiential-based learning.'},
  {listName:'Practical understanding through business workshops, analytical quizzes, case studies, reviews and industry visits.'},
  {listName:'Provision of conference/ short-term training programme/ workshops/ webinars/ online competition/ project competitions/ Industry internships.'},
  {listName:'Research Grants and Scholarships for Academic Excellence.'},
  {listName:'Software training workshops aimed at equipping students with the necessary skills to excel in the realm of research as well.'}
  
]
const eventOrgnized=[
  {listName:'Workshop on Recommender’s System.'},
 {listName:'Live Budget discussion sessions among students.'},
 {listName:'1st International Conference on "Opportunities for Business, Technology innovation and Social Sciences".'},
 {listName:'Virtual Workshop on Regularization: Lasso and Ridge Regression'},
{listName:'Business quiz competition'},
 {listName:'Farewell parties and orientation programmes'},
 {listName:'Excursion Trips'},
{listName:'Industrial visits '},
]
const strengths=[
  {listName:'Highly qualified, experienced, committed and professionally oriented faculty members.'},
 {listName:'Industry-Academia interactions in the form of industrial visits, expert lecture series.'},
 {listName:'Exceptional placement records with a 90% placement rate among academia and Industry, globally.'},
 {listName:'Excursion trips to various destinations.'},
{listName:'To foster holistic development, extracurricular activities both at the departmental and central levels.'},
 {listName:'Special focus on student mentoring and career counselling sessions.'},
 {listName:'Alumni Lecture Series providing valuable insights and networking opportunities.'},
{listName:'Student-led Clubs and Societies Fostering Leadership and Teamwork.'},
]
const knowledge=[
  {listName:'Multi Commodity Exchange of India Limited (MCX)'},
 {listName:'ICAI, Northern India Regional Council Faridabad Chapter.'},
]

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Business Management & Commerce" />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="About School of Business Management & Commerce" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Welcome to the School of Business Management & Commerce, where academic excellence meets real-world application. Our school is a vibrant hub of learning, innovation, and professional development, dedicated to preparing the next generation of business leaders. 
        <br/>
        The School of Business Management & Commerce has been imparting quality management and commerce education under the aegis of MVN University since its inception. We offer a comprehensive range of programs designed to equip students with the knowledge, skills, and experience needed to thrive in today's dynamic business environment. From undergraduate degrees to specialized master's programs, our curriculum is carefully crafted to blend theoretical foundations with practical insights, ensuring our graduates are well-prepared to tackle the challenges of the global marketplace. We offer Ph. D. in Management & Commerce also, taking education to greater heights. 
        <br/>
        Beyond the classroom, the School of Business Management & Commerce offers a wide range of extracurricular activities, including student clubs, guest lectures, and networking events, where students can further develop their skills, expand their networks, and explore their interests outside of academics.
<br/>
  <br/>
  <b color='red'>Highest Salary: INR 10 lacs per annum</b>
</Typography>
</Grid>

      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Dean’s Message" />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Card sx={{ height: 'auto', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px' }}>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <Avatar alt={'NP'} src={'/assets/img/dean-image.jpg'} sx={{ width: 150, height: 180, marginBottom: 2 }} />
            <Box sx={{ background: '#ED1E24', borderRadius: '10px', padding: 2 }}>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Dr. N.P. Singh,
              </Typography>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Dean of School of Business Management & Commerce
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        At the School of Business Management & Commerce, we aim to cultivate leaders who shape their own destinies and influence the organizations they work with, while making a positive contribution toward a working civilization.
       <br/>
       We believe that management often blends the art of leadership with the science of organizational behaviour. The balance between creativity and analytical rigour helps in achieving sustainable success. With people-centric management practices, and effective leadership, maximum human potential can be achieved for the common good. That's precisely why our curriculum features courses that accentuate self-management and meticulously explore the intricacies of leading and nurturing others.

<br/>

In addition to emphasize people, we believe in the importance of performance and accountability for getting desired outcomes. Therefore, these topics are integrated throughout our curriculum and think you to use data to make evidence-based decisions, both within specific functional area or organization at large. 
<br/>

We often mention that our student community is multi-dimensional because we admit students with diverse backgrounds and interests. We assure you that you will be equipped with knowledge across subject boundaries, and many of MVN university alumni have been part of finding solutions of the most pressing and complex problems.
    
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Our faculty and staff will form long-lasting association with student community that help newcomers to know their peers, and as newcomer you will quickly realise that you are part of a group of like-minded individuals. To make education personalized we work within small groups for extending value added services to you. We always identify that you are on an exceptional journey that will shape your future, which in turn effect changes in your work place and create positive environment in the world. This makes us different from other institutions.    
        <br/>
        We are sure that with passage of time you will better known person as we are immeasurably proud of School of Business Management & Commerce and believe that we train adults who make a positive impact to society.
      </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Business Management & Commerce Courses" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <CourseGrid courses={courses}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="PROGRAM HIGHLIGHTS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={progHiglight}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Events Organized" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={eventOrgnized}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Award Highlights" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
      MVN University has been awarded as the “OUTSTANDING UNIVERSITY WITH EXCELLENT INFRASTRUCTURE AND GREEN CAMPUS INITIATIVE 2023” at Radisson Blu, Gurugram
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Strengths of SBMC" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={strengths}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Knowledge Partners" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={knowledge}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="Top Recruiters" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={companyLogos} />
      </Grid>
    </Grid>
  );
}

export default Home;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSAlumni=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Allied Health Sciences" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Our Alumni" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Our alumni stand as living testaments to the unwavering spirit and excellence embodied by the School of Allied Health Sciences at MVN University.
    <br/><br/>
    MVNU’s SAHS Alumni — entrepreneurs, researchers, policymakers, and above all, leaders — have helped to shape the world we know today.
    <br/><br/>
    SAHS has nearly 1200+ alumni represent one of the most talented, innovative, and networked communities in India. Many remain deeply involved in the life of the Institute. Our graduates are serving in Hospitals, Research Lab, Diagnostics Lab, Physiotherapy and Rehabilitations, Pharmaceutical Industries, Food and beverage industries etc.
    <br/><br/>
    Our alumni are a valued part of our community. Their work after graduation helps us nurture our vision of a just, equitable, humane and sustainable society.
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSAlumni;
import React from 'react';
import { Typography, Grid, Card, Avatar, Box } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import { TableDataPresentation, ListDataPresentation } from '../../utils/utils';
import ImagesSlider from '../muiCommonComponent/imageSlider';
import CourseGrid from '../muiCommonComponent/coursesButtonGrid';
function Home() {
  const courses = [
    {name:'B.A',url:'/school-of-arts-science-humanities/bap'},
    {name:'B.A. Economics',url:'/school-of-arts-science-humanities/bae'},
    {name:'B.A. Journalism and Mass Communication',url:'/school-of-arts-science-humanities/bjmc'},
    {name:'B.A. Political Science',url:'/school-of-arts-science-humanities/bps'},
    {name:'B.Sc.',url:'/school-of-arts-science-humanities/bsc'},
  ]; 
  const progHiglight=[
    {listName:'Availability of scholarships covering up to 100% of expenses for eligible students'},
    {listName:'Comprehensive curriculum as per NEP 2020aligned with University Grant Commission standards along with ability enhancement, skill enhancement, value added, and vocational courses'},
    {listName:'Curriculum includes ability enhancement, skill enhancement, value added, and vocational courses.'},
    {listName:'Curriculum crafted by distinguished academicians'},
    {listName:'4 advanced laboratories with full equipment'},
    {listName:' Partnerships providing skill-enhancement training to boost job readiness'},
    {listName:' Access to e-learning platforms like MOOC, NPTEL, and COURSERA'},
    {listName:'Tailored training programs to prepare students for placement opportunities'},
    {listName:'Flexible credit system enabling students to pursue interdisciplinary minors'},
    {listName:'Experiential learning is prioritized to complement theoretical knowledge through laboratory experiments, fieldwork, internships, and research projects'},
    {listName:'Encourages interdisciplinary collaboration, allowing students to explore connections between different scientific disciplines'},
    {listName:'Departmental library'},
    {listName:'Innovative teaching methodology'},
    {listName:' Prospects for advanced studies or research in both domestic and international institutions'},
    {listName:'Engaged students in community service, outreach programs, and social initiatives to foster a sense of social responsibility and civic engagement.'},
    {listName:'Employment opportunities in prestigious organizations across defence, research, government and private sector, etc.'},
    {listName:'Modern laboratories equipped with sophisticated instruments.'},
    {listName:'Choice-based Credit System in the curriculum.'},
    {listName:' Emphasis on project-based and experiential learning methodologies'},
        
   ]
   const feeStructure=[
    {'Program':'B.Sc (Medical)','Eligiblity':'10+2 with 50% marks in PCB','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Program':'B.Sc (Non Medical)','Eligiblity':'10+2 with 50% marks in PCM','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Program':'BA (Pass)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Program':'BA (Economics-Honours)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Program':'BA (Plotical Science)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'35000' ,'1st Year Fees':'60200','2nd Year Onwards':'44200'},
        {'Program':'BA (Journalism & Mass Communication)','Eligiblity':'10+2 with 50% marks in Any Stream','Duration':'3','Tution Fee':'50000' ,'1st Year Fees':'75200','2nd Year Onwards':'59200'},
        {'Program':'Ph.D. in all Streams','Eligiblity':'PG with minimum 55% in aggregate','Duration':'Minimum 3 Years','Tution Fee':'85000' ,'1st Year Fees':'105200','2nd Year Onwards':'89200'},
  ]
  const eventOrgnized=[
    {listName:'SPORTS'},
    {listName:'FAIRWELL PARTY'},
    {listName:'FRESHER PARTY'},
    {listName:'ENVISAGE'},
    {listName:'CONVOCATION'},
    {listName:'CONFERENCE'},
    {listName:'JOB FAIR'},
  ]
  const eventLogos = [
    {img:'/assets/img/SSH/event1.jpg'},
    {img:'/assets/img/SSH/event2.jpg'},
    {img:'/assets/img/SSH/event3.png'},
    {img:'/assets/img/SSH/event4.jpg'},
    {img:'/assets/img/SSH/event5.jpg'},
   
    // Add more logo URLs as needed
];
  const companyLogos = [
    {img:'/assets/img/SSH/company1.png'},
    {img:'/assets/img/SSH/company2.png'},
    {img:'/assets/img/SSH/company3.jpg'},
    {img:'/assets/img/SSH/company4.png'},
    {img:'/assets/img/SSH/company5.png'},
    {img:'/assets/img/SSH/company6.jpg'},
    {img:'/assets/img/SSH/company7.jpg'},
    {img:'/assets/img/SSH/company8.png'},
    {img:'/assets/img/SSH/company9.jpg'},
    {img:'/assets/img/SSH/company11.png'},
    {img:'/assets/img/SSH/company12.png'},
    {img:'/assets/img/SSH/company13.jpg'},
    {img:'/assets/img/SSH/company14.png'},
    {img:'/assets/img/SSH/company15.png'},
    {img:'/assets/img/SSH/company16.png'},
    // Add more logo URLs as needed
];
const hostalFeeData=[
  {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
  {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
]
const noteList=[
{listName:'1. Hostel caution money of Rs. 10000/- one-time payment (Refundable'},
{listName:'2. Uniform fee Rs. 4500/- will be charged extra.'},
{listName:'3. Counselling fee (if applicable) will be charged extra.'},
{listName:'4. UGC norms will be adhered for fee refund'},
{listName:'5. Hostel will be provided for full Academic year only.'},
{listName:'6. All the dispute are subjected to Palwal judiciary.'},
]
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="ABOUT SCHOOL OF SCIENCE AND HUMANITIES" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        The focus of the School of Science & Humanities is to equip a fresher with the necessary skills and knowledge in the field of science and humanities to cope with challenges in life. This is achieved through classroom teaching, case studies, brainstorming sessions, practical field experiences etc. The School of Science and Humanities fosters innovation, critical thinking, and creativity by offering a dynamic learning environment.
        <br/><br/>
        The core objective of the School of Science & Humanities is to introduce a student to the world by exploring their inherent talent and skills. To bring the best out of a student, we have strategically designed curriculum & teaching methodology. The implementation and retention of knowledge by the students are of paramount importance for securing a successful future, our academia takes utmost care in imparting the knowledge interwoven with wisdom in an effective manner. No effort is spared to ensure teaching methodology is delivered in its best manner. School of Science & Humanities makes the students capable of not only self-development but also makes them socially conscious of their larger role in the society. Here the students are sensitized to the values and issues of justice, freedom and equality. School provides knowledge not for knowledge sake but for the betterment of the society.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="HOD’S MESSAGE" />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Card sx={{ height: 'auto', padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', borderRadius: '10px' }}>
          <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <Avatar alt={'NP'} src={'/assets/img/SSh/kuldeep.png'} sx={{ width: 150, height: 180, marginBottom: 2 }} />
            <Box sx={{ background: '#ED1E24', borderRadius: '10px', padding: 2 }}>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Dr. Kuldeep Tanwar
              </Typography>
              <Typography fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" color={'#fff'}>
              Welcome to the School of Science and Humanities!
              </Typography>
            </Box>
          </Box>
        </Card>
      </Grid>
      <Grid item xs={12} sm={8} md={8}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        In my position as the department head, I have the pleasure of extending a cordial welcome to all students, faculty members, visitors and stakeholders interested in the fascinating fields of science and humanities. The School of Science and Humanities is an integral part of MVN University. It is a centre of excellence for quality education in the field of Science and Humanities. The School believes in making learning a continuous process with a view to create a pool of highly educated graduates. 
<br/>
The School is committed to fostering a dynamic learning environment where curiosity is encouraged, knowledge is pursued, and critical thinking is cultivated. The Schools offers a diverse range of courses spanning the realms of science, mathematics, language, political science, economics, mass communication and social sciences, providing students with a well-rounded education that prepares them for success in their chosen fields. Supporting materials for lectures include resource-based materials, group projects, tutorials, and individual study.
<br/>
School of Science and Humanities offers curriculum aligned with NEP 2020.  The School offers skill enhancement, ability enhancement, and value added courses to enable the students to cope with challenges of real life.  The school helps students make decisions and craft pathways that are beneficial not just for themselves but also for society, the nation as a whole and the globe at large by instilling in them human values and professional ethics. We have a robust mentor-mentee program in which a faculty member interacts with a small group of students. The faculty member serves as a mentor, friend, and advisor to the students, assisting them in comprehending and achieving their goals.

        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="School of Law Courses" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <CourseGrid courses={courses}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="DEPARTMENTS" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Department of Science" />
      </Grid>
      <Grid item xs={12} sm={12} md={ 12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Department of Science provides a transformative educational experience that equips students with the knowledge, skills, and ethical framework needed to excel in scientific inquiry and contribute meaningfully to society. Through rigorous academic programs, hands-on research opportunities, and a supportive learning environment, we strive to cultivate critical thinking, creativity, and a lifelong passion for discovery. Through laboratory courses, fieldwork, and research projects, students have the opportunity to apply theoretical knowledge in practical settings and develop essential skills in observation, experimentation, and analysis. The Department of Science embraces an interdisciplinary approach to education and research, recognizing that many of the most pressing scientific questions require collaboration across traditional boundaries. By encouraging cross-disciplinary collaboration, we foster innovation and creativity and prepare students to tackle complex, real-world problems. Department of Science is more than just a place of learning—it's a supportive community of scholars, mentors, and friends. Whether through faculty office hours, peer tutoring programs, or student organizations, we are committed to providing the support and resources needed for student success. A degree in science opens doors to a wide range of exciting career opportunities in academia, industry, government, and beyond. Our alumni are making a difference in fields as diverse as healthcare, environmental conservation, technology, and education, and we are proud to support them on their professional journey.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Programs Offered:" />
      </Grid>
      <Grid item xs={12} sm={12} md={ 12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Department of Science offers two undergraduate programs designed to meet the diverse interests and career aspirations of science students. Our flagship programs include:
        <br/>
        <br/>
       1. Bachelor of Science (General) Non-Medical
       2. Bachelor of Science (General) Medical

        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Department of Humanities" />
      </Grid>
      <Grid item xs={12} sm={12} md={ 12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        The Department of Humanities is a dynamic and inclusive community dedicated to the exploration of human thought, expression, and creativity. Rooted in the traditions of critical inquiry and civic engagement, we offer a diverse array of programs designed to cultivate intellectual curiosity, foster ethical leadership, and inspire social change. The department of Arts and Humanities concentrates on providing a transformative educational experience that equips students with the knowledge, skills, and ethical foundation needed to navigate the complexities of the modern world and become informed, engaged citizens and leaders. Through innovative pedagogy, experiential learning opportunities, and a commitment to diversity, equity, and inclusion, we strive to foster a culture of intellectual curiosity, critical thinking, and creative expression.  At the Department of Humanities, the arts, humanities, and social sciences serve as catalysts for understanding, empathy, and justice. Through rigorous scholarship, interdisciplinary collaboration, and meaningful dialogue, we seek to empower individuals to engage with the complexities of the human experience and contribute to a more equitable and compassionate society.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Programs Offered:" />
      </Grid>
      <Grid item xs={12} sm={12} md={ 12}>
        <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        Department of Humanities offers four undergraduate programs designed to meet the diverse interests and career aspirations of the students. Our flagship programs include:
<br/>
<br/>
1. Bachelor of Arts (Honours) Political Science
2. Bachelor of Arts (Honours) Economics
3. Bachelor of Arts (Journalism and Mass Communication)
4. Bachelor of Arts (Pass Course)


        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="PROGRAM HIGHLIGHTS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={progHiglight}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="PROGRAMS OFFERED, DURATION, ELIGIBILTY AND FEE STRUCTURE " />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <TableDataPresentation data={feeStructure}/>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="EVENTS ORGANIZED" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ListDataPresentation DataList={eventOrgnized}/>
      <ImagesSlider imgArr={eventLogos} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="OUR RECRUITERS" />
      </Grid>
      <Grid item xs={12} sm={12} lg={12} md={12}>
      <ImagesSlider imgArr={companyLogos} />
      </Grid>
      <Grid item xs={12}>
					<MainTitle title='Hostel Fees'/>
				</Grid>
				<Grid item xs={12}>
				<TableDataPresentation data={hostalFeeData}/>
				</Grid>
				<Grid item xs={12}>
    <Typography textAlign='justify' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
	<Grid item xs={12}>
    <MainTitle title="Notes" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={noteList}/>
    </Grid>
    </Grid>
  );
}

export default Home;

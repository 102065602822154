import React, { useState } from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'

function LawLegalAidClinic() {

    const Data = [        
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-1.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-2.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-3.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-4.jpg", category : "Financial"},
        
      ]

      const [current, setcurrent] = useState(Data);

    const aids = [
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-5.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-6.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-7.jpg", category : "Financial"},
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-camp-8.jpg", category : "Financial"},
        
    ];
    
    

    


    return (
        <>
            <Header />
            <Bredcom title="Home/ School of Law" subtitle="Legal Aid Clinic" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="single-item-carousel owl-carousel owl-theme">
                                    <figure className="industryImg">
                                        <img src="https://admission.mvn.edu.in/wp-content/uploads/2021/08/legal-aid-clinic.jpg" alt="" />
                                    </figure>
                                </div>

                                <p>
                                MVN School of law has constituted a Legal Aid Clinic committee with the collaboration of Haryana State Legal Service Authority/District Legal Service Authority, Palwal.
                                </p>

                                <p>
                                Legal Aid Clinics basically serve the purpose of providing legal advice not for the aim of earning profit but in General Public Interest. In law school, though knowledge of law is thoroughly imparted. However theoretical knowledge is not sufficient when its applicability is not known. Students pursuing law have zeal and enthusiasm to provide legal services. Legal aid clinics thus serve the two-fold purpose, the first that they provide free legal services to economically weaker people and second, they direct the energies of the students of law in contributing to the society.
                                </p>

                                <p>
                                These students are headed by their teachers. The members of the Legal Aid clinic are engaged in spreading legal awareness in rural areas through street plays as well as legal aid camps where they make the target population aware of different legal service programs and guide them to avail these facilities. In 1997, the Bar Council of India has made the study of legal aid an important and compulsory subject in the academic topics of the law schools in India. One of the avowed objectives of the Law School of University is to promote legal education. In pursuance of the same the Legal Services Clinic offers legal and paralegal services by the faculty and the students.
                                </p>

                                <p>
                                MVN School of Law continuously organizes workshops, guest lectures and legal aid clinics.
                                </p>
                                
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>LEGAL AID CAMP-1</h3>
                                </div>
                                <p>
                                MVN University, School of Law, Palwal has organised legal aid camp in the villages of Palwal. Vhiduki, Ramgarh, Banswa and Maholi were the four villages where the students and the faculty members of the University have visited and awaken the local residents about the fundamental rights, fundamental duties, and various laws. Dr. Rahul Varshney, Head of Department, addressed the public followed by a speech on various rights of the citizens. Students also spoke on various topics pertaining to Right to Education and Right to clean environment. The local resident of the villages visited by the legal aid team has requested the teams to conduct such camps on frequent basis as these visits helps people to become more vigilant of their rights and claim their rights before the concerned authorities of various departments.
                                </p>

                               


                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {current.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>LEGAL AID CAMP-2</h3>
                                </div>

                                <p>
                                MVN University, School of Law, Palwal has recently organised legal aid camp to four villages of Palwal and Hodal. The university as part of its curriculum and under the directions of Bar Council organizes such legal aid camp every year. Active participation of students and faculty members has awakened the local residents of the villages about their rights, duties and various laws relating to the environment and sanitation. The legal aid team visited villages of Behraula and Attoha in the morning hours where it was welcomed by Sh. Krishna Devi (Sarpanch) and Sh. Narvir Singh (Sarpanch), respectively. Dr. Rahul Varshney, Head of Department, addressed the public followed by a speech on various rights of the citizens. Students also spoke on various topics pertaining to Right to Education and Right to clean environment. Further, the village of Beda Patti was visited in the afternoon, where huge gathering of local residents and Parshad Smt. Preeti attended the camp. Lastly, team conducted various speeches by the faculty members namely Dr. Annu Bahl Mehra, Mr. Ajay Tiwari, Dr. Ramveer Singh, Ms. Shabana, Ms. Prerna Singh, Mrs. Kavita Gupta and Mr. Dushyant Singh in the village of Banswa and received appreciation from Sarpanch Sh. Dalveer. The students and the faculty have even provided legal advice to the local people on their queries, which like previous occasions made the camp a success.
                                </p>
                               
                                <div className="row fadeInUp  animated pt-25" data-animation="fadeInUp" data-delay=".4s" >
                                    {aids.map( (item, Index)=> ( 
                                        <div className="col-md-3 col-sm-6" style={{padding:'15px'}} key={Index}>
                                                    <img src={item.img} alt="img" className='healthImg' />
                                        </div>
                                    ))}
                                </div>


                                                            


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LawLegalAidClinic
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import AcademicButton from '../academicButton';

const faqItems = [
    {
        id: 'headingThree',
        question: '01 What is the scope of career opportunities for graduates of the School of Allied Health Sciences?',
        answer: ' Graduates from the School of Allied Health Sciences are well-equipped for a wide range of rewarding careers in the healthcare industry. Depending on their chosen specialization, they can pursue roles as medical technologists, radiologic technologists, respiratory therapists, clinical laboratory scientists, and more. Our comprehensive programs prepare students to excel in hospitals, clinics, research laboratories, rehabilitation centers, and other healthcare settings.',
    },
    {
        id: 'headingOne',
        question: '02 How does the School of Allied Health Sciences emphasize practical training and real-world experience?',
        answer: 'The School of Allied Health Sciences places a strong emphasis on hands-on training and practical experience. Our students engage in clinical rotations, internships, and simulated patient interactions that mirror real healthcare scenarios. These experiences provide invaluable opportunities to apply classroom knowledge, develop critical skills, and build confidence in a controlled learning environment. By the time students graduate, they are well-prepared to seamlessly transition into their chosen healthcare professions.',
    },
    {
        id: 'headingTwo',
        question: '03 Can students in the School of Allied Health Sciences collaborate with other health disciplines?',
        answer: 'Our university is dedicated to supporting the career development of our graduates. The Career Services Center works in collaboration with the Computer Science EngineerinAbsolutely. Collaboration is a key aspect of our School philosophy. Students in the School of Allied Health Sciences often engage in interdisciplinary projects and teamwork alongside students from other health science disciplines. This collaborative approach mirrors the dynamics of modern healthcare settings, where effective teamwork and communication are vital. By working together, students gain a deeper understanding of the interconnectedness of various healthcare roles, enhancing their ability to deliver comprehensive patient care.',
    },
];

function First() {
    return (
        <>
            <section className="project-detail">
                <div className="container">
                <AcademicButton path='/school-of-allied-health-sciences' pageName="School of Allied Health Sciences"/>
                    <div className="lower-content">
                        <div className="row">
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                {/* <h2>School of Allied Health Sciences</h2> */}
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="industryImg">
                                            <img src="https://admission.mvn.edu.in/wp-content/uploads/2021/09/school-of-allied-health-sciences-5.jpg" alt="" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="inner-column">
                                    {/* <div className="course-meta2 review style2 clearfix mb-30">
                                        <ul className="left">
                                            <li>
                                                <div className="author">
                                                    <div className="thumb">
                                                        <img src="/assets/img/bg/testi_avatar.png" alt="image" />
                                                    </div>
                                                    <div className="text">
                                                        <Link to="/single-courses-2">Robto Jone</Link>
                                                        <p>Teacher</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="categories">
                                                <div className="author">
                                                    <div className="text">
                                                        <a href="#" className="course-name">
                                                            Photoshop
                                                        </a>
                                                        <p>Categories</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                         <ul className="right">
                                            <li className="price">$59.00</li>
                                        </ul> 
                                    </div> */}
                                    <h3>Course Overview</h3>
                                    <p>
                                    Paramedical courses covered under the school of Allied Health Science(SAHS) are aimed at producing skilled manpower in different areas of biomedical science for better health care delivery. To begin with, we have already started Bachelor’s in Physiotherapy(BPT), B.Sc. in Medical Laboratory Technology, B.Sc. in Medical Microbiology, Biotechnology and Diploma in Medical Laboratory Technology. The School of Allied Health Sciences was set up with the objective of imparting highest quality learning and for providing in-service training for physiotherapy, medical lab technology and other allied personnel through the classroom teaching, clinical & community exposure in the respective fields.
                                    </p>
                                    <p>
                                    The School in last 4 years has established itself as a pioneer in developing competence among students through Innovative approaches in medical programmes include hands-on training, which is provided through diversified approaches of districts-level hospitals in all disciplines of Health Sciences. The functioning of the School involves. Planning, developing and launching of academic programmes at various levels for different categories of health personnel.
                                    </p>
                                    <p>Disseminating health-related knowledge for the general public in forms of health camps, and educative campaigns.</p>
                                    
                                    <p>
                                    Research on health-related issues.
                                    </p>
                                    
                                    <h4>What You Will Learn</h4>
                                    
                                    <p>
                                        At the School of Allied Health Sciences, students delve into a wide range of specialized disciplines such as medical imaging, respiratory therapy, clinical laboratory sciences, and more. Our comprehensive programs emphasize practical, hands-on training, allowing students to gain valuable experience in real healthcare settings. From conducting diagnostic tests to assisting medical professionals, our students graduate well-prepared to make a positive impact on patient care.
                                    </p>

                                    <p>
                                        Our School fosters a collaborative environment where students from various health science disciplines work together on interdisciplinary projects and research initiatives. This unique approach prepares graduates to thrive in team-based healthcare settings, where collaboration is essential for providing comprehensive patient-centered care. Furthermore, our faculty members are actively engaged in groundbreaking research, ensuring that students have access to the latest advancements and innovations in the field of allied health sciences.
                                    </p>


                                    <ul className="pr-ul">
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                            Medical Imaging Technology
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                            Physical Therapy
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                            Medical Laboratory Science
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                            Occupational Therapy
                                            </div>
                                        </li>
                                    </ul>
                                    
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Courses Offered</th>
                                                <th>Length</th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Link to="/dmlt">DMLT (Diploma in Medical Laboratory Technology)</Link></td>
                                                <td>2 Years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/bpt">BPT (Bachelor of Physiotherapy)</Link></td>
                                                <td>4.5 years full time</td>                                                
                                            </tr>
                                            {/* <tr>
                                                <td><Link to="/bscmedicalmicrobiology">B.Sc. (Medical Microbiology)</Link></td>
                                                <td>3 years full time</td>                                                
                                            </tr> */}
                                            <tr>
                                                <td><Link to="/bmlt">B.Sc. MLT (B.Sc. in Medical Lab Technology)</Link></td>
                                                <td>3 years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/bscbiotechnology">B.Sc. (Biotechnology)</Link></td>
                                                <td>3 years full time</td>                                                
                                            </tr>
                                            <tr>
                                                <td><Link to="/mscmicrobiology">M.Sc. (Microbiology)</Link></td>
                                                <td>2 Years full time</td>                                                
                                            </tr>
                                        </tbody>
                                    </table>

                                    <h4>Eligibility and Fees Structure</h4>
                                    <p>
                                    10+2 or its equivalent with Science (Physics, Chemistry, Biology) with Physiotherapy (BPT), For Laboratory Technology and Medical Microbiology 10+2 (PCB/M) With English one of the subject with minimum 45% marks (40% in case of ST/SC candidates of Haryana States).
                                    </p>

                                    
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3 ">
                                        <h3>About Us</h3>
                                        <ul className="project-info ">
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-allied-health-sciences-vision-mission"> <strong>Vision & Mission</strong> </Link> 
                                            </li>                                            
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-allied-health-sciences-peo-po-pso-bpt"><strong>PEO, PO & PSO - BPT</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-allied-health-sciences-peo-po-pso-bmm"> <strong>PEO, PO & PSO - BMM</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-allied-health-sciences-peo-po-pso-bmlt"> <strong>PEO, PO & PSO - BMLT</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-BPT.pdf"> <strong>SOS - BPT</strong> </Link> 
                                            </li>                                             
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-BMM.pdf"><strong>SOS - BMM</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-BMLT.pdf"><strong>SOS - BMLT</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-B.Sc_.-Bio-Technology.pdf"><strong>SOS - B.SC Bio Technology</strong></Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/05/Events-Allied-Health-Science.pdf"> <strong>Events & Press Release</strong> </Link> 
                                            </li>  
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/05/Research-Publications-1.pdf"> <strong>Research Publications</strong> </Link> 
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-allied-health-sciences-activities"> <strong>Activities</strong> </Link> 
                                            </li>                                              
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/school-of-allied-health-sciences-hospitals"><strong>Collaboration with Hospital</strong></Link> 
                                            </li>
                                            <li>
                                                <div className="slider-btn">
                                                    <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                        Enroll <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
import { Grid, Typography } from "@mui/material";
import MainTitle from "../muiHome/mainTitle";

const SAHSAlumni=()=>{
return (
<Grid container spacing={2}>
<Grid item xs={12}>
    <MainTitle title="School of Science & Humanities" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Our Alumni" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Our alumni network demonstrates the legacy of excellence and innovation fostered within our school. Here, we honor the dynamic group of alumni who have journeyed through our halls and embarked on diverse paths of success. Our alumni have made a wide range of contributions to society, from pioneering scientific and technological research to transforming cultural environments via the humanities. Through their achievements, our alumni not only demonstrate the quality of education and mentorship offered by our school, but they also act as role models for both present and prospective students. 
<br/><br/>
School offers a range of opportunities to the graduates stay engaged and involved ensuring that our graduates remain an integral part of our evolving narrative. Our alumni mentor the students by participating in mentorship programs, offering career advice, or providing internship opportunities. Their advice has the potential to significantly alter a student's professional career.
<br/><br/>
    </Typography>
    </Grid>
    </Grid>

)
}
export default SAHSAlumni;
import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'
import { baseURL } from '../../config';
import DialogTitle from '@mui/material/DialogTitle';
import Headerone from '../headerone/Main'
import About from '../menhome/About'
import Courses from '../menhome/Courses'
import Event from '../menhome/Event'
import Scholarship from '../menhome/Scholarship'
import Shep from '../menhome/Shep'
import Slider from '../menhome/Sliderone'
import Frequently from '../menhome/Frequently'
import Video from '../menhome/Video'
import Testimonial from '../menhome/Testimonial'
import Search from '../menhome/Search'
import Admission from '../menhome/Admission'
import Redslider from '../menhome/Redslider'
import Blog from '../menhome/Blog'
import Subscribe from '../menhome/Subscribe'
import Loader from '../Pages/Loader/Loader';
import ScriptLoader from '../mencontact/scriptLoader';

const SpecialRegex=/^[\w-\]+[A-Za-z\s]*$/g
const EmailRegex=/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g


function Main() {
  const [open, setOpen] = useState(true);
 const [isLoading,setIsLoading]=useState(false)
 const [showContent, setShowContent] = useState(false);

 const toggleContent = () => {
   setShowContent(!showContent);
 };
 
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const [enquiryForm, setEnquiryForm] = useState({
    name: '',
    email: '',
    phone_number: '',
    comments: ''
})
const [errorStatus, setErrorStatus] = useState(false)

const handleSubmit = (e) => {
  e.preventDefault()
  setIsLoading(true)
  if (Boolean(enquiryForm?.name.match(SpecialRegex))==false || Boolean(enquiryForm?.email.match(EmailRegex))==false|| enquiryForm?.phone_number<10) {
      setErrorStatus(true)
      setIsLoading(false)
      // var x = document.getElementById("snackbar");
      // x.className = "show";
      // setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
  }
  else {
      let url = `${baseURL}/enquiry`
      axios.post(url, enquiryForm, {
          headers: {
              Accept: "application/json",
              "Content-Type": "application/json",

          }
      }).then((response) => {
          var x = document.getElementById("successsnackbar");
          x.className = "show";
          setTimeout(function () { x.className = x.className.replace("show", ""); }, 4000);
          setIsLoading(false)
          setEnquiryForm({
              name: '',
              email: '',
              phone_number: '',
              comments: ''
          })
      }).catch(error=>{
        setIsLoading(false)
      })

  }
}
const handleInput = (e) => {
  const name = e.target.name;
  const value = e.target.value;
  setEnquiryForm((values) => ({ ...values, [name]: value }))
}
  return (
    <>  
        <ScriptLoader/>
        <Headerone/>
        <Slider/>
        <About/>
        <Courses/>
        <Shep/>
        <Event/>
        <Scholarship/>
        <Frequently/>
        <Video/>
        <Testimonial/>
        {/* <Search/> */}
        {/* <Admission/> */}
        <Redslider/>
       
        {/* <Blog/> */}
        {/* <Subscribe/> */}
         {/* Content */}
        {/* Toggle button */}
        {/* <div style={{position:'relative'}}> */}
        {!showContent&&
        <div className={`toggle-button ${showContent ? 'active' : ''}`} onClick={toggleContent}>
        <button><img src='https://th.bing.com/th/id/R.bc30ad25a0c59ddc5f0d1811ed0c5d10?rik=FgJKsp4Mzc3Tfw&riu=http%3a%2f%2fwww.iband.com%2fclipart_holidays%2fnewfreeze.gif&ehk=NGphspN%2baipi73BD5mSdsag5aRUp1DqTytXqlhOe%2faw%3d&risl=&pid=ImgRaw&r=0'/> Latest Event</button>
      </div>
        }
      {/* Content */}
      <div className={`content-toggle ${showContent ? 'show' : ''}`}>
      <div className="content-toggle-header">
          {/* <h2>Annual Fest (Envisage 2k24)</h2> */}
          <button onClick={toggleContent} className="close-button">
            <CloseIcon />
          </button>
        </div>
        <p><a href='/International_conference.pdf' style={{color:'blue'}} target='_blank'>• 2nd International conference on campus to corporate industry 4.0 (14-03-2024) </a> </p>
        <p><a href='https://forms.gle/oaDSW5Uv3kVgqX5t6' style={{color:'blue'}} target='_blank' >• Convocation Registration Link for students</a></p>
        <p><a href='/List_of_Eligible_Student.pdf' style={{color:'blue'}} target='_blank' >• List of Eligible students for convocation</a></p> 
        <p>• Registration link for conference ( Job Fair ) : <a href='https://docs.google.com/forms/d/1yaMJ-0hPazlmH5yOXHERu1ZbAA3ook7c0U0RGX5XaCQ/edit' style={{color:'blue'}} target='_blank' >https://docs.google.com/forms/d/1yaMJ-0hPazlmH5yOXHERu1ZbAA3ook7c0U0RGX5XaCQ/edit</a></p> 
        {/* <p>• Registration Form for Star Night: 23.02.2024 Annual Fest (ENVISAGE 2K24) :   <a href='https://forms.gle/gZtqNXgpqoR9XiZB8' style={{color:'blue'}} target='_blank'>https://forms.gle/gZtqNXgpqoR9XiZB8</a> </p>
        <p>• Registration Form for Participants (Annual fest) :   <a href='https://forms.gle/W1fDSZJsBak5G8a6A' style={{color:'blue'}} target='_blank'>https://forms.gle/W1fDSZJsBak5G8a6A</a>  </p>
        <p>• Rule Book for Annual Fest (Envisage 2k24) :  <a href='https://drive.google.com/file/d/1kg8nFDUKRWqPkTasxZC87kK0TeK8YnLe/view?usp=sharing' style={{color:'blue'}} target='_blank'>https://drive.google.com/file/d/1kg8nFDUKRWqPkTasxZC87kK0TeK8YnLe/view?usp=sharing</a>  </p>
        <p>• Registration Link for Conference :   <a href='https://forms.gle/uNYvULo17UpeqzeR6' style={{color:'blue'}} target='_blank'>https://forms.gle/uNYvULo17UpeqzeR6</a>  </p>
        <p>• Sponsorship Registration Form :   <a href='https://forms.gle/ArzfMtUJ8f2oev5s7' style={{color:'blue'}} target='_blank'>https://forms.gle/ArzfMtUJ8f2oev5s7</a>  </p> */}
      </div>
      {/* </div> */}
        <Dialog open={open} onClose={handleClose} fullWidth>
              
        <div className="contact-bg02" style={{padding:"20px 50px "}}>
                            <div className="section-title center-align text-center">
                                    <h2>Enquiry Form</h2>
                                </div>
            <div className="npf_wgts" data-height="450px" data-w="dfc8352494c1e0ec69cfdd4fec2fbdfd"></div>
          </div>
         
          <IconButton
            edge="end"            
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: 'absolute',
              color:'#fff',             
              top: 8,
              right: 25
            }}
          >
            <CloseIcon />
          </IconButton>
          
        {isLoading&&<Loader/>}
        </Dialog>
    </>
  )
}

export default Main
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Header from '../../headerone/Main'
import NewMain from '../../headerone/newMain';
function Main() {
    const location = useLocation()
    return (
        <>
            <NewMain />
            <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="breadcrumb-wrap text-left">
                                <div className="breadcrumb-title">
                                </div>
                            </div>
                        </div>
                        <div className="breadcrumb-wrap2">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="/"> Home </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                    Branch of Examination Cell
                                    </li>

                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="project-detail">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className='text-center'>Controller of Examinations</h2>
                        <table id="admission">
					<tr>
                        <td>Name of the Department </td>
                        <td>Examination</td>
                    </tr>
                    <tr>
                        <td>Name of Branch Officer</td>
                        <td>Dr. Kuldeep Tanwar</td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td>Controller of Examinations</td>
                    </tr>
                    <tr>
                        <td>Contact Number</td>
                        <td>9671418111</td>
                    </tr>
                    <tr>
                        <td>Email Id </td>
                        <td>coe@mvn.edu.in</td>
                    </tr>
                    </table>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className='text-center'style={{marginTop:'10vh'}}>Result Branch</h2>
                        <table id="admission">
					<tr>
                        <td>Name of Branch Officer</td>
                        <td>Mr. Sunder Singh</td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td>Assistant Registrar</td>
                    </tr>
                    <tr>
                        <td>Contact No.</td>
                        <td>8397979227</td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td>s.singh@mvn.edu.in</td>
                    </tr>
                    </table>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className='text-center' style={{marginTop:'10vh'}}>Secrecy Branch & NAD</h2>
                        <table id="admission">
					<tr>
                        <td>Name of Branch Officer</td>
                        <td>Mr. Neeraj Kumar Sharma</td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td>Examination Assistant</td>
                    </tr>
                    <tr>
                        <td>Contact No.</td>
                        <td>7983945779</td>
                    </tr>
                    <tr>
                        <td>Email:</td>
                        <td>neeraj.exam@mvn.edu.in</td>
                    </tr>
                    </table>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h2 className='text-center' style={{marginTop:'10vh'}}>Conduct & Evaluation Branch</h2>
                        <table id="admission">
					<tr>
                        <td>Name of Branch Officer</td>
                        <td>Mr. Kumarpa</td>
                    </tr>
                    <tr>
                        <td>Designation</td>
                        <td>Examination Assistant</td>
                    </tr>
                    <tr>
                        <td>Contact Number</td>
                        <td>8799737187</td>
                    </tr>
                    <tr>
                        <td>Email Id </td>
                        <td>Kumar.pal@mvn.edu.in</td>
                    </tr>
                    </table>
                        </div>

                    </div>
                </div>
            </section>
           
        </>
    )
}

export default Main;
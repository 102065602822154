import React from "react";
import { Grid } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import FacultyCard from "../../muiCommonComponent/facultyCards";

const FacultyList=()=>{
    const facultyList=[
        {img:'/assets/img/SBMC/np_singh.png',name:'N.P. Singh',position:'Pro-Vice Chancellor',email:'pvc@mvn.edu.in & dr.npsingh@mcn.edu.in ',gs: "https://scholar.google.com/citations?user=cUgmsSMAAAAJ&hl=en",
        linkedin: "https://www.linkedin.com/in/netra-pal-singh-a6a6253/",
        orcid: "https://orcid.org/0000-0002-3006-9522",
        rg: "https://www.researchgate.net/profile/Netra-Singh ",
        website: "#",
        aoi: "IT Management/ Machine Learning/AI ",
        education: " PhD (Statistics)",
        re: "42",
        te: "42",
        iece: "N/A",
        articles:
          "<li>1. Singh, N. P. (2007). Consumer Perception about fast food in India, (with Anita Goyal). British Food Journal, Vol.109 (2): 182-195 (Citations -462: IF 4.09).</li><li>2. Singh, N.P. (2007). Online Frauds in banks with Phishing, The Journal of Internet and Banking Commerce, 12 (2), 1-27.(Citations-66) </li><li>3. Dahiya, Shashi; S. S. Handa, and N P Singh (2017). A feature selection enabled hybrid bagging algorithm for credit risk evaluation, Expert Systems, 34 (6): 1-11 (Citations-58- IF – 2.812) </li>          "
          ,
        books:
          "N/A",
        chapters:
          "1. Gupta, Nakul and Singh N.P. (2019). When Artificial Intelligence Meets Augmented Reality Management and Business Education in the Time of Artificial Intelligence, pages 195–210. Copyright © 2019 by Information Age Publishing (Book Edited by Dr. Agata and Dr. Wolfgang).",
        patents:
          "N/A",
        membership:
          "<li>1. Life Member of Indian Science Congress Association</li><li>2. Life Member of Indian Society of Agricultural Statistics</li><li>3. Member of AIMA, IEEE</li>",
        aa1: "1. University Scholarship by Haryana Agricultural University during Ph.D. 1977.        ",
        aa2: "2. Council of Scientific & Industrial Research (CSIR): Junior Research Fellowship 1978- 1981        ",
        aa3: "3. Young Scientist Award by Indian Scoiety of Agricultural Research 1989",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SBMC/manchanda.jpg',name:'Dr. S.C. Manchanda',position:'Professor(Management)',email:' prof.sc.manchanda@gmail.com',gs: "#",
        linkedin: "#",
        orcid: "#",
        rg: "#",
        website: "#",
        aoi: "Quantitative Techniques, Finance, HR",
        education:"PhD (Management)",
        re: "3",
        te: "17",
        iece: "35",
        articles:
          "N/A",
        books:
          "<li>1. Economics for Engineers</li><li>2. Security Analysis and Portfolio Management</li><li>3. Operation Research and Business Statistics – Under Publishing </li>",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SBMC/vikram_manchanda.png',name:'Dr. Vikram Manchanda',position:'Associate Professor',email:'vikram.manchanda@mvn.edu.in', gs: "#",
        linkedin: "https://www.linkedin.com/in/vikm ",
        orcid: "https://orcid.org/0009-0002-1391-9588   ",
        rg: "https://www.researchgate.net/profile/Vikram-Manchanda-2",
        website: "#",
        aoi: " Marketing, Healthtech, FinTech, Strategy ",
        education: "B.E. (E&C), MBA, Ph.D.",
        re: "8",
        te: "4.5",
        iece: "23",
        articles:
          "1. Information Technology in Healthcare in India: A Bibliometric Analysis.",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "1. Best Research Award in MBA.",
        aa2: "2. Topper in Strategic Management in MBA.",
        aa3: "3. Distinction holder in Ph.D. Course work.",
        aa4: "4. Trained in “Six Thinking Hats Technique” by Dr. Edward de Bono.",
        aa5: "5. Consultant to various startups in the area of Healthcare and Martech.  ",
        aa6: "",},
        {img:'/assets/img/SBMC/saraswati.png',name:'Dr. Saraswati',position:'Assistant Professor',email:'saraswati.sbmc@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_articles&hl=en&imq=Dr.+Saraswati+.",
        linkedin: "https://www.linkedin.com/in/saraswati-7800782a6/",
        orcid: "https://orcid.org/0000-0003-3729-4519",
        rg: "https://www.researchgate.net/profile/Saraswati-Tewatia",
        website: "#",
        aoi: "Finance, HRM",
        education: "PhD (Financial management)",
        re: "7",
        te: "2.5",
        iece: "N/A",
        articles:
          "<li>1. Saraswati and Netra Pal Singh, “A Study of E-Banking Services in Public Sector Banks in India,” KINERJA Volume 28, No. 1, 2024 Page. 1-22, doi: 10.14569/IJACSA.2022.0130115.</li><li>2. Saraswati, “Use of Plastic Money as an alternative to paper money” Wesleyan Journal of Research, 2020; 13(3): 110-118.</li><li>3. Chadurvedi, M., Pathak D. V., Sanehi, R., Dagar, H., Singh, S., and Saraswati, “Chrysanthemum” (Presented in National Conference in MVN University, ISBN: 978-93-92953-67-5</li>          ",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SBMC/rahul_mongia.png',name:'Rahul Mongia',position:'Assistant Professor',email:'rahul.mongia@mvn.edu.in', gs: "https://shorturl.at/vPS06",
        linkedin: "https://www.linkedin.com/feed/ ",
        orcid: "https://orcid.org/0000-0003-0186-4241",
        rg: "https://www.researchgate.net/profile/Rahul-Mongia",
        website: "#",
        aoi: "Finance, Direct Tax",
        education: " B.Com., MBA (Finance), Pursuing CA final & Ph.D. (Management)",
        re: "3.5",
        te: "5",
        iece: "5",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "1. Distinction in Advance Accounting in Both group of CA Intermediate.",
        aa2: "2. Distinction Holder in MBA program.",
        aa3: "3. Distinction holder in Ph.D. Course work.",
        aa4: "4. Completed more than 12 short term courses from University of London.",
        aa5: "5. Completed 3 years practical training from reputed CA firm. (East Delhi)",
        aa6: "6. Certified IT trainer from ICAI. ",
        aa7:"7. Been the Track Chairperson of Finance & Accounts session in an international conference on “Campus to Corporate: Industry 4.0",
        aa8:"8. Worked as Team Member in Various Audits of PSUs in India.  ",
        aa9:"9. Awarded as Best Employee in A.N. Khurana and Co. ",},
        {img:'/assets/img/SBMC/ruby_mittal.png',name:'Ruby Mittal',position:'Assistant Professor',email:'ruby.mittal@mvn.edu.in, mittalruby888@gmail.com ', gs: "1. https://scholar.google.com/citations?view_op=new_profile&hl=en&imq=Ruby+Mittal ",
        linkedin: "www.linkedin.com/in/ruby-mittal-16a612241",
        orcid: "https://orcid.org/my-orcid?orcid=0009-0007-7881-3692",
        rg: "www.researchgate.net/profile/Ruby-Mittal  ",
        website: "#",
        aoi: " Finance, Human Resource, Entrepreneurship ",
        education: "PhD (Finance)",
        re: "7",
        te: "10",
        iece: "0.5",
        articles:
          "<li>1. Mittal, R. and Kumar, Sadana, S. (2020). A Study on Working Capital Management Efficiency of TCS (India’s Top 1 Company), Wesleyan Journal of Research.</li><li>2. Ruby Mittal, Rajnish Kumar and Netra Pal Singh, “Evaluating the impact of Digitalization on Agricultural Practices: A Review” by Plant Archives (An International Journal).</li><li>3. Ruby Mittal and N.P Singh “ Bankruptcy prediction of software companies using Altman z –Score” Review of Finance and Banking.</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "Qualified H –TET PGT EXAM 2016",
        aa2: "University Positions in B.B.A. 1st sem (10th rank) & in 3rd sem (14th rank) in M.D.U. ",
        aa3: "In M.B.A. 1st SEM (11th rank) In M.D.U.",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SBMC/aradhana_sorout.png',name:'Aradhana Sorout',position:'Assistant Professor',email:'Aradhana.sorout@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_profile&hl=en",
        linkedin: "# ",
        orcid: "https://orcid.org/0000-0003-2022-6392",
        rg: "https://www.researchgate.net/profile/Aradhana-Sorout ",
        website: "#",
        aoi: "Finance",
        education: " B.com(H), M.com, UGC NET, PhD (Pursuing)",
        re: "2",
        te: "3",
        iece: "N/A",
        articles:
          "1. Singh, N. P., & Sorout, A. (2022). An Exploratory Analysis of Cost of Covid-19 Vaccines in India. Industrija, 50(3/4), 7-35.",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "1. Been the Track Chairperson of Finance & Accounts session in an international conference on \“Campus to Corporate: Industry 4.0.\"",
        aa2: "2. Conducted a workshop on Regularization: Lasso and Ridge Regression, 2022",
        aa3: "3. National Eligibility Test (N.E.T.) in Management by Union Grant Commission (UGC) India, 2019.",
        aa4: "4. Cleared Combined Defence Services Examination (CDS) twice in 2018 & 2019.",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SBMC/anuj_tiwari.png',name:'Anju Tiwari',position:'Assistant Professor',email:'anju.tiwari@mvn.edu.in', gs: "#",
        linkedin: "www.linkedin.com/in/anju-tiwari-98091b227",
        orcid: "https://orcid.org/0009-0008-9000-8108",
        rg: "www.researchgate.net/profile/Anju-Tiwari",
        website: "#",
        aoi: "Marketing",
        education: "PhD - Business Administration  ( Pursuing)",
        re: "1.5",
        te: "1.6",
        iece: "N/A",
        articles:
          "N/A",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "1. National Eligibility Test (N.E.T.) by Union Grant Commission (UGC) India, Dec-2022 with a percentile of 99.645.",
        aa2: "2. National Eligibility Test (N.E.T.), by Union Grant Commission (UGC) India, june-2023 with a percentile of 99.467",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SBMC/bharti_chaudhary.png',name:'Bharti Chaudhary',position:'Lecturer',email:'bharti.sbmc@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=new_profile&hl=en ",
        linkedin: "https://www.linkedin.com/in/bharti-chaudhary-tewatia-574182268",
        orcid: "https://orcid.org/0009-0002-4193-4646",
        rg: "https://www.researchgate.net/profile/Bharti-Chaudhary-6 ",
        website: "#",
        aoi: "Management ",
        education: "M.COM and PhD, Management (Pursuing)",
        re: "1",
        te: "2",
        iece: "N/A",
        articles:
          "<li>1. Bharti Chaudhary, N. P. Singh, Ruby Mittal “Review of literature on capital structure: Independent and Dependent Variables”</li><li>2. Bharti Chaudhary, N. P. Singh, Ruby Mittal “Venture capital investment in India: Sectoral Analysis”</li><li>3. Tadiwanashe Gweshe, Bharti Chaudhary, Tanaka Muzira “AI integration in commerce: Bridging the gap between academicians and carrier opportunities”</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "1.Course on Computer Concepts (CCC) from Government of India: National Institute of Electronics & Information Technology. (NIELIT)",
        aa2: "2.Advance Diploma in Computer Applications (ADCA) from IIT",
        aa3: "3.Computer Typing speed 35+",
        aa4: "",
        aa5: "",
        aa6: "",},
        {img:'/assets/img/SBMC/anjali_gupta.png',name:'Anjali Gupta',position:'Lecturer',email:'anjaligupta.sbmc@mvn.edu.in', gs: "https://scholar.google.com/citations?view_op=list_works&hl=en&authuser=1&user=o-409_sAAAAJ",
        linkedin: "www.linkedin.com/in/anjali-gupta-89b982303",
        orcid: "https://orcid.org/0009-0002-8027-8362",
        rg: "https://www.researchgate.net/profile/Anjali-Gupta-93",
        website: "#",
        aoi: " Finance",
        education: "B.COM, MBA(Finance), PHD (Finance) Pursuing",
        re: "1",
        te: "4.5",
        iece: "N/A",
        articles:
          "<li>1. A. Gupta, S. Twetia and N.P Singh,  “An Empirical Study on Cost of Capital, Optimal Capital Structure, and Value of Firm of Selected Indian Companies”</li><li>2. A. Gupta, Nasir, Manowar, Anjali, K. Jha, H. Narula, Saurabh, Jaisika, “Semi-rural entrepreneurship in palwal district: challenge and problems”</li>",
        books:
          "N/A",
        chapters:
          "N/A",
        patents:
          "N/A",
        membership:
          "N/A",
        aa1: "N/A",
        aa2: "",
        aa3: "",
        aa4: "",
        aa5: "",
        aa6: "",},
    ]
return(
    <Grid container spacing={2} sx={{margin:'10px 0px'}}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <MainTitle title='School of Business Management & Commerce Faculties'/>
        </Grid>
        {facultyList?.map((fac)=>(
        <Grid item xs={12} sm={12} md={4} lg={4}>
            <FacultyCard faculty={fac} />
        </Grid>
        ))}
    </Grid>
)
}
export default FacultyList;
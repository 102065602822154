import React from "react";
import { Container } from "@mui/material";
import PageBanner from "../muiCommonComponent/pageBanner";
import Home from "./home";
import NewMain from "../headerone/newMain";
import WidgetsMenu from "../muiCommonComponent/menuWidgets";
import Studentshub from "./studentshub";
import Initiative from "./initiative";
import Alumni from "./alumni";
import Placement from "./training&placement";
import Research from "./r&d";
import Lab from "./lab";
import { useParams } from "react-router-dom";
import BBABA from './SBMC/bbaba'
import BBADM from './SBMC/bbadm'
import Bcom from './SBMC/bcom'
import Bcom_hons from './SBMC/bcom_hons'
import MBA from './SBMC/mba'
import MBAHM from './SBMC/mbahm'
import MBAPM from './SBMC/mbapm'
import BBA from './SBMC/bba'
import FacultyList from "./faculties/facultyList";
import FacultyDetail from "../facultydetail/main";
const SBMCHome=()=>{
    const { screenname } = useParams();
    console.log('screenname::::::::::::',screenname)
    const screensName=()=>{
        let screen=''
        if(screenname=='home'){
            screen=Home()
        }
       else if(screenname=='student-hub'){
            screen=Studentshub()
        }else if(screenname=='intiative'){
            screen=Initiative()
        }
        else if(screenname=="our-alumi"){
            screen=Alumni()
        }
        else if(screenname=='t-p'){
            screen=Placement()
        }else if(screenname=='r-d'){
            screen=Research()
        }
        else if(screenname=="laboratory"){
            screen=Lab()
        }
        else if(screenname=='bcom'){
            //screen=Bcom()
            screen=Bcom_hons()
        }else if(screenname=='bcom_hons'){
            screen=Bcom_hons()
        }
        else if(screenname=='bba-general'){
            screen=BBA()
        }
        else if(screenname=='bba-ba'){
            screen=BBABA()
        }else if(screenname=='bba-dm'){
            screen=BBADM()
        }
        else if(screenname=="bba-ba"){
            screen=BBA()
        }
        else if(screenname=='mba-marketing'){
            screen=MBA()
        }
        else if(screenname=="mba-finance"){
            screen=MBA()
        }
        else if(screenname=="mba-hrc"){
            screen=MBA()
        }
        else if(screenname=="mba-ba"){
            screen=MBA()
        }
        else if(screenname=="mba-pm"){
            screen=MBAPM()
        }
        else if(screenname=="mba-hs"){
            screen=MBAHM()
        }
        else if(screenname=="faculties"){
            screen=FacultyList()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
       
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/school-of-business-management-and-commerce/home', subHeader: [] },
        { mainHeader: 'About SBMC', path: '/school-of-business-management-and-commerce/home', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [ 
            /*{  title:'B.Com',path:'/school-of-business-management-and-commerce/bcom' }, */ 
            {title:'B.Com.(Hons.)',path:'/school-of-business-management-and-commerce/bcom_hons'},
            {title:'BBA- General',path:'/school-of-business-management-and-commerce/bba-general'},
            {title:'BBA- Digital Marketing',path:'/school-of-business-management-and-commerce/bba-dm'},
            {title:'BBA- Business Analytics',path:'/school-of-business-management-and-commerce/bba-ba'},
            {title:'MBA- Marketing',path:'/school-of-business-management-and-commerce/mba-marketing'},
            {title:'MBA- Finance',path:'/school-of-business-management-and-commerce/mba-finance'},
            {title:'MBA- Human Resource Management',path:'/school-of-business-management-and-commerce/mba-hrc'},
            {title:'MBA- Business Analytics',path:'/school-of-business-management-and-commerce/mba-ba'},
            {title:'MBA- Pharm Management',path:'/school-of-business-management-and-commerce/mba-pm'},
            {title:'MBA- Healthcare Management',path:'/school-of-business-management-and-commerce/mba-hs'},
        ] },
        { mainHeader: 'Laboratories ', path: '/school-of-business-management-and-commerce/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/school-of-business-management-and-commerce/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/school-of-business-management-and-commerce/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/school-of-business-management-and-commerce/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/school-of-business-management-and-commerce/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/school-of-business-management-and-commerce/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/school-of-business-management-and-commerce/intiative', subHeader: [] },
    ]
return(<>
<NewMain/>
<WidgetsMenu headerMenus={headerMenus}/>
<PageBanner bannerUrl={"/assets/img/SBMC/sbmc.jpg"}/>
<Container>
    {screensName()}

</Container>
</>)
}
export default SBMCHome;

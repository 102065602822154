import React from "react";
import { Typography, Grid, List, ListItem, ListItemText } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";

function Home() {
  const key_highlight = [
"Curriculum aligning with AICTE standards and offering industry-relevant elective courses.",
"State of arts laboratories with full equipment",
"Flexible choice-based credit system (CBCS) enabling students to pursue interdisciplinary minors",
"Course curriculum designed with the input of Industry persons",
"Outstanding placements records",
"Project based teaching and learning",
"Curriculum crafted by industry professionals and distinguished academics",
"Partnerships providing skill-enhancement training to boost job readiness",
"Access to e-learning platforms like MOOC, NPTEL, Coursera etc.",
"Tailored training programs to prepare students for placement opportunities",
"Publication of over 100 research papers in both national and international journals and conferences",
"Published filled and granted more than 15 patents by the faculties ",
"Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student start-ups",
"Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships",
"Courses on Design Thinking and Innovation, focusing on originality, start up viability, and the documentation of outcomes such as research papers, patents, products, start-ups, or copyrights",
"Employment opportunities in various sectors like Automobile, Chemical, Aerospace and Defence, construction and Space industry etc. So its scope is equally in Government sector as well as in private sectors. ",
"Prospects for advanced studies or research in both domestic and international institutions",
"Availability of scholarships covering up to 100% of expenses.",
"Prospects for advanced studies or research in both domestic and international institutions.",

];
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Department Of ECE" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
          Welcome to the Electronics and Communication Engineering (ECE) Department at MVN University!
          <br/><br/>
The Department of Electronics and Communication Engineering (ECE), over the course of time has grown its expertise and competence in the Electronics and Communication Engineering (ECE) and allied research areas. The department is continually taking action to train the our students for the future by fusing theoretical and applied knowledge and putting an emphasis on critical thinking and learning. It aims to equip students with the skills they need to meet society's emerging challenges. The Department offers B.Tech in Electronics and Communication Engineering .
<br/><br/>
The vision of the Department of Electronics and Communication Engineering is to strive for excellence in education and targeted research themes in emerging areas, with an aim to become one of the leaders in the education field for Electronics and Communication Engineering (ECE) engineering students. To do this, the department works to produce highly qualified graduates through demanding curriculum and cutting-edge research projects. The course contents are periodically updated for introducing new scientific and technological developments. The School maintains active research groups for carrying out collaborative and interdisciplinary research. In addition to the regular academic work, the students of the department are encouraged to participate in co-curricular activities.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <MainTitle title="Key Highlights" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        {key_highlight.map((item, index) => (
          <List sx={{ margin: 0, padding: 0 }}>
            <ListItem disableGutters key={index}>
              <ListItemText
                primary={
                  <Typography component="span" variant="body1">
                    &#8226; {item}
                  </Typography>
                }
              />
            </ListItem>
          </List>
        ))}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Vision" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
To take an anchoring position as one of the prime departments of the university in molding Electronics and Communication Engineering professionals who are ready to face the demands of industrial development and societal commitment.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Mission" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
To prepare students of the Department of Electronics and Communication Engineering for a brilliant career/entrepreneurship along with the development of knowledge, skills, attitude and teamwork ideal for a professional through the designed programme. The faculties of the Department continually seek to enhance their knowledge to competently lead and empower students.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Scope" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
The scope of Electronics and Communication Engineering (ECE) is vast and encompasses various domains, offering numerous opportunities for career growth and innovation. Here are some key aspects of the scope of ECE:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} className="ps-lg-5 ps-2">
      <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
         <ol>
          <li> <b>Telecommunication Industry:</b>
          ECE graduates play crucial roles in designing, implementing, and maintaining telecommunications systems, including mobile networks, satellite communications, fiber optics, and wireless communication technologies like 5G and beyond.</li>
          <li> <b>Information Technology (IT) Sector:</b>
          ECE professionals contribute to the development of hardware and software solutions in the IT industry. This includes designing and optimizing computer systems, microprocessors, embedded systems, and networking devices.</li>
          <li> <b>Consumer Electronics: </b>
          With the rapid evolution of consumer electronics, ECE engineers are involved in designing and improving devices such as smartphones, tablets, wearable gadgets, home appliances, entertainment systems, and gaming consoles.</li>
          <li> <b>Automation and Robotics</b>
          ECE expertise is vital in the development of automation and robotics systems used in manufacturing, healthcare, agriculture, transportation, and various other sectors. This involves the integration of sensors, actuators, control systems, and artificial intelligence (AI) algorithms.</li>
          <li> <b>Signal Processing and Image/Video Processing:</b>
          ECE professionals work on signal processing techniques used in audio, video, and image processing applications. This includes compression, enhancement, recognition, and analysis of signals and multimedia content.</li>
          <li> <b>Power and Energy Systems:</b>
          ECE engineers contribute to the design and optimization of power generation, transmission, and distribution systems. They also work on renewable energy technologies, smart grid systems, and energy-efficient devices.</li>
          <li> <b>Biomedical Engineering</b>
          ECE skills are applied in developing medical devices, diagnostic equipment, and healthcare systems. This includes technologies such as medical imaging, patient monitoring, prosthetics, and telemedicine.</li>
          <li> <b>Research and Development (R&D):</b>
          ECE graduates have opportunities in R&D institutions, laboratories, and academia, where they engage in cutting-edge research to advance technology in various fields like communication, electronics, nanotechnology, and photonics.</li>
          <li> <b>Entrepreneurship:</b>
          ECE professionals often venture into entrepreneurship, founding startups and innovative companies focused on developing new technologies, products, and solutions to address specific market needs or societal challenges.</li>
          <li> <b>Global Opportunities:</b>
          The scope of ECE extends globally, with opportunities to work in multinational corporations, research institutes, and governmental organizations worldwide, contributing to international collaborations and projects.</li>
         </ol>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
In essence, the scope of Electronics and Communication Engineering is dynamic and continually evolving, offering a wide range of career paths and opportunities for individuals with a passion for innovation and technology.
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Home;

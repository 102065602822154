import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function LLB() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="LLB" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>Programme Overview</h3>
                                </div>
                                <p>
                                Since its year of inception in 2015, the MVN University School of Law has offering a three-year Bachelor of Laws (LL.B.) degree. The curriculum is developed in accordance with the latest Bar Council of India rules and University Grants Commission guidelines, and it strives to foster a culture of research and academic achievement among students.
                                </p>

                                <p>
                                The programme is intended to assist in the dissemination of legal information among professionals from other disciplines in this society. Its goals are to provide legal education to students from diverse backgrounds and to prepare them to undertake various responsibilities of professional lawyers other than the traditional role of litigation. Students will obtain information and build advocacy skills that will assist them in attaining their goals and objectives while also serving society. This programme also provides students the ability to argue in court and defend their case.
                                </p>
                                <p>
                                Students will also acquire and use legal knowledge to complicated socio-legal situations after completing their degree. This course also qualifies students to work as legal practitioners in courts, industries, and businesses by providing them with professional abilities such as arguing, pleading, drafting, and conveyancing. The student will also acquire a self-reflective attitude. This course trains students to engage in autonomous and lifelong learning in the wide framework of changing legal settings, as well as to offer a platform for self-employment. It offers students with a thorough understanding of constitutional legislation and sociological developments, as well as clinical ability to detect students’ motivation in learning.
                                </p>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LLB
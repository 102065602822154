import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCSEHeader from "./header";

const CSEBTechInitiative=()=>{
return (
<Grid container spacing={2}>
{/* <SOETCSEHeader/>
        <BreadcrumbsBanner bannerUrl={"https://uploads.sarvgyan.com/2014/06/mechanical-engineering-image.jpg"}/>  */}
<Grid item xs={12}>
    <MainTitle title="B. Tech Computer Science Engineering" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Initiative" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    In the Department of Computer Science and Engineering, our initiatives reflect our commitment to innovation, social responsibility, and the advancement of technology for the greater good. This section showcases the diverse range of projects and programs we have launched to address real-world challenges, foster inclusivity in technology, and promote sustainable practices within and beyond our campus. From community outreach programs aimed at inspiring the next generation of tech enthusiasts to environmental sustainability projects leveraging technology, our initiatives are designed to make a tangible impact. We also focus on diversity and inclusion, ensuring that technology education is accessible to all, and nurturing a culture where everyone can thrive. Through collaborations with industry partners, NGOs, and governmental bodies, we extend our reach, applying our knowledge and skills to benefit society. Our commitment to these initiatives not only enriches the educational experience for our students but also positions our department as a catalyst for positive change in the tech world and the community at large.

    </Typography>
    </Grid>
    </Grid>

)
}
export default CSEBTechInitiative;
import React from 'react';
import { Typography, Grid, Container } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import BreadcrumbsBanner from './breadcrumbs';
import SOETHeader from './header';
import WidgetsMenu from '../muiCommonComponent/menuWidgets';
import NewMain from "../headerone/newMain";
function SOETInsights() {
  const headerMenus = [
    { mainHeader: 'Home', path: '/school-of-engineering-technology', subHeader: [] },
    { mainHeader: 'SOET Insights', path: '', subHeader: [
        { title: 'Insights', path: '/soet/insights', },
        { title: 'Vision & Mission', path: '/soet/vision-mission', },
        { title: 'Knowledge Partners', path: '/soet/knowledge-partners', },
        { title: 'Pedagogical Advances – MOOCs', path: '/soet/mcoos', },
    ] },
    { mainHeader: 'SOET Departments', path: '', subHeader: [
        { title: 'Computer Science & Engineering', path: '/soet-cse/home', },
        { title: 'Mechanical Engineering', path: '/soet-me/home' },
        { title: 'Civil Engineering', path: '/soet-civil/home' },
        { title: 'Electronic & Communication Engineering', path: '/soet-ece/home' },
    ] },
    { mainHeader: 'MVN Times', path: '#', subHeader: [] },
    { mainHeader: 'Scholarly Work ', path: '#', subHeader: [] },
  ];
  return (<>
    <NewMain/>
    {/* <Navbar/> */}
    <WidgetsMenu headerMenus={headerMenus}/>
         <BreadcrumbsBanner bannerUrl={"https://www.shutterstock.com/image-photo/business-technology-internet-network-concept-260nw-1023559927.jpg"}/>
    <Container>
    <Grid container spacing={3}>
         <Grid item xs={12} sm={12} md={12} lg={12} >
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} >
        <MainTitle title="SOET Insights" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
            The School of Engineering and Technology at MVN University stands as a beacon of academic prowess, offering a comprehensive and multidisciplinary approach of engineering education. Rooted in a commitment to excellence, the school emphasizes a curriculum that blends theoretical knowledge with practical applications, preparing students for the complexities of the modern technological landscape. Under the guidance of distinguished faculty member, many of whom are actively engaged in cutting-edge research, students are exposed to the latest advancements in their respective fields. The collaborative and innovative spirit at MVN University permeates the school, creating an environment where curiosity is celebrated, and students are encouraged to think critically, fostering the development of well-rounded professionals.
        </Typography>
       </Grid>
       <Grid item xs={12} sm={12} md={12}>
        <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
        As an integral part of MVN University, the School of Engineering and Technology benefits from the institution's robust infrastructure and global collaborations. State-of-the-art laboratories, well-equipped classrooms, and modern research facilities provide students with the tools they need to delve into practical applications of their theoretical learning. The University's emphasis on research and innovation further elevates the academic experience, with opportunities for students to engage in impactful projects, publish research papers, and participate in national and international conferences. This dynamic approach ensures that graduates not only possess a strong academic foundation but are also well-prepared to meet the challenges of an ever-evolving technological landscape.
        MVN University's commitment to holistic education extends beyond the classroom, emphasizing the importance of character development, ethical considerations, and social responsibility. The school actively promotes extracurricular activities, leadership programs, and industry internships that contribute to the overall growth and development of students. With a focus on cultivating well-rounded individuals, the school instils in its graduates a sense of purpose and commitment for making positive contributions to society. In essence, the School of Engineering and Technology at MVN University is more than an academic institution; it is a dynamic ecosystem that fosters intellectual curiosity, practical skills, and a sense of responsibility. 
        </Typography>
      </Grid>
      </Grid>
      </Container>
      </> )
}
export default SOETInsights;

import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function Bcom() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.Com (Hons.)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                Commerce has always been regarded as one of the most prestigious academic streams to pursue with excellent career opportunities. B.Com. (Hons.) one of the few traditional courses under its under-graduate stream is the most popular and sought after course amongst the student community and has umpteen takers due to its application-oriented approach. It is a 3 year, semester based programme with two semesters per year of six months duration each. Pursuing B.Com.(Hons.) after 10+2 opens a plethora of opportunities for the smart digital generation entering the workforce in both the financial and non-financial sectors.
                                </p>

                                <p>
                                    B.Com.(Hons.) program at MVN University is a multi-disciplinary program consisting of subjects from areas such as – Accounting, Finance, Management, Economics, Mathematics and Statistics, Computer Applications, Taxation, Business Research Methods, Entrepreneurship, Business Law and Communication.
                                </p>

                                <p>
                                    The program is complemented and strengthened by dedicated computerized accounting, statistics and taxation labs coupled with numerous co-curricular activities. The curriculum is designed keeping in view current market trends, adequately grooming the students in the skill sets that will help them deliver in an ever-changing competitive global business environment with confidence.
                                </p>
                                <p>
                                    The department constantly endeavours to contribute the best towards academic excellence and is committed towards making student experience the finest it can be. We strongly believe in endorsing and nourishing an atmosphere where ideas are nurtured and shared, students are enthused and teaching is dynamic. We genuinely care for our students, our assets and future glory and seek their valuable suggestions and inputs all along. The students we serve are our greatest priority and we’re committed to dedicating all our resources and channelizing all our energies in an effort to supporting their success. We also foster parental involvement and support, a significant motivational factor to help address student issues in a positive and constructive way. Besides imparting subject knowledge and conducting myriad co-curricular activities, the department facilitates building up the essential skills needed for success in the business world and believes in grooming its students into responsible, ethical and contributory members of the society, citizens of the nation and most of all good human beings. I also take this opportunity to convey my warmest greetings on behalf of the Department of Commerce, MVN University to all the potential aspirants of the commerce programs of our University. We appreciate your interest in our department and its programs and invite you to be a part of our journey towards quality education in commerce. We wish all our students an extremely memorable, worthwhile and enriching experience at MVN with a brilliant future ahead.
                                </p>


                                <div className="about-title second-title pb-25">                                    
                                    <h3>Why pursue a Commerce Program - B.Com (Hons.) from MVN?</h3>
                                </div>

                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We Are –  A Team of dedicated and qualified faculty
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We Balance – Classroom teaching with practical exposure via Computer oriented Accountancy, Statistics and Taxation labs
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We Complement  – Curricular teaching  by adequate focus on co /extra-curricular activities
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We Initiate –  Early internship and industry exposure 
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We Organize – Domain specific industry expert sessions on contemporary issues in commerce and regular industrial visits
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Hone –  Communications and professional skills to enable student preparedness for higher education, industry absorption and self-sufficiency for self employment 
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Groom  – Young minds and hearts into responsible, ethical and contributory members of society and nurture values of humanity 
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Impart  – Au courant and innovative knowledge  in Commerce and allied subjects
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Foster – Constructive, ingenious, gratifying and unique  self and inter-personal learning experience
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Inculcate – Analytical and research skills via project based activities
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Keep Informed  –  News Hour Sessions on Current Business Affairs
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Provide – Determined pre-placement support via regular mock interviews, group discussions, personality development and CV design initiatives
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Conduct – Specialized and need-based personal and career counselling sessions
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        We Value – Our relationship through regular parent educator meetings and encourage parental involvement and support; provide regular feedback on student performance
                                        </div>    
                                    </li>
                                </ul>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>At MVN University, Department of Commerce:</h3>
                                </div>

                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We seek regular inputs and suggestions from industry experts in the realm of commerce to stay abreast with the latest industry requirements and trends to ensure the course structure remains updated.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We ensure our commerce students are in tune with the contemporary issues in their respective subjects through regular sessions/workshops from experts in both academia and industry.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We ensure our students get to develop communication and professional skills through both classroom lectures and regular workshops from outside experts on communication and personality development.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We ensure that a student’s life is a balanced blend of both classroom lectures and practical exposure. Industrial visits are a regular feature for our commerce students to this end. 
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            We ensure that students are groomed for a career in commerce and not just a degree.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                            Our faculty is extremely supportive and ensures all round development of the students through its endeavour towards counselling and mentoring exercises/sessions.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Students can avail the library facility which has a rich collection of books on various subjects related to commerce and allied streams.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Students get to hone their skills and enhance their subject knowledge as well as keep themselves updated through regular participation in Commerce News Update and Commerce Terminology activities.
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Students get to participate, showcase and nurture their talents by involving themselves in extracurricular activities.
                                        </div>    
                                    </li>
                                    

                                </ul>

                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Educational and Career Opportunities after B.Com (Hons.)</h3>
                                </div>

                                <h5>Education Ahead</h5>
                               
                                <p>
                                Students can pursue higher studies in the some of the following streams after completing their B.Com. (Hons.) program:
                                </p>

                                <ol>
                                    <li>Commerce : M.Com and M.Com in various areas of sliecialization</li>
                                    <li>Economics : MA Economics</li>
                                    <li>Management : MBA in different areas of sliecialization as Marketing, Human Resource, Finance and International Business etc.</li>
                                    <li>Statistics : M.Sc in Statistics</li>
                                    <li>Education : B.Ed.</li>
                                    <li>Comliuter Alililications : MCA</li>
                                </ol>

                                <h5>Specialized Courses</h5>
                                <ol>
                                    <li>Chartered Accountancy (CA)</li>
                                    <li>Comliany Secretary (CS)</li>
                                    <li>Cost and Works Accountants (CWA)</li>
                                    <li>Chartered Financial Analyst (CFA)</li>
                                </ol>


                                <h5>Other Areas</h5>

                                <ol>
                                    <li>Investment Banking</li>
                                    <li>Securities Market</li>
                                    <li>Forensic Accounting and Fraud Detection</li>
                                    <li>Mutual Funds</li>
                                    <li>Actuarial Science</li>
                                    <li>Insurance and Risk Management</li>
                                </ol>

                                <h5>Civil Services Examinations</h5>
                                <p>Indian Administrative Service (IAS), Indian Police Services (IPS) etc.</p>

                                <h5>Career Ahead</h5>
                                <p>Students can pursue a career in the following areas after completing their B.Com (Hons.) among others:</p>

                                <ol>
                                    <li>Banking Sector</li>
                                    <li>Economics</li>
                                    <li>Investments</li>
                                    <li>Insurance</li>
                                    <li>Auditing</li>
                                    <li>Budget Analysts</li>
                                    <li>Book Keepers</li>
                                    <li>Financial Accounting</li>
                                    <li>Cost Accounting</li>
                                    <li>Foreign Trade</li>
                                </ol>

                                

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bcom
import React, { useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import AcademicButton from '../academicButton';

const faqItems = [
    {
        id: 'headingThree',
        question: '01 What career opportunities are available to graduates of the Computer Science Engineering program ?',
        answer: 'A degree in Computer Science Engineering opens up a wide range of career paths. Graduates often pursue roles such as software developer, computer programmer, systems analyst, AI/ML engineer, web developer, cybersecurity analyst, and more. The tech industry continues to expand, and qualified computer engineers are in demand across various sectors, including finance, healthcare, entertainment, and telecommunications.',
    },
    {
        id: 'headingOne',
        question: '02 How does the program stay up-to-date with the latest technology trends and advancements in the field?',
        answer: 'The Computer Science Engineering program is regularly updated to reflect the rapidly evolving technology landscape. The curriculum is often designed in consultation with industry experts to ensure its relevance and alignment with current industry practices. Moreover, students may have opportunities for internships, co-op programs, or industry projects, which expose them to real-world challenges and the latest tools being used in the field. This way, students are well-prepared to enter the workforce with up-to-date knowledge and skills.',
    },
    {
        id: 'headingTwo',
        question: '03 What kind of career support is available for graduates of the Computer Science Engineering program?',
        answer: 'Our university is dedicated to supporting the career development of our graduates. The Career Services Center works in collaboration with the Computer Science Engineering department to provide various resources such as resume building, interview preparation, and internship opportunities. We frequently host career fairs, bringing in top-tier technology companies to connect with our talented students. Our alumni have gone on to work at industry giants like TechCo, SoftLink, and DigitalVista, showcasing the vast employment opportunities available upon graduation. Furthermore, our strong alumni network, consisting of numerous successful professionals in the field of computer science engineering, often provides mentorship and guidance to current students, opening doors to exciting career pathways.',
    },
];

function First() {
    const navigate = useNavigate()
    const handleNavigate = (renderPage,buttonName) => {
        const render_path=renderPage?renderPage:'/under-construction'
        navigate(render_path, { state: { title: buttonName, path: '/computer-science-engineering', pageName: "Computer Science Engineering" } })
    }
    return (
        <>
            <section className="project-detail">
                <div className="container">
                {/* <AcademicButton path='/computer-science-engineering' pageName="Computer Science Engineering" renderPage="cse"/> */}
                    <div className="lower-content">
                        <div className="row">
                        <div className="btn-group col-lg-12 col-md-6 col-sm-6">
            <button className='btn-course' onClick={() => handleNavigate('','Grants')}>Grants</button>
            <button className='btn-course' onClick={() => handleNavigate('/cse-consultancy','Consultancy')}>Consultancy</button>
            <button className='btn-course' onClick={() => handleNavigate('/cse-patent','Patents')}>Patents</button>
            <button className='btn-course' onClick={() => handleNavigate('/cse-publication','Publications')}>Publications</button>
            <button className='btn-course' onClick={() => handleNavigate('','Technology Transfers')}>Technology Transfers</button>
            <button className='btn-course' onClick={() => handleNavigate('','Collaborations')}>Collaborations</button>
            <button className='btn-course' onClick={() => handleNavigate('','PhD Program')}>PhD Program</button>
            <button className='btn-course' onClick={() => handleNavigate('/cse-teams','Team')}>Team</button>
        </div>
                            <div className="text-column col-lg-9 col-md-9 col-sm-12">
                                {/* <h2>Computer Science Engineering</h2> */}
                                <div className="upper-box">
                                    <div className="single-item-carousel owl-carousel owl-theme">
                                        <figure className="industryImg">
                                            <img src="/assets/img/class/class-9.jpg" alt="" />
                                        </figure>
                                    </div>
                                </div>
                                
                                <div className="inner-column">
                                   
                                    <h3>Course Overview</h3>
                                    <p>
                                        The Department of Computer Science and Engineering at MVN University is committed to preparing undergraduates to be outstanding technocrats and responsible citizens. We are able to accomplish this by offering a rich array of basic curriculum based courses supplemented by industry oriented courses beyond curriculum, taught by professors committed to the highest standards of excellence in learning. An innovative and flexible approach to teaching of programming that incorporates cutting-edge technology backed by research based study and offer of contemporary electives strongly positions our students for success in today’s evolving information technology challenges.
                                    </p>
                                    <p>
                                        The dedicated team of faculty in our department encourages students to consider the wide range of professional possibilities open to them and prepares them to meet their chosen objectives. We believe that practical learning through practice is a means of inviting the perfection desired. The sequence of study proceeds from an engagement with engineering fundamentals, along with ethics and human behavior in the First year, towards a focused project based training in the second year along with soft skills training. The third year study is designed to give students mastery of contemporary principles and technologies oriented towards the industry requirements by undertaking job oriented elective programs in their chosen area. The final year is spent consolidating the expertise gained, through team based research and development projects resulting in industry ready engineers.
                                    </p>
                                    <h4>What You Will Learn</h4>
                                    <p>
                                        In the Computer Science Engineering program, you will develop a strong foundation in algorithmic thinking and problem-solving. You will learn how to analyze complex problems, break them down into smaller manageable components, and design efficient algorithms to solve them. This skill is crucial for developing software applications, optimizing performance, and tackling real-world challenges in various domains.
                                    </p>
                                    <p>
                                        The program will provide you with a comprehensive understanding of programming languages, software development methodologies, and version control systems. You will gain hands-on experience in writing code for different platforms and applications, and you'll learn about best practices in software design, testing, and debugging. Through projects and assignments, you will enhance your coding skills and learn how to create reliable, maintainable, and user-friendly software solutions.
                                    </p>
                                    <ul className="pr-ul">
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Cloud & Big Data
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Web Design
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                Mobile App Development
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fal fa-check" />
                                            </div>
                                            <div className="text">
                                                AI & Machine Learning
                                            </div>
                                        </li>
                                    </ul>
                                    {/* <h4>Study Options:</h4> */}
                                    <table className="table table-bordered mb-40">
                                        <thead>
                                            <tr>
                                                <th>Courses Offered</th>
                                                <th>Length</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><Link to="/bTechCSE">B.Tech CSE</Link></td>
                                                <td>4 years full time</td>
                                            </tr>
                                            <tr>
                                                <td><Link to="/bScComputerScience">B.Sc Computer Science</Link></td>
                                                <td>3 years full time</td>
                                            </tr>
                                            <tr>
                                                <td><Link to="/Bca">BCA</Link></td>
                                                <td>3 years full time</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                            </div>
                            <div className="col-lg-3">
                                <aside className="sidebar-widget info-column">
                                    <div className="inner-column3 ">
                                        <h3>About</h3>
                                        <ul className="project-info ">
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-vision-mission"> <strong>Vision & Mission</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-peo-po-pso-cse"><strong>PEO, PO & PSO - CSE </strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-peo-po-pso-bca"> <strong>PEO, PO & PSO - BCA</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2021/09/SOS-B.Tech-2021-25.pdf"><strong>SOS - B.Tech CSE 2021-25 </strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2023/03/SOS-%E2%80%93-BCA-2022-25.pdf"><strong>SOS - BCA 2022-25</strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/05/Events-Computer-Science-and-Engineering.pdf"> <strong>Events</strong> </Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="https://admission.mvn.edu.in/wp-content/uploads/2022/06/Publications.pdf"><strong>Research Contributions </strong></Link>
                                            </li>
                                            <li>
                                                <span className="icon fal fa-globe" />{" "}
                                                <Link to="/computer-science-engineering-media"> <strong>Media</strong> </Link>
                                            </li>

                                            <li>
                                                <div className="slider-btn">
                                                    <Link to="/contact" className="btn ss-btn smoth-scroll">
                                                        Enroll <i className="fal fa-long-arrow-right" />
                                                    </Link>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default First
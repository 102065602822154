import React, { useState } from 'react'
import { Link } from 'react-router-dom'

function First() {

    const Data = [
        
        {img : "https://admission.mvn.edu.in/wp-content/uploads/2021/09/our-recruiters.png", category : "Financial"},        
        
      ]

      const [current, setcurrent] = useState(Data);

      

  return (
    <>
        <section id="work" className="pt-150 pb-105">
            <div className="container">
                <div className="portfolio ">
                <div className="row center fadeInUp  animated" data-animation="fadeInUp" data-delay=".4s" >
                    {current.map( (item, Index)=> ( 
                        <div className="col-md-12 col-sm-12" key={Index}>
                                   <center> <img src={item.img} alt="img" className='industryImg' /></center>
                        </div>
                    ))}
                </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default First
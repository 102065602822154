import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Grid, Typography, Button, Stack, Box } from '@mui/material';
import MainTitle from './mainTitle';
import { useNavigate } from 'react-router-dom';
import HomegalleryCompo from './HomegalleryCompo';

function Homegallery() {
      
    const notificationList=[

         {
            title:'Diwali Milan Samaroh 2024 dated 25 October 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/diwali-milan-samaroh-2024-dated-25-october-2024/thum01.jpg`, 
            link: `/gallery_details/diwali-milan-samaroh-2024-dated-25-october-2024`
        },
 
        {
            title:'Report On International Conference Of Pharmacay on  15 March 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-international-conference-of-pharmacay/sime05.jpg`, 
            link: `/gallery_details/report-on-international-conference-of-pharmacay`
        },

        {
            title:'Report On Educationist’S Lecture In Iternational Conference on  15 March 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-educationists-lecture-in-iternational-conference/eves0501.jpg`, 
            link: `/gallery_details/report-on-educationists-lecture-in-iternational-conference`
        },


        {
            title:'Report In Job Fair Cum Hr Summit on 13 March 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/report-in-job-fair-cum-hr-summit/news05.jpg`, 
            link: `/gallery_details/report-in-job-fair-cum-hr-summit`
        },

        
        {
            title:'Report On Press Confrence For Beginning Of New Courses on 13 March 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-press-confrence-for-beginning-of-new-courses/some09.jpg`, 
            link: `/gallery_details/report-on-press-confrence-for-beginning-of-new-courses`
        },
 
        {
            title:'Report On Celebration Of Yoga Diwas on 11 June, 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-celebration-of-yoga-diwas/gallery42.jpg`, 
            link: `/gallery_details/report-on-celebration-of-yoga-diwas`
        },


        
        {
            title:'Report On Annual Sports Meet on  15 February 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-annual-sports-meet/imes05.jpg`, 
            link: `/gallery_details/report-on-annual-sports-meet`
        },


        {
            title:'Report On 19Th Death Aniversary Of Founder Of Mvnu Sh. Gopal Sharma Ji on  19 December 2023',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023/sime05aa05.jpg`, 
            link: `/gallery_details/report-on-19th-aniversaryof-shri-gopal-sharma-ji-19-dec-2023`
        },


 



        {
            title:'Health and Eye Checkup Camp on 09 Oct 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/health_eye_checkup_camp_09_oct_2024/even01.jpg`, 
            link: `/gallery_details/health-eye-checkup-camp-09-oct-2024`
        },
        { 
            title:'Urja 2k24 Freshers Party dated 27th September 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery32.jpg`, 
            link: `/gallery_details/freshers-welcome-party`
        },
        {
            title:'2nd international conference photography on 14 March 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery01.jpg`, 
            link: `/gallery_details/2nd-international-conference-photography-on-14-march-2024`
        }, 
        {
            title:'8th Convocation Ceremony Photo on 16 March 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery21.jpg`, 
            link:'/gallery_details/8th-convocation-ceremony-photo-on-16-march-2024'},
        {
            title:'Dr.Rajeev K singla Guest lecture West china Hospital Photo on 29 April 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery22.jpg`, 
            link:'/gallery_details/drrajeev-k-singla-guest-lecture-west-china-hospital-photo-on-29-april-2024'},
        {
            title:'Envisage 2k24 Photo 22,23 Feb 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery23.jpg`, 
            link:'/gallery_details/envisage-2k24-photo-2223-feb-2024'},
        {
            title:'Farewell Party Celebration Pharmacy Dept on 31 May 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery24.jpg`, 
            link:'/gallery_details/farewell-party-celebration-pharmacy-dept-on-31-may-2024'},
        {
            title:'International Yoga Day Celebration Photo on 10 June 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery25.jpg`, 
            link:'/gallery_details/international-yoga-day-celebration-photo-on-10-june-2024'},
        {
            title:'Mega Job Fair Cum HR Summit on 15 March 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery26.jpg`, 
            link:'/gallery_details/mega-job-fair-cum-hr-summit-on-15-march-2024'},
        {
            title:'News Reporters visit on 12-03-2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery27.jpg`, 
            link:'/gallery_details/news-reporters-visit-on-12-03-2024'},
        {
            title:'Sh. Gopal Sharma Death Anniversary  Photo on 18 Dec 2023',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery28.jpg`, 
            link:'/gallery_details/sh-gopal-sharma-death-anniversary-photo-on-18-dec-2023'},
        {
            title:'Tug of War Photo 12 Feb 2024',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery29.jpg`, 
            link:'/gallery_details/tug-of-war-photo-12-feb-2024'},
        {
            title:'Urja 2k23 Freshers Party Celebration Photo on 24 Nov 2023',
            src: `${process.env.PUBLIC_URL}/assets/img/new_images/gallery1/gallery30.jpg`, 
            link:'/gallery_details/urja-2k23-freshers-party-celebration-photo-on-24-nov-2023'},
         
    ]


    return (
       
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <MainTitle title="Event Gallery" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <Container>
                 <div className='gallerylist_home'>
                     <HomegalleryCompo notificationList={notificationList} />  
                 </div>
                 </Container>
            </Grid>
        </Grid>
    )
}

export default Homegallery
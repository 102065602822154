const First = () => {
    const glanceImage = []
    for (let i = 0; i <= 7; i++) {
        glanceImage.push({ image: `https://admission.mvn.edu.in/wp-content/uploads/2022/08/why-mvn-new-${i + 1}.jpeg` })
    }

    return (
        <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="animations-02">
                <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
            </div>
            <div className="container">
                <div className="row why-mvn-image" >
                    {glanceImage.map((imgsrc) => (
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <img src={imgsrc.image} style={{ width: '100%'}} />
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default First;
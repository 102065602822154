import React from 'react' 
import Bredcom from '../../Bredcom/Main' 
import Header from '../../headerone/Main' 
import First from './First' 
// import Brand from './Brand' 
 
function Pharmaceutical() { 
  return ( 
    <> 
        <Header/> 
        <Bredcom title="Home" subtitle="School of Pharmaceutical Sciences" /> 
        <First/> 
        {/* <Brand/>  */}
    </> 
  ) 
} 
 
export default Pharmaceutical 
import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const BMLT=()=>{
    const progHiglight=[
{listName:'Availability of scholarships covering up to 100% of expenses.'},
{listName:'Successful job placement showcasing a variety of opportunities with leading global hospitals, Diagnostics laboratories, and Microbiology laboratories.'},
{listName:'Partnerships providing skill-enhancement training to boost job readiness.'},
{listName:'Prospects for advanced studies or research in both domestic and international institutions'},
{listName:'Modern laboratories equipped with sophisticated instruments.'},
{listName:'Curriculum crafted by industry professionals and distinguished academics.'},
{listName:'Emphasis on project-based and experiential learning methodologies'},
{listName:'Access to e-learning platforms like MOOC, NPTEL, and Coursera'},
{listName:'Tailored training programs to prepare students for placement opportunities.'},
{listName:'Highly motivated faculties expert of the domain has vast research experience for experiential learning.'},
{listName:'Faculties have publications in SCI-E/WoS and Scopus indexed Journals of high impact factor.'},
{listName:'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships'},


        
    ]
    const feeStructureData=[
        {duration:'1st Year Fees','3 Years':'₹85200/- (Tuition Fee - ₹65000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','3 Years':'₹69200/- ( Tuition Fee – ₹65000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='3 years'
    const fee_1st= '85,200'
    const fee_2nd= '69,200'
    const eligibility='Pass in 10+2 (PCB/PCM) examination. Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="B.Sc. Medical Laboratory Technology" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    A medical laboratory professional (also referred to as a Medical Laboratory Technologist, a Clinical Laboratory Scientist or Clinical Laboratory Technologist) is a healthcare professional who performs chemical, hematological, immunologic, microscopic, and microbiological diagnostic analyses on body fluids such as blood, urine, sputum, stool, cerebrospinal fluid (CSF), peritoneal fluid, pericardial fluid, and synovial fluid, as well as other specimens. Medical laboratory scientists work in clinical laboratories at hospitals, reference laboratories, biotechnology laboratories and non-clinical industrial labs.
    <br/><br/>
Bachelor of Medical Laboratory Technology (BMLT) students have played a crucial role during the COVID-19 pandemic. They have been actively involved in diagnostic testing, sample collection, and laboratory analysis, contributing to the timely identification and monitoring of COVID-19 cases. These students have gained practical experience in handling various diagnostic tools and technologies, helping to strengthen healthcare systems in the face of the pandemic. Their role in supporting frontline healthcare professionals has been significant in controlling the spread of the virus. As the demand for healthcare services continues to grow, pursuing a BMLT course not only provides students with rewarding career opportunities but also allows them to make a meaningful impact on community well-being, especially during challenging times such as the current pandemic.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Pass in 10+2 (PCB/PCM) examination.
    <br/>
Minimum 55% aggregate marks for general category and 45% aggregate marks in case of SC/ST category.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The selection and ranking of candidates will be determined by their 12th-grade final examination scores. Aggregate in PCB/PCM will only be considered for merit list.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Why one should pursue BMLT?</b>
    <br/><br/>
It is obvious that the health care sector is among the fastest-growing sector and the rise of demand of professionals who are experts and have complete knowledge in handling various technical equipment that are used all over the clinics and hospitals concerns every so often. If you have a degree in BMLT, several job opportunities will open to you in various sectors.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.Sc. Medical Laboratory Technology
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default BMLT;
import React from 'react';
import { Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import MainTitle from '../../muiHome/mainTitle';
import CSEProgramList from './programms';

function Home() {
    const key_highlight=[
        'Curriculum aligning with AICTE standards and offering industry-relevant elective courses',
        'Curriculum crafted by industry professionals and distinguished academics.',
        'Partnerships providing skill-enhancement training to boost job readiness.',
        'Access to e-learning platforms like MOOC, NPTEL, Coursera, and IBM.',
        'Tailored training programs to prepare students for placement opportunities.',
        'Publication of over 100 research papers in both national and international journals and conferences.',
        'Support for securing patents, copyrights, engaging in funded projects, consultancy, and launching student startups.',
        'Organizing conferences, short-term training, workshops, webinars, online coding contests, project contests, and industry internships.',
        'Emphasis on project-based and experiential learning methodologies.',
        'Flexible credit system enabling students to pursue interdisciplinary minors.'
    ]
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <MainTitle title="Department Of CSE" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
                    Welcome to the Computer Science and Engineering (CSE) Department at MVN University! Here, we
                    offer a wide range of programs for anyone interested in technology, from beginners to those looking
                    to become experts. Our courses include Bachelor of Technology (B.Tech) in general Computer
                    Science and Engineering, and B.Tech with special focuses like Artificial Intelligence &amp; Machine
                    Learning, Data Science, and Cyber Security. We also have Bachelor of Science (B.Sc) in Computer
                    Science, Bachelor of Computer Applications (BCA), and BCA with a special focus on AI &amp; ML. For
                    those looking to go further in their career, we offer Master of Computer Applications (MCA) and
                    Master of Technology (M.Tech). In addition, for those who are interested in doing deep research in
                    computer science, we are offering Ph.D. program. Our programs are designed to give you a strong
                    foundation in the basics while also letting you explore the latest in technology. We aim to prepare you
                    to be a leader and innovator in the tech world, ready to solve new challenges and shape the future of
                    technology.
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <MainTitle title="Computer Science & Engineering Programmes" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <CSEProgramList />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                <MainTitle title="Key Highlights" />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
                {key_highlight.map((item,index)=>(
                <List sx={{ margin: 0, padding: 0 }}>
                    <ListItem disableGutters key={index}>
                        <ListItemText
                            primary={
                                <Typography component="span" variant="body1">
                                    &#8226;  {item}
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
                ))}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Vision" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
          The Computer Science and Engineering department is committed to continually improve the educational environment in order to develop professionals with strong technical and research backgrounds.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <MainTitle title="Mission" />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Typography
          textAlign="left"
          fontSize="14px"
          letterSpacing="0.5px"
          fontFamily="sans-serif"
        >
           To provide quality education in both theoretical and applied foundations of Computer Science and Engineering. Create highly skilled computer engineers, capable of being gainfully employed in the rapidly expanding Information Technology sector and also develop solutions for the betterment of the nation. To inculcate social sensitivity, economic prudence, professional and ethical values and environmental consciousness among students.
        </Typography>
      </Grid>
        </Grid>
    );
}

export default Home;

import { Grid, Container, Typography } from "@mui/material";
import SOETCSEHeader from "../header";
import BreadcrumbsBanner from "../../breadcrumbs";
import { useParams } from "react-router-dom";
import CSEBTech from "./bTech";
import CSEBTechAI from "./bTechAI";
import CSEBTechML from './bTechML'
import CSEBSC  from './bSc'
import CSEBCA  from './bca'
import CSEBCADS from './bcaDS'
import CSEBCAAI from './bcaAI';
import CSEBCAML from "./bcaML";
import CSEMCA from './mca'
import CSEMTech from './mTech';
import CSEBTechCyberSecurity from './bTechCyberSecurity'
import DepartmentWidgetsMenu from "../../departmentWidgetsMenu";
import NewMain from "../../../headerone/newMain";
import WidgetsMenu from "../../../muiCommonComponent/menuWidgets";
const CSECourses=()=>{
    const { course } = useParams();
    console.log('course::::::::',course)
    const courseName=()=>{
        let coursename=''
        if(course=='b-tech'){
            coursename=CSEBTech()
        }else if(course=='b-tech-ai'){
            coursename=CSEBTechAI()
        }
        else if(course=="b-tech-ml"){
            coursename=CSEBTechML()
        }else if(course=='bsc-it'){
            coursename=CSEBSC()
        }else if(course=='bca'){
            coursename=CSEBCA()
        }
        else if(course=="bca-ds"){
            coursename=CSEBCADS()
        }
        else if(course=='bca-ai'){
            coursename=CSEBCAAI()
        }else if(course=='bca-ml'){
            coursename=CSEBCAML()
        }
        else if(course=="mca"){
            coursename=CSEMCA()
        }
        else if(course=='m-tech'){
            coursename=CSEMTech()
        }
        else if(course=="mca"){
            coursename=CSEMCA()
        }
        else if(course=="b-tech-cs"){
            coursename=CSEBTechCyberSecurity()
        }
     
        return coursename
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/soet-cse/home', subHeader: [] },
        { mainHeader: 'About CSE', path: '#', subHeader: [] },
        { mainHeader: 'Academic Courses ',path: '#', subHeader: [
            { title: 'B.Tech Computer science & Engineering', path: '/soet-cse/course/b-tech', },
            { title: 'B.Tech CSE with specilisation in Artificial Intelligence', path: '/soet-cse/course/b-tech-ai', },
            { title: 'B.Tech CSE with specilisation in Machine Learning', path: '/soet-cse/course/b-tech-ml', },
            { title: 'B.Tech CSE with specilisation in Cyber Security', path: '/soet-cse/course/b-tech-cs', },
            { title: 'B.Tech civil Engineering',path:'/soet-civil/btech-civil'},
            { title: 'B.Tech CSE with specilisation in Robotics & Automation',path: ''},
            { title: 'B.Tech Electronics and Comunnication Engineering',path: '/soet-ece/btech-ece'},
            { title: 'M.Tech Computer science & Engineering', path: '/soet-cse/course/m-tech', },
            { title: 'B.Sc Computer science', path: '/soet-cse/course/bsc-it', },
            { title: 'BCA', path: '/soet-cse/course/bca', },
            { title: 'BCA with Specilisation in Data Science', path: '/soet-cse/course/bca-ds', },
            { title: 'BCA with Specilisation in Artificial Intelligence', path: '/soet-cse/course/bca-ai', },
            { title: 'BCA with Specilisation in Machine Learning', path: '/soet-cse/course/bca-ml', },
            { title: 'MCA', path: '/soet-cse/course/mca', },
        ] },
        { mainHeader: 'Laboratories ', path: '/soet-cse/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '#', subHeader: [] },
        { mainHeader: 'R & D', path: '/soet-cse/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/soet-cse/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/soet-cse/student-hub', subHeader: [] },
        { mainHeader: 'Our Alumni', path: '/soet-cse/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/soet-cse/intiative', subHeader: [] },
    ]
return (
<>
<NewMain/>
<WidgetsMenu headerMenus={headerMenus}/>
<BreadcrumbsBanner bannerUrl={"https://gnitipu.in/front_assets/images/Cse%20Cover.jpg"}/> 
<Container>
{courseName()}
</Container>
</>

)
}
export default CSECourses;
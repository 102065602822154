import { Container } from "@mui/material";
import { useParams } from "react-router-dom";
import BreadcrumbsBanner from "../breadcrumbs";
import SOETCIVILHeader from "./header";
import Home from "./home";
import NewMain from "../../headerone/newMain";
import DepartmentWidgetsMenu from "../departmentWidgetsMenu";
import ECEBTechStudentshub from "./studentshub";
import ECEBTechInitiative from "./initiative";
import ECEBTechAlumni from "./alumni";
import ECEBTechPlacement from "./training&placement";
import ECEBTechResearch from "./r&d";
import ECEBTechLab from "./lab";
import FacultyList from "./faculties/facultyList";
import WidgetsMenu from "../../muiCommonComponent/menuWidgets";
import FacultyDetail from "../../facultydetail/main";
import MEBTech from "./ME_courses/bTech";
const SOETCIVIL=()=>{
    const { screenname } = useParams();
    console.log('ECE scrennnnn:::::::',screenname)
    const screensName=()=>{
        let screen=''
        if(screenname=='home'){
            screen=Home()
        }else if(screenname=='student-hub'){
            screen=ECEBTechStudentshub()
        }else if(screenname=='intiative'){
            screen=ECEBTechInitiative()
        }
        else if(screenname=="our-alumi"){
            screen=ECEBTechAlumni()
        }
        else if(screenname=='t-p'){
            screen=ECEBTechPlacement()
        }else if(screenname=='r-d'){
            screen=ECEBTechResearch()
        }
        else if(screenname=="laboratory"){
            screen=ECEBTechLab()
        }
        else if(screenname=="faculties"){
            screen=FacultyList()
        }
        else if(screenname=="facultyDetail"){
            screen=FacultyDetail()
        }
        else if(screenname=="btech-me"){
            screen=MEBTech()
        }
        // else if(screenname=='bca-ai'){
        //     screen=CSEBCAAI()
        // }else if(screenname=='bca-ml'){
        //     screen=CSEBCAML()
        // }
        // else if(screenname=="mca"){
        //     screen=CSEMCA()
        // }
        // else if(screenname=='m-tech'){
        //     screen=CSEMTech()
        // }
        // else if(screenname=="mca"){
        //     screen=CSEMCA()
        // }
        // else if(screenname=="b-tech-cs"){
        //     screen=CSEBTechCyberSecurity()
        // }
     
        return screen
    }
    const headerMenus = [
        { mainHeader: 'Home ', path: '/soet-me/home', subHeader: [] },
        { mainHeader: 'About ME', path: '/soet-me/btech-me', subHeader: [] },
        // { mainHeader: 'Academic courses ',path: '#', subHeader: [
        //     { title: 'B.Tech Computer science & Engineering', path: '/soet-cse/course/b-tech', },
        //     { title: 'B.Tech CSE with specilisation in Artificial Intelligence', path: '/soet-cse/course/b-tech-ai', },
        //     { title: 'B.Tech CSE with specilisation in Machine Learning', path: '/soet-cse/course/b-tech-ml', },
        //     { title: 'B.Tech CSE with specilisation in Cyber Security', path: '/soet-cse/course/b-tech-cs', },
        //     { title: 'B.Tech civil Engineering',path:''},
        //     { title: 'B.Tech CSE with specilisation in Robotics & Automation',path: ''},
        //     { title: 'B.Tech Electronics and Comunnication Engineering',path: ''},
        //     { title: 'M.Tech Computer science & Engineering', path: '/soet-cse/course/m-tech', },
        //     { title: 'B.Sc Computer science', path: '/soet-cse/course/bsc-it', },
        //     { title: 'BCA', path: '/soet-cse/course/bca', },
        //     { title: 'BCA with Specilisation in Data Science', path: '/soet-cse/course/bca-ds', },
        //     { title: 'BCA with Specilisation in Artificial Intelligence', path: '/soet-cse/course/bca-ai', },
        //     { title: 'BCA with Specilisation in Machine Learning', path: '/soet-cse/course/bca-ml', },
        //     { title: 'MCA', path: '/soet-cse/course/mca', },
        // ] },
        { mainHeader: 'Laboratories ', path: '/soet-me/laboratory', subHeader: [] },
        { mainHeader: 'Faculty', path: '/soet-me/faculties', subHeader: [] },
        { mainHeader: 'R & D', path: '/soet-me/r-d', subHeader: [] },
        { mainHeader: 'T & P', path: '/soet-me/t-p', subHeader: [] },
        { mainHeader: 'Student Hub', path: '/soet-me/student-hub', subHeader: [] },
        // { mainHeader: 'Our Alumni', path: '/soet-me/our-alumi', subHeader: [] },
        { mainHeader: 'Initiative ', path: '/soet-me/intiative', subHeader: [] },
    ]
    return(<>
         <NewMain/>
        <WidgetsMenu headerMenus={headerMenus}/>
        <BreadcrumbsBanner bannerUrl={"/assets/img/SOET/me.jpg"}/> 
    <Container>
        {screensName()}
        {/* <Home/> */}
    </Container>
    </>)
}
export default SOETCIVIL;
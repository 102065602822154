import React, { useState } from 'react'
import Header from '../headerone/Main'
import Bredcom from '../Bredcom/Main'

function BscBiotechnology() {
    return (
        <>
            <Header />
            <Bredcom title="Home" subtitle="B.Sc. (Biotechnology)" />
            <section className="about-area about-p pt-120 pb-120 p-relative fix">
                <div className="animations-02">
                    <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
                </div>
                <div className="container">
                    <div className="row justify-content-center align-items-center">                        
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                                <div className="about-title second-title pb-25">                                    
                                    <h3>About Program</h3>
                                </div>
                                <p>
                                Bachelor of Science (B.Sc) in Biotechnology is one of the most popular undergraduate programmes in India. The course is the intersection of two broad subjects- Biology and Technology. In simple parlance, Biotechnology is a field of applied biology that makes use of living organisms or biological systems to make technological advances and use them in various fields. It basically seeks to improve the quality of human life and the health of the planet.
                                </p>

                                <p>
                                B.Sc in Biotechnology is a three-year undergraduate course divided into six semesters that provides detailed knowledge of the bi-molecular and cellular activities and be informed about the different technologies to study them. It includes a wide spectrum of subjects like genetics, molecular biology and chemistry.
                                </p>

                                <p>
                                Candidates who are fascinated by biological sciences and have good problem-solving skills and are proficient with computers should definitely go for this programme. Biotechnology graduates invite a number of high-paying job opportunities across the country. The sector is ever-evolving and after completing the course, one can become a research scientist, Biotech analysts, specialists in their respective fields.
                                </p>

                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Course Details</h3>
                                </div>
                                <p>
                                B.Sc. (Biotechnology) is an undergraduate course. Duration of this Academic program is 3 years (Exam Pattern – Semester System).
                                </p>

                                
                                
                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Eligibility Criteria</h3>
                                </div>
                                <p>
                                Candidates seeking admission to B.Sc in Biotechnology should have passed 10+2 with Physics, Chemistry and Biology as the mandatory subjects from a recognized board.
                                </p>    
                                

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>B.Sc in Biotechnology Career Options and Job Prospects</h3>
                                </div>
                                <p>
                                Biotechnology is the field that can never cease to evolve. This has resulted in an ever-increasing demand for B.Sc Biotechnology graduates and job opportunities for them abound across the country. One can easily find a preferred position in a number of sectors/industries like Drug and pharmaceutical research, Waste management, Chemicals, Energy, Food processing, Bio-processing industries, Animal Husbandry, Environment Conservation, Genetic, Engineering. Fresh graduates can expect a salary package ranging from Rs 2-5 LPA. However, the package keeps on increasing with the work experience of the candidate. B.Sc Biotechnology professionals can explore a wide range of career opportunities in both the private and government sectors:
                                </p> 

                                <ul className="pr-ul">
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Research Interns- R&D Jobs
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Safety Expert
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Bioinformist
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Clinical Research Jobs
                                        </div>    
                                    </li>

                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Production Jobs in Pharma Industry
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Scientific Writer
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Biotech Specialist
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        QA/QC Jobs
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Calibration Technician
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Research Analyst
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Scientific Assistant
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Biostatistician
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        R&D Intern Jobs in Govt Labs
                                        </div>    
                                    </li>
                                    <li> 
                                        <div className="icon">
                                            <i className="fal fa-check" />
                                        </div>
                                        <div className="text">  
                                        Academic Jobs in Colleges & Universities
                                        </div>    
                                    </li>
                                    
                                </ul>

                                <div className="about-title second-title pb-25 pt-25">                                    
                                    <h3>Scope</h3>
                                </div>
                                <p>
                                The course has tremendous scope and a very bright future. The scope of microbiology is immense since it is applied in many fields like Medical, Dairy, Pharmaceutical, Industrial, Clinical, research, water industry, agriculture, nanotechnology, chemical etc. The students, who pursue their career as a Microbiologist, get golden opportunities in government, research and industries (cosmetic/food/pharmaceutical).
                                </p> 

                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BscBiotechnology
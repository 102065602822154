import React from 'react';
import { Grid, } from '@mui/material';
import MainTitle from '../muiHome/mainTitle';
import ImagesSlider from '../muiCommonComponent/imageSlider';

const companyLogos = [
    {img:'/assets/img/SOET/google.png'},
    {img:'/assets/img/SOET/tech_mahindra.png'},
    {img:'/assets/img/SOET/infosys.png'},
    {img:'/assets/img/SOET/wipro.png'},
    {img:'/assets/img/SOET/eicher.png'},
    {img:'/assets/img/SOET/jcb.png'},
    {img:'/assets/img/SOET/escorts.png'},
    {img:'/assets/img/SOET/adobe.png'},
    // Add more logo URLs as needed
];

const RecuiterCompany = () => {

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <MainTitle title="Recuiter Companies" />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} >
                <ImagesSlider imgArr={companyLogos} />
            </Grid>
        </Grid>

    );
};

export default RecuiterCompany;

import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
const MBAPM=()=>{
    const progHiglight=[
{listName:'Rigorous Curriculum: Our MBA program offers a comprehensive curriculum that covers essential topics such as finance, marketing, operations, human resources, and strategic management.'},
{listName:'Experiential Learning: Emphasis on experiential learning through case studies, simulations, industry projects, and internships to bridge the gap between theory and practice.'},
{listName:'Expert Faculty: Learn from a diverse team of experienced faculty members and industry practitioners who bring real-world insights into the classroom.'},
{listName:'Personalized Mentorship: Receive personalized mentorship and guidance to help you chart your career path and achieve your professional goals.'},
{listName:'Networking Opportunities: Access to a vast network of alumni, industry professionals, and corporate partners for networking, internships, and job placements.'},
{listName:'Leadership Development: Develop leadership skills through workshops, seminars, and leadership development programs designed to nurture future business leaders.'},
{listName:'State-of-the-Art Facilities: Study in a modern campus equipped with state-of-the-art facilities, including libraries, computer labs, seminar halls, and recreational areas.'},
{listName:'Industry Partnerships: Benefit from our strong industry partnerships that provide opportunities for industry exposure, guest lectures, and corporate mentorship programs.'},
{listName:'Holistic Development: Focus on holistic development, including communication skills, teamwork, problem-solving abilities, and ethical decision-making.'},

]
    const feeStructureData=[
        {duration:'1st Year Fees','2 Years':'₹100200/- (Tuition Fee - ₹75000 + Other Charges- ₹25200/-)'},
        {duration:'2nd Year Onwards Fees','2 Years':'₹84200/- ( Tuition Fee – ₹75000 + Other Charges- ₹ 9200/-)'}
    ]
    const hostalFeeData=[
        {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
        {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
    ]
    const duration='2 years'
    const fee_1st= '1,00,200'
    const fee_2nd= '84,200'
    const eligibility='50% in Graduation in any Discipline.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} fee_2nd={fee_2nd} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="MBA- Master of Business Administration (Pharm Management)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    MBA programme in Pharmaceutical Management, two-year, full-time programme is designed to equip the students with the skills necessary to excel in pharmaceutical management. The industry-focused curriculum integrates management competencies with Pharma systems management, allowing students to effectively manage pharmaceutical industry including diagnostic chains, Drug manufacturing companies, medical devices companies and consultancy projects. Students will be exposed to a wide range of topics such as Pharmaceutical Distribution Management, Pharmaceutical event management & communication, Pharmaceutical International Business Management and other relevant courses. Our expert faculty members are passionate about sharing their knowledge and experience, ensuring that you receive the highest quality education. Our state-of-the-art facilities provide you with access to cutting-edge technology, enabling you to stay ahead of the curve. Upon completion of our programme, students will have access to lucrative career opportunities in the pharmaceutical management industry. MVN University's MBA program provides the platform for students to tackle the challenges that arise in the field of pharmaceutical management, making our students highly sought-after candidate in the job market. Join us and embark on a transformative journey towards success.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
50% in Graduation in any Discipline. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The comprehensive fee structure is designed to be transparent, helping you and your family plan effectively for all four years of the program. Below, you will find a detailed breakdown of tuition fees, including specific costs for the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    B.B.A.
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Ensure your comfort and convenience while studying at our institution with our range of hostel accommodations. We offer various options to suit your preferences, including triple seater rooms, both air-conditioned and non-air-conditioned, complete with meal plans. Below, you’ll find detailed information on our hostel fee structures for each option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default MBAPM;
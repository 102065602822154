import { Grid, Typography } from "@mui/material";
import MainTitle from "../../muiHome/mainTitle";
import BannerCoursesDurationFee from "../../muiCommonComponent/bannerCoursesDurationFee";
import { ListDataPresentation, TableDataPresentation } from "../../../utils/utils";
const LLM=()=>{
    const progHiglight=[
        {listName:'1. Specialized Focus: One-year LL.M. program offers focused study in a specific area of law, such as Constitutional and Administrative Law, Corporate and Commercial Law, Criminal Law. '},
        {listName:'2. Meticulous Curriculum: The curriculum is intensive, covering advanced legal theory, case studies, and practical applications relevant to the chosen specialization.'},
        {listName:'3. Proficient Faculty: Renowned professors and legal experts often lead LL.M. program, providing students with access to cutting-edge research and real-world insights.'},
        {listName:'4. Practical Experience: LL.M. program offers opportunities for hands-on learning through internships, externships, or clinics, enabling students to apply theoretical knowledge in real legal settings.'},
        {listName:'5. Networking Opportunities: Student shall have an edge from networking events, guest lectures, and alumni connections, fostering professional relationships within the legal community.'},
        {listName:'6. Career Advancement: Graduates of one-year LL.M. program shall gain specialized expertise that can enhance their career prospects, whether pursuing opportunities in law firms, corporations, government agencies, or academia.'},
        {listName:'8. Assessment: Evaluation includes: 30% Mid-term written examination  / 50% End-term written examination / 20% Assessment on various grounds.'},
        {listName:'9. Passing Criteria: The maximum grade point is 10 (Outstanding), and the minimum passing grade is 4. Students scoring less than 40% absolute marks in each category will be considered as fail.'},
        {listName:'10. Scholarship: Availability of scholarships as per the University norms and additional benefits for Girl students specifically.'},
        {listName:'11. Pathways to Further Education: The LL.M. degree serves as a stepping stone for further legal education, including pursuing a specialized certification, Ph.D. (Doctor of Philosophy) in law, or opening up diverse career opportunities in the legal field.'},
                
            ]
            const feeStructureData=[
                {duration:'1st Year Fees','1 Years':'₹95200/- (Tuition Fee - ₹70000 + Other Charges- ₹25200/-)'},
            ]
            const hostalFeeData=[
                {item:'Triple Seater with Meal','fee':'₹ 86000/-'},
                {item:'Triple Seater (AC) with Meal','fee':'₹110000/-'}
            ]
            const duration='1 years'
            const fee_1st= '95,200'
    const eligibility='The candidate should have graduation in Law from a recognised University with at least 50% marks in aggregate.'
return (
<Grid container spacing={2}>
<Grid item xs={12}>
<BannerCoursesDurationFee duration={duration} fee_1st={fee_1st} eligibility={eligibility}/>
    </Grid>
<Grid item xs={12}>
    <MainTitle title="LL.M. (Master of Laws)" />
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="About the Program" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    LL.M. (Master of Laws) is a post-graduate degree in Law. One year LL.M. Program offers an intensive and focussed study of law for those who already hold a degree in law. The condensed timeframe of one year provides a swift but comprehensive immersion in the chosen legal discipline, equipping graduates with enhanced expertise and credentials for their legal careers. It provides an opportunity for advanced legal education and specialisation in particular field of law. The curriculum includes a combination of core subjects, electives and research work. Students engage in in-depth analysis, research, writing, gaining expertise in their chosen area. 
<br/><br/>
    The School of Law is equipped with a team of dedicated and experienced faculties ever ready to guide the students in academic and extra co-curricular activities like Debate, Legal Quiz, Seminar, Conferences and Workshops etc. The programme will bridge the gap between classroom learning and teaching theory as well as focusing on practical knowledge. Completion of an LL.M. program can open doors to academia, legal researcher, advanced legal careers, or specialized legal practice.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Eligibility" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
   . Minimum Eligibility Criteria
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    To be eligible for admission to the LL.M. programme, a candidate should have graduation in Law from any recognised University. The selection and ranking of candidates will be determined by their performance in the CLAT/ LSAT/ law graduation final examination scores with personal interview. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" fontWeight={800} >
    Criteria For Selection
    </Typography>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    To be eligible for admission in the LL.B. programme, an applicant should have graduated/ post-graduated in any discipline of knowledge from a recognised University. The selection and ranking of applicant will be determined by their performance in the CLAT/ LSAT/ graduation/ post-graduation final examination scores with personal interview. 
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Programme Highlights" />
    </Grid>

    <Grid item xs={12}>
    <ListDataPresentation DataList={progHiglight}/>
    <br/>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Why one should pursue LLM?</b>
    <br/><br/>
It is obvious that the health care sector is among the fastest-growing sector and the rise of demand of professionals who are experts and have complete knowledge in handling various technical equipment that are used all over the clinics and hospitals concerns every so often. If you have a degree in LLM, several job opportunities will open to you in various sectors.

    </Typography>
    </Grid>

    <Grid item xs={12}>
    <MainTitle title="Fee Structure" />
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    The fee structure aims to provide clarity, empowering you and your family to plan effectively for the entire four-year program. Here, you'll discover a thorough breakdown of tuition fees, outlining costs for both the first year and subsequent years.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    LL.M.
    </Typography>
    <TableDataPresentation data={feeStructureData}/>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    Enhance your study experience at our institution by choosing from our array of hostel accommodations designed for your comfort and convenience. We provide diverse options to cater to your preferences, from triple-seater rooms, available in both air-conditioned and non-air-conditioned variants, to inclusive meal plans. Below, you'll discover comprehensive details on our hostel fee structures tailored to each accommodation option.
    </Typography>
    </Grid>

    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" alignItems={'center'} fontWeight={800}>
    Hostel Fee
    </Typography>
    <TableDataPresentation data={hostalFeeData}/>
    </Grid>
    <Grid item xs={12}>
    <Typography textAlign='left' fontSize="14px" letterSpacing="0.5px" fontFamily="sans-serif" >
    <b>Note:</b> Hostel Caution Money of ₹10,000/- One Time Payment (Refundable)
    </Typography>
    </Grid>
    </Grid>

)
}
export default LLM;
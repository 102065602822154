import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import First from './first'
import NewMain from '../../headerone/newMain'
function WhyMVN() {
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Why MVN" />
     <First/>
    </>
  )
}

export default WhyMVN
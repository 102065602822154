import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Header from '../../headerone/Main'
import { Link } from 'react-router-dom'
import First from './first'
import NewMain from '../../headerone/newMain'
function ExaminationCommittee() {
 /* const navigate=useNavigate()
  useEffect(() => {
     
      const pdfUrl = `${process.env.PUBLIC_URL}/assets/img/new_images/examination_cmmittee.pdf`;
      
      
      window.open(pdfUrl, '_blank');
      navigate(-1)
    }, []);*/
  return (
    <>
      <NewMain />
      <section className="breadcrumb-area d-flex  p-relative align-items-center" style={{ backgroundImage: "url(/assets/homepage/banner1.jpg)", backgroundSize: "100% 100%" }} >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12 col-lg-12">
              <div className="breadcrumb-wrap text-left">
                <div className="breadcrumb-title">
                  {/* <h2> {image?"":subtitle} </h2> */}
                </div>
              </div>
            </div>
            <div className="breadcrumb-wrap2">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/"> Home </Link>
                  </li> 
                  <li className="breadcrumb-item">
                    <Link to="/re-evalution-rules">Examination </Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>




      <section className="about-area about-p pt-60 pb-60 p-relative fix">
            <div className="container">
            <h2 class="text-center"> Examination Committee
            </h2>
            <br/> 
            </div>
                
            <div className="container">
                <div className="row" >
                    <div className="col-sm-12 col-md-12 col-lg-12">
                        

                    <div class="about-content s-about-content pl-15 wow fadeInRight animated" data-animation="fadeInRight" data-delay=".4s">
                      


                      <table class="table table-bordered" border="1" cellpadding="10" cellspacing="0">
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Name</th>
                            <th>Designation</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>Prof. (Dr.) N.P. Singh</td>
                            <td>Chairman</td>
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>Prof. (Dr.) Yogendra Singh</td>
                            <td>Member</td>
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>Prof. (Dr.) Vineet Kumar</td>
                            <td>Member</td>
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>Prof. (Dr.) Rahul Varshney</td>
                            <td>Member</td>
                          </tr>
                          <tr>
                            <td>5</td>
                            <td>Prof. (Dr.) Ashutosh Upadhayay</td>
                            <td>Member</td>
                          </tr>
                          <tr>
                            <td>6</td>
                            <td>Dr. Ruby Mittal</td>
                            <td>Member</td>
                          </tr>
                          <tr>
                            <td>7</td>
                            <td>Mr. Amit Sagu</td>
                            <td>Member</td>
                          </tr>
                          <tr>
                            <td>8</td>
                            <td>Mr. Daya Shankar Prasad</td>
                            <td>Member</td>
                          </tr>
                          <tr>
                            <td>9</td>
                            <td>Dr. Kuldeep Tanwar</td>
                            <td>Member Secretary</td>
                          </tr>
                        </tbody>
                      </table>


                  </div>

                        
                </div>
            </div>
            </div>
        </section>
    </>
  )
}

export default ExaminationCommittee
import React from 'react'
import Header from '../../headerone/Main'
import Bredcom from '../../Bredcom/Main'
import NewMain from '../../headerone/newMain'

function Main() {
  return (
    <>
      <NewMain />
      <Bredcom title="Home" subtitle="Chancellor’s Message" image="/assets/homepage/chancellor_banner.jpg"/>
      <section className="about-area about-p pt-60 pb-60 p-relative fix">
        <div className="animations-02">
          <img src="/assets/img/bg/an-img-02.png" alt="contact-bg-an-01" />
        </div>
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-4 col-md-12 col-sm-12">
              <div className=" p-relative  wow fadeInLeft animated" data-animation="fadeInLeft" data-delay=".4s" >
                <img src="/assets/img/chancellor_imge.jpg" alt="img" className='crc-img'/>
                <div className="crc-about">
                  <p><strong>MR. VARUN SHARMA </strong> <br/>Chancellor, MVN University   </p> 
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12">
              <div className="about-content s-about-content pl-15 wow fadeInRight  animated" data-animation="fadeInRight" data-delay=".4s" >
                <div className="about-title second-title pb-25">
                  <h5>
                    <i className="fal fa-graduation-cap" /> Chancellor’s Message
                  </h5>
                  <h2>The person who wins is the one who thinks he can.</h2>
                </div>
                 

                <p> 
                It is with great pleasure and pride that I extend my warmest greetings to all of you. As we prepare to embark on another exciting journey, I am delighted to share my thoughts and sentiments in this message for our Students and Recruiters. The journey of education is not only about acquiring knowledge but also about nurturing the skills and talents that transform our students into responsible, well-rounded individuals who can make a meaningful contribution to society. At MVN University, we always strived to provide an environment that not only imparts knowledge but also fosters holistic growth. 

                <br/><br/>
                
                I have witnessed the relentless dedication of our students, the unwavering commitment of our faculty, and the tireless efforts of our staff to maintain and enhance the high standards we have set for ourselves. I am proud of our alumni, who have carved successful careers in diverse fields and are now contributing positively to industry and society. Our placement record is a testament to the quality of education and the dedication of our students and faculty. This year, we are excited to present another cohort of highly capable and motivated individuals ready to make their mark in the corporate world for which we seek your support. I extend my best wishes to all our students in their endeavours, and I am confident that they will make us all proud.

  
                </p>              
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Main